import AccordionSection from "../../components/kalorieKompassComponents/main/home/dietPlanAccordin";
import { Grid } from "@material-ui/core";

const FoodAdder = ({ selectedButtonContent, meals }) => {
  return (
    <Grid container spacing={2}>
      {Object.keys(meals).map((mealType) => (
        <Grid
          item
          xs={12} // For mobile devices
          sm={12} // For tablets and up
          md={6} // For medium screens and up
          lg={4} // For large screens and up
          key={mealType}
        >
          <AccordionSection
            mealType={mealType}
            selectedButtonContent={selectedButtonContent}
            meals={meals[mealType]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FoodAdder;
