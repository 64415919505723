import Faqs from "../../commonScreens/faqs";
import Header from "../../components/kalorieKompassComponents/starterpage/headerPage";
import constants from "../../constants.json";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import axios from "axios";
import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const StarterPage = () => {
  const [faqsList, setFaqsList] = useState([]);
  useEffect(() => {
    const getitems =
      "https://gzu79b0vyi.execute-api.eu-north-1.amazonaws.com/develop/faqs";
    axios
      .get(getitems, {
        headers: {
          authorizationToken:
            "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
        },
      })
      .then((response) => {
        if (response.data) {
          setFaqsList(response.data.body);
          console.log("tryyy", response.data.body);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <>
      <Header />
      <div class="max-w-screen self-center border-t border-gray-400 flex justify-center">
        <h1 className="text-4xl font-bold text-center p-3 text-primaryColor-light">
          {constants.Faqs}
          <div class="max-w-sm border-t-2 border-orangeColor"></div>
        </h1>
      </div>
      <div
        style={{
          //   backgroundImage:
          //     "linear-gradient(90deg, rgba(255, 209, 113, 0.6) 50%, rgba(255, 113, 119, 0.4) 50%)",
          // width: "100%",
          // minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            width: "80%",
            // width: "8s0%",
          }}
        >
          {/* <div style={{ justifyContent: "center", alignItems: "center" }}>
            <h1 className="blog__info p-3">FAQs</h1>
          </div> */}

          {!faqsList ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <div
              className=""
              style={{
                padding: "10px",
                justifyContent: "center",
                alignself: "center",
              }}
            >
              <Faqs faqsList={faqsList} />
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default StarterPage;
