import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Typography,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Box,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useKalorie } from "../../userContext/context";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import constants from "../../constants.json";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import useStyles from "../../assets/css/common.js";
import logo from "../../assets/images/logo.png";
const InfoPage2 = () => {
  const classes = useStyles();
  const { data, updateData } = useKalorie();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    updateData("image", file);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateData(name, value);
  };

  useEffect(() => {
    console.log(data); // Log the updated data after state update
  }, [data]); // This effect runs whenever the 'data' state changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isError = false; // Flag to track if there's an error

    if (!data.medications) {
      setError("Please enter your medications (if any), else please type none");
      isError = true;
    }

    if (!data.kind_of_allergies) {
      setError(
        "Please enter the detials of the allergies you have (if any), else please type none"
      );
      isError = true;
    }
    if (!data.kind_of_work) {
      setError("Please enter the type of work you do e.g. desk job");
      isError = true;
    }
    if (!data.physical_activity_level) {
      setError("Please select your level of physical activity");
      isError = true;
    }

    if (!isError) {
      setError("");

      navigate("/familyHistory");
    }
  };
  const goback = () => {
    navigate(-1);
  };

  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={classes.outercontainer_info2}
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            variant="h4"
            className={classes.text1}
          >
            <img
              style={{ marginRight: "10px" }}
              src={logo}
              alt="Logo is missing"
            />
            {constants.appTitle}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon size="small" />}
            className={classes.buttonstyle3}
            onClick={goback}
          >
            Back
          </Button>
        </Grid>
        <Grid item container>
          <Grid item className={classes.triangleRight}></Grid>
          <Grid item className={classes.triangleLeft}></Grid>
          <Grid className={classes.outerGrid} container>
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              className={`${classes.mainContainer} ${classes.physicalContainer}`}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit} // This handles the form submission
                className={classes.innerBox}
              >
                <div>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel
                      id="demo-simple-select-standard-label"
                      className={classes.inputLabel}
                    >
                      Level of Physical Activity
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={data.physical_activity_level}
                      onChange={handleChange}
                      label=" Level of Physical Activity"
                      className={classes.input}
                      name="physical_activity_level"
                      MenuProps={{
                        classes: { icon: classes.dropdownIcon },
                      }}
                    >
                      <MenuItem value="male" className={classes.dropdownItem}>
                        Mild
                      </MenuItem>
                      <MenuItem value="female" className={classes.dropdownItem}>
                        Moderate
                      </MenuItem>
                      <MenuItem value="female" className={classes.dropdownItem}>
                        Extreme
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-firstname"
                      className={classes.inputLabel} // Apply custom styles to the label
                    >
                      Kind of Work
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-firstname"
                      type="text"
                      name="kind_of_work"
                      value={data.kind_of_work}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-firstname"
                      className={classes.inputLabel} // Apply custom styles to the label
                    >
                      Any Kind of Allergies
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-firstname"
                      type="text"
                      name="kind_of_allergies"
                      value={data.kind_of_allergies}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-firstname"
                      className={classes.inputLabel} // Apply custom styles to the label
                    >
                      Medication
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-firstname"
                      type="text"
                      name="medications"
                      value={data.medications}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                    />
                  </FormControl>
                </div>
                {error && (
                  <Typography className={classes.errorText}>{error}</Typography>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  align="center"
                  className={[classes.buttonstyle_info2, classes.continueStyle]}
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage2;
