import React from "react";
import Typography from "@mui/material/Typography";
import constants from "../../constants.json";
function CustomTypography({
  text,
  color,
  fontSize,
  marginLeft,
  fontWeight,
  marginTop,
  marginRight,
  marginBottom,
  minWidth,
  ...restProps
}) {
  const customStyle = {
    color: color || "inherit", // Default to inherit if no color is provided
    fontSize: fontSize || "inherit", // Default to inherit if no fontSize is provided
    fontFamily: constants.fontFamily,
    marginLeft: marginLeft || "inherit",
    fontWeight: fontWeight || "inherit",
    marginTop: marginTop || "inherit",
    marginRight: marginRight || "inherit",
    marginBottom: marginBottom || "inherit",
    minWidth: minWidth || "inherit",
  };

  return (
    <Typography style={customStyle} {...restProps}>
      {text}
    </Typography>
  );
}

export default CustomTypography;
