import React from "react";
import {
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Paper,
  Modal,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import "../../../styles/ckEditor.css";
import constants from "../../../constants.json";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { WhatsappShareButton } from "react-share";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    marginRight: theme.spacing(1),
  },
  modalContent: {
    position: "absolute",
    width: "45%", // Adjust the width as needed
    maxWidth: 350, // Set a maximum width if necessary
    backgroundColor: constants.orange1,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto", // Enable scrolling if content exceeds modal height
    maxHeight: "80%", // Set a maximum height if necessary
    color: constants.black,
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: "#FF0000", // Red color for the close icon
  },
  shareButton: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    color: "#38A389", // Green color for the WhatsApp icon
  },
  searchContainer: {
    backgroundColor: "rgba(110, 205, 231, 0.26)",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%", // ensure full width
    height: "100%",
  },

  autocomplete: {
    width: "auto", // let it be automatically sized
    maxWidth: "550px", // maximum width you prefer
    flexGrow: 1, // allow it to grow if needed

    "& .MuiFilledInput-root": {
      paddingRight: 0,
      borderRadius: 15,
      backgroundColor: "#fff",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: theme.spacing(0.5),
    },
  },
  searchIcon: {
    color: "#31525B", // icon color
    marginBottom: 15,
    marginRight: 15,
  },
  inputLabel: {
    color: "rgba(12, 163, 205, 0.88)", // The color you want for the label
    "&.Mui-focused": {
      color: "#757575", // Color of the label on focus (if you want it to change)
    },
  },

  tableRow: {
    marginBottom: "0.6rem", // Adjust margin as needed
  },
}));

const SearchSection = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Function to handle search API call
  const handleSearch = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(apiUrl + "/api/food-items", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      console.log(result);

      if (result.status === true) {
        console.log("response from header", result.foodItems);
        setSearchResults(result.foodItems);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
  };
  useEffect(() => {
    handleSearch();
  }, [searchQuery, searchResults]);
  // Function to handle item selection and open modal
  const handleItemSelected = (value) => {
    const selectedFood = searchResults.find((food) => food.food_name === value);
    setSelectedItem(selectedFood);
    setIsModalOpen(true);
  };
  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const classes = useStyles();
  return (
    <>
      <Grid
        item
        xs={12}
        md={6}
        lg={12}
        container
        justifyContent="center"
        className={classes.searchContainer}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={searchResults.map((food) => food.food_name)}
          onChange={(event, newValue) => {
            setSearchQuery(newValue);
            handleItemSelected(newValue);
          }}
          className={classes.autocomplete}
          renderInput={(params) => (
            <TextField
              {...params}
              label={constants.search}
              id="outlined-basic"
              variant="filled"
              type="text"
              margin="normal"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
            />
          )}
        />
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Paper className={classes.modalContent}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" id="modal-title">
            {selectedItem ? selectedItem.food_name : ""}
          </Typography>
          {selectedItem && (
            <Box>
              {/* Display other properties of the selected item */}
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr className={classes.tableRow}>
                    <td>
                      <strong>{constants.calories}:</strong>
                    </td>
                    <td>
                      <strong>{selectedItem.calories_kcal} kcal</strong>
                    </td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td>{constants.proteins}:</td>
                    <td>{selectedItem.protein_g} g</td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td>{constants.carbs}:</td>
                    <td>{selectedItem.carbs_g} g</td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td>{constants.fats}:</td>
                    <td>{selectedItem.fats_g} g</td>
                  </tr>
                </tbody>
              </table>
            </Box>
          )}
          <WhatsappShareButton
            url={window.location.href}
            title={`Check out ${
              selectedItem ? selectedItem.food_name : ""
            }'s details!`}
            className={classes.shareButton}
          >
            <IconButton
              aria-label={constants.share}
              className={classes.shareButton}
            >
              <WhatsAppIcon />
            </IconButton>
          </WhatsappShareButton>
        </Paper>
      </Modal>
    </>
  );
};

export default SearchSection;
