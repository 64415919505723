import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core/";
import { useTheme } from "@mui/material/styles";
import maam from "../../../assets/images/ma'amSidra.jpg";
import awais from "../../../assets/images/awais.jpeg";
import sir from "../../../assets/images/sir1.png";
import hania from "../../../assets/images/hania.png";
import hajrah from "../../../assets/images/hajrah.jpeg";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import constants from "../../../constants.json";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      backgroundColor: "#FDA101",
      paddingBottom: theme.spacing(5),
      fontFamily: constants.fontFamily, // Consistent font family
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    imagePaper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      padding: 0,
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "80%",
        margin: "0 auto",
      },
    },
    image: {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
    highlightedImagePaper: {
      border: `4px solid ${constants.blueTextColor}`,
      boxShadow: "0px 10px 20px rgba(0,0,0,0.2)",
    },
    reviewContainer: {
      height: "50vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
      backgroundColor: "#FDA101",
      fontFamily: constants.fontFamily, // Consistent font family
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        padding: theme.spacing(1),
      },
    },
    reviewText: {
      textAlign: "center",
      fontSize: "3vh",
      color: constants.blueTextColor,
      fontFamily: constants.fontFamily, // Consistent font family
      lineHeight: "1.2",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.5vh",
      },
    },
    header: {
      paddingTop: theme.spacing(5),
      backgroundColor: "#FDA101",
      color: "white",
      textAlign: "center",
      width: "100%",
      marginBottom: 0,
      fontFamily: constants.fontFamily, // Consistent font family
      fontSize: "4.6vh",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "3.5vh",
        paddingTop: theme.spacing(3),
      },
    },
    progress: {
      color: "white",
    },
    root: {
      "& .MuiMobileStepper-dotActive": { color: "red" },
      "& .MuiMobileStepper-dot": { color: "green" },
    },
  })
);

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const reviews = [
  {
    id: 1,
    name: "Awais",
    image: awais,
    review:
      "“KalorieKompass has been a game changer for me. The simple and user-friendly interface makes tracking meals much easier and the personalized recommendations keep me on the right track.”",
  },
  {
    id: 2,
    name: "Sidra",
    image: maam,
    review:
      '"Kaloriekompass has helped me a lot being conscious of the macro and micro nutrients I am consuming. After all, “We are what we eat”."',
  },
  {
    id: 3,
    name: "Hajrah",
    image: hajrah,
    review:
      "“KalorieKompass provides valuable insights into nutrition. Highly recommend for anyone on a health journey”",
  },
  {
    id: 4,
    name: "Asad",
    image: sir,
    review:
      "“KalorieKompass gave me a wake up call to the way I was eating and made things clear which I needed to change”",
  },
  {
    id: 5,
    name: "Hania",
    image: hania,
    review:
      "“KalorieKompass gave me a wake up call to the way I was eating and made things clear which I needed to change”",
  },
];

const ExperienceSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = reviews.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Grid className={classes.container} container>
      <Grid container style={{ maxWidth: "55%" }}>
        <Grid
          container
          sm={12}
          md={6}
          lg={6}
          spacing={1}
          style={{ flexGrow: 1, justifyContent: "center" }}
        >
          <Grid item sm={12} md={12} lg={12}>
            <Typography variant="h4" className={classes.header}>
              What our customers say....
            </Typography>
          </Grid>

          {!isMatch ? (
            reviews.map((review, index) => (
              <Grid
                key={index}
                item
                sm={6}
                md={4}
                lg={4}
                className={classes.imagePaper}
              >
                <Paper
                  className={
                    activeStep === index ? classes.highlightedImagePaper : ""
                  }
                  elevation={activeStep === index ? 8 : 1}
                >
                  <img
                    src={review.image}
                    alt={review.name}
                    className={classes.image}
                  />
                </Paper>
              </Grid>
            ))
          ) : (
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              style={{ padding: "50px" }}
              className={classes.imagePaper}
            >
              <Paper className={classes.highlightedImagePaper} elevation={8}>
                <img
                  src={reviews[activeStep].image}
                  alt={reviews[activeStep].name}
                  className={classes.image}
                />
              </Paper>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          sm={12}
          md={6}
          lg={6}
          direction="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={10}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {reviews.map((step, index) => (
                <div
                  key={step.name}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{ backgroundColor: "#FDA101" }}
                    className={classes.reviewContainer}
                  >
                    <Typography variant="h6" className={classes.reviewText}>
                      {step.name}
                    </Typography>
                    <Typography className={classes.reviewText}>
                      {step.review}
                    </Typography>
                  </Paper>
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              style={{ backgroundColor: "#FDA101" }}
              variant="dots"
              position="static"
              activeStep={activeStep}
              className={classes.root}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExperienceSection;
