import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import constants from "../constants.json";
const useStyles = makeStyles((theme) => ({
  text5: {
    color: "#A87DB4 !important",
    fontFamily: constants.fontFamily,
    fontSize: "1.8rem",
    fontWeight: "bold",
    marginTop: "-60px",
  },
}));

function MealPieChartComp({ email, mealType }) {
  const [pieChartDetails, setPieChartDetails] = useState([]);
  const [err, setErr] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const classes = useStyles();

  async function fetchData() {
    try {
      const response = await fetch(apiUrl + "/api/meal-type-tracker", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          diet_plan_id: 1,
          month: 1,
          week: 1,
          day: 1,
          meal_type: "Breakfast",
        }),
      });

      const result = await response.json();
      console.log(result.status);

      if (result.status === true) {
        console.log("response from meal-type-tracker", result);
        console.log("result.results[0]", result.results[0]);
        const colors = ["#FF7171", "#EFE0BB", "#38A389", "#C7934E"];
        const data = Object.keys(result.results[0])
          .filter((key) => key.startsWith("total_"))
          .map((key, index) => ({
            title: key.replace("total_", ""),
            value: parseFloat(result.results[0][key]),
            color: colors[index % colors.length],
          }))
          .filter((entry) => entry.value !== 0); // Filter out entries with value 0
        console.log("data111:", data);
        setPieChartDetails(data);
      } else {
        console.log("error in meal type tracker");
        setErr(true);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
  }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div
      style={{
        width: "300px",
        height: "300px",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {err ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",

            alignItems: "center",
            textAlign: "center",
            marginLeft: "-25px",
          }}
        >
          <Typography className={classes.text5}>No meal added</Typography>
        </div>
      ) : (
        <PieChart
          animate
          animationDuration={40}
          animationEasing="ease-in"
          center={[33, 33]}
          data={pieChartDetails}
          lineWidth={100}
          lengthAngle={360}
          paddingAngle={0}
          radius={27}
          startAngle={0}
          viewBoxSize={[80, 80]}
          labelPosition={62}
          labelStyle={{
            fontSize: "4px",
            fontColor: "#FFFFFA",
            fontWeight: "bold",
            fontFamily: constants.fontFamily,
            padding: "10px",
          }}
          label={(data) =>
            `${data.dataEntry.title}\n${Math.round(data.dataEntry.percentage)}%`
          }
          reveal
        />
      )}
    </div>
  );
}

export default MealPieChartComp;
