import {
  Container,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import axios from "axios";
import { useState, useEffect } from "react";
import IngredientsTracker from "./ingredientstracker";
import FoodAdder from "./foodadder";
import { useKalorie } from "../../../../userContext/context";
import { Store } from "react-notifications-component";
import constants from "../../../../constants.json";
import useStyles from "../../../../assets/css/dietPlan";
const serverIp = process.env.REACT_APP_SERVERIP;
const port = process.env.REACT_APP_PORT;
const userObject = JSON.parse(localStorage.getItem("user"));

const ProgressTracker = ({
  setDurationtype,
  setActiveButton,
  durationtype,
  activeButton,
}) => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hour = date.getHours();
  let minutes = date.getMinutes();
  const currentDate = year + "-" + month + "-" + day;
  const classes = useStyles();
  const {
    data,
    updateData,
    totalCalories,
    setTotalCal,
    recCalories,
    setRecCalories,
  } = useKalorie();
  const [selectedFormattedDate, setSelectedFormattedDate] = useState("");
  const [reload, setReload] = useState(false);
  const [diet, setDiet] = useState();
  const [rec, setRec] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [firstDayObject, setFirstDayObject] = useState();
  const [diffDays, setDiffDays] = useState();
  const [weekOfToday, setWeekOfToday] = useState();
  const [foodObjects, setFoodObjects] = useState([]);
  const handleChange = (event) => {
    console.log("kooooo", event.target.value);
    setDurationtype(event.target.value);
    updateData("week", event.target.value);
  };
  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };
  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    const fetchDietDetails = async () => {
      const apiUrl = `${serverIp}:${port}/api/weekly-meal-calories`;
      console.log("im data:::::", data);
      const userObject = JSON.parse(localStorage.getItem("user"));
      const req_data = {
        user_id: userObject.user_id,
        week: durationtype.charAt(durationtype.length - 1),
      };
      await axios
        .post(apiUrl, req_data)
        .then((response) => {
          console.log("user", userObject);
          console.log("response from dietdetails", response.data);
          if (response.status === 200) {
            setTotalCal(response.data.results[0].total_calories);
            console.log(
              "items.total_calories",
              response.data.results[0].total_calories
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    fetchDietDetails();

    if (recCalories - totalCalories < 0) {
      Store.addNotification({
        title: "No more calories left!",
        message: "You've exeeded your dialy recommended calorie intake!",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
    if (recCalories - totalCalories == 500) {
      Store.addNotification({
        title: "500 kcal left!",
        message: "You are near your dialy goal of calories",
        type: "warning",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }, [recCalories, totalCalories]);

  const handleButtonClick = (index) => {
    setActiveButton(index);
    console.log("index", index);
    updateData("day", index + 1);

    if (durationtype === "1") {
      const day = index + 1;
      const date = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        day
      );
      const formattedDate = date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
      setSelectedFormattedDate(formattedDate);
    }
  };
  async function fetchRecommendedCalories() {
    var response = await axios.get(
      `${serverIp}:${port}/api/get-recommended-calories/${userObject.user_id}`
    );
    console.log("response1111", response.data.RecommendedCalories);

    setRec(response.data.RecommendedCalories);
    setRecCalories(
      response.data.RecommendedCalories.recommended_calories_intake
    );
  }
  async function getMealTrackerData() {
    console.log("wowww1111", data.diet_plan_id, {
      diet_plan_id: data.diet_plan_id,
      month: 1,
      week: durationtype,
      day: activeButton + 1,
    });
    try {
      var response = await fetch(
        `${serverIp}:${port}/api/weekly-meal-schedule-client`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            diet_plan_id: data.diet_plan_id,
            week: durationtype,
            day: activeButton + 1,
          }),
        }
      );
      const result = await response.json();
      setDiet(result);
      console.log("newresponse1111212", result);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
    console.log("nooo", diet);
  }
  async function fetchFoodNames() {
    const apiUrl = `${serverIp}:${port}/api/get-allowed-food-items/${data.diet_plan_id}`;
    axios
      .get(apiUrl)
      .then((response) => {
        console.log("abcd", response.data);
        if (response.data.status === true) {
          const foodNames = response.data.foodNames.map(
            (item) => item.food_name
          );
          setFoodObjects(foodNames);
          console.log("watchh", foodNames);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log("waittttt", error.response.status);
      });
  }
  useEffect(() => {
    fetchRecommendedCalories();
    getMealTrackerData();
    fetchFoodNames();
    // Calculate today's date
    const today = new Date();
    const todayIndex = today.getDate() - 1;
    const todayWeek = Math.ceil(todayIndex / 7); // Calculate the current week
    const todayMonth = today.getMonth(); // Get the current month index

    // Set the active button based on the current duration type
    if (durationtype === "days") {
      // setActiveButton(todayIndex);
    } else if (durationtype === "weeks") {
      setActiveButton(todayWeek);
    } else if (durationtype === "months") {
      setActiveButton(todayMonth);
    }

    // Format today's date
    const formattedDate = today.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
    });
    setSelectedFormattedDate(formattedDate);
  }, [durationtype, activeButton, reload, weekOfToday]);

  useEffect(() => {
    function fetchFirstDateOfMeal() {
      const apiUrl = `${serverIp}:${port}/api/get-first-diet-plan-day/${data.diet_plan_id}`;
      axios
        .get(apiUrl)
        .then((response) => {
          console.log("abcd", response.data);
          setFirstDayObject(response.data);
          // const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

          let firstDate = new Date(response.data?.first_day);
          let secondDate = new Date(currentDate);
          let daysDiff = Math.round(
            Math.abs((firstDate - secondDate) / 86400000)
          );
          console.log("diff", daysDiff, firstDayObject);
          if (daysDiff < 7) {
            setDurationtype("1");
            setDiffDays(daysDiff);
            setActiveButton(daysDiff);
            setWeekOfToday("1");
          } else if (daysDiff >= 7 && daysDiff < 14) {
            setDurationtype("2");
            setDiffDays(daysDiff - 7);
            setActiveButton(daysDiff - 7);
            setWeekOfToday("2");
          } else if (daysDiff >= 14 && daysDiff < 21) {
            setDurationtype("3");
            setDiffDays(daysDiff - 14);
            setActiveButton(daysDiff - 14);
            setWeekOfToday("3");
          } else if (daysDiff >= 21 && daysDiff < 28) {
            setDurationtype("4");
            setDiffDays(daysDiff - 21 && daysDiff < 28);
            setActiveButton(daysDiff - 21);
            setWeekOfToday("4");
          } else if (daysDiff > 28) {
            setDurationtype("5");
            setDiffDays(daysDiff - 28);
            setActiveButton(daysDiff - 28);
            setWeekOfToday("5");
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.log("abcd", error.response.data);
          setFirstDayObject(error.response.data);
          console.log("error from dietdetials", error);
          console.log("waittttt", error.response.status);
        });
    }

    fetchFirstDateOfMeal();
  }, []);

  const renderButtons = () => {
    console.log("newdate", durationtype);
    if (firstDayObject?.status === true) {
      let dateObject = new Date(firstDayObject?.first_day);
      let result;
      dateObject.setDate(dateObject.getDate() + (durationtype - 1) * 7);
      dateObject.setDate(dateObject.getDate() - 1);

      return Array.from({ length: 7 }, (_, index) => {
        console.log("asani", index);
        dateObject.setDate(dateObject.getDate() + 1);
        result = dateObject.toISOString().split("T")[0];
        return (
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <Button
              key={`day-${index + 1}`}
              variant="outlined"
              className={`${classes.weekButton} ${
                activeButton === index ? classes.activeButton : ""
              }`}
              onClick={() => handleButtonClick(index)}
            >
              {constants.day} {index + 1}
            </Button>
            <Divider />
            <p className={activeButton === index ? classes.weekButtonDate : ""}>
              {result}
            </p>
          </div>
        );
      });
    } else {
      setActiveButton(0);
      let dateObject = new Date(currentDate);
      let result;
      dateObject.setDate(dateObject.getDate() + (durationtype - 1) * 7);
      if (durationtype === "1") {
        dateObject.setDate(dateObject.getDate() + 1);
        return (
          <>
            <div style={{ justifyContent: "center", alignItems: "center" }}>
              <Button
                key={`day-${1}`}
                variant="outlined"
                className={`${classes.weekButton} ${classes.activeButton}`}
                onClick={() => handleButtonClick(0)} // Index adjusted to 0 for Day 1
              >
                {constants.day} 1
              </Button>
              <Divider />
              <p className={classes.weekButtonDate}>{currentDate}</p>
            </div>
            {Array.from({ length: 6 }, (_, index) => {
              dateObject.setDate(dateObject.getDate() + 1);
              result = dateObject.toISOString().split("T")[0];
              return (
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <Button
                    key={`day-${index + 2}`}
                    variant="outlined"
                    className={`${classes.weekButton} ${
                      activeButton === index + 1 ? classes.activeButton : ""
                    }`}
                    onClick={() => handleButtonClick(index + 1)}
                  >
                    {constants.day} {index + 2}
                  </Button>
                  <Divider />
                  <p>{result}</p>
                </div>
              );
            })}
          </>
        );
      } else {
        return Array.from({ length: 7 }, (_, index) => {
          console.log("asani", index);
          dateObject.setDate(dateObject.getDate() + 1);
          result = dateObject.toISOString().split("T")[0];
          return (
            <div style={{ justifyContent: "center", alignItems: "center" }}>
              <Button
                key={`day-${index + 1}`}
                variant="outlined"
                className={`${classes.weekButton} ${
                  activeButton === index ? classes.activeButton : ""
                }`}
                onClick={() => handleButtonClick(index)}
              >
                {constants.day} {index + 1}
              </Button>
              <Divider />
              <p>{result}</p>
            </div>
          );
        });
      }
    }
  };

  return (
    <div>
      <Container className={classes.dietTrackerContainer}>
        <Container className={classes.horizontalContainer}>
          <Typography className={classes.inputLabel}>
            {durationtype === "days"
              ? "Daily "
              : durationtype === "weeks"
              ? "Weekly "
              : "Monthly "}
            {constants.goal}
          </Typography>

          <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={durationtype}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className={classes.inputLabel}
                MenuProps={{
                  classes: { icon: classes.dropdownIcon },
                }}
              >
                <MenuItem
                  value={"1"}
                  className={`${classes.dropdownItem} ${
                    durationtype === "1" && activeButton === null
                      ? classes.activeButton
                      : ""
                  }`}
                >
                  {constants.week}-1
                </MenuItem>
                <MenuItem value={"2"} className={classes.dropdownItem}>
                  {constants.week}-2
                </MenuItem>
                <MenuItem value={"3"} className={classes.dropdownItem}>
                  {constants.week}-3
                </MenuItem>
                <MenuItem value={"4"} className={classes.dropdownItem}>
                  {constants.week}-4
                </MenuItem>
                <MenuItem value={"5"} className={classes.dropdownItem}>
                  {constants.week}-5
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </Container>
        <Container
          style={{
            paddingLeft: "25%",
          }}
        >
          <div className={classes.buttonContainer}>{renderButtons()}</div>
        </Container>
        {rec && (
          <>
            <IngredientsTracker
              durationtype={durationtype}
              selectedButtonContent={
                activeButton !== null
                  ? {
                      label: selectedFormattedDate,
                      index: activeButton + 1,
                    }
                  : null
              }
              reload={reload}
              rec={rec}
            />
            {diet && (
              <>
                <FoodAdder
                  durationtype={durationtype}
                  selectedButtonContent={{
                    label: selectedFormattedDate,
                    index: activeButton + 1,
                  }}
                  rec={rec}
                  setReload={setReload}
                  reload={reload}
                  diet={diet}
                  foodObjects={foodObjects}
                />
              </>
            )}
          </>
        )}
        {/* {rec && (
          <>
            <CaloriesSidebar
              open={sidebarOpen}
              handleClose={handleSidebarClose}
              totalCalories={recCalories}
              remainingCalories={recCalories - totalCalories}
              consumedCalories={totalCalories}
            />
          </>
        )} */}
      </Container>
    </div>
  );
};

export default ProgressTracker;
