import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useState, useEffect } from "react";
import { useKalorie } from "../../../../userContext/context";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import constants from "../../../../constants.json";
export default function LinearDeterminate({
  microNutrients,
  recMicroNutrients,
}) {
  const { dateTrackingDiary, menuValue } = useKalorie();
  console.log(
    "hello worldd in microcomponent the people don't do it right",
    menuValue,
    dateTrackingDiary
  );

  console.log("Hello worlddd i have some logical problemss", microNutrients);
  const [progressProtien, setProgressProtien] = React.useState(0);
  // const [menuValue, setMenuValue] = useState("weekly");
  const [day, setday] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const getWeekDates = (currentDate) => {
  //   const weekStart = new Date(
  //     currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)
  //   );
  //   const weekEnd = new Date(
  //     currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7)
  //   );
  //   return {
  //     startWeek: weekStart.toISOString().split("T")[0],
  //     weekStart: weekStart.getDate(),
  //     month: weekStart.toLocaleString("default", { month: "long" }),
  //     endWeek: weekEnd.toISOString().split("T")[0],
  //     weekEnd: weekEnd.getDate(),
  //   };
  // };
  // const [currentDate, setCurrentDate] = useState(new Date());

  // const { weekStart, weekEnd, startWeek, endWeek, month } = getWeekDates(
  //   new Date(currentDate)
  // );

  // const goToPreviousWeek = () => {
  //   setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
  // };

  // const goToNextWeek = () => {
  //   setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
  // };
  // const goToPreviousDate = () => {
  //   setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  // };

  // const goToNextDate = () => {
  //   setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  // };
  // const formatDate = (date) => {
  //   // Format the date to DD-MM-YYYY
  //   let day = date.getDate().toString().padStart(2, "0");
  //   let month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   let year = date.getFullYear();
  //   return `${day}-${month}-${year}`;
  // };
  let weeklyCalcium = 0;
  let weeklyCholines = 0;
  let weeklyFiber = 0;
  let weeklyIron = 0;
  let weeklyMagnesium = 0;
  let weeklyPotassium = 0;
  let weeklyRibo = 0;
  let weeklySodium = 0;
  let weeklyZinc = 0;

  let dailyCalcium = 0;
  let dailyCholines = 0;
  let dailyFiber = 0;
  let dailyIron = 0;
  let dailyMagnesium = 0;
  let dailyPotassium = 0;
  let dailyRibo = 0;
  let dailySodium = 0;
  let dailyZinc = 0;

  let weeklyCalciumPercent = 0;
  let weeklyCholinesPercent = 0;
  let weeklyFiberPercent = 0;
  let weeklyIronPercent = 0;
  let weeklyMagnesiumPercent = 0;
  let weeklyPotassiumPercent = 0;
  let weeklyRiboPercent = 0;
  let weeklySodiumPercent = 0;
  let weeklyZincPercent = 0;

  let dailyCalciumPercent = 0;
  let dailyCholinesPercent = 0;
  let dailyFiberPercent = 0;
  let dailyIronPercent = 0;
  let dailyMagnesiumPercent = 0;
  let dailyPotassiumPercent = 0;
  let dailyRiboPercent = 0;
  let dailySodiumPercent = 0;
  let dailyZincPercent = 0;
  if (menuValue === "weekly") {
    microNutrients.map((items, index) => {
      weeklyCalcium = weeklyCalcium + items?.total_calcium;
      weeklyCholines = weeklyCholines + items?.total_choline;
      weeklyFiber = weeklyFiber + items?.total_fiber;
      weeklyIron = weeklyIron + items?.total_iron;
      weeklyMagnesium = weeklyMagnesium + items?.total_magnesium;
      weeklyPotassium = weeklyPotassium + items?.total_potassium;
      weeklyRibo = weeklyRibo + items?.total_ribo;
      weeklySodium = weeklySodium + items?.total_sodium;
      weeklyZinc = weeklyZinc + items?.total_zinc;
    });
    weeklyCalciumPercent =
      (weeklyCalcium / (recMicroNutrients?.calcium * 7)) * 100;
    weeklyCholinesPercent =
      (weeklyCholines / (recMicroNutrients?.choline_mg * 7)) * 100;
    weeklyFiberPercent = (weeklyFiber / (recMicroNutrients?.fiber_g * 7)) * 100;
    weeklyIronPercent =
      (weeklyIron / (recMicroNutrients?.iron_fe_mg * 7)) * 100;
    weeklyMagnesiumPercent =
      (weeklyMagnesium / (recMicroNutrients?.magnesium * 7)) * 100;
    weeklyPotassiumPercent =
      (weeklyPotassium / (recMicroNutrients?.potassium_mg * 7)) * 100;
    weeklyRiboPercent = (weeklyRibo / (recMicroNutrients?.ribo_mg * 7)) * 100;
    weeklySodiumPercent =
      (weeklySodium / (recMicroNutrients?.sodium_mg * 7)) * 100;
    weeklyZincPercent = (weeklyZinc / (recMicroNutrients?.zinc_mg * 7)) * 100;
    console.log(weeklyCalcium, weeklyCholines, weeklyFiber);
  }

  if (menuValue === "daily") {
    microNutrients.map((items, index) => {
      if (items.date === dateTrackingDiary) {
        dailyCalcium = items.total_calcium;
        dailyCholines = items.total_choline;
        dailyFiber = items.total_fiber;
        dailyIron = items.total_iron;
        dailyMagnesium = items.total_magnesium;
        dailyPotassium = items.total_potassium;
        dailyRibo = items.total_ribo;
        dailySodium = items.total_sodium;
        dailyZinc = items.total_zinc;
      }
    });
    dailyCalciumPercent = (dailyCalcium / recMicroNutrients?.calcium) * 100;
    dailyCholinesPercent =
      (dailyCholines / recMicroNutrients?.choline_mg) * 100;
    dailyFiberPercent = (dailyFiber / recMicroNutrients?.fiber_g) * 100;
    dailyIronPercent = (dailyIron / recMicroNutrients?.iron_fe_mg) * 100;
    dailyMagnesiumPercent =
      (dailyMagnesium / recMicroNutrients?.magnesium) * 100;
    dailyPotassiumPercent =
      (dailyPotassium / recMicroNutrients?.potassium_mg) * 100;
    dailyRiboPercent = (dailyRibo / recMicroNutrients?.ribo_mg) * 100;
    dailySodiumPercent = (dailySodium / recMicroNutrients?.sodium_mg) * 100;
    dailyZincPercent = (dailyZinc / recMicroNutrients?.zinc_mg) * 100;
    console.log(weeklyCalcium, weeklyCholines, weeklyFiber);
  }

  return (
    <Box sx={{ width: "100%" }}>
      {/* <div className="px-4">
        <div className="flex justify-center items-center">
          {menuValue === "weekly" ? (
            <>
              <button className="m-2" onClick={goToPreviousWeek}>
                {"<"}
              </button>
              <button className="m-4">
                {`${weekStart} - ${weekEnd} ${month}`}
              </button>
              <button className=" m-2" onClick={goToNextWeek}>
                {">"}
              </button>
            </>
          ) : (
            <>
              <button className="m-2" onClick={goToPreviousDate}>
                {"<"}
              </button>
              <button className=" m-4">
                {formatDate(selectedDate)} */}
      {/* Display selected date */}
      {/* </button>
              <button className=" m-2" onClick={goToNextDate}>
                {">"}
              </button>
            </>
          )}

          <FormControl sx={{ m: 1, minWidth: 100 }} size="small"> */}
      {/* <InputLabel id="demo-select-small-label">Duration</InputLabel> */}
      {/* <Select
              sx={{ height: 30 }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={menuValue}
              // label="Weekly"
              onChange={console.log("hello")}
            >
              <MenuItem
                onClick={() => {
                  setMenuValue("weekly");
                }}
                value={"weekly"}
              >
                {constants.weekly}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuValue("daily");
                }}
                value={"daily"}
              >
                {constants.daily}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div> */}
      {menuValue === "weekly" ? (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <h4 style={{ width: "25%" }}>{constants.nutrients}</h4>
            <p style={{ width: "25%" }}>
              <strong>{constants.taken}</strong>
            </p>
            <p style={{ width: "25%" }}>
              <strong>{constants.recommended}</strong>
            </p>
            <p style={{ width: "25%" }}>
              <strong>{constants.remaining}</strong>
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.calcium}</p>
            <p style={{ width: "25%" }}>{weeklyCalcium}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.calcium * 7}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.calcium * 7 - weeklyCalcium).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={weeklyCalciumPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.cholines}</p>
            <p style={{ width: "25%" }}>{weeklyCholines}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.choline_mg * 7}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.choline_mg * 7 - weeklyCholines).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={weeklyCholinesPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.fiber}</p>
            <p style={{ width: "25%" }}>{weeklyFiber}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.fiber_g * 7}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.fiber_g * 7 - weeklyFiber).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={weeklyFiberPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.iron}</p>
            <p style={{ width: "25%" }}> {weeklyIron}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.iron_fe_mg * 7}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.iron_fe_mg * 7 - weeklyIron).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={weeklyIronPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.magnesium}</p>
            <p style={{ width: "25%" }}>{weeklyMagnesium}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.magnesium * 7}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.magnesium * 7 - weeklyMagnesium).toFixed(2)}g
            </p>
          </div>
          <LinearProgress
            variant="determinate"
            value={weeklyMagnesiumPercent}
          />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.potassium}</p>
            <p style={{ width: "25%" }}>{weeklyPotassium}g</p>
            <p style={{ width: "25%" }}>
              {recMicroNutrients?.potassium_mg * 7}g
            </p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.potassium_mg * 7 - weeklyPotassium).toFixed(
                2
              )}
              g
            </p>
          </div>
          <LinearProgress
            variant="determinate"
            value={weeklyPotassiumPercent}
          />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.ribo}</p>
            <p style={{ width: "25%" }}>{weeklyRibo}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.ribo_mg * 7).toFixed(2)}g
            </p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.ribo_mg * 7 - weeklyRibo).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={weeklyRiboPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.sodium}</p>
            <p style={{ width: "25%" }}>{weeklySodium}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.sodium_mg * 7}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.sodium_mg * 7 - weeklySodium).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={weeklySodiumPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.zinc}</p>
            <p style={{ width: "25%" }}>{weeklyZinc}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.zinc_mg * 7}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.zinc_mg * 7 - weeklyZinc).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={weeklyZincPercent} />
        </div>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <h4 style={{ width: "25%" }}>{constants.nutrients}</h4>
            <p style={{ width: "25%" }}>
              <strong>{constants.taken}</strong>
            </p>
            <p style={{ width: "25%" }}>
              <strong>{constants.recommended}</strong>
            </p>
            <p style={{ width: "25%" }}>
              <strong>{constants.remaining}</strong>
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.calcium}</p>
            <p style={{ width: "25%" }}>{dailyCalcium}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.calcium}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.calcium - dailyCalcium).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={dailyCalciumPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.cholines}</p>
            <p style={{ width: "25%" }}>{dailyCholines}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.choline_mg}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.choline_mg - dailyCholines).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={dailyCholinesPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.fiber}</p>
            <p style={{ width: "25%" }}>{dailyFiber}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.fiber_g}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.fiber_g - dailyFiber).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={dailyFiberPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.iron}</p>
            <p style={{ width: "25%" }}>{dailyIron}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.iron_fe_mg}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.iron_fe_mg - dailyIron).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={dailyIronPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.magnesium} </p>
            <p style={{ width: "25%" }}>{dailyMagnesium}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.magnesium}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.magnesium - dailyMagnesium).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={dailyMagnesiumPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.potassium}</p>
            <p style={{ width: "25%" }}>{dailyPotassium}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.potassium_mg}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.potassium_mg - dailyPotassium).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={dailyPotassiumPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.ribo}</p>
            <p style={{ width: "25%" }}>{dailyRibo.toFixed(2)}g</p>
            <p style={{ width: "25%" }}>
              {recMicroNutrients?.ribo_mg.toFixed(2)}g
            </p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.ribo_mg - dailyRibo).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={dailyRiboPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.sodium}</p>
            <p style={{ width: "25%" }}>{dailySodium}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.sodium_mg}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.sodium_mg - dailySodium).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={dailySodiumPercent} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ width: "25%" }}>{constants.zinc}</p>
            <p style={{ width: "25%" }}>{dailyZinc}g</p>
            <p style={{ width: "25%" }}>{recMicroNutrients?.zinc_mg}g</p>
            <p style={{ width: "25%" }}>
              {(recMicroNutrients?.zinc_mg - dailyZinc).toFixed(2)}g
            </p>
          </div>
          <LinearProgress variant="determinate" value={dailyZincPercent} />
        </>
      )}
    </Box>
  );
}
