import React from "react";
import {
  Container,
  Typography,
  Button,
  IconButton,
  TextField,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PieChartComp from "./pieChart";
import MealPieChartComp from "./mealCardPieChart";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer } from "react-toastify";
import constants from "../../constants.json";
import useStyles from "../../assets/css/fooddiary";

// Cache for storing fetched food items
var foodItemsCache = [];
const FoodAdder = ({
  selectedButtonContent,
  userObject,
  rec,
  setReload,
  reload,
  diet,
}) => {
  const classes = useStyles();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedMealTypeTesting, setSelectedMealTypeTesting] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [MealDialogOpen, setMealDialogOpen] = useState(false);
  const MealDialog = ({ open, onClose, meal }) => {
    const [foodName, setFoodName] = useState("");
    const [foodWeight, setFoodWeight] = useState("1");
    const [displayIngredients, setDisplayIngredients] = useState(false);
    const [nutrientData, setNutrientData] = useState(null);
    const [mess, setMess] = useState(false);
    const [pieChartDetails, setPieChartDetails] = useState(null);
    const [foodObjects, setFoodObjects] = useState([]);
    const [foodId, setFoodId] = useState(0);
    const [foodQuantity, setFoodQuantity] = useState(0);
    const [foodQuantityUnit, setFoodQuantityUnit] = useState("");
    console.log("in the mealdialog", meal);

    useEffect(() => {
      // Reset state when the dialog is closed
      if (!open) {
        setFoodName("");
        setFoodWeight("1");
        setDisplayIngredients(false);
        setNutrientData(null);
        setMess(false);
        setPieChartDetails(null);
        setFoodObjects([]);
        setFoodId(0);
        setFoodQuantity(0);
        setFoodQuantityUnit("");
        setErrorMessage("");
      }
    }, [open]);
    const fetchFoodNames = async () => {
      try {
        const response = await fetch(apiUrl + "/api/food-items", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (result.status === true) {
          foodItemsCache = result.foodItems.map((item) => ({
            foodName: item.food_name,
            quantity: item.quantity,
            quantityUnit: item.quantity_unit,
          }));
          setFoodObjects(foodItemsCache);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
      }
    };
    const fetchFoodId = async (foodName) => {
      try {
        const response = await fetch(apiUrl + "/api/get-food-details", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            food_name: foodName,
          }),
        });

        const result = await response.json();
        setFoodId(result.foodDetails.id);
        console.log("foodID", result);
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
        throw error;
      }
    };
    console.log("meal", meal, "mealType:none");
    const handleAddManually = () => {
      setFoodName("");
      fetchFoodNames();
      setFoodWeight("1");

      setDisplayIngredients(false);
    };
    useEffect(() => {
      // Fetch food items only if not cached
      if (!foodItemsCache.length) {
        handleAddManually();
      } else {
        setFoodObjects(foodItemsCache);
      }
    }, []);

    function getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

    const currentDate = getCurrentDate();
    console.log(currentDate);
    const handleConfirmManual = async () => {
      if (!foodName || !foodWeight) {
        // setMealDialogOpen(true);
        setErrorMessage("Select a food from the dropdown");

        console.log("im if", meal); // Set error message if no food selected
        return;
      } else if (!(foodWeight > 0)) {
        // setMealDialogOpen(true);
        setErrorMessage("Enter  a valid serving size for your food.");
        console.log("im if", meal); // Set error message if no food selected
        return;
      } else {
        try {
          const response = await fetch(apiUrl + "/api/add-diary-meal", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: userObject.user_id,
              date: selectedButtonContent.label,
              meal_type: meal,
              food_item_id: foodId,
              quantity: foodWeight,
            }),
          });

          const result = await response.json();
          if (result.status === true) {
            setMess(true);
          } else {
            console.error("An error occurred:");
          }
        } catch (error) {
          console.error("An error occurred:", error);
          // Handle error scenario
        }
      }
    };

    const filterNonZeroAndNaN = (nutrientDetails) => {
      const filteredDetails = {};
      for (const key in nutrientDetails) {
        if (
          key !== "food_name" &&
          key !== "id" &&
          isFinite(nutrientDetails[key]) &&
          nutrientDetails[key] !== 0 &&
          nutrientDetails[key] !== 0.0 &&
          nutrientDetails[key] !== "0" &&
          nutrientDetails[key] !== "0.00"
        ) {
          if (key === "quantity") {
            filteredDetails[key] = nutrientDetails[key];
          } else {
            filteredDetails[key] = nutrientDetails[key];
          }
        }
      }
      return filteredDetails;
    };

    const fetchScaledNutrientDetails = async (foodName, foodWeight) => {
      try {
        const response = await fetch(apiUrl + "/api/get-food-details", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            food_name: foodName,
          }),
        });

        const result = await response.json();

        if (result.status === true) {
          const foodDetails = result.foodDetails;
          setFoodId(foodDetails.id);

          // Calculate scaled nutrient details based on entered weight
          const scaledNutrientDetails = {};
          for (const key in foodDetails) {
            if (key !== "food_name") {
              scaledNutrientDetails[key] = foodDetails[key]; // Round to 2 decimal places
            }
          }

          return scaledNutrientDetails;
        } else {
          // Handle the case where the API request was not successful
          throw new Error(result.message);
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
        throw error;
      }
    };
    const handleGetIngredients = async () => {
      setErrorMessage("");
      if (!(foodWeight > 0)) {
        // setMealDialogOpen(true);
        setErrorMessage("Enter  a valid serving size for your food.");
        console.log("im if", meal); // Set error message if no food selected
        return;
      }
      try {
        const scaledNutrientDetails = await fetchScaledNutrientDetails(
          foodName,
          foodWeight
        );

        delete scaledNutrientDetails.id;
        delete scaledNutrientDetails.user_id;
        // Update the nutrientData state with the fetched data
        setNutrientData(scaledNutrientDetails);
        setPieChartDetails(scaledNutrientDetails);

        // Update the filtered nutrient details by filtering out non-zero and non-NaN values
        const filteredDetails = filterNonZeroAndNaN(scaledNutrientDetails);
        setNutrientData(filteredDetails);
        setDisplayIngredients(true); // Display the nutrient details
      } catch (error) {
        console.error("Error:", error.message);
        // Handle the error scenario here
      }
    };

    const IngredientsInfoComponent = ({
      foodWeight,
      nutrientData,
      pieChartData,
    }) => {
      const formatNutrientKey = (key) => {
        // Remove "_g" suffix if it exists
        let formattedKey = key.replace(/_g$/, "");

        // Capitalize the first letter
        formattedKey =
          formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);

        // Display only the first word for keys with underscores
        if (formattedKey.includes("_")) {
          formattedKey = formattedKey.split("_")[0];
        }

        return formattedKey;
      };
      const formatNutrientValue = (value, key) => {
        const unitMap = {
          _g: " (gram)",
          _kcal: " (Kilocalories)",
          _mg: " (milligram)",
          _micro_g: " (microgram)",
          _mic_g: " (microgram)",
          _microg: " (microgram)",
        };
        const unitKey = Object.keys(unitMap).find((unit) => key.endsWith(unit));
        const unit = unitKey ? unitMap[unitKey] : "";
        return `${value} ${unit}`;
      };

      return (
        <Container>
          <Grid container spacing={2} className={classes.verticalcontainer3}>
            <Grid item xs={12} md={6}>
              <Typography className={classes.text1}>
                Nutrient Composition
              </Typography>

              {nutrientData && (
                <div>
                  {Object.entries(nutrientData).map(([key, value]) => (
                    <div key={key} className={classes.nutrientItem}>
                      <span className={classes.nutrientKey}>
                        {formatNutrientKey(key)}:{" "}
                      </span>
                      <span className={classes.nutrientValue}>
                        {foodWeight
                          ? formatNutrientValue(
                              parseFloat(
                                value * parseFloat(foodWeight)
                              ).toFixed(2),
                              key
                            )
                          : "0 g"}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={6} className={classes.verticalcontainer4}>
              <PieChartComp apiData={pieChartData} foodWeight={foodWeight} />
            </Grid>
          </Grid>
        </Container>
      );
    };
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        {mess ? (
          <>
            <DialogTitle className={classes.text1}>
              <Typography className={[classes.fontCaleda, classes.boldText]}>
                Add Food
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.verticalcontainer}>
              <Typography
                className={[
                  classes.fontCaleda,
                  classes.text1,
                  classes.smallerText,
                ]}
              >
                Meal added successfully
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose();
                  setReload(!reload);
                }}
                className={[classes.buttonstyle1, classes.colorOrange]}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <div className={classes.spaceBetweenFlex}>
              <DialogTitle>
                <Typography className={classes.text_title}>
                  {constants.searchFood}
                </Typography>
              </DialogTitle>
              <IconButton
                edge="end"
                onClick={onClose}
                aria-label="close"
                className={classes.closeButton}
              >
                <CloseIcon color="error" />
              </IconButton>
            </div>
            <DialogContent className={classes.verticalcontainer2}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={foodItemsCache.map((item) => item.foodName)}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    const selectedFood = foodItemsCache.find(
                      (item) => item.foodName === newValue
                    );
                    setFoodName(selectedFood.foodName);
                    setFoodQuantity(selectedFood.quantity);
                    setFoodQuantityUnit(selectedFood.quantityUnit);
                    fetchFoodId(newValue);
                    handleGetIngredients();
                  }
                }}
                sx={{ width: "100%" }} // Adjust width to be responsive
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Food Name"
                    variant="filled"
                    type="text"
                    margin="normal"
                    value={foodName}
                    onChange={(e) => setFoodName(e.target.value)}
                    className={classes.input}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: classes.inputLabel,
                      },
                    }}
                  />
                )}
              />

              {foodName && (
                <div className={classes.inlineContainer}>
                  <TextField
                    variant="filled"
                    value={foodWeight}
                    onChange={(e) => setFoodWeight(e.target.value)}
                    type="number"
                    fullWidth
                    margin="normal"
                    className={[classes.input, classes.smallerInput]}
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.inputLabel,
                        focused: classes.inputLabel,
                      },
                    }}
                  />
                  <span className="m-4 text-lg">
                    Serving(s) of {foodQuantity} {foodQuantityUnit}
                  </span>
                </div>
              )}
              {errorMessage && (
                <Typography variant="body2" color="error">
                  {errorMessage}
                </Typography>
              )}
              {displayIngredients && (
                <IngredientsInfoComponent
                  foodName={foodName}
                  foodWeight={foodWeight}
                  nutrientData={nutrientData}
                  pieChartData={pieChartDetails}
                />
              )}
            </DialogContent>
            <DialogActions>
              {!displayIngredients && foodName && (
                <Button
                  onClick={handleGetIngredients}
                  className={[classes.buttonstyle1, classes.colorOrange]}
                >
                  {constants.getNutrients}
                </Button>
              )}
              <Button
                onClick={handleConfirmManual}
                className={[classes.buttonstyle1, classes.colorOrange]}
              >
                {constants.addMeal}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  };

  const MealCard = ({ selectedButtonContent, mealType, rec, diet }) => {
    const classes = useStyles();
    console.log(
      "meal type in meal card ",
      mealType,
      "selected meallslllslslsa buttonnnn ",
      selectedButtonContent
    );
    const [takenCal, setTakenCal] = useState(0);
    const [protein, setProtein] = useState(0);
    const [carbs, setCarbs] = useState(0);
    const [fibre, setFibre] = useState(0);

    const handleCloseDialog = () => {
      setMealDialogOpen(false);
    };
    useEffect(() => {
      let sum = 0;
      let protein = 0;
      let carbs = 0;
      let fibre = 0;
      if (mealType === constants.breakfast) {
        diet.breakfast.map((items, index) => {
          sum += items.calories_kcal * items.quantity;
          protein += items.protein_g * items.quantity;
          carbs += items.carbs_g * items.quantity;
          fibre += items.fats_g * items.quantity;
        });
      } else if (mealType === constants.snack) {
        diet.snack.map((items, index) => {
          sum += items.calories_kcal * items.quantity;
          protein += items.protein_g * items.quantity;
          carbs += items.carbs_g * items.quantity;
          fibre += items.fats_g * items.quantity;
        });
      } else if (mealType === constants.lunch) {
        diet.lunch.map((items, index) => {
          sum += items.calories_kcal * items.quantity;
          protein += items.protein_g * items.quantity;
          carbs += items.carbs_g * items.quantity;
          fibre += items.fats_g * items.quantity;
        });
      } else if (mealType === constants.dinner) {
        diet.dinner.map((items, index) => {
          sum += items.calories_kcal * items.quantity;
          protein += items.protein_g * items.quantity;
          carbs += items.carbs_g * items.quantity;
          fibre += items.fats_g * items.quantity;
        });
      }
      setTakenCal(sum);
      setProtein(protein);
      setCarbs(carbs);
      setFibre(fibre);
    }, []);

    return (
      <Card className={classes.cardstyle1}>
        <CardContent>
          <div className={classes.horizontalcontainer2}>
            <IconButton
              onClick={() => {
                setMealDialogOpen(true);
                setSelectedMealTypeTesting(mealType);
                console.log("in the meal card", mealType);
              }}
            >
              <AddCircleOutlineIcon className={classes.colorWhite} />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              className={[classes.text1, classes.boldText]}
            >
              {selectedButtonContent}
            </Typography>
          </div>
          <Typography
            sx={{ mb: 1.5 }}
            className={[classes.text5, classes.blackText]}
          >
            {constants.recommendedCalories}:{" "}
            <strong> {Math.round(rec / 4)} kcl</strong>
          </Typography>
          <Typography className={[classes.text5, classes.blackText]}>
            {constants.takenCalories}:<strong>{takenCal} kcl</strong>
          </Typography>
          <div className={classes.centeredContainer}>
            <MealPieChartComp protein={protein} carbs={carbs} fats={fibre} />
          </div>
        </CardContent>
        <MealDialog
          open={MealDialogOpen}
          onClose={handleCloseDialog}
          meal={selectedMealTypeTesting}
        />
      </Card>
    );
  };
  return (
    <div className={classes.rowFlexStart}>
      <Grid container spacing={2} className={classes.mealCardContainer}>
        {diet && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MealCard
                selectedButtonContent={constants.breakfast}
                mealType={constants.breakfast}
                day={selectedButtonContent.index}
                rec={rec}
                diet={diet}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MealCard
                selectedButtonContent={constants.lunch}
                mealType={constants.lunch}
                day={selectedButtonContent.index}
                rec={rec}
                diet={diet}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MealCard
                selectedButtonContent={constants.dinner}
                mealType={constants.dinner}
                day={selectedButtonContent.index}
                rec={rec}
                diet={diet}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MealCard
                selectedButtonContent={constants.snack}
                mealType={constants.snack}
                day={selectedButtonContent.index}
                rec={rec}
                diet={diet}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default FoodAdder;
