import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Typography,
  FormControl,
  Input,
  Button,
  InputLabel,
  Box,
  IconButton,
  InputAdornment,
  Grid,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKalorie } from "../../userContext/context";
import { useLocation } from "react-router-dom";
import constants from "../../constants.json";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import useStyles from "../../assets/css/common.js";
import axios from "axios";
import logo from "../../assets/images/logo.png";
import sha256 from "crypto-js/sha256";

const InfoPage2 = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data, updateData } = useKalorie();
  console.log("data:::::", data);
  const [error, setError] = useState("");
  const location = useLocation();
  const screenStatus = location.state;
  console.log("world", screenStatus);
  const handleChange = ({ currentTarget: input }) => {
    updateData(input.name, input.value);
    console.log(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (data.firstname.trim() === "") {
      setError("Please enter your first name");
      return;
    }
    if (!/^[A-Za-z]+$/.test(data.firstname)) {
      setError("First Name can only contain alphabets");
      return;
    }

    if (data.lastname.trim() === "") {
      setError("Please enter your last name");
      return;
    }
    if (!/^[A-Za-z]+$/.test(data.lastname)) {
      setError("Last Name can only contain alphabets");
      return;
    }

    if (data.email.trim() === "") {
      setError("Please enter your email");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(data.email) || /\.{2,}|,\./.test(data.email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (data.password.trim() === "") {
      setError("Please enter a password");
      return;
    }

    if (data.password.length < 6) {
      setError("Password too short");
      return;
    }

    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(data.password)) {
      setError("Password Needs special character");
      return;
    }
    if (data.password !== data.c_password) {
      setError("Passwords do not match");
      return;
    }
    const serverIp = process.env.REACT_APP_API_URI;
    const port = process.env.REACT_APP_PORT;
    // API call to check if the email already exists
    try {
      const response = await axios.post(
        `${serverIp}:${port}/api/email-exists`,
        { email: data.email }
      );

      if (response.data.status === true) {
        setError("User with this email already exists.");
        return;
      } else {
        if (response.data.status === false) {
          // If the email does not exist, proceed
          updateData("hashed_password", sha256(data.password).toString());
          setError("");
          console.log(data);
          navigate("/personalInfo", { state: screenStatus });
        }
      }
    } catch (error) {
      console.error("There was an error!", error);
      updateData("hashed_password", sha256(data.password).toString());
      setError("");
      console.log(data);
      navigate("/personalInfo", { state: screenStatus });
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const goback = () => {
    navigate(-1);
  };

  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={classes.outercontainer_info2}
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            variant="h4"
            className={classes.text1}
          >
            <img
              style={{ marginRight: "10px" }}
              src={logo}
              alt="Logo is missing"
            />
            {constants.appTitle}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon size="small" />}
            className={classes.buttonstyle3}
            onClick={goback}
          >
            Back
          </Button>
        </Grid>
        <Grid item container>
          <Grid item className={classes.triangleRight}></Grid>
          <Grid item className={classes.triangleLeft}></Grid>
          <Grid
            style={{
              width: "100%",
              justifyContent: "center",
            }}
            container
          >
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              className={`${classes.mainContainer} ${classes.signUpContainer}`}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "20ch" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className={classes.innerBox}
              >
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-firstname"
                      className={classes.inputLabel} // Apply custom styles to the label
                    >
                      First Name
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-firstname"
                      type="text"
                      name="firstname"
                      value={data.firstname}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-lastname"
                      className={classes.inputLabel} // Apply custom styles to the label
                    >
                      Last Name
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-lastname"
                      type="text"
                      name="lastname"
                      value={data.lastname}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-email"
                      className={classes.inputLabel} // Apply custom styles to the label
                    >
                      Email
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-email"
                      type="email"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                    />
                  </FormControl>
                </div>

                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      className={classes.inputLabel}
                    >
                      Password
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      name="password" // Add the name attribute
                      value={data.password}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            className={classes.iconstyle}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      className={classes.input}
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-confirm-password"
                      className={classes.inputLabel}
                    >
                      Confirm Password
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-confirm-password"
                      type={showConfirmPassword ? "text" : "password"}
                      name="c_password" // Add the name attribute
                      value={data.c_password}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            className={classes.iconstyle}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      className={classes.input}
                    />
                  </FormControl>
                </div>

                {error && (
                  <Typography className={classes.errorText}>{error}</Typography>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  align="center"
                  className={classes.signUpButton}
                >
                  Continue
                </Button>
                <div className={classes.horizontalcontainer}>
                  <Typography className={classes.text2}>
                    Already have an account?
                  </Typography>
                  <Button
                    className={classes.buttonstyle}
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                  >
                    Sign In
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage2;
