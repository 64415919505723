import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./home";

import Profile from "./profile";

function Main() {
  return (
    <div>
      <div>
        <Home />
      </div>
    </div>
  );
}

export default Main;
