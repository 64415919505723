import React from "react";
import { Container, Row } from "reactstrap";
// import Helmet from "../components/Helmet/Helmet";
// import CommonSection from "../components/UI/CommonSection";
import FoodBlogsList from "./foodBlogsList";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Editor from "ckeditor5-custom-build/build/ckeditor";
// import {Editor as ClassicEditor} from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import GlobalStyles from "@mui/material/GlobalStyles";
import constants from "../constants.json";
const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      ".ck.ck-balloon-panel": {
        zIndex: "1400 !important", // Put a higher value that your MUI Dialog (generaly 1300)
      },
    }}
  />
);

const serverIp = process.env.REACT_APP_API_URI;
const port = process.env.REACT_APP_PORT;
const userObject = JSON.parse(localStorage.getItem("user"));
const FoodBlogs = () => {
  const [openResponsiveDialog, setOpenResponsiveDialog] = useState(false);
  const [reload, setReload] = useState(false);
  const [author, setAuthor] = useState("");
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  useEffect(() => {
    const getitems = `${serverIp}:${port}/api/users/${userObject.user_id}`;
    axios
      .get(getitems)
      .then((response) => {
        if (response.status) {
          console.log(
            response.data.user.first_name + " " + response.data.user.last_name
          );
          setAuthor(
            response.data.user.first_name + " " + response.data.user.last_name
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const ResponsiveDialog = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const handleClose = () => {
      setOpenResponsiveDialog(false);
      setReload(!reload);
    };

    return (
      <React.Fragment>
        <Dialog
          fullScreen={fullScreen}
          open={openResponsiveDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {constants.foodBlogs}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {constants.newFoodBlogHasBeenCreated}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              {constants.close}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };
  const FullScreenDialog = () => {
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [foodBlogsData, setFoodBlogsData] = useState();
    const [category, setCategory] = useState("");
    const [tags, setTags] = useState([]);

    const handleChange = (event) => {
      setCategory(event.target.value);
    };
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    const createFoodBlogs = async () => {
      const createitems =
        "https://jbq4swurmf.execute-api.eu-north-1.amazonaws.com/dev/foodBlogs";
      const data = {
        title: title,
        description: description,
        foodBlogsData: foodBlogsData,
        date: `${day}/${month}/${year}`,
        time: `${hour}:${minutes}`,
        author: author,
        userId: userObject.user_id,
        category: category,
        tags: tags,
      };
      axios
        .post(createitems, data, {
          headers: {
            authorizationToken:
              "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
          },
        })
        .then((response) => {
          if (response.status) {
            console.log(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      if (
        document.getElementById("title").value == "" ||
        document.getElementById("description").value == "" ||
        category == "" ||
        tags.length == 0
      ) {
        toast(constants.kindlyFillAllFields);
      } else {
        createFoodBlogs();
        setOpen(false);
        setReload(!reload);
        setOpenResponsiveDialog(true);
      }
    };
    const tagList = [
      "Food",
      "Fitness",
      " Software",
      "Diet",
      " Beauty",
      "nutrition",
      "weight loss",
    ];
    return (
      <React.Fragment>
        <div>
          <ToastContainer />
        </div>
        <div style={{ margin: "20px" }}>
          <Button
            onClick={handleClickOpen}
            class="ml-2 items-center bg-green-100 hover:bg-green-400  text-sm font-bold rounded px-2 py-1"
            variant="outlined"
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            {constants.addNewFoodBlogs}
          </Button>
        </div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          disableEnforceFocus
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setOpen(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.addFoodBlog}
              </Typography>
              <Button
                autoFocus
                class="ml-2 items-center bg-green-400 hover:bg-red-400  text-lg font-bold rounded px-2 py-1"
                variant="outlined"
                onClick={handleClose}
              >
                {constants.save}
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.title}
              </Typography>
            </ListItem>
            <ListItem>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label={constants.foodBlogTitle}
                type="text"
                fullWidth
                variant="standard"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </ListItem>
            <ListItem>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.shortDescription}
              </Typography>
            </ListItem>
            <ListItem>
              <TextField
                multiline
                autoFocus
                margin="dense"
                id="description"
                label={constants.foodBlogShortDescription}
                type="text"
                fullWidth
                variant="standard"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </ListItem>
            <ListItem>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.category}
              </Typography>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {constants.category}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="category"
                  value={category}
                  label={constants.category}
                  onChange={handleChange}
                >
                  <MenuItem value={"Weight Concerns & Emotional Eating"}>
                    {constants.weightConcernsAndEmotionalEating}
                  </MenuItem>
                  <MenuItem value={"Digestion Issues"}>
                    {constants.digestionIssues}
                  </MenuItem>
                  <MenuItem value={"Diabetes & Blood Sugar Concerns"}>
                    {constants.diabetesAndBloodSugarConcerns}
                  </MenuItem>
                  <MenuItem value={"Heart Health"}>
                    {constants.heartHealth}
                  </MenuItem>
                  <MenuItem value={"Kids Nutrition"}>
                    {constants.kidsNutrition}
                  </MenuItem>
                  <MenuItem value={"Meal Planning & Health Prevention"}>
                    {constants.preventiveHealthcare}
                  </MenuItem>
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.tags}
              </Typography>
            </ListItem>
            <ListItem>
              <Autocomplete
                multiple
                id="tags"
                onChange={(event, newValue) => {
                  setTags(newValue);
                  console.log("new value", newValue);
                  // Update the context's foodName
                }}
                options={tagList}
                getOptionLabel={(option) => option}
                defaultValue={tags}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={constants.blogTags}
                    placeholder="Favorites"
                  />
                )}
                sx={{ width: "100%" }}
              />
            </ListItem>
            <ListItem>
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }} className="App">
                  {open && (
                    <>
                      {inputGlobalStyles}
                      <CKEditor
                        editor={Editor}
                        data={foodBlogsData}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFoodBlogsData(data);
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </ListItem>
          </List>
        </Dialog>
      </React.Fragment>
    );
  };

  return (
    <section>
      <Container>
        <FullScreenDialog />
        <ResponsiveDialog />
        <Row>
          <FoodBlogsList reload={reload} setReload={setReload} />
        </Row>
      </Container>
    </section>
  );
};

export default FoodBlogs;
