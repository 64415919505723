import React, { useState, useEffect } from "react";
import CustomTypography from "../../components/reusableComponents/muiTypography";
import MuiTextField from "../../components/reusableComponents/muiTextField";
import AddButton from "../../commonScreens/signinButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../../assets/css/addFood.css";
import constants from "../../constants.json";
function EditFood() {
  const [foodName, setFoodName] = useState("");
  const [foodWeight, setFoodWeight] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantity_unit, setQuantity_unit] = useState("");
  const [water, setWater] = useState("");
  const [protein, setProtein] = useState("");
  const [fats, setFats] = useState("");
  const [fiber, setFiber] = useState("");
  const [carbs, setCarbs] = useState("");
  const [zinc, setZinc] = useState("");
  const [potassium, setPotassium] = useState("");
  const [magnesium, setMagnesium] = useState("");
  const [iron, setIron] = useState("");
  const [calories, setCalories] = useState("");
  const [sodium, setSodium] = useState("");
  const [thiamin, setThiamin] = useState("");
  const [vitE, setVitE] = useState("");
  const [vitA, setVitA] = useState("");
  const [riboflavin, setRiboflavin] = useState("");
  const [b3, setB3] = useState("");
  const [b6, setB6] = useState("");
  const [b9, setB9] = useState("");
  const [vitC, setVitC] = useState("");
  const [vitb12, setVitb12] = useState("");
  const [selenium, setSelenium] = useState("");
  const [choline, setCholine] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { foodDetails } = location.state;
  const [error, setError] = useState("");
  const [foodItem, setFoodItem] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const response = await fetch(
          apiUrl + `/api/food-items/${foodDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        console.log("result from get edit food", result);

        if (result.status === true) {
          setFoodItem(result.foodItem);
        } else {
          console.log("food not add");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
      }
    }

    fetchData();
  }, []);
  console.log(foodItem.choline_mg);
  const hanldeEdit = async (event) => {
    event.preventDefault();
    console.log("im handle edittttttttttttttt   ", foodItem.calories_kcal);
    const userObject = JSON.parse(localStorage.getItem("user"));
    console.log("im user from add food:::", userObject);
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(
        apiUrl + `/api/food-items/${foodDetails.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: foodDetails.id,
            user_id: userObject.user_id,
            approved: 0,
            food_name: foodItem.food_name,
            quantity: foodItem.quantity,
            quantity_unit: foodItem.quantity_unit,
            weight_g: foodItem.weight_g,
            water_g: foodItem.water_g,
            calories_kcal: foodItem.calories_kcal,
            protein_g: foodItem.protein_g,
            carbs_g: foodItem.carbs_g,
            fiber_g: foodItem.fiber_g,
            fats_g: foodItem.fats_g,
            calcium: foodItem.calcium,
            iron_fe_mg: foodItem.iron_fe_mg,
            magnesium: foodItem.magnesium,
            potassium_mg: foodItem.potassium_mg,
            sodium_mg: foodItem.sodium_mg,
            zinc_mg: foodItem.zinc_mg,
            vitA_mic_g: foodItem.vitA_mic_g,
            thia_mg: foodItem.thia_mg,
            vitE_mg: foodItem.vitE_mg,
            ribo_mg: foodItem.ribo_mg,
            b3_niac_mg: foodItem.b3_niac_mg,
            b6_mg: foodItem.b6_mg,
            b9_micro_g: foodItem.b9_micro_g,
            vitC_mg: foodItem.vitC_mg,
            vitb12_microg: foodItem.vitb12_microg,
            selenium_microg: foodItem.selenium_microg,
            choline_mg: foodItem.choline_mg,
            food_item_pic_link: "path/to/pic",
          }),
        }
      );

      const result = await response.json();
      console.log("result from edit fooddddd", result);
      console.log("im handle edit2");

      if (result.status === true) {
        console.log("im handle edit3");
        console.log("response", result.message);
        navigate("/allFoods");
      } else {
        console.log("food not add");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
  };
  const handleFieldChange = (fieldName, name) => {
    console.log("newValue", fieldName);
    console.log("name", name);
    setFoodItem((prevItemInfo) => ({
      ...prevItemInfo,
      [name]: fieldName,
    }));
  };

  return (
    <div className="addFoodContainer">
      <Grid container className="addFoodBox">
        {foodItem.food_name ? (
          <>
            <Grid container className="addFoodGrid">
              <CustomTypography
                text={constants.editFoodItem}
                fontSize={30}
                color={"#06647D"}
                marginLeft={31}
                marginBottom={20}
                // fontWeight={"bold"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.foodName}
                type="text"
                name={"food_name"}
                color={"black"}
                value={foodItem.food_name}
                placeholder={"e.g. Apple pie"}
                onChange={(value) => handleFieldChange(value, "food_name")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.foodWeight}
                type="number"
                color={"black"}
                value={foodItem.weight_g || "0"}
                placeholder={"in grams"}
                // onChange={(value, isValid) => { ABABAB
                //   setFoodWeight(value);
                //   setFoodItem({ ...foodItem, weight_g: value });
                // }}
                onChange={(value) => handleFieldChange(value, "weight_g")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.quantity}
                type="number"
                color={"black"}
                value={foodItem.quantity || "0"}
                placeholder={"e.g. 1 cup"}
                // onChange={(value, isValid) => {
                //   setQuantity(value);
                // }}
                onChange={(value) => handleFieldChange(value, "quantity")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.unitOfQuantity}
                type="text"
                color={"black"}
                value={foodItem.quantity_unit || "0"}
                placeholder={"e.g. 1 cup"}
                // onChange={(value, isValid) => {
                //   setQuantity_unit(value);
                // }}
                onChange={(value) => handleFieldChange(value, "quantity_unit")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.calories + "(kcal)"}
                type="number"
                color={"black"}
                value={foodItem.calories_kcal || "0"}
                placeholder={"in Kcal"}
                onChange={(value) => handleFieldChange(value, "calories_kcal")}
                // onChange={(value, isValid) => {
                //   setCalories(value);
                // }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.water + "(g)"}
                type="number"
                color={"black"}
                value={foodItem.water_g || "0"}
                placeholder={"in grams"}
                // onChange={(value, isValid) => {
                //   setWater(value);
                // }}
                onChange={(value) => handleFieldChange(value, "water_g")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.proteins + "(g)"}
                type="number"
                color={"black"}
                value={foodItem.protein_g || "0"}
                placeholder={"in grams"}
                // onChange={(value, isValid) => {
                //   setProtein(value);
                // }}
                onChange={(value) => handleFieldChange(value, "protein_g")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.fiber + "(g)"}
                type="number"
                color={"black"}
                value={foodItem.fiber_g || "0"}
                placeholder={"in grams"}
                // onChange={(value, isValid) => {
                //   setFiber(value);
                // }}
                onChange={(value) => handleFieldChange(value, "fiber_g")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.carbs + "(g)"}
                type="number"
                color={"black"}
                value={foodItem.carbs_g || "0"}
                placeholder={"in grams"}
                // onChange={(value, isValid) => {
                //   setCarbs(value);
                // }}
                onChange={(value) => handleFieldChange(value, "carbs_g")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.fats + "(g)"}
                type="number"
                color={"black"}
                value={foodItem.fats_g || "0"}
                placeholder={"in grams"}
                // onChange={(value, isValid) => {
                //   setFats(value);
                // }}
                onChange={(value) => handleFieldChange(value, "fats_g")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.iron + "(mg)"}
                type="number"
                color={"black"}
                value={foodItem.iron_fe_mg || "0"}
                placeholder={"in milli-grams"}
                // onChange={(value, isValid) => {
                //   setIron(value);
                // }}
                onChange={(value) => handleFieldChange(value, "iron_fe_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.magnesium + "(mg)"}
                type="number"
                color={"black"}
                value={foodItem.magnesium || "0"}
                placeholder={"in milli-grams"}
                // onChange={(value, isValid) => {
                //   setMagnesium(value);
                // }}
                onChange={(value) => handleFieldChange(value, "magnesium")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.sodium + "(mg)"}
                type="number"
                color={"black"}
                placeholder={"in milli-grams"}
                value={foodItem.sodium_mg || "0"}
                // onChange={(value, isValid) => {
                //   setSodium(value);
                // }}
                onChange={(value) => handleFieldChange(value, "sodium_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.zinc + "(mg)"}
                type="number"
                color={"black"}
                value={foodItem.zinc_mg || "0"}
                placeholder={"in milli-grams"}
                // onChange={(value, isValid) => {
                //   setZinc(value);
                // }}
                onChange={(value) => handleFieldChange(value, "zinc_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.vitaminA + "(µg)"}
                type="number"
                color={"black"}
                placeholder={"in micro-grams"}
                value={foodItem.vitA_mic_g || "0"}
                // onChange={(value, isValid) => {
                //   setVitA(value);
                // }}
                onChange={(value) => handleFieldChange(value, "vitA_mic_g")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.thiamin + "(mg)"}
                type="number"
                color={"black"}
                value={foodItem.thia_mg || "0"}
                placeholder={"in milli-grams"}
                // onChange={(value, isValid) => {
                //   setThiamin(value);
                // }}
                onChange={(value) => handleFieldChange(value, "thia_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.vitaminE + "(mg)"}
                type="number"
                color={"black"}
                placeholder={"in milli-grams"}
                value={foodItem.vitE_mg || "0"}
                // onChange={(value, isValid) => {
                //   setVitE(value);
                // }}
                onChange={(value) => handleFieldChange(value, "vitE_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.riboflavin + "(mg)"}
                type="number"
                color={"black"}
                value={foodItem.ribo_mg || "0"}
                placeholder={"in milli-grams"}
                // onChange={(value, isValid) => {
                //   setRiboflavin(value);
                // }}
                onChange={(value) => handleFieldChange(value, "ribo_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.vitaminB3Niacin + "(mg)"}
                type="number"
                color={"black"}
                placeholder={"in milli-grams"}
                value={foodItem.b3_niac_mg || "0"}
                // onChange={(value, isValid) => {
                //   setB3(value);
                // }}
                onChange={(value) => handleFieldChange(value, "b3_niac_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.vitaminB6 + "(mg)"}
                type="number"
                color={"black"}
                value={foodItem.b6_mg || "0"}
                placeholder={"in milli-grams"}
                // onChange={(value, isValid) => {
                //   setB6(value);
                // }}
                onChange={(value) => handleFieldChange(value, "b6_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.vitaminB9 + "(µg)"}
                type="number"
                color={"black"}
                placeholder={"in micro-grams"}
                value={foodItem.b9_micro_g || "0"}
                // onChange={(value, isValid) => {
                //   setB9(value);
                // }}
                onChange={(value) => handleFieldChange(value, "b9_micro_g")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.vitaminC + "(mg)"}
                type="number"
                color={"black"}
                value={foodItem.vitC_mg || "0"}
                placeholder={"in milli-grams"}
                // onChange={(value, isValid) => {
                //   setVitC(value);
                // }}
                onChange={(value) => handleFieldChange(value, "vitC_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.vitaminB12 + "(µg)"}
                type="number"
                color={"black"}
                placeholder={"in micro-grams"}
                value={foodItem.vitb12_microg || "0"}
                // onChange={(value, isValid) => {
                //   setVitb12(value);
                // }}
                onChange={(value) => handleFieldChange(value, "vitb12_microg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.selenium + "(µg)"}
                type="number"
                color={"black"}
                value={foodItem.selenium_microg || "0"}
                placeholder={"in micro-grams"}
                // onChange={(value, isValid) => {
                //   setSelenium(value);
                // }}
                onChange={(value) =>
                  handleFieldChange(value, "selenium_microg")
                }
              />
            </Grid>{" "}
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.cholines + "(mg)"}
                type="number"
                color={"black"}
                placeholder={"in milli-grams"}
                value={foodItem.choline_mg || "0"}
                // onChange={(value, isValid) => {
                //   setCholine(value);
                // }}
                onChange={(value) => handleFieldChange(value, "choline_mg")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label={constants.potassium + "(mg)"}
                type="number"
                color={"black"}
                value={foodItem.potassium_mg || "0"}
                placeholder={"in milli-grams"}
                borderColor={"#ABABAB"}
                // onChange={(value, isValid) => {
                //   setPotassium(value);
                // }}
                onChange={(value) => handleFieldChange(value, "potassium_mg")}
              />
            </Grid>{" "}
            {error && <div className="addFoodErrorMessage">{error}</div>}
            <Grid
              container
              justifyContent="flex-end"
              className="addFoodButtonContainer"
              marginRight={6}
            >
              <Grid item>
                <AddButton
                  buttonVariant="contained"
                  buttonColor="primary"
                  buttonText={constants.cancel}
                  onClick={() => navigate(-1)}
                  width={"15vh"}
                  margin={"60px"}
                  background={"#06647D"}
                >
                  Cancel
                </AddButton>
              </Grid>
              <Grid item>
                <AddButton
                  buttonVariant="contained"
                  buttonColor="primary"
                  buttonText={constants.editFoodItem}
                  onClick={hanldeEdit}
                  width={"25vh"}
                  margin={"60px"}
                  marginLeft={"10px"}
                  background={"#06647D"}
                />
              </Grid>
            </Grid>
            {/* <Grid item className="addFoodButton">
              <div className="justify-items-center">
                <AddButton
                  buttonVariant="contained"
                  buttonColor="primary"
                  buttonText={constants.cancel}
                  onClick={() => navigate(-1)}
                  width={"25vh"}
                  margin={"60px"}
                  background={"#06647D"}
                >
                  Cancel
                </AddButton>

                <AddButton
                  buttonVariant="contained"
                  buttonColor="primary"
                  buttonText={constants.editFoodItem}
                  onClick={hanldeEdit}
                  width={"25vh"}
                  margin={"60px"}
                  background={"#06647D"}
                />
              </div>
            </Grid> */}
            {success && (
              <div className="addFoodSuccessMessage">
                <CustomTypography
                  text={constants.foodItemUpdatedSuccessfully}
                  fontSize={30}
                  color={"#38A389"}
                  marginLeft={31}
                  fontWeight={"bold"}
                />
              </div>
            )}
          </>
        ) : (
          <Typography>{constants.loadingFoodItemData}</Typography>
        )}
      </Grid>
    </div>
  );
}

export default EditFood;
