import { Typography, Container, Grid, Link } from "@material-ui/core/";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import useStyles from "../../../assets/css/style";
import constants from "../../../constants.json";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Container
      maxWidth={false}
      style={{ width: "100%", height: "100%" }}
      className={classes.container_footer}
    >
      <Grid
        style={{
          width: "100%",
          justifyContent: "space-evenly",
        }}
        container
        justifyContent="space-evenly"
      >
        <Grid style={{ padding: "20px" }} item sm={12} md={2} lg={2}>
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            className={classes.text1_footer}
          >
            {constants.appTitle}
          </Typography>
          <Typography className={classes.text2_footer}>
            {`${constants.takeControlOfYourCaloriesGoalBreakDownIngredientsAndLongActivitiesWith} ${constants.appTitle}`}
          </Typography>
        </Grid>
        <Grid
          style={{ padding: "20px", marginLeft: "50px" }}
          item
          sm={12}
          md={2}
          lg={2}
        >
          <Typography className={classes.text1_footer}>
            {constants.about}
          </Typography>
          <Link
            component={RouterLink}
            to="/howitworks"
            color="inherit"
            className={classes.textstyle2_footer}
          >
            <Typography className={classes.textstyle2_footer}>
              {constants.howItWorks}
            </Typography>
          </Link>
          <Link
            component={RouterLink}
            to="/community"
            color="inherit"
            className={classes.textstyle2_footer}
          >
            <Typography className={classes.textstyle2_footer}>
              {constants.community}
            </Typography>
          </Link>
          {/* <Typography className={classes.textstyle2_footer}>
            {constants.community}
          </Typography> */}
        </Grid>
        <Grid
          style={{ padding: "20px", marginLeft: "0px" }}
          item
          sm={12}
          md={2}
          lg={2}
        >
          <Typography className={classes.text1_footer}>Features</Typography>

          <Link
            component={RouterLink}
            to="/"
            color="inherit"
            className={classes.textstyle2_footer}
          >
            <Typography className={classes.textstyle2_footer}>
              {constants.dietTracker}
            </Typography>
          </Link>
          <Link
            component={RouterLink}
            to="/"
            color="inherit"
            className={classes.textstyle2_footer}
          >
            <Typography className={classes.textstyle2_footer}>
              {constants.foodDiary}
            </Typography>
          </Link>
          <Link
            component={RouterLink}
            to="/calculateTEE"
            color="inherit"
            className={classes.textstyle2_footer}
          >
            <Typography className={classes.textstyle2_footer}>
              {constants.TEECalculator}
            </Typography>
          </Link>
          <Link
            component={RouterLink}
            to="/blogs"
            color="inherit"
            // className={classes.textstyle2_footer}
          >
            <Typography className={classes.textstyle2_footer}>
              {constants.blogs}
            </Typography>
          </Link>
        </Grid>
        <Grid
          style={{ padding: "20px", marginLeft: "10px" }}
          item
          sm={12}
          md={2}
          lg={3}
        >
          <Typography className={classes.text1_footer}>
            {constants.quickLinks}
          </Typography>

          <Link
            component={RouterLink}
            to="/aboutus"
            color="inherit"
            className={classes.textstyle2_footer}
          >
            <Typography className={classes.textstyle2_footer}>
              {constants.aboutUs}
            </Typography>
          </Link>
          <Link
            component={RouterLink}
            to="/faqs"
            color="inherit"
            className={classes.textstyle2_footer}
          >
            <Typography className={classes.textstyle2_footer}>
              {constants.Faqs}
            </Typography>
          </Link>
          <Link
            component={RouterLink}
            to="/blogs"
            color="inherit"
            className={classes.textstyle2_footer}
          >
            <Typography className={classes.textstyle2_footer}>
              {constants.blogs}
            </Typography>
          </Link>
        </Grid>
        <Grid
          style={{ padding: "20px", marginLeft: "-70px" }}
          item
          sm={12}
          md={2}
          lg={2}
        >
          <Typography className={classes.text1_footer}>
            {constants.contactUs}
          </Typography>
          <Typography className={classes.textstyle2_footer}>
            {constants.companyBussinessEmail}
          </Typography>

          <div className={classes.horizontalcontainer_footer}>
            <Link
              href="https://instagram.com/kalorie_kompass?igshid=MTRuOGg5M2NpdnAycw=="
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              <InstagramIcon className={classes.footerIcon} />
            </Link>
            <Link
              href="https://www.facebook.com/profile.php?id=61551648118507&mibextid=9R9pXO"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              <FacebookIcon className={classes.footerIcon} />
            </Link>
            <Link
              href="https://www.linkedin.com/posts/kalorie-kompass_when-setting-upon-the-journey-of-healthy-activity-7130023696575979520-0GID"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              <LinkedInIcon className={classes.footerIcon} />
            </Link>
            <Link
              href="https://youtube.com/@KalorieKompass?si=3rICc-L7HgA9APfH"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              <YouTubeIcon className={classes.footerIcon} />
            </Link>
          </div>
        </Grid>

        <hr className={classes.linestyle_footer}></hr>
        <Grid style={{ padding: "20px" }} item sm={12} md={12} lg={12}>
          <Typography
            style={{ display: "flex", justifyContent: "center" }}
            className={classes.textstyle_footer}
          >
            {constants.appTitle + " " + constants.PoweredByEonIntelligence}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
