import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import Header from "../header";
import { useState } from "react";
import EditProfile from "./editprofilesection";
import ProfilePic from "./profilepicsection";
import AssignmentIndRoundedIcon from "@material-ui/icons/AssignmentIndRounded";
import LockIcon from "@material-ui/icons/Lock";
import PasswordSection from "./passwordsection";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import constants from "../../../../constants.json";
const useStyles = makeStyles((theme) => ({
  outercontainer: {
    width: "100%",
    height: "80%",
    // border: "2px solid #A87DB4",
    backgroundColor: "#38A389",
    zIndex: -1,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "87vh",
    // border: "2px solid #A87DB4",
    backgroundColor: "white",
    marginTop: "auto",
    borderTopLeftRadius: "100px",
    zIndex: 1,
  },
  innercontainer: {
    display: "flex",
    flexDirection: "column",
    width: "35%",
    height: "100%",
    // border: "2px solid #A87DB4",
  },
  innercontainer2: {
    display: "flex",
    flexDirection: "row",
    width: "65%",
    height: "100%",

    // border: "2px solid #A87DB4",
  },
  HeaderText: {
    color: "#FF7171 !important", // Customize placeholder color
    fontFamily: constants.fontFamily,
    fontSize: "1.8rem",
    margin: "3rem",
  },

  buttonstyle: {
    width: "300px",
    height: "60px",
    // borderRadius: "10px",
    borderBottom: "2px solid #FFD171",
    color: "#FFD171 ", // Customize placeholder color
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    // paddingBottom: "-1.5rem",
    alignItems: "end",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",

    // paddingLeft: "1.2rem",
  },
  buttonColor: {
    border: "2px solid #FF7171",
    color: "#FF7171 ",
    height: "45px",
  },
  buttonColor2: {
    border: "2px solid white",
    color: "#FF7171 ",
    marginTop: "0rem",
  },

  iconstyle: {
    fontSize: "1.7rem",
    marginRight: "0.5rem",
    marginBottom: "-0.2rem",
  },
  iconstyle2: {
    fontSize: "2rem",
    // marginRight: "0.2rem",
  },
  profilepicstyle: {
    width: "250px",
    height: "250px",
    borderRadius: "40px",
    border: "1px solid #FFD171",
    marginTop: "0rem",
    alignSelf: "center",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [avatar, setAvatar] = useState(null);
  const [activeSection, setActiveSection] = useState("editprofile");

  const handleSectionToggle = (section) => {
    setActiveSection(section);
  };

  return (
    <>
      <Header />
      <div className={classes.outercontainer}>
        <div className={classes.container}>
          <Container className={classes.innercontainer}>
            <Typography className={classes.HeaderText}>
              Account Settings
            </Typography>
            <Container
              className={classes.buttonstyle}
              onClick={() => handleSectionToggle("editprofile")}
            >
              <div>
                <AssignmentIndRoundedIcon className={classes.iconstyle} />
                Edit Profile
              </div>
              {activeSection === "editprofile" && (
                <ArrowRightIcon className={classes.iconstyle2} />
              )}
            </Container>
            <Container
              className={classes.buttonstyle}
              onClick={() => handleSectionToggle("password")}
            >
              <div>
                <LockIcon className={classes.iconstyle} />
                Change Password
              </div>
              {activeSection === "password" && (
                <ArrowRightIcon className={classes.iconstyle2} />
              )}
            </Container>
          </Container>

          <Container className={classes.innercontainer2}>
            {activeSection === "editprofile" ? <EditProfile /> : null}
            {activeSection === "password" ? <PasswordSection /> : null}
            <ProfilePic />
          </Container>
        </div>
      </div>
    </>
  );
};

export default Profile;
