import { Typography, Button, Box, Grid } from "@material-ui/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useEffect, useState } from "react";
import { useKalorie } from "../../userContext/context.js";
import constants from "../../constants.json";
import useStyles from "../../assets/css/common.js";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import profile from "../../assets/images/userProfile.png";
import logo from "../../assets/images/logo.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const InfoPage2 = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data, updateData } = useKalorie();

  const [error, setError] = useState("");
  const [dieticians, setDieticians] = useState(null);
  const [displayedDietitians, setDisplayedDietitians] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  //const displayedDietitians = dieticians.slice(currentIndex, currentIndex + 3);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleSubmit = async (dietician) => {
    setError("");

    updateData("dietitian_id", dietician.id);
    console.log(data);

    navigate("/selectDietPlan", {
      state: {
        charges_one_day: dietician.charges_one_day,
        charges_one_week: dietician.charges_one_week,
        charges_one_month: dietician.charges_one_month,
      },
    });
  };

  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  useEffect(() => {
    const apiUrl = `${serverIp}:${port}/api/all-dietitians`;

    const fetchData = () => {
      axios
        .get(apiUrl)
        .then((response) => {
          if (response.status) {
            console.log("response", response.data.dietitians);
            setDieticians(response.data.dietitians);
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.log(error);
          setError("An error occurred while fetching dieticians.");
        });
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (dieticians) {
      setDisplayedDietitians(dieticians.slice(currentIndex, currentIndex + 3));
    }
  }, [dieticians, currentIndex]);

  const goback = () => {
    navigate(-1);
  };
  const truncateWords = (text, limit) => {
    const words = text.split(" ");
    const truncated = words.slice(0, limit).join(" ");
    return `${truncated}${words.length > limit ? "" : ""}`;
  };
  const [maxVisibleCards, setMaxVisibleCards] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      // Adjust maxVisibleCards based on window width
      if (window.innerWidth < 600) {
        setMaxVisibleCards(1);
      } else if (window.innerWidth < 900) {
        setMaxVisibleCards(2);
      } else {
        setMaxVisibleCards(3);
      }
    };

    // Initial call to set maxVisibleCards based on current window width
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={classes.outercontainer_info2}
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            variant="h4"
            className={classes.text1}
          >
            <img
              style={{ marginRight: "10px" }}
              src={logo}
              alt="Logo is missing"
            />
            {constants.appTitle}
          </Typography>
          <div style={{ paddingTop: "20px" }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIosIcon size="small" />}
              className={[classes.buttonstyle3, classes.buttonMargin]}
              onClick={goback}
            >
              Back
            </Button>
          </div>
        </Grid>
        <Grid item className={classes.triangleRight}></Grid>
        <Grid item className={classes.triangleLeft}></Grid>
        <Grid className={classes.outerGrid} container>
          <Grid
            container
            sm={12}
            md={9}
            lg={9}
            className={classes.selectDieticianMainContainer}
            style={{ justifyContent: "center" }}
          >
            <Grid item>
              <Typography
                id="demo-radio-buttons-group-label"
                className={classes.labelstyle2_info3}
              >
                Select Your Dietician
              </Typography>
            </Grid>
            <Grid container style={{ display: "flex" }}>
              <Grid item sm={1} md={1} lg={1}>
                <Button
                  onClick={handlePrevious}
                  disabled={!dieticians || currentIndex === 0}
                >
                  <ArrowBackIosIcon />
                </Button>
              </Grid>
              <Grid container sm={10} md={10} lg={10}>
                {displayedDietitians === null ? (
                  // Show a loading message while data is being fetched
                  <Typography>Loading dieticians...</Typography>
                ) : (
                  <>
                    {/* <div className={classes.outerItemStyle} style={{ display: 'flex', flexWrap: 'wrap' }}> */}
                    {displayedDietitians
                      .slice(0, maxVisibleCards)
                      .map((dietician, index) => (
                        <Grid
                          container
                          sm={12}
                          md={6}
                          lg={4}
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          <div
                            className={classes.itemStyle}
                            key={dietician.id}
                            style={{
                              width: "250px",
                              margin: "10px",
                              padding: "10px",
                              boxSizing: "border-box",
                            }}
                          >
                            <img src={profile} className={classes.profileImg} />
                            <Typography variant="h3" className={classes.text4}>
                              {dietician.first_name}
                            </Typography>
                            <Typography variant="h3" className={classes.text5}>
                              {truncateWords(dietician.brief_info, 20)}
                            </Typography>

                            <Button
                              variant="outlined"
                              className={classes.buttonstyle4}
                              onClick={() => handleSubmit(dietician)}
                            >
                              Confirm
                            </Button>
                          </div>
                        </Grid>
                      ))}
                  </>
                )}
              </Grid>
              {error && <Typography>{error}</Typography>}
              <Grid item sm={1} md={1} lg={1}>
                <Button
                  onClick={handleNext}
                  disabled={
                    !dieticians || currentIndex + 1 >= dieticians.length
                  }
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Footer />
      </Grid>
    </>
  );
};

export default InfoPage2;
