import React from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
// import {Editor as ClassicEditor} from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import "../styles/ckEditor.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";
import { Container } from "reactstrap";
import { CommentSection } from "react-comments-section";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Divider from "@mui/material/Divider";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import BlogNavbar from "./blogNavbar";
import styled from "styled-components";
import Header from "../components/kalorieKompassComponents/starterpage/headerPage";

const FoodBlogsDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // const location = useLocation();
  // const foodBlogsObject = location.state;
  const [comment, setComment] = useState("");
  const [data, setData] = useState([]);
  const [foodBlogsObject, setFoodBlogsObject] = useState();
  const [reload, setReload] = useState(false);
  const [replyField, setReplyField] = useState(false);
  const [blogCommentObject, setBlogCommentObject] = useState();
  const [openResponsiveDialog, setOpenResponsiveDialog] = useState(false);
  const [commentsReplies, setCommentsReplies] = useState(false);
  const [clickedComment, setClickedComment] = useState();
  const Section = styled.section`
    margin: 5rem 0;
    .title {
      text-align: center;
      margin-bottom: 2rem;
    }
    .testimonials {
      display: flex;
      justify-content: center;
      margin: 0 2rem;
      gap: 2rem;
      .testimonial {
        background-color: aliceblue;
        padding: 2rem;
        border-radius: 0.5rem;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: 0.3s ease-in-out;
        &:hover {
          transform: translateX(0.4rem) translateY(-1rem);
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
        .info {
          display: flex;
          justify-content: center;
          gap: 1rem;
          align-items: center;
          margin-top: 1rem;
          img {
            border-radius: 3rem;
            height: 3rem;
          }
          .details {
            span {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
    @media screen and (min-width: 280px) and (max-width: 768px) {
      .testimonials {
        flex-direction: column;
        margin: 0;
        .testimonial {
          justify-content: center;
          .info {
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  `;
  useEffect(() => {
    const getBlogsItems = `https://jbq4swurmf.execute-api.eu-north-1.amazonaws.com/dev/foodBlogs`;
    axios
      .get(getBlogsItems, {
        headers: {
          authorizationToken:
            "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
        },
      })
      .then((response) => {
        if (response.data) {
          const filtered = response.data.body.filter((blog) => blog.id === id);
          setFoodBlogsObject(filtered[0]);
          console.log("tryyy", filtered);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    const getitems = `https://j3xs9qhcnk.execute-api.eu-north-1.amazonaws.com/dev/foodBlogsComments/${id}`;
    axios
      .get(getitems, {
        headers: {
          authorizationToken:
            "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
        },
      })
      .then((response) => {
        if (response.data) {
          setData(response.data.body);
          console.log("tryyy", response.data.body);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [reload]);
  const data1 = [
    {
      userId: "02b",
      comId: "017",
      fullName: "Lily",
      userProfile: "https://www.linkedin.com/in/riya-negi-8879631a9/",
      text: "I think you have a point🤔",
      avatarUrl: "https://ui-avatars.com/api/name=Lily&background=random",
      replies: [],
    },
  ];

  const createFoodBlogsComments = async (blogId) => {
    if (comment === "") {
      toast("Kindly fill all fields");
    } else {
      const postitems = `https://j3xs9qhcnk.execute-api.eu-north-1.amazonaws.com/dev/foodBlogsComments`;
      const postData = {
        comments: comment,
        blogId: blogId,
        reply: [],
      };
      axios
        .post(postitems, postData, {
          headers: {
            authorizationToken:
              "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
          },
        })
        .then((response) => {
          if (response.status) {
            console.log(response);
            setComment("");
            setReload(!reload);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const ResponsiveDialog = () => {
    const [reply, setReply] = useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const handleClose = () => {
      setOpenResponsiveDialog(false);
    };
    const updateFoodBlogsReply = async (item) => {
      if (reply === "") {
        toast("Kindly fill all fields");
      } else {
        const updateitems = `https://j3xs9qhcnk.execute-api.eu-north-1.amazonaws.com/dev/foodBlogsComments/${item.id}`;
        const updateData = {
          reply: [...item.reply, reply],
        };
        axios
          .put(updateitems, updateData, {
            headers: {
              authorizationToken:
                "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
            },
          })
          .then((response) => {
            if (response.status) {
              console.log(response);
              setReply("");
              setReload(!reload);
              handleClose();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    return (
      <React.Fragment>
        <Dialog
          fullScreen={fullScreen}
          open={openResponsiveDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Comment Reply"}
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
                            {blogCommentObject?.comments}
                        </DialogContentText> */}

            <AccountCircleOutlinedIcon
              style={{
                backgroundColor: "lightpink",
                fontSize: "50px",
                borderRadius: "100%",
                margin: "10px",
              }}
            />
            <TextField
              multiline
              margin="dense"
              id="comment"
              label="Type Your Reply Here"
              type="text"
              fullWidth
              variant="standard"
              value={reply}
              onChange={(e) => {
                setReply(e.target.value);
              }}
            />
          </DialogContent>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <DialogActions>
              <Button
                onClick={() => {
                  setReply("");
                  handleClose();
                }}
                style={{ width: "100px", display: "flex" }}
                class="ml-2 items-center bg-red-100 hover:bg-red-400  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<CancelOutlinedIcon />}
              >
                Cancel
              </Button>
            </DialogActions>
            <DialogActions>
              <Button
                onClick={() => {
                  updateFoodBlogsReply(blogCommentObject);
                }}
                style={{ width: "100px", display: "flex" }}
                class="ml-2 items-center bg-green-100 hover:bg-green-400  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<AddCircleOutlineOutlinedIcon />}
              >
                Post
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };
  return (
    <div>
      {/* <BlogNavbar foodBlogsObject={foodBlogsObject} /> */}
      <Header />
      {/* {isHeaderVisible ? <Header /> : null} */}
      <div className="text-5xl text-center mt-10 ">
        <span className=" font-bold">Discover</span>
        <span className=" italic font-medium">Wellness</span>
      </div>
      <div className="text-2xl text-center mt-2 font-bold">
        Fueling Your Future: A Calorie Counting Lifestyle Blog
      </div>
      <Container>
        <div>
          <ToastContainer />
        </div>
        <div style={{ width: "100%" }}>
          {foodBlogsObject && (
            <div>
              <Grid
                style={{ justifyContent: "center", alignItems: "center" }}
                container
              >
                <Grid
                  item
                  sm={12}
                  md={6}
                  lg={6}
                  style={{
                    width: "100%",
                    borderRadius: "5%",
                    padding: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <Section id="testimonials">
                    <div className="testimonials">
                      {/* <div
                        style={{ width: "100%", backgroundColor: "#FFE5B4" }}
                        className="testimonial"
                      >
                        <div className="info">
                          <div className="details">
                            <h6>
                              {" "}
                              <p>
                                <strong>Tags: </strong>
                                {foodBlogsObject.tags.map((item, index) => {
                                  return foodBlogsObject.tags.length ===
                                    index + 1
                                    ? item
                                    : item + " ,";
                                })}
                              </p>
                            </h6>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </Section>
                </Grid>
              </Grid>
              <div class="ck-content">
                {ReactHtmlParser(foodBlogsObject.foodBlogsData)}
                <div>
                  <h4>
                    {data.length} {data.length > 1 ? "Comments" : "Comment"}{" "}
                  </h4>
                </div>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <AccountCircleOutlinedIcon
                    style={{
                      backgroundColor: "lightpink",
                      fontSize: "50px",
                      borderRadius: "100%",
                      margin: "10px",
                    }}
                  />
                  <TextField
                    multiline
                    margin="dense"
                    id="comment"
                    label="Type Your Comment Here"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />

                  <Button
                    onClick={() => {
                      createFoodBlogsComments(foodBlogsObject.id);
                    }}
                    style={{ width: "100px", display: "flex" }}
                    class="ml-2 items-center bg-green-100 hover:bg-green-400  text-sm font-bold rounded px-2 py-1"
                    variant="outlined"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Post
                  </Button>
                  <Button
                    onClick={() => {
                      setComment("");
                    }}
                    style={{ width: "100px", display: "flex" }}
                    class="ml-2 items-center bg-red-100 hover:bg-red-400  text-sm font-bold rounded px-2 py-1"
                    variant="outlined"
                    startIcon={<CancelOutlinedIcon />}
                  >
                    Cancel
                  </Button>
                </div>

                {data?.map((item, index) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <AccountCircleOutlinedIcon
                          style={{
                            backgroundColor: "lightpink",
                            fontSize: "50px",
                            borderRadius: "100%",
                            margin: "10px",
                          }}
                        />
                        <div className="flex-row">
                          <div>
                            <p className="bg-orange-100 rounded-3xl p-2 mb-2">
                              {item.comments}
                            </p>
                          </div>
                          <Button
                            onClick={() => {
                              setReplyField(!replyField);
                              setBlogCommentObject(item);
                              setOpenResponsiveDialog(true);
                            }}
                            class="ml-2 items-center   text-sm font-bold rounded px-2 py-1 mb-2"
                          >
                            Reply
                          </Button>
                        </div>
                      </div>
                      {commentsReplies && clickedComment === item.id ? (
                        item.reply.map((replyItem) => {
                          return (
                            <div style={{ margin: "30px", marginLeft: "50px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }}
                              >
                                <AccountCircleOutlinedIcon
                                  style={{
                                    backgroundColor: "lightpink",
                                    fontSize: "50px",
                                    borderRadius: "100%",
                                    margin: "10px",
                                  }}
                                />
                                <div className="flex-row">
                                  <div>
                                    <p className="bg-orange-100 rounded-3xl p-2 mb-2">
                                      {replyItem}
                                    </p>
                                  </div>
                                  <Button
                                    onClick={() => {
                                      setReplyField(!replyField);
                                      setBlogCommentObject(item);
                                      setOpenResponsiveDialog(true);
                                    }}
                                    class="ml-2 items-center   text-sm font-bold rounded px-2 py-1 mb-2"
                                  >
                                    Reply
                                  </Button>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : item.reply.length > 0 ? (
                        <Button
                          onClick={() => {
                            console.log("neww", item);
                            setClickedComment(item.id);
                            setCommentsReplies(true);
                          }}
                          class="ml-2 items-center   text-sm font-bold rounded px-2 py-1 mb-2"
                        >
                          View {item.reply.length}{" "}
                          {item.reply.length === 1 ? "reply" : "replies"}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
                <ResponsiveDialog />
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default FoodBlogsDetails;
