import React, { Component,useState } from "react";
import apple from '../assets/images/apple.png';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import MessageIcon from '@mui/icons-material/Message';
import { useForm } from "react-hook-form";
import '../components/reusableComponentsCss/slider.css';
import { IconButton, TextField,Grid,Modal,Button,Box,Card,CardActions } from "@mui/material";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #A87BD4',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};
export default function UnapprovedFoodItems({unapitems}){
  const { register,reset, handleSubmit, watch, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false);
  const [commentopen, setcommentOpen] = useState(false);
  const [foodname,setfoodname]=useState('');
  const [quantity,setquantity]=useState('');
  const [foodimage,setfoodimage]=useState('');
  const [b3,setb3]=useState('');
  const [b6,setb6]=useState('');
  const [b9,setb9]=useState('');
  const [calcium,setcalcium]=useState('');
  const [calories,setcalories]=useState('');
  const [carbs,setcarbs]=useState('');
  const [choline,setcholine]=useState('');
  const [fats,setfats]=useState('');
  const [fiber,setfiber]=useState('');
  const [iron,setiron]=useState('');
  const [magnesium,setmagnesium]=useState('');
  const [potassium,setpotassium]=useState('');
  const [protein,setprotein]=useState('');
  const [ribo,setribo]=useState('');
  const [selenium,setselenium]=useState('');
  const [sodium,setsodium]=useState('');
  const [thia,setthia]=useState('');
  const [vitA,setvitA]=useState('');
  const [vitC,setvitC]=useState('');
  const [vitE,setvitE]=useState('');
  const [vitb12,setvitb12]=useState('');
  const [water,setwater]=useState('');
  const [weight,setweight]=useState('');
  const [zinc,setzinc]=useState('');

  const handleOpen = (items) => {
    setfoodname(items.food_name);
    setquantity(items.quantity);
    setfoodimage(items.food_item_pic_link);
    setb3(items.b3_niac_mg);
    setb6(items.b6_mg);
    setb9(items.b9_micro_g);
    setcalcium(items.calcium);
    setcalories(items.calories_kcal);
    setcarbs(items.carbs_g);
    setcholine(items.choline_mg);
    setfats(items.fats_g);
    setfiber(items.fiber_g);
    setiron(items.iron_fe_mg);
    setmagnesium(items.magnesium);
    setpotassium(items.potassium_mg);
    setprotein(items.protein_g);
    setribo(items.ribo_mg);
    setselenium(items.selenium_microg);
    setsodium(items.sodium_mg);
    setthia(items.thia_mg);
    setvitA(items.vitA_mic_g);
    setvitC(items.vitC_mg);
    setvitE(items.vitE_mg);
    setvitb12(items.vitb12_microg);
    setwater(items.water_g);
    setweight(items.weight_g);
    setzinc(items.zinc_mg);
    setOpen(true);
  }
  const handlecommentOpen=()=>{
    setcommentOpen(true);
  }
  const handlecommentClose=()=>{
    setcommentOpen(false);
  }
  const handleClose = () => setOpen(false);
    return (
      <div >
        <Grid container >
          {unapitems.map((items,index)=>
           <Grid item 
           xs={12} sm={6} md={4} lg={3} 
           key={index}>
          <div onClick={()=>handleOpen(items)} class="m-1 flip-card" >
          <div class="flip-card-inner">
          <div  className="m-2 flip-card-front  " >
          <Card className="py-1" sx={{width:"12rem",height:"9rem",backgroundColor:"#EEC4C4" }}>
  <div className="mt-1"><img className="imagesize" src={items.food_item_pic_link}/></div>
  <h3 className="m-2 text-lg text-[#38A389]">
            {items.food_name}
          </h3>
    </Card >
          </div>
          <div  class="flip-card-back">
          <h1 className="text-[#38A389] text-lg">{items.food_name}</h1> 
      <CardActions className="mt-3" style={{display:'flex', justifyContent: "center" }}>
       <div className="flex justify-center items-center w-7 h-7  rounded-full ml-1 mr-2 transform cursor-pointer bg-[#e8ddf3] hover:text-purple-500 hover:scale-120"> <IconButton size="small"><CheckIcon sx={{color:'#38A389'}}/></IconButton></div>
       <div className="flex justify-center items-center w-8 h-8  rounded-full mr-2 transform cursor-pointer bg-[#e8ddf3] hover:text-purple-500 hover:scale-120"><IconButton size="small" onClick={handlecommentOpen}><MessageIcon className="mt-1 w-6 h-6" sx={{color:'#A87BD4'}}/></IconButton></div>
       <div className="flex justify-center items-center w-7 h-7  rounded-full mr-2 transform cursor-pointer bg-[#e8ddf3] hover:text-purple-500 hover:scale-120"> <IconButton size="small"><ClearIcon sx={{color:'red'}}/></IconButton> </div>
      </CardActions>
          </div>
          </div>
          </div>
          </Grid>
          )}
        </Grid>
        {commentopen?(
        <Modal
        open={commentopen}
        onClose={handlecommentClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="items-center">
          <form noValidate className='bg-white rounded-sm border-[E3CFF6]'>
                 <div className="mt-4" >
                 <TextField
                  id="message"
                  type='text'
                  InputProps={{
                    style: { borderRadius: '10px' }, // Adjust the border radius value as needed
                  }}
                  label="Enter Message"
                  sx={{height:'42px',borderRadius:'10px'}}
                {...register("message",{required:"Message is required"})}
                  className=" rounded-lg w-full p-2.5"
                />
                 {errors.message && <p className='text-red-500 text-xs'>{errors.message.message}</p>}
</div>
<div className="flex justify-end mt-7">
<Button variant="contained" endIcon={<SendIcon className="w-8 h-8" />} className="text-sm">Send</Button>
</div>
           </form>
          </div>
        </Box>
      </Modal>
      ):(
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center w-17">
            <div>
              <img  src={foodimage || apple}></img>
              <h1 className="flex justify-center text-xl font-bold text-[#38A389]">{foodname}</h1>
            </div>
            <div className="ml-3 justify-start w-[60%]">
         <p className="text-sm font-bold text-[#38A389]">Quantity: <span className="inline text-red-500 ml-2">{quantity}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Water: <span className="inline text-red-500 ml-2">{water}</span>  </p>
         <p className="text-sm font-bold text-[#38A389]">Carbs: <span className="inline text-red-500 ml-2">{carbs}</span>  </p>
         <p className="text-sm font-bold text-[#38A389]">Fats: <span className="inline text-red-500 ml-2">{fats}</span>  </p>
         <p className="text-sm font-bold text-[#38A389]">Irons:  <span className="inline text-red-500 ml-2">{iron}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Potassium:  <span className="inline text-red-500 ml-2">{potassium}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Zinc: <span className="inline text-red-500 ml-2">{zinc}</span>  </p>
         <p className="text-sm font-bold text-[#38A389]">Thiamin:  <span className="inline text-red-500 ml-2">{thia}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Riboflavin: <span className="inline text-red-500 ml-2">{ribo}</span>  </p>
         <p className="text-sm font-bold text-[#38A389]">VitaminB6:  <span className="inline text-red-500 ml-2">{b6}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Vitamin C:  <span className="inline text-red-500 ml-2">{vitC}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Selenium:  <span className="inline text-red-500 ml-2">{selenium}</span> </p>
          </div>
          <div className="ml-3 justify-start w-[70%]">
         <p className="text-sm font-bold text-[#38A389]">Calories kcal:  <span className="inline text-red-500 ml-2">{calories}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Weight:  <span className="inline text-red-500 ml-2">{weight}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Protein:  <span className="inline text-red-500 ml-2">{protein}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Fiber:  <span className="inline text-red-500 ml-2">{fiber}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Calcium: <span className="inline text-red-500 ml-2">{calcium}</span>  </p>
         <p className="text-sm font-bold text-[#38A389]">Magnesium:  <span className="inline text-red-500 ml-2">{magnesium}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Sodium:  <span className="inline text-red-500 ml-2">{sodium}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Vitamin A:  <span className="inline text-red-500 ml-2">{vitA}</span> </p>
         <p className="text-sm font-bold text-[#38A389]">Choline: <span className="inline text-red-500 ml-2">{choline}</span>  </p>
         <p className="text-sm font-bold text-[#38A389]">Vitamin B3: <span className="inline text-red-500 ml-2">{b3}</span>  </p>
         <p className="text-sm font-bold text-[#38A389]">Vitamin B9: <span className="inline text-red-500 ml-2">{b9}</span>  </p>
         <p className="text-sm font-bold text-[#38A389]">Vitamin B12:  <span className="inline text-red-500 ml-2">{vitb12}</span> </p>
          </div>
          </div>
        </Box>
      </Modal>
      )}
      </div>
    );
  }