import { Typography, Button, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useEffect, useState } from "react";
import { useKalorie } from "../../userContext/context.js";
import useStyles from "../../assets/css/common.js";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import { useLocation } from "react-router-dom";
import constants from "../../constants.json";
import logo from "../../assets/images/logo.png";
const InfoPage2 = () => {
  const classes = useStyles();
  const { state } = useLocation();
  console.log("state:::::", state);
  const navigate = useNavigate();
  const { data, updateData } = useKalorie();
  useEffect(() => {
    console.log("sdjfldskjf:::::::", data);
  }, [data]);

  const [error, setError] = useState("");
  const handleSubmit = async (cost, duration) => {
    setError("");
    console.log("charges::::", cost);
    setDuration(duration);
    updateData("charges", cost);

    console.log(data);
    navigate("/signup", { state: "Get a Diet Plan" });
  };

  const setDuration = async (duration) => {
    updateData("duration", duration);
  };

  const planData = [
    {
      id: 1,
      duration: "2 Days",
      cost: state.charges_one_day,
      description:
        "Transform your lifestyle with our personalized diet plan for optimal health and lasting weight management",
    },
    {
      id: 2,
      duration: "7 Days",
      cost: state.charges_one_week,
      description:
        "Transform your lifestyle with our personalized diet plan for optimal health and lasting weight management",
    },
    {
      id: 3,
      duration: "1 Month",
      cost: state.charges_one_month,
      description:
        "Transform your lifestyle with our personalized diet plan for optimal health and lasting weight management",
    },
  ];

  const goback = () => {
    navigate(-1);
  };

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid
        item
        sm={12}
        md={12}
        lg={12}
        className={classes.outercontainer_info2}
      >
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
          variant="h4"
          className={classes.text1}
        >
          <img
            style={{ marginRight: "10px" }}
            src={logo}
            alt="Logo is missing"
          />
          {constants.appTitle}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIosIcon size="small" />}
          className={[classes.buttonstyle3, classes.buttonMargin]}
          onClick={goback}
        >
          Back
        </Button>
      </Grid>
      <Grid item className={classes.triangleRight}></Grid>
      <Grid item className={classes.triangleLeft}></Grid>
      <Grid className={classes.outerGrid} container>
        <Grid
          className={classes.selectDieticianMainContainer}
          container
          sm={12}
          md={9}
          lg={9}
        >
          <Grid style={{ justifyContent: "center" }} container>
            <Grid sm={12} md={12} lg={12} item></Grid>
            <Grid item>
              <Typography
                id="demo-radio-buttons-group-label"
                className={[classes.labelstyle2_info3, classes.topMargin]}
              >
                Select Your Diet Plan
              </Typography>
            </Grid>
            <Grid style={{ marginBottom: "20px" }}>
              {planData.map((plan) => (
                <Grid className={classes.itemDietPlanStyle}>
                  <Typography variant="h3" className={classes.dietPlanText1}>
                    {plan.duration}
                  </Typography>

                  <Typography variant="h3" className={classes.dietPlanText2}>
                    Cost Rs.{plan.cost}
                  </Typography>

                  <Typography variant="h3" className={classes.dietPlanText3}>
                    {plan.description}
                  </Typography>
                  <Button
                    variant="outlined"
                    className={classes.buttonstyle5}
                    onClick={() => handleSubmit(plan.cost, plan.duration)}
                  >
                    Select
                  </Button>
                </Grid>
              ))}
            </Grid>
            {error && <Typography>{error}</Typography>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default InfoPage2;
