import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import DeleteIcon from "@mui/icons-material/Delete";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import EditIcon from "@mui/icons-material/Edit";
import apple from "../../assets/images/apple.png";
import CustomTypography from "../../components/reusableComponents/muiTypography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import constants from "../../constants.json";
const randomColors = [
  "#EEC4C4",
  "#F4E0B4",
  "#E3CFF6",
  "#EEC4C4",
  "#F4E0B4",
  "#EEC4C4",
];
const fixedColors = [
  "#EEC4C4",
  "#F4E0B4",
  "#E3CFF6",
  "#EEC4C4",
  "#F4E0B4",
  "#EEC4C4",
];

export default function FoodCard(props) {
  const randomIndex = Math.floor(Math.random() * randomColors.length);
  const randomColor = randomColors[randomIndex];
  const navigate = useNavigate();
  const colorIndex = props.id % fixedColors.length;
  const fixedColor = fixedColors[colorIndex];
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleteSuccess, setDeleteSuccess] = useState(false);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteClick = () => {
    handleOpenDeleteModal();
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleZoomClick = () => {
    const foodDetails = {
      id: props.id,
    };
    navigate("/foodDetails", { state: { foodDetails } });
  };

  const handleEditClick = () => {
    const foodDetails = {
      id: props.id,
    };

    navigate("/editFood", { state: { foodDetails } });
  };

  const handleConfirmDelete = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(apiUrl + `/api/food-items/${props.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      console.log("result from delete food", result);

      if (result.status === true) {
        console.log("response", result.message);
        setDeleteSuccess(true);
      } else {
        console.log("food not deleted");
        setDeleteSuccess(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
    handleCloseDeleteModal();
  };
  useEffect(() => {
    if (isDeleteSuccess) {
      // Set a timer to hide the success message after 2 seconds
      const timer = setTimeout(() => {
        setDeleteSuccess(false);
      }, 2000);

      return () => {
        // Clean up the timer if the component unmounts
        clearTimeout(timer);
      };
    }
  }, [isDeleteSuccess]);
  return (
    <div>
      {isDeleteSuccess ? (
        <div
          style={{
            color: "green",
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            maxHeight: "250px",
            overflowY: "auto",
          }}
        >
          {constants.foodItemDeletedSuccessfully}
        </div>
      ) : (
        <Card
          sx={{
            maxWidth: 250,
            height: 150,
            position: "relative",
            borderRadius: 5,
            backgroundColor: fixedColor,
          }}
        >
          {/* <CardMedia
            sx={{
              height: 70,
              width: 65,
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1,
            }}
            image={apple}
            title="Food Image"
          /> */}
          <CardContent>
            <CustomTypography
              text={capitalizeFirstLetter(props.foodName)}
              fontSize={20}
              color={"#38A389"}
              marginLeft={5}
              fontWeight={"bold"}
            />

            <CustomTypography
              text={`${constants.calories}: ${props.calories} kcal`}
              fontSize={16}
              color={"#6D0B0B"}
              marginLeft={5}
              fontWeight={"bold"}
              marginTop={5}
            />
          </CardContent>
          <CardActions>
            <div
              style={{
                position: "absolute",
                right: 5,
                bottom: 5,
                display: "flex",
              }}
            >
              <div
                onClick={handleZoomClick}
                style={{
                  backgroundColor: "#FFA07A",
                  padding: "3px",
                  borderRadius: "30%",
                  marginRight: "5px",
                  cursor: "pointer", // Change cursor to pointer on hover
                }}
              >
                <ZoomOutMapIcon
                  sx={{ color: "#ffffff", width: "20px", height: "20px" }}
                />
              </div>
              <div
                onClick={handleEditClick}
                style={{
                  backgroundColor: "rgba(56, 163, 137, 0.8)",
                  padding: "3px",
                  borderRadius: "30%",
                  marginRight: "5px",
                  cursor: "pointer", // Change cursor to pointer on hover
                }}
              >
                <EditIcon
                  sx={{ color: "#ffffff", width: "20px", height: "20px" }}
                />
              </div>

              <div
                // onClick={handleDeleteClick}
                style={{
                  backgroundColor: "rgba(255, 113, 113, 1)",
                  padding: "3px",
                  borderRadius: "30%",
                  cursor: "pointer", // Change cursor to pointer on hover
                }}
              >
                <DeleteIcon
                  sx={{ color: "#ffffff", width: "20px", height: "20px" }}
                />
              </div>
            </div>
          </CardActions>
        </Card>
      )}
      <Dialog
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{constants.confirmDeletion}</DialogTitle>
        <DialogContent>
          <div>{constants.areYouSureYouWantToDeleteThisItem}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            {constants.cancel}
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            {constants.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
