import React, { useState } from "react";
import styled from "styled-components";
// import logo from "../assets/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
import { WhatsappShareButton } from "react-share";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Container } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import constants from "../constants.json";
export default function Navbar({ foodBlogsObject }) {
  const [navbarState, setNavbarState] = useState(false);
  return (
    <>
      {foodBlogsObject && (
        <>
          <Nav  className="bg-green-100 ">
            <div className="brand">
              <a style={{ textDecoration: "none", color: "black" }} href="/">
                <div className="container">
                  {/* <img
                style={{ width: "50px", height: "50px" }}
                src={logo}
                alt=""
              /> */}
                  {constants.appTitle}
                </div>
              </a>
              <div className="toggle">
                {navbarState ? (
                  <VscChromeClose onClick={() => setNavbarState(false)} />
                ) : (
                  <GiHamburgerMenu onClick={() => setNavbarState(true)} />
                )}
              </div>
            </div>

            <ul style={{ paddingTop: "10px" }}>
              <WhatsappShareButton
                url={window.location.href} // You can customize the URL to share
                title={`Check out ${foodBlogsObject.title}'s blog!`}
              // className={classes.shareButton}
              >
                <IconButton style={{ backgroundColor: "lightgreen" }} class="ml-2 items-center bg-green-100 hover:bg-green-400  text-sm font-bold rounded px-2 py-1" aria-label="share">
                  <WhatsAppIcon />  Share
                </IconButton>
              </WhatsappShareButton>
            </ul>
          </Nav>
          <ResponsiveNav state={navbarState}>
            <ul>
              <WhatsappShareButton
                url={window.location.href} // You can customize the URL to share
                title={`Check out ${foodBlogsObject.title}'s blog!`}
              // className={classes.shareButton}
              >
                <IconButton class="ml-2 items-center bg-green-100 hover:bg-green-400  text-sm font-bold rounded px-2 py-1" aria-label="share">
                  <WhatsAppIcon /> Share
                </IconButton>
              </WhatsappShareButton>
            </ul>
          </ResponsiveNav>
        </>
      )
      }
    </>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .brand {
    .container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      font-size: 1.2rem;
      font-weight: 900;
      text-transform: uppercase;
    }
    .toggle {
      display: none;
    }
  }
  ul {
    display: flex;
    gap: 2rem;
    list-style-type: none;
    li {
      a {
        text-decoration: none;
        color: black;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: orange;
        }
      }
      &:first-of-type {
        a {
          color: orange;
          font-weight: 900;
        }
      }
    }
  }
  button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 1rem;
    border: none;
    color: white;
    background-color: #48cae4;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: #023e8a;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .brand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        display: block;
      }
    }
    ul {
      display: none;
    }
    button {
      display: none;
    }
  }
`;

const ResponsiveNav = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: ${({ state }) => (state ? "50px" : "-400px")};
  background-color: white;
  height: 30vh;
  width: 100%;
  align-items: center;
  transition: 0.3s ease-in-out;
  ul {
    list-style-type: none;
    width: 100%;
    li {
      width: 100%;
      margin: 1rem 0;
      margin-left: 2rem;

      a {
        text-decoration: none;
        color: #0077b6;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #023e8a;
        }
      }
      &:first-of-type {
        a {
          color: #023e8a;
          font-weight: 900;
        }
      }
    }
  }
`;
