import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Button,
  IconButton,
  TextField,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import AccordionSection from "./accordion";
import PieChartComp from "./pieChart";
import MealPieChartComp from "./mealCardPieChart";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useKalorie } from "../userContext/context";
import { useEffect, useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import constants from "../constants.json";
import "../assets/css/style.css";
import useStyles from "../assets/css/fooddiary";

const FoodAdder = ({
  durationtype,
  selectedButtonContent,
  dietPlanDataWithFoodAllowedAndNotAllowed,
  rec,
  setReload,
  reload,
  diet,
}) => {
  console.log("kkkk", diet);
  const classes = useStyles();
  console.log("Duration Type:", durationtype);
  console.log("Selected Button Content:", selectedButtonContent);
  const [expanded, setExpanded] = useState(false);
  const [meal, setMeal] = useState("");
  const {
    data,
    dialyProtein,
    setProteins,
    setFats,
    dialyFats,
    setCarbs,
    dialyCarbs,
    setFibres,
    dialyFibre,
  } = useKalorie();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const date = `${year}-${month}-${day}`;
  const [showaccordion, setShowAccordion] = useState(false);
  const [selectedMealType, setSelectedMealType] = useState(null);
  const [mealInfo, setMealInfo] = useState([]);
  const [err, setErr] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAccordion = async (mealtype) => {
    console.log("Clicked Meal Type:", diet.breakfasts);

    setMeal(mealtype);
    if (selectedMealType === mealtype) {
      // If the clicked meal type is the same as the selected one, close the accordion
      setShowAccordion(false);
      setSelectedMealType(null); // Reset selected meal type
    } else {
      // If the clicked meal type is different, open the accordion
      setShowAccordion(true);
      setSelectedMealType(mealtype); // Set the selected meal type
    }
  };

  const MealCard = ({
    selectedButtonContent,
    mealType,
    onAddClick,
    day,
    rec,
    diet,
  }) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pieChartData, setPieChartData] = useState({});
    const [takenCal, setTakenCal] = useState(0);
    const [recCal, setRecCal] = useState(0);
    console.log("mealType", mealType);

    const handleAddIconClick = () => {
      // Call the onAddClick function and pass the mealType
      onAddClick(mealType);
      setDialogOpen(true);
    };

    const handleCloseDialog = () => {
      setDialogOpen(false);
    };
    useEffect(() => {
      console.log("newww1", rec);
      setRecCal(rec.recommended_calories_intake);
      let sum = 0;
      console.log("how", diet);
      if (mealType === "Breakfast") {
        diet.breakfasts.map((items, index) => {
          sum += items.calories_kcal;
        });
      } else if (mealType === "Snack") {
        diet.snacks.map((items, index) => {
          sum += items.calories_kcal;
        });
      } else if (mealType === "Lunch") {
        diet.lunchs.map((items, index) => {
          sum += items.calories_kcal;
        });
      } else if (mealType === "Dinner") {
        diet.dinners.map((items, index) => {
          sum += items.calories_kcal;
        });
      }
      setTakenCal(sum);
    }, []);

    // var takenCal = 0;

    return (
      <Card className={classes.cardstyle}>
        <CardContent>
          <div className={classes.horizontalcontainer2}>
            <IconButton
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              <AddCircleIcon className={classes.text1} />
            </IconButton>
            <Typography variant="h5" component="div" className={classes.text1}>
              {selectedButtonContent}
            </Typography>
          </div>
          <Typography sx={{ mb: 1.5 }} className={classes.text5}>
            {constants.recommendedCalories}: {recCal / 4} kcl
          </Typography>
          <Typography sx={{ mb: 1.5 }} className={classes.text5}>
            {constants.takenCalories}: {takenCal} kcl
          </Typography>
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <MealPieChartComp email={data.email} mealType={mealType} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "-300px",
            }}
          >
            <Button
              className={[classes.buttonstyle, classes.getdetailsbutton]}
              onClick={() => {
                handleAccordion(mealType);
              }}
            >
              {constants.getDetails}
            </Button>
          </div>
        </CardContent>
        <MealDialog
          dietPlanDataWithFoodAllowedAndNotAllowed={
            dietPlanDataWithFoodAllowedAndNotAllowed
          }
          meal={mealType}
          open={dialogOpen}
          onClose={handleCloseDialog}
        />
      </Card>
    );
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("enterManually");

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const MealDialog = ({
    dietPlanDataWithFoodAllowedAndNotAllowed,
    meal,
    open,
    onClose,
  }) => {
    const [manualDialogOpen, setManualDialogOpen] = useState(false);
    const [pictureDialogOpen, setPictureDialogOpen] = useState(false);
    const [foodName, setFoodName] = useState("");
    const [foodWeight, setFoodWeight] = useState("1");
    const [displayIngredients, setDisplayIngredients] = useState(false);
    const [nutrientData, setNutrientData] = useState(null);
    const [mess, setMess] = useState(false);
    const [pieChartDetails, setPieChartDetails] = useState(null);
    const [foodObjects, setFoodObjects] = useState([]);
    const [foodId, setFoodId] = useState(0);
    const [portionList, setPortionList] = useState([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
    ]);
    useEffect(() => {
      // Fetch food details and update nutrient information whenever foodWeight changes
      handleGetIngredients();
    }, [foodWeight]);

    const [uploadPictureDisabled, setUploadPictureDisabled] = useState(true);

    const fetchFoodNames = async () => {
      try {
        const response = await fetch(
          apiUrl +
            `/api/get-allowed-food-items/${dietPlanDataWithFoodAllowedAndNotAllowed.dietplanObject.diet_plan_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        console.log(result);
        console.log("yess", result);
        if (result.status === true) {
          const foodNames = result.foodNames.map((item) => item.food_name);
          setFoodObjects(foodNames);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
      }
    };
    const fetchFoodId = async (foodName) => {
      try {
        const response = await fetch(apiUrl + "/api/get-food-details", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            food_name: foodName,
          }),
        });

        const result = await response.json();
        setFoodId(result.foodDetails.id);
        console.log("foodID", result);

        // if (result.status === true) {
        //   const foodDetails = result.foodDetails;
        //   console.log("foodDetails", foodDetails);

        //   // Calculate scaled nutrient details based on entered weight
        //   const scaledNutrientDetails = {};
        //   for (const key in foodDetails) {
        //     if (key !== "food_name") {
        //       const scaledValue =
        //         (foodDetails[key] * foodWeight) / foodDetails.weight_g;
        //       scaledNutrientDetails[key] = scaledValue.toFixed(2); // Round to 2 decimal places
        //     }
        //   }

        //   return scaledNutrientDetails;
        // } else {
        //   // Handle the case where the API request was not successful
        //   throw new Error(result.message);
        // }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
        throw error;
      }
    };
    const handleAddManually = () => {
      setFoodName("");
      fetchFoodNames();
      setFoodWeight("1");
      setManualDialogOpen(true);
      setDisplayIngredients(false);
    };
    useEffect(() => {
      handleAddManually();
    }, []);
    const handleCloseManualDialog = () => {
      setFoodWeight("1");
      // setManualDialogOpen(false);
      setDisplayIngredients(false);
      onClose();
    };

    var scaledNutrientDetails = {};
    const handleConfirmManual = async () => {
      console.log("Food Name:", foodName);
      console.log("Food Weight:", foodWeight);

      // Calculate scaled nutrient details based on entered weight
      // try {
      //   scaledNutrientDetails = await fetchScaledNutrientDetails(
      //     foodName,
      //     foodWeight
      //   );
      //   console.log("Scaled Nutrient Details:", scaledNutrientDetails);
      // } catch (error) {
      //   console.error("Error:", error.message);
      //   // Handle the error scenario here
      // }
      var check = false;
      if (meal === constants.breakfast) {
        diet.breakfasts.map((items, index) => {
          if (items.food_name === foodName) {
            check = true;
          }
        });
      } else if (meal === constants.snack) {
        diet.snacks.map((items, index) => {
          if (items.food_name === foodName) {
            check = true;
          }
        });
      } else if (meal === constants.lunch) {
        diet.lunchs.map((items, index) => {
          if (items.food_name === foodName) {
            check = true;
          }
        });
      } else if (meal === constants.dinner) {
        diet.dinners.map((items, index) => {
          if (items.food_name === foodName) {
            check = true;
          }
        });
      }

      console.log("meal typeee", meal);
      const apiUrl = process.env.REACT_APP_API_URL;
      if (check === true) {
        toast(constants.foodItemAlreadyAddedInCurrentMeal);
      } else {
        try {
          const response = await fetch(
            apiUrl + "/api/add-diet-plan-food-item",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                diet_plan_id:
                  dietPlanDataWithFoodAllowedAndNotAllowed.dietplanObject
                    .diet_plan_id,
                month: 1,
                week: durationtype.charAt(durationtype.length - 1),
                day: selectedButtonContent.index,
                meal_type: meal,
                food_item_id: foodId,
                portion: foodWeight,
              }),
            }
          );

          const result = await response.json();
          // console.log(result.status);

          if (result.status === true) {
            console.log("response", result);
            setMess(true);

            console.log("message", mess);

            async function nutrientInfo() {
              try {
                const response = await fetch(apiUrl + "/api/meal-tracker", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    email: data.email,
                  }),
                });

                const result = await response.json();
                console.log(result);

                if (result.status === true) {
                  console.log(
                    "result protein food adder ",
                    result.results[0].total_protein
                  );
                  console.log("proootein food adder", dialyProtein);
                  const protein_percent =
                    (result.results[0].total_protein /
                      (dialyProtein * 0.129598)) *
                    100;
                  console.log(
                    "percentage protein food adder :",
                    protein_percent
                  );
                  setProteins(protein_percent);

                  console.log(
                    "result fats food adder ",
                    result.results[0].total_fats
                  );
                  console.log("fats food adder", dialyFats);
                  const fat_percent =
                    (result.results[0].total_fats / (dialyFats * 0.129598)) *
                    100;
                  console.log("percentage fats food adder :", fat_percent);
                  setFats(fat_percent);

                  console.log(
                    "result carbs food adder ",
                    result.results[0].total_carbs
                  );
                  console.log("carbs food adder", dialyCarbs);
                  const carbs_percent =
                    (result.results[0].total_carbs / (dialyCarbs * 0.129598)) *
                    100;
                  console.log("percentage carbs food adder :", carbs_percent);
                  setCarbs(carbs_percent);

                  console.log(
                    "result fiber food adder ",
                    result.results[0].total_fiber
                  );
                  console.log("fiber food adder", dialyFibre);
                  const fibre_percent =
                    (result.results[0].total_fiber / (dialyFibre * 0.129598)) *
                    100;
                  console.log("percentage fiber food adder :", fibre_percent);
                  setFibres(fibre_percent);
                } else {
                  setProteins(0);
                  console.log("error");
                }
              } catch (error) {
                console.error("An error occurred:", error);
                // Handle error scenario
              }
            }
            nutrientInfo();
          } else {
            // setError(result.message);
          }
        } catch (error) {
          console.error("An error occurred:", error);
          // Handle error scenario
        }
        // handleCloseManualDialog();
      }
    };

    const handleUploadPicture = () => {
      setPictureDialogOpen(true);
    };

    const handleClosePictureDialog = () => {
      setPictureDialogOpen(false);
    };

    const handleConfirmPicture = () => {
      // Add your logic for handling "Confirm" button in picture dialog
      console.log("Confirm Picture clicked");
      handleClosePictureDialog(); // Close the picture dialog after handling
    };

    const handleImageUpload = (event) => {
      const uploadedImage = event.target.files[0];
      // Perform actions with the uploaded image, e.g., display a preview, send to the server, etc.
      console.log("Uploaded Image:", uploadedImage);
    };

    const filterNonZeroAndNaN = (nutrientDetails) => {
      const filteredDetails = {};
      for (const key in nutrientDetails) {
        if (
          key !== "food_name" &&
          isFinite(nutrientDetails[key]) &&
          nutrientDetails[key] !== 0 &&
          nutrientDetails[key] !== 0.0
        ) {
          filteredDetails[key] = nutrientDetails[key];
        }
      }
      return filteredDetails;
    };
    var scaledNutrientDetails = {};
    const fetchScaledNutrientDetails = async (foodName, foodWeight) => {
      try {
        const response = await fetch(apiUrl + "/api/get-food-details", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            food_name: foodName,
          }),
        });

        const result = await response.json();
        console.log(result);

        if (result.status === true) {
          const foodDetails = result.foodDetails;
          console.log("foodDetails", foodDetails);
          setFoodId(foodDetails.id);

          // Calculate scaled nutrient details based on entered weight
          const scaledNutrientDetails = {};
          for (const key in foodDetails) {
            if (key !== "food_name") {
              const scaledValue = foodDetails[key];
              scaledNutrientDetails[key] = foodDetails[key] * foodWeight; // Round to 2 decimal places
            }
          }

          return scaledNutrientDetails;
        } else {
          // Handle the case where the API request was not successful
          throw new Error(result.message);
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
        throw error;
      }
    };
    const handleGetIngredients = async () => {
      console.log("Food Name:", foodName);
      console.log("this is Food Weight:", foodWeight);

      try {
        const scaledNutrientDetails = await fetchScaledNutrientDetails(
          foodName,
          foodWeight
        );
        console.log("Scaled Nutrient Details:", scaledNutrientDetails);
        delete scaledNutrientDetails.id;
        delete scaledNutrientDetails.user_id;
        // Update the nutrientData state with the fetched data
        // Update the filtered nutrient details by filtering out non-zero and non-NaN values
        const filteredDetails = filterNonZeroAndNaN(scaledNutrientDetails);
        console.log("Filtered Nutrient Details:", filteredDetails);
        setNutrientData(filteredDetails);
        setDisplayIngredients(true); // Display the nutrient details
        setNutrientData(filteredDetails);
        setPieChartDetails(filteredDetails);
      } catch (error) {
        console.error("Error:", error.message);
        // Handle the error scenario here
      }
      console.log("meal typeee", meal);
    };

    const IngredientsInfoComponent = ({
      foodName,
      foodWeight,
      nutrientData,
      pieChartData,
    }) => {
      console.log("pieChartData", pieChartData);
      console.log("foodName", foodName);
      console.log("foodWeight", foodWeight);
      const formatNutrientKey = (key) => {
        // Remove "_g" suffix if it exists
        let formattedKey = key.replace(/_g$/, "");

        // Capitalize the first letter
        formattedKey =
          formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);

        // Display only the first word for keys with underscores
        if (formattedKey.includes("_")) {
          formattedKey = formattedKey.split("_")[0];
        }

        return formattedKey;
      };

      const formatNutrientValue = (value) => {
        // Add " g" after the value
        return `${value} g`;
      };

      return (
        <Container>
          <Grid container spacing={0.5} className={classes.verticalcontainer3}>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              // className={classes.verticalcontainer3}
            >
              <Typography className={classes.text1}>
                {constants.nutrientComposition}
              </Typography>

              {nutrientData && (
                <div>
                  {Object.entries(nutrientData).map(([key, value]) => (
                    <div key={key}>
                      <span className={classes.nutrientKey}>
                        {formatNutrientKey(key)}:{"  "}
                      </span>
                      <span className={classes.nutrientValue}>
                        {formatNutrientValue(value)}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              className={classes.verticalcontainer4}
            >
              <PieChartComp apiData={pieChartData} foodWeight={foodWeight} />
            </Grid>
          </Grid>
        </Container>
      );
    };
    return (
      <Dialog open={open} onClose={onClose}>
        {mess ? (
          <Dialog
            open={open}
            onClose={onClose}
            style={{ backgroundColor: constants.lighterOrange }}
          >
            <DialogTitle className={classes.text1}>
              {constants.addFood}
            </DialogTitle>
            <DialogContent className={classes.verticalcontainer}>
              <Typography>{constants.mealAddedSuccessfully}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose();
                  setReload(!reload);
                }}
                className={`${classes.buttonstyle}${classes.colorOrange}`}
              >
                {constants.close}
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <>
            {/* <DialogTitle className={classes.text1}>Add Food</DialogTitle> */}
            <div
              style={{
                display: "flex",
                width: "90%",
                justifyContent: "space-between",
              }}
            >
              <DialogTitle className={classes.text1}>
                {constants.searchFood}
              </DialogTitle>
              <IconButton
                edge="end"
                color="red"
                onClick={onClose}
                aria-label="close"
                className={classes.closeButton}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <ToastContainer />
            </div>
            <DialogContent className={classes.verticalcontainer2}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[...foodObjects]}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setFoodName(newValue);
                    fetchFoodId(newValue);
                    handleGetIngredients();
                  }
                  // Update the context's foodName
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Food Name"
                    variant="filled"
                    type="text"
                    margin="normal"
                    value={foodName}
                    onChange={(e) => setFoodName(e.target.value)}
                    className={classes.input}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: classes.inputLabel,
                      },
                    }}
                    style={{ backgroundColor: "#F5F5F5", borderRadius: "2%" }}
                    //   InputProps={{
                    //     classes: {
                    //       notchedOutline: classes.notchedOutline,
                    //     },
                    //   }}
                  />
                )}
              />
              <TextField
                label="Food Weight (in grams)"
                variant="filled"
                value={foodWeight}
                onChange={(e) => setFoodWeight(e.target.value)}
                type="number"
                fullWidth
                margin="normal"
                className={classes.input}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: classes.inputLabel,
                  },
                }}
                // InputProps={{
                //   classes: {
                //     notchedOutline: classes.notchedOutline,
                //   },
                // }}
                style={{ backgroundColor: "#F5F5F5", borderRadius: "2%" }}
              />

              {displayIngredients && (
                <IngredientsInfoComponent
                  foodName={foodName}
                  foodWeight={foodWeight}
                  nutrientData={nutrientData}
                  pieChartData={pieChartDetails}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirmManual}
                className={[classes.buttonstyle, classes.colorOrange]}
              >
                {constants.addMeal}
              </Button>
              <Button
                onClick={handleGetIngredients}
                className={[classes.buttonstyle, classes.colorOrange]}
              >
                {constants.getNutrients}
              </Button>
            </DialogActions>

            <Dialog open={pictureDialogOpen} onClose={handleClosePictureDialog}>
              <DialogTitle className={classes.text1}>
                {constants.uploadPicture}
              </DialogTitle>
              <DialogContent>
                <input
                  type="file"
                  accept="image/*"
                  className={classes.input}
                  onChange={handleImageUpload} // Replace with your image upload handler
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClosePictureDialog}
                  className={classes.buttonstyle}
                >
                  {constants.cancel}
                </Button>
                <Button
                  onClick={handleConfirmPicture}
                  className={classes.buttonstyle}
                >
                  {constants.confirm}
                </Button>
                <Button className={classes.buttonstyle}>Get nutrients</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Dialog>
    );
  };
  const handleAddIconClick = (mealType) => {
    console.log("Meal Type Clicked:", mealType);
    setMeal(mealType);
  };
  return (
    <Container className={classes.container}>
      <Grid>
        <Container className={classes.horizontalcontainer}>
          {rec && (
            <>
              {diet && (
                <Grid
                  style={{
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                  spacing={0.5}
                  container
                >
                  <Grid
                    item
                    sm={12}
                    md={5}
                    lg={3}
                    style={{ marginBottom: "15px" }}
                  >
                    <MealCard
                      selectedButtonContent={constants.breakfast}
                      mealType={constants.breakfast}
                      onAddClick={handleAddIconClick}
                      day={selectedButtonContent.index}
                      rec={rec}
                      diet={diet}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={5}
                    lg={3}
                    style={{ marginBottom: "15px" }}
                  >
                    <MealCard
                      selectedButtonContent={
                        constants.lunch
                      } /* Example value for selectedButtonContent */
                      mealType={constants.lunch}
                      onAddClick={handleAddIconClick}
                      day={selectedButtonContent.index}
                      rec={rec} /* Pass the recommendedCalories value */
                      diet={diet}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={5}
                    lg={3}
                    style={{ marginBottom: "15px" }}
                  >
                    <MealCard
                      selectedButtonContent={
                        constants.dinner
                      } /* Example value for selectedButtonContent */
                      mealType={constants.dinner}
                      onAddClick={handleAddIconClick}
                      day={selectedButtonContent.index}
                      rec={rec} /* Pass the recommendedCalories value */
                      diet={diet}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={5}
                    lg={3}
                    style={{ marginBottom: "15px" }}
                  >
                    <MealCard
                      selectedButtonContent={
                        constants.snack
                      } /* Example value for selectedButtonContent */
                      mealType={constants.snack}
                      onAddClick={handleAddIconClick}
                      day={selectedButtonContent.index}
                      rec={rec} /* Pass the recommendedCalories value */
                      diet={diet}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Container>
      </Grid>

      {showaccordion && (
        <>
          {diet && (
            <>
              <AccordionSection
                mealType={diet} // Pass the selected meal type
                selectedButtonContent={meal} // Pass the selected button content
                setReload={setReload}
                reload={reload}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default FoodAdder;
