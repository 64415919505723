import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  CartesianGrid,
} from "recharts";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import constants from "../../../../constants.json";
import { useKalorie } from "../../../../userContext/context";

const SimpleAreaChart = ({ rec, dietSummary, type, microNutrients }) => {
  // const [menuValue, setMenuValue] = useState("weekly");

  const { dateTrackingDiary, menuValue, setMenuValue } = useKalorie();
  const [day, setday] = useState(1);
  console.log(
    "helloooooooo area chart",
    rec,
    dietSummary,
    type,
    microNutrients,
    menuValue,
    dateTrackingDiary
  );
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const data = [];
  // function formatDate(date) {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");

  //   return `${year}-${month}-${day}`;
  // }
  // const handleDateChange = (numOfDays) => {
  //   const newDate = new Date(selectedDate);
  //   newDate.setDate(selectedDate.getDate() + numOfDays);
  //   setSelectedDate(newDate);
  // };
  // function getStartAndEndDateOfWeek(date) {
  //   const currentDate = new Date(date);
  //   const currentDay = currentDate.getDay();
  //   const startDate = new Date(currentDate);
  //   startDate.setDate(currentDate.getDate() - currentDay + 1);

  //   const endDate = new Date(startDate);
  //   endDate.setDate(startDate.getDate() + 6);

  //   const formattedStartDate = formatDate(startDate);
  //   const formattedEndDate = formatDate(endDate);

  //   return { startDate: formattedStartDate, endDate: formattedEndDate };
  // }

  console.log(
    dateTrackingDiary,
    menuValue,
    "this is diet summaryyyyyyyyyyyyyy",
    dietSummary[0]
  );
  if (type === "totalCal") {
    if (menuValue === "weekly") {
      console.log(
        dateTrackingDiary,
        menuValue,
        "inside the weeekly area chart ",
        dietSummary
      );
      dietSummary[0] &&
        dietSummary.map((items, index) => {
          console.log(
            dateTrackingDiary,
            menuValue,
            "inside the weeekly area chart part-2 "
          );
          data.push({
            name: items.date.split("-").reverse().join("-"),
            "Recommended Calories": rec,
            "Taken Calories": items.total_calories,
          });
        });
    } else if (menuValue === "daily") {
      dietSummary.map((items, index) => {
        if (items.date === dateTrackingDiary) {
          console.log("hello haniya", items.snacks_total_calories);
          data.push({
            name: constants.breakfast,
            "Recommended Calories": rec / 4,
            "Taken Calories": items?.breakfast_total_calories,
          });
          data.push({
            name: constants.lunch,
            "Recommended Calories": rec / 4,
            "Taken Calories": items?.lunch_total_calories,
          });
          data.push({
            name: constants.snack,
            "Recommended Calories": rec / 4,
            "Taken Calories": items?.snacks_total_calories,
          });
          data.push({
            name: constants.dinner,
            "Recommended Calories": rec / 4,
            "Taken Calories": items?.dinner_total_calories,
          });
        }
      });
    }
  } else if (type === "macro") {
    console.log(
      "in the area chart daily",
      microNutrients,
      "jhruerkjer0",
      menuValue,
      "the date ",
      dateTrackingDiary
    );

    if (menuValue === "weekly") {
      dietSummary.map((items, index) => {
        data.push({
          name: items.date.split("-").reverse().join("-"),
          "Protiens (g)": items.total_protein,
          "Carbs (g)": items.total_carbs,
          "Fats (g)": items.total_fats,
        });
      });
    } else if (menuValue === "daily") {
      microNutrients.map((items, index) => {
        if (items.date === dateTrackingDiary) {
          data.push({
            name: constants.breakfast,
            "Protiens (g)": items?.breakfast_micronutrients?.total_protein,
            "Carbs (g)": items?.breakfast_micronutrients?.total_carbs,
            "Fats (g)": items?.breakfast_micronutrients?.total_fats,
          });
          data.push({
            name: constants.lunch,
            "Protiens (g)": items?.lunch_micronutrients?.total_protein,
            "Carbs (g)": items?.lunch_micronutrients?.total_carbs,
            "Fats (g)": items?.lunch_micronutrients?.total_fats,
          });
          data.push({
            name: constants.snack,
            "Protiens (g)":
              items?.snacks_micronutrients?.total_protein !== undefined
                ? items?.snacks_micronutrients?.total_protein
                : 0,
            "Carbs (g)":
              items?.snacks_micronutrients?.total_carbs !== undefined
                ? items?.snacks_micronutrients?.total_carbs
                : 0,
            "Fats (g)":
              items?.snacks_micronutrients?.total_fats !== undefined
                ? items?.snacks_micronutrients?.total_fats
                : 0,
          });

          data.push({
            name: constants.dinner,
            "Protiens (g)": items?.dinner_micronutrients?.total_protein,
            "Carbs (g)": items?.dinner_micronutrients?.total_carbs,
            "Fats (g)": items?.dinner_micronutrients?.total_fats,
          });
        }
      });
    }
  }
  // const getWeekDates = (currentDate) => { 03025084666
  //   const weekStart = new Date(
  //     currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)
  //   );
  //   const weekEnd = new Date(
  //     currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7)
  //   );
  //   return {
  //     startWeek: weekStart.toISOString().split("T")[0],
  //     weekStart: weekStart.getDate(),
  //     month: weekStart.toLocaleString("default", { month: "long" }),
  //     endWeek: weekEnd.toISOString().split("T")[0],
  //     weekEnd: weekEnd.getDate(),
  //   };
  // };
  // const [currentDate, setCurrentDate] = useState(new Date());

  // const { weekStart, weekEnd, startWeek, endWeek, month } = getWeekDates(
  //   new Date(currentDate)
  // );

  // const goToPreviousWeek = () => {
  //   setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
  // };

  // const goToNextWeek = () => {
  //   setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
  // };
  // const goToPreviousDate = () => {
  //   setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  // };

  // const goToNextDate = () => {
  //   setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  // };
  console.log("hania here", data);
  return (
    <div
      style={{ height: "400px", width: "100%" }}
      className="bg-whiteColor rounded-xl shadow-md"
    >
      <div className="px-4">
        {/* <div className="flex justify-center items-center">
          {menuValue === "weekly" ? (
            <>
              <button className="m-2" onClick={goToPreviousWeek}>
                {"<"}
              </button>
              <button className="border-2 m-4">
                {`${weekStart} - ${weekEnd} ${month}`}
              </button>
              <button className=" m-2" onClick={goToNextWeek}>
                {">"}
              </button>
            </>
          ) : (
            <>
              <button className="m-2" onClick={goToPreviousDate}>
                {"<"}
              </button>
              <button className="border-2 m-4">
                {selectedDate.toLocaleDateString()}{" "}
                Display selected date
              </button>
              <button className=" m-2" onClick={goToNextDate}>
                {">"}
              </button>
            </>
          )}*/}
        {/* <FormControl sx={{ m: 1, minWidth: 100 }} size="small"> */}
        {/* <InputLabel id="demo-select-small-label">Duration</InputLabel> */}
        {/* <Select
            sx={{ height: 30 }}
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={menuValue}
            // label="Weekly"
          >
            <MenuItem
              onClick={() => {
                setMenuValue("weekly");
              }}
              value={"weekly"}
            >
              {constants.weekly}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setMenuValue("daily");
              }}
              value={"daily"}
            >
              {constants.daily}
            </MenuItem>
          </Select>
        </FormControl> */}
      </div>

      <ResponsiveContainer width="100%" height="80%">
        <AreaChart
          data={data}
          width={500}
          height={300}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorAv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ffcccc" stopOpacity={0.8} />{" "}
              {/* Light red color */}
              <stop offset="95%" stopColor="#ffcccc" stopOpacity={0} />{" "}
              {/* Light red color with 0 opacity */}
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip /> <Legend />
          {type === "totalCal" ? (
            <>
              <Area
                legendType="square"
                type="monotone"
                dataKey="Recommended Calories"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorUv)"
              />
              <Area
                legendType="rec"
                type="monotone"
                dataKey="Taken Calories"
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorPv)"
              />
            </>
          ) : (
            <>
              <Area
                legendType="square"
                type="monotone"
                dataKey="Protiens (g)"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorUv)"
              />
              <Area
                legendType="rec"
                type="monotone"
                dataKey="Carbs (g)"
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorPv)"
              />
              <Area
                legendType="star"
                type="monotone"
                dataKey="Fats (g)"
                stroke="red"
                fillOpacity={1}
                fill="url(#colorAv)"
              />
            </>
          )}
        </AreaChart>
      </ResponsiveContainer>
      {/* </div> */}
    </div>
  );
};
export default SimpleAreaChart;
