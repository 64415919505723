import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import PostAddSharpIcon from "@material-ui/icons/PostAddSharp";
import AllInboxSharpIcon from "@material-ui/icons/AllInboxSharp";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Link from "@mui/material/Link";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
export const MainListItems = ({ activeTab, setActiveTab }) => (
  <div>
    <Link href="/dietitiondashboard" underline="none" color="#FFFFFFE6">
      <ListItem
        button
        selected={activeTab === "Dashboard"}
        onClick={() => setActiveTab("Dashboard")}
      >
        <ListItemIcon>
          <DashboardOutlinedIcon
            className="w-6 h-6 mr-2 mb-1 "
            sx={{ color: "#FFFFFFE6" }}
          />
        </ListItemIcon>
        <ListItemText
          className="text-lg text-[#FFFFFFE6] font-medium"
          primary="Dietition Portal"
        />
      </ListItem>
    </Link>
    <Link href="/dietitionClients" underline="none" color="#FFFFFFE6">
      <ListItem
        button
        selected={activeTab === "Clients"}
        onClick={() => setActiveTab("Clients")}
      >
        <ListItemIcon>
          <FactCheckOutlinedIcon
            className="w-6 h-6 mr-2 mb-1 "
            sx={{ color: "#FFFFFFE6" }}
          />
        </ListItemIcon>
        <ListItemText
          className="text-lg text-[#FFFFFFE6] font-medium"
          primary="Clients"
        />
      </ListItem>
    </Link>
    <Link href="/dietuserrequests" underline="none" color="#FFFFFFE6">
      <ListItem
        button
        selected={activeTab === "Diet Plan Requests"}
        onClick={() => setActiveTab("Diet Plan Requests")}
      >
        <ListItemIcon>
          <BookOutlinedIcon
            className="w-6 h-6 mr-2 mb-1 "
            sx={{ color: "#FFFFFFE6" }}
          />
        </ListItemIcon>
        <ListItemText
          className="text-lg text-[#FFFFFFE6] font-medium"
          primary="Diet Plan Requests"
        />
      </ListItem>
    </Link>
  </div>
);
