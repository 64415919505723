import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKalorie } from "../../userContext/context";
import { useLocation } from "react-router-dom";
import constants from "../../constants.json";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import useStyles from "../../assets/css/common.js";
import logo from "../../assets/images/logo.png";

const InfoPage2 = () => {
  const classes = useStyles();
  const { data, updateData } = useKalorie();
  const navigate = useNavigate();
  const [successful, setSuccessful] = useState(true);
  const [bmr, setBmr] = useState(null);
  const [protein, setProtein] = useState(null);
  const [fats, setFats] = useState(null);

  const [carbs, setCarbs] = useState(null);
  const [isGoalSelected, setIsGoalSelected] = useState(true);
  const [selectedGoal, setSelectedGoal] = useState(data.goal_id || "");

  const location = useLocation();
  const screenStatus = location.state;
  const handleGoalChange = (event) => {
    const newGoal = event.target.value;
    setSelectedGoal(newGoal); // Update local state
    updateData("goal_id", newGoal); // Update context
    setIsGoalSelected(true);
    console.log("handle data gaol value: ", event.target.value);
    console.log("handle data" + data);
    console.log("handle data goal" + data.goal);
    console.log("handle data weight" + data.weight);
  };

  function calculateAge(dob) {
    if (!dob) return null; // Handle the case where dateOfBirth is not provided

    const currentDate = new Date();
    const birthDate = new Date(dob);

    const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
    const isSameMonthAndDay =
      currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate();
    const age = isSameMonthAndDay ? yearsDiff - 1 : yearsDiff;

    return age;
  }
  function convertHeightToCentimeters(height, unit) {
    const heightFloat = parseFloat(height);

    if (isNaN(heightFloat)) {
      return "Invalid input";
    }

    switch (unit) {
      case "Centimeters":
        return heightFloat;
      case "Meters":
        return heightFloat * 100; // Convert meters to centimeters

      default:
        return "Invalid unit";
    }
  }

  function convertWeightToKilograms(weight, unit) {
    const weightFloat = parseFloat(weight);

    if (isNaN(weightFloat)) {
      return "Invalid input";
    }

    switch (unit) {
      case "Kilograms":
        return weightFloat;
      case "Pounds":
        return weightFloat * 0.45359237; // Convert pounds to kilograms

      default:
        return "Invalid unit";
    }
  }

  useEffect(() => {
    console.log("use effect data", data);

    const age = calculateAge(data.dob);
    const height = convertHeightToCentimeters(
      parseFloat(data.height),
      data.height_units
    );
    console.log("height1", height);

    const weight = convertWeightToKilograms(
      parseFloat(data.weight),
      data.weight_units
    );

    if (data.gender === "female") {
      console.log(weight);
      console.log(height);
      console.log(age);
      const bmr = Math.round(
        447.593 + 9.247 * weight + 3.098 * height - 4.33 * age
      );
      console.log("bmr", bmr);
      setBmr(bmr);
    }
    if (data.gender === "male") {
      console.log(weight);
      console.log(height);
      console.log(age);
      const bmr = Math.round(
        88.362 + 13.397 * weight + 4.799 * height - 5.677 * age
      );
      setBmr(bmr);
    }

    setCarbs(Math.round(0.3 * bmr));

    setProtein(Math.round(0.2 * bmr));
    setFats(Math.round(0.2 * bmr));
  }, [data, bmr]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!data.goal_id || data.goal_id === "") {
      setIsGoalSelected(false); // Show error message
      return; // Prevent form submission
    }
    screenStatus === "Get a Diet Plan"
      ? navigate("/selectDietician", {})
      : navigate("/signup", {
        state: screenStatus,
      });
  };
  const goback = () => {
    navigate(-1);
  };
  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={classes.outercontainer_info2}
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            variant="h4"
            className={classes.text1}
          >
            <img
              style={{ marginRight: "10px" }}
              src={logo}
              alt="Logo is missing"
            />
            {constants.appTitle}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon size="small" />}
            className={classes.buttonstyle3}
            onClick={goback}
          >
            Back
          </Button>
        </Grid>
        <Grid item className={classes.triangleRight}></Grid>
        <Grid item className={classes.triangleLeft}></Grid>
        <Grid style={{
          width: "100%",
          justifyContent: "center",
        }} container>
          <Grid item sm={12} md={6} lg={4} className={classes.mainContainer}>
            <Box
              className={classes.innerBox}
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <FormLabel
                id="demo-radio-buttons-group-label"
                className={classes.labelstyle2_info2}
              >
                What is your Goal?
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedGoal}
                name="radio-buttons-group"
                onChange={handleGoalChange}
              >
                <FormControlLabel
                  className={classes.margincontainer_info2}
                  value="Maintain Weight"
                  control={
                    <Radio
                      size="small"
                      classes={{ root: classes.radio_info2 }}
                    />
                  }
                  label="Maintain Weight"
                  classes={{ label: classes.labelstyle3_info2 }}
                />
                <FormControlLabel
                  className={classes.margincontainer_info2}
                  value="Lose Weight"
                  label="Lose Weight"
                  control={
                    <Radio
                      size="small"
                      classes={{ root: classes.radio_info2 }}
                    />
                  }
                  classes={{ label: classes.labelstyle3_info2 }}
                />
                <FormControlLabel
                  className={classes.margincontainer_info2}
                  value="Gain Weight"
                  control={
                    <Radio
                      size="small"
                      classes={{ root: classes.radio_info2 }}
                    />
                  }
                  label="Gain Weight"
                  classes={{ label: classes.labelstyle3_info2 }}
                />
              </RadioGroup>

              {!successful && (
                <Typography
                  className="purpleClass
               fontFamilyClass
              "
                  style={{ color: "red" }}
                >
                  User with this email is already registered
                </Typography>
              )}
              {!isGoalSelected && (
                <Typography style={{ color: "red", textAlign: "center" }}>
                  Please select a goal
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                size="medium"
                align="center"
                className={classes.buttonstyle_info2}
              >
                Continue
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage2;
