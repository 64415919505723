import { createStyles, makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { useRef, useEffect, useState } from "react";

import SaveIcon from "@material-ui/icons/Save";
import { useKalorie } from "../../../../userContext/context";
import constants from "../../../../constants.json";
const useStyles = makeStyles((theme) => ({
  outercontainer: {
    width: "50%",
    height: "100%",
    // border: "2px solid #A87DB4",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
    height: "100%",
    // border: "2px solid #A87DB4",
    marginTop: "6rem",
  },
  input: {
    marginBottom: "1.5rem",
    width: "100%",
    // heught: "30px",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    borderRadius: "100px",
  },
  notchedOutline: {
    borderColor: "#FFD171 !important", // Change the border color of the outline
  },
  inputLabel: {
    color: "#FFD171 !important", // Customize placeholder color
    borderColor: "#FFD171",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    borderRadius: "100px",
    width: "100%",
    "&.Mui-focused": {
      color: "#FFD171 !important", // Customize focus color
      borderColor: "#FFD171",
      fontFamily: constants.fontFamily,
      fontSize: "1rem",
    },
  },

  Icon: {
    color: "#FF7171",
    fontSize: "1.5rem",

    marginRight: "0.5rem",
    marginBottom: "-0.3rem",

    // alignSelf: "flex-end",
  },

  labelContainer: {
    width: "350px",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    // border: "2px solid #A87DB4",
    marginBottom: "1rem",
    fontFamily: constants.fontFamily,
  },
  calendarIcon: {
    color: "#FFD171",
    fontSize: "1.5rem",
  },
  labelStyle: {
    width: "75%",
    height: "30px",
    borderRadius: "30px",
    border: "2px solid #FF7171",
    color: "#FF7171 ", // Customize placeholder color
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    // alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "5rem",
    paddingTop: "0.5rem",
    cursor: "pointer",
    marginBottom: "1rem",
    fontFamily: constants.fontFamily,
  },
  labelStyle2: {
    width: "75%",
    height: "30px",
    borderRadius: "30px",
    border: "2px solid #FFD171",
    color: "#FFD171 ", // Customize placeholder color
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    paddingTop: "0.5rem",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "5rem",
    cursor: "pointer",
    "& $Icon": {
      // Apply the styles to the nested Icon component
      color: "#FFD171",
    },
  },
  loadingMessage: {
    color: "#777", // Customize text color
    fontSize: "1.4rem", // Customize font size
    marginTop: "1rem", // Customize top margin
    alignContent: "center",
    color: "#FFD171 ", // Customize placeholder color
    fontFamily: constants.fontFamily,
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  },
}));

const CustomTextField = ({
  label,
  type,
  defaultValue,
  readOnly,
  onChange,
  name,
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    onChange(name, event.target.value);
  };

  return (
    <TextField
      id={`outlined-${label.toLowerCase().replace(" ", "-")}-input`}
      label={label}
      type={type}
      defaultValue={defaultValue}
      variant="outlined"
      className={classes.input}
      name={name}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          focused: classes.inputLabel,
        },
      }}
      InputProps={{
        style: {
          color: "#38A389",
          fontFamily: constants.fontFamily,
          fontSize: "1.3rem",
          borderRadius: "50px",
          height: "45px",
          marginTop: "0.1rem",
        },
        classes: {
          notchedOutline: classes.notchedOutline,
        },
        readOnly: readOnly,
      }}
      onChange={handleChange}
    />
  );
};

const EditProfile = () => {
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [profileInfo, setProfileInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
    weight: "",
    height: "",
    weight_units: "",
    height_units: "",
    goal: "",
  });
  const { data } = useKalorie();

  const handleFieldChange = (fieldName, newValue) => {
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      [fieldName]: newValue,
    }));
  };
  const handleEditClick = () => {
    setEditable(true);
  };

  const handleSaveClick = async () => {
    setEditable(false);
    try {
      const response = await fetch(apiUrl + "/api/update-profile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: profileInfo.first_name,
          last_name: profileInfo.last_name,
          email: data.email,
          gender: profileInfo.gender,
          dob: profileInfo.dob,
          weight: profileInfo.weight,
          weight_units: profileInfo.weight_units,
          height: profileInfo.height,
          height_units: profileInfo.height_units,
          goal: profileInfo.goal,
        }),
      });

      const result = await response.json();
      console.log(result.status);

      if (result.status === true) {
        console.log("response from edit profile api", result);
      } else {
        console.log("edit profile api error");
        // Handle the error case here
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
  };

  useEffect(() => {
    async function fetchProfileInfo() {
      if (!data || !data.email) {
        return; // Ensure data and data.email are defined
      }

      try {
        const response = await fetch(apiUrl + "/api/get-profile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: data.email,
          }),
        });

        const result = await response.json();
        console.log(result.status);

        if (result.status === true) {
          console.log("response from view profile api", result);
          setProfileInfo(result.profileData);
        } else {
          console.log("view profile api error");
          // Handle the error case here
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
      }
    }

    fetchProfileInfo();
  }, [data]);

  // Conditional rendering based on whether profileInfo is populated
  return (
    <div className={classes.outercontainer}>
      <form noValidate autoComplete="off" className={classes.form}>
        {profileInfo.email ? ( // Check if email is populated (or any other suitable field)
          <>
            <CustomTextField
              label="First Name"
              defaultValue={profileInfo.first_name}
              readOnly={!editable}
              name="first_name"
              onChange={handleFieldChange}
            />

            <CustomTextField
              label="Last Name"
              defaultValue={profileInfo.last_name}
              readOnly={!editable}
              name="last_name"
              onChange={handleFieldChange}
            />
            <CustomTextField
              label="Email(Read Only)"
              type="email"
              name="email"
              defaultValue={profileInfo.email}
              readOnly={true}
            />
            <CustomTextField
              label="Date of Birth"
              type="date"
              defaultValue={profileInfo.dob}
              name="dob"
              readOnly={!editable}
              onChange={handleFieldChange}
            />
            <CustomTextField
              label="Weight(Kilograms)"
              type="number"
              defaultValue={profileInfo.weight}
              readOnly={!editable}
              name="weight"
              onChange={handleFieldChange}
            />
            <CustomTextField
              label="Height(Centimeter)"
              type="number"
              defaultValue={profileInfo.height}
              name="height"
              readOnly={!editable}
              onChange={handleFieldChange}
            />
          </>
        ) : (
          <Typography className={classes.loadingMessage}>
            Loading profile data...
          </Typography>
        )}

        {editable ? (
          <label className={classes.labelStyle2} onClick={handleSaveClick}>
            <SaveIcon className={classes.Icon} />
            Save Changes
          </label>
        ) : (
          <label className={classes.labelStyle} onClick={handleEditClick}>
            <EditIcon className={classes.Icon} />
            Edit Profile
          </label>
        )}
      </form>
    </div>
  );
};

export default EditProfile;
