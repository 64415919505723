import React from "react";
import { MenuItem, FormControl, Select } from "@mui/material";
import UnapprovedFoodFactSlider from "./unapprovedFoodFactSlider";
import ApprovedFoodFactCardSlider from "./approvedFootFactSlider";
import ApprovedFoodItems from "./approvedFoodItems";
import { useParams } from "react-router-dom";
import UnapprovedFoodItems from "./unapprovedfoodItems";
import UnapprovedFoodTips from "./unapprovedFoodTips";
import ApprovedFoodTips from "./approvedFoodTips";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
export default function ApprovedRequest() {
  const [option, setoption] = useState("facts");
  const [unapfacts, setunapfacts] = useState([]);
  const [apfacts, setapfacts] = useState([]);
  const [aptips, setaptips] = useState([]);
  const [unaptips, setunaptips] = useState([]);
  const [unapitems, setunapitems] = useState([]);
  const [apitems, setapitems] = useState([]);
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;

  const handleApproveFact = (factId, fact) => {
    console.log(fact);
    console.log("approve request page", factId, fact);
    const factToApprove = { ...fact, approved: 1 };

    const updateFactApiEndpoint = `${serverIp}:${port}/api/food-facts/${factId}`;
    axios
      .put(updateFactApiEndpoint, factToApprove)
      .then((response) => {
        console.log(response.data);
        if (response.status) {
          //     const getFacts=`${serverIp}:${port}/api/nutritionist/foodfacts/${id}`;
          // axios.get(getFacts)
          // .then(response => {
          //   if(response.status){
          //     // console.log(response.data.approvedFoodFacts);
          //     setunapfacts(response.data.unapprovedFoodFacts);
          //     setapfacts(response.data.approvedFoodFacts);
          //   }
          // })
          // .catch(error => {
          //   alert(error.message);
          // });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const params = useParams();
  const id = params.id;
  const name = params.name;
  useEffect(() => {
    if (id) {
      const getFacts = `${serverIp}:${port}/api/nutritionist/foodfacts/${id}`;
      axios
        .get(getFacts)
        .then((response) => {
          if (response.status) {
            setunapfacts(response.data.unapprovedFoodFacts);
            setapfacts(response.data.approvedFoodFacts);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);
  const handleChange = (event) => {
    setoption(event.target.value);
    console.log(option, id);
    if (option === "facts" && id) {
      const getFacts = `${serverIp}:${port}/api/nutritionist/foodfacts/${id}`;
      axios
        .get(getFacts)
        .then((response) => {
          if (response.status) {
            setunapfacts(response.data.unapprovedFoodFacts);
            setapfacts(response.data.approvedFoodFacts);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (option === "tips" && id) {
      const gettips = `${serverIp}:${port}/api/nutritionist/foodtips/${id}`;
      axios
        .get(gettips)
        .then((response) => {
          if (response.status) {
            setunaptips(response.data.unapprovedFoodTips);
            setaptips(response.data.approvedFoodTips);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (option === "items" && id) {
      const getitems = `${serverIp}:${port}/api/nutritionist/fooditems/${id}`;
      axios
        .get(getitems)
        .then((response) => {
          if (response.status) {
            console.log(response.data);
            setunapitems(response.data.unapprovedFoodItems);
            setapitems(response.data.approvedFoodItems);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  return (
    <div className="ml-10 mr-10 mt-10">
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-semibold text-[#38A389] mt-2">
            Nutritionist: {name}
          </h1>
        </div>
        <div>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              id="demo-simple-select-required"
              className="mb-2"
              value={option}
              size="small"
              onChange={handleChange}
            >
              <MenuItem value="facts">Facts</MenuItem>
              <MenuItem value="tips">Tips</MenuItem>
              <MenuItem value="items">Items</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {option === "facts" && (
        <div>
          <h1 className="text-xl text-[#38A389] tracking-wide font-semibold">
            UnApproved Food Fact
          </h1>
          <div style={{ height: "50%" }}>
            <UnapprovedFoodFactSlider
              unapfacts={unapfacts}
              onApprove={handleApproveFact}
            />
          </div>
          <h1 className="text-xl mt-5 text-[#38A389] tracking-wide font-semibold">
            Approved Food Facts
          </h1>
          <div className="mt-2" style={{ height: "50%" }}>
            <ApprovedFoodFactCardSlider apfacts={apfacts} />
          </div>
        </div>
      )}

      {option === "tips" && (
        <div>
          <h1 className="text-xl text-[#38A389] tracking-wide font-semibold">
            UnApproved Food Tips
          </h1>
          <div style={{ height: "50%" }}>
            <UnapprovedFoodTips unaptips={unaptips} />
          </div>
          <h1 className="text-xl mt-5 text-[#38A389] tracking-wide font-semibold">
            Approved Food Tips
          </h1>
          <div className="mt-2" style={{ height: "50%" }}>
            <ApprovedFoodTips aptips={aptips} />
          </div>
        </div>
      )}

      {option === "items" && (
        <div>
          <h1 className="text-xl text-[#38A389] tracking-wide font-semibold">
            UnApproved Food Items
          </h1>
          <div style={{ height: "50%" }}>
            <UnapprovedFoodItems unapitems={unapitems} />
          </div>
          <h1 className="text-xl mt-5 text-[#38A389] tracking-wide font-semibold">
            Approved Food Items
          </h1>
          <div className="mt-2" style={{ height: "50%" }}>
            <ApprovedFoodItems apitems={apitems} />
          </div>
        </div>
      )}
    </div>
  );
}
