import { makeStyles } from "@material-ui/core/styles";
import constants from "../../constants.json";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "20px",
    justifyContent: "center",
    alignItems: "center",
  },
  spaceBetweenFlex: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingRight: "5%",
  },
  horizontalcontainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "30px",
    alignItems: "center",
  },
  horizontalcontainer2: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    fontFamily: constants.fontFamily,
  },
  boldText: {
    fontWeight: "bold",
  },
  text1: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.5rem",
    margin: "0px 10px 0px 0px",
    // width: "100%",
    // border: "2px solid black",
  },
  text_title: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.8rem",
    margin: "0px 10px 0px 0px",
    fontWeight: "bold",
  },
  smallerText: {
    fontSize: "1rem",
  },
  text2: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.1rem",
    margin: "0px 10px 0px 0px",
  },
  disabled: {
    color: "#EBEBE4 !important",
    fontFamily: constants.fontFamily,
    fontSize: "1.1rem",
    margin: "0px 10px 0px 0px",
  },

  text3: {
    color: "#FF7171 !important",
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
    justifyItems: "center",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
  },
  text4: {
    color: "#A87DB4 !important",
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
    alignItems: "center",
    inlineSize: "200px",
  },
  text5: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
  },
  blackText: {
    color: constants.black,
    textAlign: "center",
  },
  cardstyle: {
    width: "95%",
    height: "100%",
    borderRadius: "10px",
    justifyContent: "center",
    alignItems: "center",
    // border: "2px solid #FFD171",
    marginLeft: "15px",
    marginRight: "15px",
    fontFamily: constants.fontFamily,
    backgroundColor: "rgba(255, 198, 100, 0.96)",
  },
  cardstyle1: {
    width: "90%",
    height: "100%",
    borderRadius: "10px",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "2rem",
    fontFamily: constants.fontFamily,
    backgroundColor: "rgba(255, 198, 100, 0.9)",
  },
  mealCardContainer: {
    margin: theme.spacing(0), // Adjust the spacing as needed
  },
  verticalcontainer: {
    display: "flex",
    flexDirection: "column",
    width: "250px",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: constants.fontFamily,
  },
  centeredContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  verticalcontainer2: {
    display: "flex",
    flexDirection: "column",
    // border: " 3px solid black",
    // paddingLeft: "2%",
  },
  red: { backgroundColor: "#ffc6645c" },
  verticalcontainer3: {
    display: "flex",
    flexDirection: "row",
  },
  verticalcontainer4: {
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    marginBottom: "0.8rem",
    width: "18.75rem",
    color: "#38A389",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    border: "none",
    boxShadow: "0px 4px 4px #00000040",
    borderRadius: "1rem !important",

    "& .MuiFilledInput-root": {
      background: "white",
      borderRadius: "1rem !important",
      border: "none",
    },

    "& input": {
      // Added CSS rule targeting input element
      fontFamily: constants.fontFamily,
    },
  },
  inlineContainer: {
    display: "flex",
    alignItems: "center",
  },
  smallerInput: {
    width: "16%",
    fontFamily: constants.fontFamily,
  },
  whiteBackground: {},
  notchedOutline: {
    borderColor: constants.blue,
    padding: "1rem",
  },

  inputLabel: {
    color: constants.black,
    borderColor: constants.blue,
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    "&.Mui-focused": {
      color: constants.primaryColor,
      borderColor: constants.primaryColor,
      fontFamily: constants.fontFamily,
      fontSize: "1rem",
    },
  },
  focusedStyle: {
    borderColor: constants.primaryColor,
    labelColor: constants.primaryColor,
  },
  closeButton: {
    // position: "relative",
    // top: 10,
    // left: "90%",
  },
  buttonstyle: {
    alignSelf: "center",
    marginTop: "1rem",
    color: "white",
    fontWeight: "bold",
    border: "1px solid white",
    fontFamily: constants.fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(2, 48, 106, 0.60)",
    borderRadius: "30px",
    fontSize: "12px",
    height: "30px",

    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: constants.blueTextColor,
    },
    "&:focus": {
      backgroundColor: constants.blueTextColor,
      outline: "none",
    },
  },
  buttonstyle1: {
    alignSelf: "center",
    marginTop: "1rem",
    color: "white",
    border: "1px solid white",
    fontFamily: constants.fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(2, 48, 106, 0.60)",
    borderRadius: "1.2rem",
    fontSize: "1.3rem",
    height: "2.4rem",
    paddingLeft: "1.3rem",
    paddingRight: "1.3rem",
    transition: "background-color 0.3s",
    textTransform: "none",
    "&:hover": {
      backgroundColor: constants.blueTextColor,
    },
    "&:focus": {
      backgroundColor: constants.blueTextColor,
      outline: "none",
    },
  },
  getdetailsbutton: {
    alignSelf: "center",
  },
  fontCaleda: {
    fontFamily: constants.fontFamily,
  },
  nutrientKey: {
    color: "black",
  },

  nutrientValue: {
    color: "black",
  },
  colorOrange: {
    backgroundColor: constants.orange,
  },
  colorBlue: {
    backgroundColor: constants.blue,
  },

  //accordin style
  accordinContainer: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
    marginTop: "0.5%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  accordionstyle: {
    width: "100%",
    marginTop: "0.25rem",
    padding: 0,
    paddingRight: 0,
    paddingLeft: 0,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: constants.grey1,
    // border: "2px solid yellow",
  },

  headertext: {
    color: constants.black,
    fontFamily: constants.fontFamily,
    fontSize: "1.5rem",
    // border: "2px solid blue",
  },
  acc_text1: {
    color: constants.black,
    fontFamily: constants.fontFamily,
    fontSize: "1.4rem",
    margin: "0px 0.5rem 0px 0px",
  },

  purpletext: {
    color: constants.primaryColor,
  },
  textcontainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    margin: 0,
  },
  detailscontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: "1.4rem",
    color: "#A87DB4",
    fontFamily: constants.fontFamily,
  },
  rowFlexStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  colorWhite: {
    color: "white",
  },
}));

export default useStyles;
