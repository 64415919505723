import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@material-ui/core/";
import trackimg from "../../../assets/images/service2.png";

import scanimg from "../../../assets/images/service1.png";
import resourceimg from "../../../assets/images/service3.png";
import constants from "../../../constants.json";
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "80%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      marginTop: "1rem",
    },
    text: {
      color: "#FDA101",
      fontWeight: "bold",
      fontFamily: constants.fontFamily,
    },
    logoimg: {
      borderRadius: "2%",
      width: "20vh",
      height: "15vh",
      margin: "auto",
    },
    cardcontainer: {
      width: "80%",
      height: "60vh",
    },
    itemContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(2),
    },
    headingtext: {
      color: "#31525B",
      fontWeight: "bold",
      fontFamily: constants.fontFamily,
      fontSize: "1.25em",
      textAlign: "center",
      marginLeft: theme.spacing(2),
      alignSelf: "center",
    },
    paratext: {
      color: "#02306A",
      fontFamily: constants.fontFamily,
      fontSize: "2.60vh",
      marginTop: theme.spacing(2),
      textAlign: "center",
    },
    straightcontainer: {
      display: "flex",
      flexDirection: "row",
    },
  })
);

const servicesitems = [
  {
    title: constants.dietPlanner,
    description:
      constants.takeControlOfYourCaloriesGoalGetYourOwnCustomMadeDietPlanByOurDietitions,
    image: trackimg,
  },
  {
    title: constants.foodDiary,
    description: constants.trackYourFoodIntakeOnaDialyBasisToLiveaHealthierLife,
    image: scanimg,
  },
  {
    title: constants.nutrientInsights,
    description:
      constants.accessaWideRangeOfResourcesToHelpYouOnYourHealthJourneyYouCanFindFoodFactsBlogsAsWellAsSearchForFoodItemHereInKalorieKompass,
    image: resourceimg,
  },
];

const Services = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      // spacing={0}
      justifyContent="center"
      alignItems="center"
      xs={8}
      style={{ maxWidth: "55%" }}
    >
      <Grid item sm={12} md={12} lg={12} style={{ marginTop: "5%" }}>
        <Typography variant="h4" className={classes.text} align="center">
          {constants.servicesWeAreProviding}
        </Typography>
      </Grid>

      {servicesitems.map((item) => (
        <Grid
          item
          sm={12}
          md={4}
          key={item.title}
          container
          direction="row"
          style={{
            justifyContent: "center", // Center horizontally
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <Card className={classes.cardcontainer}>
            <CardContent className={classes.itemContent}>
              <CardMedia
                component="img"
                alt="Service Image"
                height="180"
                image={item.image}
                title="Service Image"
                className={classes.logoimg}
              />
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className={classes.headingtext}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                className={classes.paratext}
              >
                {item.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Services;
