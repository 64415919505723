import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import PeopleIcon from '@mui/icons-material/People';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../adminScreens/adminScreens.css';
export default function AdminDashboard() {
  const percentage = 66;
  return (
    <div className='mt-20'>
    <div className='flex justify-center space-x-2'>
      <p>this page is under construction</p>
      {/* <div>
      <Card sx={{ maxWidth: 345,backgroundColor:"#E3CFF6" }}>
      <CardContent>
        <div className='flex items-center'>
        <div 
      ><CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
        strokeLinecap: 'round',
        textSize: '22px',
        width: '100px', 
    height: '100px',
        pathTransitionDuration: 0.5,
        pathColor: '#38A389',
        textColor: '#38A389',
        trailColor: 'white',
        backgroundColor: '#38A389',
      })} /></div>
      <div className='ml-3 py-6 nowrap-text'>
      <div className='flex justify-between'>
        <Typography gutterBottom variant="h6" component="div">
          Nutritionist's
        </Typography>
        <div className='flex mt-1'>
          <PeopleIcon/><span className='inline'><p className='text-lg ml-1'>55</p></span>
        </div>
        </div>
       <Typography className='text-2xl' variant="body2" color="text.secondary">
       66% requests are approved
        </Typography>
       </div>
        </div>
      </CardContent>
    </Card>
      </div>
      <div>
      <Card sx={{ maxWidth: 345,backgroundColor:"#B4EADD" }}>
      <CardContent>
        <div className='flex items-center'>
        <div 
      ><CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
        strokeLinecap: 'round',
        textSize: '22px',
        width: '100px', 
    height: '100px',
        pathTransitionDuration: 0.5,
        pathColor: '#38A389',
        textColor: '#38A389',
        trailColor: 'white',
        backgroundColor: '#38A389',
      })} /></div>
      <div className='ml-3 py-6 nowrap-text'>
      <div className='flex justify-between'>
        <Typography gutterBottom variant="h6" component="div">
          Bloggers
        </Typography>
        <div className='flex mt-1'>
          <PeopleIcon/><span className='inline'><p className='text-lg ml-1'>55</p></span>
        </div>
        </div>
       <Typography className='text-2xl' variant="body2" color="text.secondary">
       66% requests are approved
        </Typography>
       </div>
        </div>
      </CardContent>
    </Card>
      </div>
      <div>
      <Card sx={{ maxWidth: 345,backgroundColor:"#F4E0B4" }}>
      <CardContent>
        <div className='flex items-center'>
        <div 
      ><CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
        strokeLinecap: 'round',
        textSize: '22px',
        width: '100px', 
    height: '100px',
        pathTransitionDuration: 0.5,
        pathColor: '#38A389',
        textColor: '#38A389',
        trailColor: 'white',
        backgroundColor: '#38A389',
      })} /></div>
      <div className='ml-3 py-6 nowrap-text'>
      <div className='flex justify-between'>
       <p className='text-lg'>
          Nutritionist's
        </p>
        <div className='flex'>
          <PeopleIcon/><span className='inline'><p className='text-lg ml-1'>55</p></span>
        </div>
        </div>
       <Typography className='text-2xl' variant="body2" color="text.secondary">
       66% requests are approved
        </Typography>
       </div>
        </div>
      </CardContent>
    </Card>
      </div>
      <div>
      <Card sx={{ maxWidth: 340,backgroundColor:"#F4E0B4" }}>
      <CardContent>
      <div className='flex'>
       <p className='text-lg'>
          Nutritionist's
        </p>
        <div className='flex'>
          <PeopleIcon/><span className='inline'><p className='text-lg ml-1'>55</p></span>
        </div>
        </div>
        <div className='flex items-center'>
        <div 
      ><CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
        strokeLinecap: 'round',
        textSize: '22px',
        width: '50px', 
    height: '50px',
        pathTransitionDuration: 0.5,
        pathColor: '#38A389',
        textColor: '#38A389',
        trailColor: 'white',
        backgroundColor: '#38A389',
      })} /></div>
      <div className='ml-3 nowrap-text'>
     
       <Typography className='text-2xl' variant="body2" color="text.secondary">
       66% requests are approved
        </Typography>
       </div>
       </div>
        
      </CardContent>
    </Card>
      </div> */}
    </div>
    </div>
  )
}
