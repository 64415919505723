import Header from "../components/kalorieKompassComponents/starterpage/header";
import IntroSection from "../components/kalorieKompassComponents/starterpage/introsection";
import Services from "../components/kalorieKompassComponents/starterpage/services";
import SearchSection from "../components/kalorieKompassComponents/starterpage/searchSection";
import ExperienceSection from "../components/kalorieKompassComponents/starterpage/experiencesection";
import RecommendationSection from "../components/kalorieKompassComponents/starterpage/recommendationsection";
import Footer from "../components/kalorieKompassComponents/starterpage/footer";
import { useKalorie } from "../userContext/context";
import axios from "axios";
import useStyles from "../assets/css/style";
import React, { useState, useEffect } from "react";
const StarterPage = () => {
  const { data } = useKalorie();
  const classes = useStyles();
  const [foodfacts, setFoodFacts] = useState([]);
  console.log("this is context data:::::::", data);
  useEffect(() => {
    const getitems =
      "https://yqp4a5mgxj.execute-api.eu-north-1.amazonaws.com/develop/foodFacts";
    axios
      .get(getitems, {
        headers: {
          authorizationToken:
            "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
        },
      })
      .then((response) => {
        if (response.data) {
          setFoodFacts(response.data.body);
          console.log("tryyy", response.data.body[0].foodFactsdata);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <div className={classes.outercontainer_starter}>
      {/* <Container className={classes.container_starter}> */}
      <div className={classes.innercontainer_starter}>
        <Header />
        <IntroSection />
        <Services />
        <ExperienceSection />
        {foodfacts && <RecommendationSection foodfacts={foodfacts} />}
        <SearchSection />
        <Footer />
      </div>
      {/* </Container> */}
    </div>
  );
};

export default StarterPage;
