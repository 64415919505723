import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function BasicAccordion({ faqsList }) {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleChange = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div>
      {faqsList.map((item, index) => (
        <Accordion
          key={index}
          expanded={expandedIndex === index}
          onChange={() => handleChange(index)}
          style={{
            marginBottom: "16px",
            // border: "1px solid #e0e0e0",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            // width: "80%",
            // justifyContent: "center",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
            style={{
              backgroundColor: "#f3f3f3",
              //   borderBottom: "1px solid #e0e0e0",
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="subtitle1"
              class="text-xl"
              style={{ color: "#06647D" }}
            >
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: "16px",
              //   borderTop: "1px solid #e0e0e0",
              backgroundColor: "#F5F5F5",
            }}
          >
            <Typography>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
