import React, { useState } from "react";
import { Typography, Grid, Tabs, Tab } from "@material-ui/core/";
import useStyles from "../../../assets/css/style.js";
import "../../../styles/ckEditor.css";
import constants from "../../../constants.json";

const RecommendationSection = ({ foodfacts }) => {
  const classes = useStyles();
  const [foodfactsObject, setFoodFactsObject] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  const extractContent = (htmlString) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlString, "text/html");
    const img = htmlDoc.querySelector("img");
    if (img) {
      return { type: "image", src: img.src };
    } else {
      const table = htmlDoc.querySelector("table");
      if (table) {
        return { type: "table", html: table.outerHTML };
      }
    }
    return { type: "none" };
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div style={{ width: "100%", backgroundColor: "#F8F3E9" }}>
      <Typography
        variant="h4"
        className={classes.headertext_recommendations}
        align="center"
        gutterBottom
        style={{
          fontSize: "3vh",
          marginLeft: "5%",
          fontFamily: constants.fontFamily, // Consistent font family
        }}
      >
        {constants.foodFacts}
      </Typography>
      <Typography
        variant="h5"
        className={classes.headertext_recommendations1}
        align="center"
        gutterBottom
        style={{
          fontSize: "2.5vh",
          marginLeft: "5%",
          fontFamily: constants.fontFamily, // Consistent font family
        }}
      >
        {constants.thatYouWillBeAmazedToKnow}
      </Typography>
      <div
        style={{
          width: "100%",
          height: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={classes.straightcontainer_recommendations}
      >
        <Grid
          container
          direction="row"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap", // Adjust for responsiveness
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            style={{
              padding: "2vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {foodfactsObject === null
              ? foodfacts && (
                  <Grid
                    style={{
                      padding: "15px",
                      height: "400px", // Doubled height
                      width: "400px", // Doubled width
                      boxShadow: "1px 2px 9px grey",
                      borderRadius: "10px",
                      fontFamily: constants.fontFamily, // Consistent font family
                      overflow: "hidden", // Hide overflow to maintain square shape
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center", // Center text inside the container
                    }}
                    className={classes.verticalScrollContainer_recommendations}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center content inside
                        color: "black", // Ensure text color is black
                      }}
                    >
                      {(() => {
                        const content = extractContent(
                          foodfacts[activeTab]?.foodFactsdata
                        );
                        if (content.type === "image") {
                          return (
                            <img
                              src={content.src}
                              alt="Food Fact"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain", // Ensure image fits within container
                              }}
                            />
                          );
                        } else if (content.type === "table") {
                          return (
                            <div
                              dangerouslySetInnerHTML={{ __html: content.html }}
                            />
                          );
                        } else {
                          return <p>No image or table found in the content.</p>;
                        }
                      })()}
                    </div>
                  </Grid>
                )
              : foodfactsObject && (
                  <Grid
                    style={{
                      padding: "15px",
                      height: "400px", // Doubled height
                      width: "400px", // Doubled width
                      borderRadius: "10px",
                      fontFamily: constants.fontFamily, // Consistent font family
                      overflow: "hidden", // Hide overflow to maintain square shape
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center", // Center text inside the container
                    }}
                    className={classes.verticalScrollContainer_recommendations}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center content inside
                        color: "black", // Ensure text color is black
                      }}
                    >
                      {(() => {
                        const content = extractContent(
                          foodfactsObject?.foodFactsdata
                        );
                        if (content.type === "image") {
                          return (
                            <img
                              src={content.src}
                              alt="Food Fact"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain", // Ensure image fits within container
                              }}
                            />
                          );
                        } else if (content.type === "table") {
                          return (
                            <div
                              dangerouslySetInnerHTML={{ __html: content.html }}
                            />
                          );
                        } else {
                          return <p>No image or table found in the content.</p>;
                        }
                      })()}
                    </div>
                  </Grid>
                )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            style={{
              height: "auto",
              display: "flex",
              justifyContent: "center",
              marginBottom: "2vh", // Responsive margin
            }}
            className={classes.verticalScrollContainer_recommendations}
          >
            <div
              onClick={() => {
                setFoodFactsObject(foodfacts[activeTab]);
              }}
              style={{
                borderRadius: "20px",
                color: "black",
                marginBottom: "10px",
                cursor: "pointer",
                width: "100%", // Full width for responsiveness
                paddingLeft: "5%", // Add left padding for better alignment on mobile
                fontFamily: constants.fontFamily, // Consistent font family
                textAlign: "center", // Center text inside the container
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column", // Ensure text is stacked vertically
                  alignItems: "center", // Center text items horizontally
                  justifyContent: "center", // Center text items vertically
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    color: constants.blueTextColor,
                    fontSize: "3vh", // Adjust font size for responsiveness
                    fontFamily: constants.fontFamily, // Consistent font family
                  }}
                >
                  {foodfacts[activeTab]?.title}
                </div>
                <p
                  style={{
                    fontSize: "2.5vh",
                    fontFamily: constants.fontFamily, // Consistent font family
                    color: "black", // Set text color to black
                    overflowY: "auto", // Add vertical scrollbar
                    maxHeight: "150px", // Fixed height for scrollbar
                  }}
                >
                  {foodfacts[activeTab]?.description}
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.centerAligned}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          className={classes.scrollableTabs}
          fullWidth
          variant="scrollable"
          scrollButtons="auto"
          style={{
            paddingLeft: "2vh",
            paddingRight: "2vh", // Adjust padding for responsiveness
            fontFamily: constants.fontFamily, // Consistent font family
          }}
        >
          {foodfacts.map((item, index) => (
            <Tab
              key={index}
              label={item.title}
              sx={{ flexShrink: 0 }}
              style={{
                minWidth: "80px",
                fontSize: "2vh",
                fontFamily: constants.fontFamily,
              }} // Responsive tab size and font family
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default RecommendationSection;
