import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addUser, fetchAllUsers, updateUser,fetchUsers } from './userAPI';

const initialState = {
  users: [],
  status: 'idle',
  currentUser:null,
  totalUsers:0
};
//we may need more info of current order
export const addUserAsync = createAsyncThunk(
  'user/addUser',
  async (user) => {
    const response = await addUser(user);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const fetchUsersAsync = createAsyncThunk(
  'user/fetchUsers',
  async () => {
    const response = await fetchUsers();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const fetchAllUsersAsync = createAsyncThunk(
  'user/fetchAllUsers',
  async ({sort,pagination}) => {
    const response = await fetchAllUsers(sort,pagination);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const updateUserAsync=createAsyncThunk(
  'user/updateUser',
  async(id)=>{
    const response=await updateUser(id);
    return response.data;
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetUser: (state) => {
      state.currentUser=null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users.push(action.payload);
        state.currentUser=action.payload;
      })
      .addCase(fetchAllUsersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllUsersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users=action.payload.users;
        state.totalUsers=action.payload.totalUsers;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const index=state.users.findIndex(user=>user.id===action.payload.id);
        state.users[index]=action.payload;
      })
      .addCase(fetchUsersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload;
      });
  },
});

export const { resetUser } = userSlice.actions;
export const selectcurrentUser = (state) => state.user.currentUser;
export const selectallusers=(state)=>state.user.users;
export const selecttotalUsers=(state)=>state.user.totalUsers;

export default userSlice.reducer;
