import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FlakyIcon from "@mui/icons-material/Flaky";
import GroupIcon from "@mui/icons-material/Group";
import List from "@mui/material/List";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link, useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NotificationCenter from "react-notification-center-component";
import constants from "../../constants.json";
const drawerWidth = 240;
const theme = createTheme({
  palette: {
    primary: {
      main: "#38A389",
    },
    secondary: {
      main: "#daf8ec",
    },
  },
});
const userObject = JSON.parse(localStorage.getItem("user"));
export default function DietitionProtected({ children }) {
  console.log("yessss", "64e7f7397a784904801d0ad0" + userObject.user_id);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => navigate("/dietitionprofile")}>Profile</MenuItem>
      <MenuItem
        onClick={() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          navigate("/");
          window.location.reload();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton>
          <NotificationCenter
            className="checking"
            appId="R1mZPd6xWo"
            subscriberId={"64e7f7397a784904801d0ad0" + userObject.user_id}
          />
        </IconButton>
        <p>Notifications </p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <Toolbar>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
              className="text-3xl font-bold tracking-wide text-[#38A389]"
            >
              {constants.appTitle}
            </p>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <MenuItem>
                <NotificationCenter
                  className="checking"
                  appId="R1mZPd6xWo"
                  subscriberId={"64e7f7397a784904801d0ad0" + userObject.user_id}
                />

                {/* <p>Notifications</p> */}
              </MenuItem>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <img
                  className="h-12 w-12 rounded-full "
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto", marginTop: "1rem" }}>
            <List>
              <ListItem disablePadding>
                <ListItemButton component={Link} to={"/nutritionistDashboard"}>
                  <ListItemIcon>
                    <DashboardIcon
                      className="w-6 h-6 mr-2 mb-1 "
                      sx={{ color: "white" }}
                    />
                  </ListItemIcon>
                  <p className="text-lg text-whiteColor font-medium">
                    Nutritionist Portal
                  </p>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} to={"/addFood"}>
                  <ListItemIcon>
                    <GroupIcon
                      className="w-6 h-6 mr-2 mb-1"
                      sx={{ color: "#38A389" }}
                    />
                  </ListItemIcon>
                  <p className="text-lg text-whiteColor font-medium">
                    Add Food
                  </p>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} to={"/allFoods"}>
                  <ListItemIcon>
                    <FlakyIcon
                      className="w-6 h-6 mr-2 mb-1 "
                      sx={{ color: "#38A389" }}
                    />
                  </ListItemIcon>
                  <p className="text-lg text-whiteColor font-medium">
                    All Food
                  </p>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
