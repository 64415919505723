import * as React from "react";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import FoodDiary from "./foodDiary";
import UserProgressTracker from "./userProgressTracker";
import { useState } from "react";
import constants from "../../constants.json";
import FoodBlogsList from "../../commonScreens/blog";
import IntroSection from "../../components/kalorieKompassComponents/starterpage/formTEE";

export default function UnstyledTabsCustomized() {
  const [durationtype, setDurationtype] = useState("1");
  const [activeButton, setActiveButton] = useState(0);
  const [showHeader, setShowHeader] = useState(false);

  return (
    <Tabs defaultValue={1}>
      <TabsList>
        <Tab value={1}>{constants.foodDiary}</Tab>
        <Tab value={2}>{constants.progressTracker}</Tab>
        <Tab value={3}>{constants.calculateTEE}</Tab>
        <Tab value={4}>{constants.blogs}</Tab>
      </TabsList>
      <TabPanel value={1}>
        <FoodDiary
          setDurationtype={setDurationtype}
          setActiveButton={setActiveButton}
          durationtype={durationtype}
          activeButton={activeButton}
        />
      </TabPanel>
      <TabPanel value={2}>
        <UserProgressTracker
          setDurationtype={setDurationtype}
          setActiveButton={setActiveButton}
          durationtype={durationtype}
          activeButton={activeButton}
        />
      </TabPanel>
      <TabPanel value={3}>
        <IntroSection
          setDurationtype={setDurationtype}
          setActiveButton={setActiveButton}
          durationtype={durationtype}
          activeButton={activeButton}
        />
      </TabPanel>
      <TabPanel value={4}>
        <FoodBlogsList
          setDurationtype={setDurationtype}
          setActiveButton={setActiveButton}
          durationtype={durationtype}
          activeButton={activeButton}
          showHeader={showHeader}
        />
      </TabPanel>
    </Tabs>
  );
}

const lightOrange = {
  50: "#FFFDFC",
  100: "#FFF8F4",
  200: "#FFEFD9",
  300: "#FFE5BD",
  400: "#FFDB99",
  500: "#FFD076",
  600: "#FFC354",
  700: "#FFB635",
  800: "#FFAB1F",
  900: "#FF9B00",
};

const Tab = styled(BaseTab)`
  font-family: Caladea-Regular;
  color: white;
  cursor: pointer;
  font-size: 1rem; /* Adjusted font size for responsiveness */
  background-color: transparent;
  max-width: 50%;
  line-height: 1.5;
  padding: 0.5em 1em; /* Adjusted padding for responsiveness */
  margin: 0.2em; /* Reduced margin to bring tabs closer */
  border: none;
  border-radius: 0.25em;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${lightOrange[400]};
  }

  &:focus {
    color: white;
    outline: 3px solid ${lightOrange[200]};
  }

  &.${tabClasses.selected} {
    background-color: ${constants.orange};
    color: white;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 600px) {
    font-size: 0.875rem; /* Smaller font size on mobile */
    padding: 0.3em 0.75em; /* Smaller padding on mobile */
    margin: 0.1em; /* Further reduce margin on mobile for better fit */
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  font-family: Caladea-Regular;
  font-size: 0.875rem;

  @media (max-width: 600px) {
    font-size: 0.75rem; /* Smaller font size on mobile */
  }
`;

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  width: 100%;
  background-color: ${constants.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem; /* Adjusted padding for responsiveness */

  @media (max-width: 600px) {
    
    padding: 0; /* Remove padding on mobile */
  }
`
);
