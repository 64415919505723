import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
} from "@material-ui/core";
import { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import constants from "../../constants.json";
import useStyles from "../../assets/css/fooddiary";

const serverIp = process.env.REACT_APP_SERVERIP;
const port = process.env.REACT_APP_PORT;

const AccordionSection = ({ mealType, meals, setReload, reload }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const sortedMeals = meals.sort((a, b) => {
    const mealOrder = {
      breakfast: 1,
      lunch: 2,
      dinner: 3,
      snack: 4,
    };
    return mealOrder[a.meal_type] - mealOrder[b.meal_type];
  });

  const removeMeal = async (mealId) => {
    const URL = `${serverIp}:${port}/api/remove-diary-meal/${mealId}`;
    try {
      await axios.delete(URL);
      setReload(!reload);
    } catch (error) {
      // Handle error if necessary
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const capitalizedMealType = capitalizeFirstLetter(mealType);

  return (
    <Container className={classes.accordinContainer} maxWidth="xl">
      {meals.length > 0 && (
        <Typography className={classes.headertext}>
          {capitalizedMealType}
        </Typography>
      )}

      <Grid container spacing={2}>
        {meals.map((meal, index) => (
          <Grid item xs={12} sm={12} md={12} key={index}>
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              className={classes.accordionstyle}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={9} sm={10} md={10}>
                    <Container className={classes.textcontainer}>
                      <Typography
                        sx={{ flexShrink: 0, width: "100%", textAlign: "left" }}
                        className={classes.acc_text1}
                      >
                        {meal.food_name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "secondary",
                          flexShrink: 0,
                          width: "100%",
                          textAlign: "left",
                        }}
                        className={classes.acc_text1}
                      >
                        {constants.calories}:{" "}
                        <span className="font-bold">
                          {meal.calories_kcal * meal.quantity} Kcal
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          color: "secondary",
                          flexShrink: 0,
                          width: "100%",
                          textAlign: "left",
                        }}
                        className={classes.acc_text1}
                      >
                        {constants.quantity}:{" "}
                        <span className="font-bold">{meal.quantity}</span>
                      </Typography>
                    </Container>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    md={2}
                    style={{ textAlign: "right" }}
                  >
                    <Tooltip title="Delete">
                      <IconButton
                        className="bg-white-100 hover:bg-red-200 text-sm font-bold rounded-full px-2 py-1"
                        variant="outlined"
                        onClick={(event) => {
                          event.stopPropagation(); // Prevents click from affecting accordion
                          removeMeal(meal.id);
                        }}
                        aria-label="Delete"
                        size="small"
                      >
                        <DeleteForeverIcon style={{ color: "black" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Container className={classes.detailscontainer}>
                      <Typography className={classes.acc_text1}>
                        {meal.fats_g} g
                      </Typography>
                      <Typography className={classes.acc_text1}>
                        {constants.fats}
                      </Typography>
                    </Container>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Container className={classes.detailscontainer}>
                      <Typography className={classes.acc_text1}>
                        {meal.protein_g} g
                      </Typography>
                      <Typography className={classes.acc_text1}>
                        {constants.proteins}
                      </Typography>
                    </Container>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Container className={classes.detailscontainer}>
                      <Typography className={classes.acc_text1}>
                        {meal.carbs_g} g
                      </Typography>
                      <Typography className={classes.acc_text1}>
                        {constants.carbs}
                      </Typography>
                    </Container>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AccordionSection;
