import React from "react";
import { PieChart } from "react-minimal-pie-chart";

function PieChartComp({ chartData }) {
  return (
    <div>
      <PieChart
        animate
        animationDuration={500}
        animationEasing="ease-out"
        center={[50, 50]}
        data={chartData}
        lengthAngle={360}
        lineWidth={15}
        paddingAngle={0}
        radius={35}
        rounded
        startAngle={0}
        viewBoxSize={[100, 100]}
        label={() => (
          <text
            dominantBaseline="central"
            x="50"
            y="50"
            dx="0"
            dy="0"
            fill="#06647D"
            fontSize="0.5rem"
            fontColor="#06647D"
            fontWeight="700"
            textAnchor="middle"
          >
            Consumed {chartData[1].value.toFixed(0)}%
          </text>
        )}
      />
    </div>
  );
}

export default PieChartComp;
