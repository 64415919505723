// A mock function to mimic making an async request for data
export function addUser(user) {
    return new Promise(async (resolve) => {
      const response = await fetch('http://localhost:8080/users', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: { 'content-type': 'application/json' },
      });
      const data = await response.json();
      // TODO: on server it will only return some info of user (not password)
      resolve({ data });
    });
  }
  export function fetchAllUsers(sort,pagination) {
    let queryString = '';
    for(let key in sort){
      queryString+=`${key}=${sort[key]}&`
    }
     for (let key in pagination) {
       queryString += `${key}=${pagination[key]}&`;
     }
   
     return new Promise(async (resolve) => {
       //TODO: we will not hard-code server URL here
       const response = await fetch(
         'http://localhost:8080/users?' + queryString
       );
       const data = await response.json();
       const totalUsers = await response.headers.get('X-Total-Count');
       console.log(totalUsers);
       resolve({ data: { users: data, totalUsers: +totalUsers } });
     });
   }
   export function updateUser(user) {
    return new Promise(async (resolve) => {
      const response = await fetch('http://localhost:8080/users/'+user.id, {
        method: 'PATCH',
        body: JSON.stringify(user),
        headers: { 'content-type': 'application/json' },
      });
      const data = await response.json();
      // TODO: on server it will only return some info of user (not password)
      resolve({ data });
    });
  }
  export function fetchUsers() {
    return new Promise(async(resolve) =>{
      //TODO: we will not hard code server URL here
   const response=await fetch(' http://localhost:8080/users');
   //TODO: Server will filter all deleted products
   const data=await response.json();
   resolve({data});
    }
    );
  }