import React, { useState } from "react";
import FlipCard from "./flipCard.js";
import {
  Typography,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
} from "@material-ui/core";
import useStyles from "../../../assets/css/style.js";
import calImg from "./../../../assets/images/calTEEimg.png";
import { useMediaQuery } from "@material-ui/core";
import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#38A389",
    },
    secondary: {
      main: "#daf8ec",
    },
  },
});
const IntroSection = () => {
  const classes = useStyles();
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("female");
  const [bodyWeight, setBodyWeight] = useState("");
  const [bodyHeight, setBodyHeight] = useState("");
  const [bodyWeightUnit, setBodyWeightUnit] = useState("kg");
  const [heightUnit, setHeightUnit] = useState("m");
  const [activityLevel, setActivityLevel] = useState("moderate");
  const [TEE, setTEE] = useState(null);
  const [name, setName] = useState("");
  const [tempName, setTempName] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    age: "",
    bodyWeight: "",
    bodyHeight: "",
  });

  const handleChange = ({ currentTarget: input }) => {
    const { name, value } = input;
    if (name === "age") setAge(value);
    else if (name === "gender") setGender(value);
    else if (name === "bodyWeight") setBodyWeight(value);
    else if (name === "bodyHeight") setBodyHeight(value);
    else if (name === "bodyWeightUnit") setBodyWeightUnit(value);
    else if (name === "heightUnit") setHeightUnit(value);
    else if (name === "activityLevel") setActivityLevel(value);
    else if (name === "name") setName(value);
    setValidationErrors({ ...validationErrors, [name]: "" });
  };
  const validateForm = () => {
    let isValid = true;
    const errors = {
      age: "",
      bodyWeight: "",
      bodyHeight: "",
    };
    if (age.trim() === "") {
      errors.age = "Age is required.";
      isValid = false;
    }

    if (bodyWeight.trim() === "") {
      errors.bodyWeight = "Body weight is required.";
      isValid = false;
    }

    if (bodyHeight.trim() === "") {
      errors.bodyHeight = "Body height is required.";
      isValid = false;
    }

    setValidationErrors(errors);

    return isValid;
  };

  const calculateTEE = () => {
    if (!validateForm()) {
      return;
    }
    // Convert body weight to kg
    const weightInKg =
      bodyWeightUnit === "lb" ? bodyWeight / 2.20462 : bodyWeight;

    // Convert body height to centimeters
    const heightInCentimeters =
      heightUnit === "m" ? bodyHeight * 100 : bodyHeight;

    let activityFactor;
    switch (activityLevel) {
      case "sedentary":
        activityFactor = 30;
        break;
      case "moderate":
        activityFactor = 50;
        break;
      case "strenuous":
        activityFactor = 100;
        break;
      default:
        activityFactor = 50;
    }
    if (age === "" || gender === "" || bodyHeight === "" || bodyWeight === "") {
      return;
    }

    if (gender === "male") {
      //   BEE = 66 + (13.7 x weight in kg) + (5 x height in cm) – (6.8 x age in years)
      let bee = 66 + weightInKg * 13.7 + heightInCentimeters * 5 - 6.8 * age;

      let tdee = bee + (bee * activityFactor) / 100;
      setTEE(tdee);
    } else if (gender === "female") {
      // 655.0955 + (9.5634 x weight in kg) + (1.8496 x height in cm) – (4.6756 x age in years)
      let bee =
        655.0955 +
        parseInt(weightInKg) * 9.5634 +
        parseInt(heightInCentimeters) * 1.8496 -
        parseInt(age) * 4.6756;
      // TDEE = BEE + (BEE x activity level)

      let tdee = bee + (bee * activityFactor) / 100;
      setTEE(tdee);
    }

    if (tempName && TEE !== null) {
      setName(tempName);
    }
  };
  const handleReset = () => {
    resetForm();
  };
  const resetForm = () => {
    setAge("");
    setGender("female");
    setBodyWeight("");
    setBodyHeight("");
    setBodyWeightUnit("kg");
    setHeightUnit("m");
    setActivityLevel("moderate");
    setTEE(null);
    setName("");
    setTempName("");
    setValidationErrors({
      age: "",
      bodyWeight: "",
      bodyHeight: "",
    });
  };
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Grid container className={classes.container_intro_tee}>
      <Grid container style={{ justifyContent: "center" }}>
        <Grid container className={classes.displayFlexJustified}>
          <Grid item sm={12} md={4} lg={4}>
            <FlipCard
              frontContent={
                <>
                  <div>Maintain </div>
                  <div>Weight</div>
                </>
              }
              backContent="To Maintain weight, stick to your total energy consumption"
            />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <FlipCard
              frontContent={
                <>
                  Lose <div>Weight</div>
                </>
              }
              backContent="To lose 1kg of weight in a week you'll have to reduce your calorie intake by around 1000kcal a day"
            />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <FlipCard
              frontContent={
                <>
                  Gain <div>Weight</div>
                </>
              }
              backContent="To gain 1kg of weight in a week you'll have to increase your calorie intake by around 1000kcal a day"
            />
          </Grid>
        </Grid>
        {!isMatch ? (
          <img
            src={calImg}
            alt="Description"
            style={{
              width: "26.563rem",
              height: "20.875rem",
              position: "absolute",
              top: "93%",
              left: "14.7rem",
            }}
          />
        ) : null}
        <Grid container justifyContent="center">
          <Grid
            container
            style={{
              overflow: "visible",
              justifyContent: "center",
              width: isMatch ? "90%" : "40%",
            }}
            className={classes.TEE_form}
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.text1_red}>
                Enter your data:
              </Typography>
            </Grid>

            {/* Age Section */}
            <Grid item xs={12} md={4}>
              <Typography variant="h4" className={classes.text1_green}>
                Age
              </Typography>
            </Grid>
            {!isMatch ? (
              <>
                <Grid item xs={12} md={4} className={classes.displayFlex}>
                  <TextField
                    type="number"
                    id="age"
                    label="Enter Age"
                    variant="filled"
                    fullWidth
                    className={classes.input}
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        min: 0,
                      },
                      style: {
                        padding: "0px",
                        height: "40px",
                        fontSize: "20px",
                        paddingBottom: "12px",
                      },
                    }}
                    InputLabelProps={{ hidden: true }}
                    name="age"
                    value={age}
                    onChange={handleChange}
                    error={!!validationErrors.age}
                    helperText={validationErrors.age}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h4" className={classes.green_caladea}>
                    {" "}
                    Years
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={4} className={classes.displayFlex}>
                  <TextField
                    type="number"
                    id="age"
                    label="Enter Age"
                    variant="filled"
                    fullWidth
                    className={classes.input}
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        min: 0,
                      },
                      style: {
                        padding: "0px",
                        height: "40px",
                        fontSize: "20px",
                        paddingBottom: "12px",
                      },
                    }}
                    InputLabelProps={{ hidden: true }}
                    name="age"
                    value={age}
                    onChange={handleChange}
                    error={!!validationErrors.age}
                    helperText={validationErrors.age}
                  />
                  <Typography
                    variant="h4"
                    className={classes.green_caladea}
                    style={{ marginLeft: 10 }}
                  >
                    {"Years"}
                  </Typography>
                </Grid>
              </>
            )}

            {/* Gender Section */}
            <Grid item xs={12} md={4}>
              <Typography variant="h4" className={classes.text1_green}>
                Gender
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} className={classes.columnFlex}>
              <RadioGroup
                row
                aria-label="gender"
                name="gender"
                defaultValue="male"
                onChange={handleChange}
              >
                <FormControlLabel
                  value="male"
                  control={
                    <Radio color="primary" style={{ color: "#06647D" }} />
                  }
                  label={
                    <Typography className={classes.green_caladea}>
                      Male
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="female"
                  control={
                    <Radio color="primary" style={{ color: "#06647D" }} />
                  }
                  label={
                    <Typography className={classes.green_caladea}>
                      Female
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>

            {/* Body Weight Section */}
            <Grid item xs={12} md={4}>
              <Typography variant="h4" className={classes.text1_green}>
                Body Weight
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className={classes.displayFlex}>
              <TextField
                type="number"
                id="bodyWeight"
                label="Enter Body Weight"
                variant="filled"
                name="bodyWeight"
                value={bodyWeight}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    min: 0,
                  },
                  style: {
                    padding: "0px",
                    height: "50px",
                    fontSize: "20px",
                    paddingBottom: "12px",
                  },
                }}
                InputLabelProps={{ hidden: true }}
                className={classes.input}
                onChange={handleChange}
                error={!!validationErrors.bodyWeight}
                helperText={validationErrors.bodyWeight}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="unit"
                  name="bodyWeightUnit"
                  defaultValue="kg"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="kg"
                    control={
                      <Radio color="primary" style={{ color: "#06647D" }} />
                    }
                    label={
                      <Typography className={classes.green_caladea}>
                        kg
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="lb"
                    control={
                      <Radio color="primary" style={{ color: "#06647D" }} />
                    }
                    label={
                      <Typography className={classes.green_caladea}>
                        lb
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Body Height Section */}
            <Grid item xs={12} md={4}>
              <Typography variant="h4" className={classes.text1_green}>
                Body Height
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className={classes.displayFlex}>
              <TextField
                type="number"
                id="bodyHeight"
                label="Enter Body Height"
                variant="filled"
                fullWidth
                style={{ width: "100%", color: "#06647D" }}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    min: 0,
                  },
                  style: {
                    padding: "0px",
                    height: "50px",
                    fontSize: "20px",
                    paddingBottom: "12px",
                  },
                }}
                InputLabelProps={{ hidden: true }}
                name="bodyHeight"
                value={bodyHeight}
                onChange={handleChange}
                className={classes.input}
                error={!!validationErrors.bodyHeight}
                helperText={validationErrors.bodyHeight}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="heightUnit"
                  name="heightUnit"
                  defaultValue="m"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="m"
                    control={
                      <Radio color="primary" style={{ color: "#06647D" }} />
                    }
                    label={
                      <Typography className={classes.green_caladea}>
                        m
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="cm"
                    control={
                      <Radio color="primary" style={{ color: "#06647D" }} />
                    }
                    label={
                      <Typography className={classes.green_caladea}>
                        cm
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Activity Level Section */}
            <Grid item xs={12} md={4}>
              <Typography variant="h4" className={classes.text1_green}>
                Activity
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} className={classes.columnFlex}>
              <FormControl component="fieldset" className={classes.width_50}>
                <RadioGroup
                  row
                  aria-label="activityLevel"
                  name="activityLevel"
                  defaultValue="moderate"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="moderate"
                    control={
                      <Radio color="primary" style={{ color: "#06647D" }} />
                    }
                    label={
                      <Typography className={classes.colorGreen}>
                        Moderate
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="sedentary"
                    control={
                      <Radio color="primary" style={{ color: "#06647D" }} />
                    }
                    label={
                      <Typography style={{ color: "#06647D" }}>
                        Sedentary
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="strenuous"
                    control={
                      <Radio color="primary" style={{ color: "#06647D" }} />
                    }
                    label={
                      <Typography className={classes.colorGreen}>
                        Strenuous
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Name Section */}
            <Grid item xs={12} md={4}>
              <Typography variant="h4" className={classes.text1_green}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} className={classes.displayFlex}>
              <TextField
                id="name"
                label="Enter Name"
                variant="filled"
                fullWidth
                className={classes.input}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    min: 0,
                  },
                  style: {
                    padding: "0px",
                    height: "40px",
                    fontSize: "20px",
                    paddingBottom: "12px",
                  },
                }}
                InputLabelProps={{ hidden: true }}
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            {/* Buttons Section */}
            <Grid item xs={12} className={classes.displayRight}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReset}
                className={classes.TEE_buttons}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={calculateTEE}
                className={`${classes.TEE_buttons} ${classes.margin_Right}`}
              >
                Calculate
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {TEE !== null && name ? (
          <Grid
            container
            style={{ width: !isMatch ? "40%" : "90%" }}
            className={classes.TEE_results}
          >
            <Grid item sm={12} md={12} lg={12}>
              <Typography
                variant="h5"
                style={{
                  color: "#06647D",
                  fontSize: "2rem",
                  font: "Caladea",
                  fontWeight: "400",
                  lineHeight: "36.8px",
                }}
              >
                Hello, {name}!
              </Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <Typography
                variant="h4"
                style={{
                  color: "#02306A",
                  fontSize: "24px",
                  font: "Caladea",
                  fontWeight: "400",
                  lineHeight: "27.6px",
                }}
              >
                Your Results: TEE - {TEE.toFixed(2)} kcal/d
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default IntroSection;
