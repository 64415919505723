import { makeStyles } from "@material-ui/core/styles";
import constants from "../../constants.json";
import backgroundImage from "../../assets/images/homepageBg.png";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "80%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    //  border: "2px solid #A87DB4",
    // backgroundColor: "rgba(255, 209, 113, 0.6)",
  },
  innerText: {
    paddingTop: "1rem",
    fontSize: "1.3rem",
    maxWidth: "55%",
  },
  whiteText: {
    color: constants.white,
  },
  boldText: {
    fontWeight: "bold",
  },
  textClass: {
    color: constants.blueTextColor,
    fontSize: "1.5rem",
    fontFamily: constants.fontFamily,
  },
  innercontainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    // border: "2px solid #A87DB4",
    backgroundColor: "white",
    marginTop: "auto",
    borderTopLeftRadius: "100px",
    zIndex: 1,
    marginBottom: "300px",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  navcontainer: {
    display: "flex",
    flexDirection: "row",
    height: "15%",
    width: "100%",
    // border: "2px solid #A87DB4",
  },
  smallcontainer1: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "35%",
    // border: "2px solid #A87DB4",
    // justifyContent: "center",
    alignItems: "center",
    paddingLeft: "40px",
  },
  smallcontainer2: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "65%",
    // border: "2px solid #A87DB4",
  },
  logoimg: {
    width: "85px",
    height: "80px",
  },
  text1: {
    color: "white",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "32px",
  },
  buttonstyle: {
    alignSelf: "center",
    height: "40px",
    color: "white",
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    textDecoration: "none", // Remove the default underline
    outline: "none",
    "&:hover": {
      textDecoration: "underline", // Add underline on hover
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:focus": {
      textDecoration: "underline", // Add underline when focused
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:active": {
      textDecoration: "none", // Remove underline when pressed
      backgroundColor: "transparent",
    },
  },

  horizontalcontainer: {
    display: "flex",
    flexDirection: "row",
    width: "85%",
    height: "100px",
    border: "2px solid #A87DB4",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
  },
  horizontalContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputLabel: {
    color: constants.blueTextColor, // Customize placeholder color
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    width: "150px",

    "&.Mui-focused": {
      color: constants.blueTextColor, // Customize focus color
    },

    // Add this section to change underline color
    "&.MuiFormLabel-root.Mui-focused": {
      color: constants.blueTextColor,
    },
    "&.MuiInput-underline:before": {
      borderBottomColor: constants.blueTextColor, // Change to your desired underline color
    },
    "&.MuiInput-underline:after": {
      borderBottomColor: constants.blueTextColor, // Change to your desired underline color
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: constants.blueTextColor, // Change to your desired underline color
    },
  },
  dropdownIcon: {
    fill: constants.blueTextColor, // Use 'fill' property to change the color of SVG icons
  },

  dropdownItem: {
    color: constants.blueTextColor,
  },

  underline: {
    textDecoration: "underline",
  },

  activeButton: {
    backgroundColor: "#38A389",
    color: "#FF7171",
    borderColor: "#FF7171",
    margin: "5px",
    marginBotton: "10px",
    borderRadius: "35%",
    transition: "transform 0.2s, margin-bottom 0.2s", // Update the transition property
    "&:hover": {
      transform: "scale(1.1)",
    },
    "&.active": {
      position: "relative", // Add relative positioning
      top: "-5px", // Adjust this value to move the selected button slightly above
      borderBottom: "2px solid #FFD171",
      marginBotton: "20px", // Typo in the property name, should be marginBottom
    },
  },
  height: {
    height: "80%",
  },
  mainGrid: {
    width: "100%",
    justifyContent: "space-around",
  },
  width: {
    width: "15.625rem",
  },
  innerGridStyle: {
    padding: "1.25rem",
    fontSize: "1.25rem",
  },
  innerGridStyle1: {
    padding: "1.25rem",
    fontSize: "1.563rem",
    fontWeight: "bold",
    color: constants.blue,
  },
  innerGridStyle2: {
    padding: "1.25rem",
    fontSize: "1.563rem",

    color: constants.blue,
  },
  flexSpaceAround: {
    display: "flex",
    justifyContent: "space-around",
  },
  offWhiteGrid: {
    backgroundColor: constants.offwhite,
    borderRadius: "5%",
    margin: "10px",
  },
  gridStyle1: {
    width: "90%",
    marginLeft: "5%",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "40px",
  },
  gridStyle2: {
    marginLeft: "12%",
    fontSize: "1.25rem",
  },
  gridStyle3: {
    width: "97%",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "0.5em",
    backgroundColor: "#F5F5F5",
    marginLeft: "1.5%",
  },
  inputLabel1: {
    width: "15.625rem",
    paddingTop: "15%",
  },
  divStyle1: {
    height: "100%",
    width: "100%",
    padding: "10px",
  },
}));

export default useStyles;
