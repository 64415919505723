import React, { useState, useEffect } from "react";
import CustomTypography from "../../components/reusableComponents/muiTypography";
import MuiTextField from "../../components/reusableComponents/muiTextField";
import AddButton from "../../commonScreens/signinButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../../assets/css/addFood.css";

function EditFood() {
  const { slug } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log("nameee", slug);
  useEffect(() => {
    axios
      .post(apiUrl + "/api/get-food-details", {
        food_name: slug,
      })
      .then((response) => {
        console.log("Response is wow", response.data);
        setFoodItem(response.data.foodDetails);
      })
      .catch((error) => {});
  }, []);
  const [foodItem, setFoodItem] = useState([]);
  const [foodName, setFoodName] = useState(slug);
  const [foodWeight, setFoodWeight] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [quantity_unit, setQuantity_unit] = useState(foodItem.quantity_unit);
  const [water, setWater] = useState(foodItem.water_g);
  const [protein, setProtein] = useState(foodItem.protein_g);
  const [fats, setFats] = useState(foodItem.fats_g);
  const [fiber, setFiber] = useState(foodItem.fiber_g);
  const [carbs, setCarbs] = useState(foodItem.carbs_g);
  const [zinc, setZinc] = useState(foodItem.zinc_mg);
  const [potassium, setPotassium] = useState(foodItem.potassium_mg);
  const [magnesium, setMagnesium] = useState(foodItem.magnesium);
  const [iron, setIron] = useState(foodItem.iron_fe_mg);
  const [calories, setCalories] = useState(foodItem.calories_kcal);
  const [sodium, setSodium] = useState(foodItem.sodium_mg);
  const [thiamin, setThiamin] = useState(foodItem.thia_mg);
  const [vitE, setVitE] = useState(foodItem.vitE_mg);
  const [vitA, setVitA] = useState(foodItem.vitA_mic_g);
  const [riboflavin, setRiboflavin] = useState(foodItem.ribo_mg);
  const [b3, setB3] = useState(foodItem.b3_niac_mg);
  const [b6, setB6] = useState(foodItem.b6_mg);
  const [b9, setB9] = useState(foodItem.b9_micro_g);
  const [vitC, setVitC] = useState(foodItem.vitC_mg);
  const [vitb12, setVitb12] = useState(foodItem.vitb12_microg);
  const [selenium, setSelenium] = useState(foodItem.selenium_microg);
  const [choline, setCholine] = useState(foodItem.choline_mg);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  //   const location = useLocation();
  //   const { foodDetails } = location.state;

  const [error, setError] = useState("");

  const hanldeEdit = async (event) => {
    event.preventDefault();
    console.log("im handle edit");
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      console.log("object", {
        id: foodItem.id,
        user_id: 2,
        approved: 0,
        food_name: foodName,
        quantity: quantity,
        weight_g: foodWeight,
        water_g: water,
        calories_kcal: calories,
        protein_g: protein,
        carbs_g: carbs,
        fiber_g: fiber,
        fats_g: fats,
        calcium: foodItem.calcium,
        iron_fe_mg: iron,
        magnesium: magnesium,
        potassium_mg: potassium,
        sodium_mg: sodium,
        zinc_mg: zinc,
        vitA_mic_g: vitA,
        thia_mg: thiamin,
        vitE_mg: vitE,
        ribo_mg: riboflavin,
        b3_niac_mg: b3,
        b6_mg: b6,
        b9_micro_g: b9,
        vitC_mg: vitC,
        vitb12_microg: vitb12,
        selenium_microg: selenium,
        choline_mg: choline,
        food_item_pic_link: "path/to/pic",
      });
      const response = await fetch(apiUrl + `/api/food-items/${foodItem.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: foodItem.id,
          user_id: 2,
          approved: 0,
          food_name: foodName,
          quantity_unit: quantity_unit,
          quantity: quantity,
          weight_g: foodWeight,
          water_g: water,
          calories_kcal: calories,
          protein_g: protein,
          carbs_g: carbs,
          fiber_g: fiber,
          fats_g: fats,
          calcium: foodItem.calcium,
          iron_fe_mg: iron,
          magnesium: magnesium,
          potassium_mg: potassium,
          sodium_mg: sodium,
          zinc_mg: zinc,
          vitA_mic_g: vitA,
          thia_mg: thiamin,
          vitE_mg: vitE,
          ribo_mg: riboflavin,
          b3_niac_mg: b3,
          b6_mg: b6,
          b9_micro_g: b9,
          vitC_mg: vitC,
          vitb12_microg: vitb12,
          selenium_microg: selenium,
          choline_mg: choline,
          food_item_pic_link: "path/to/pic",
        }),
      });
      const result = await response.json();
      console.log("result from edit fooddddd", result);
      console.log("im handle edit2");
      if (result.status === true) {
        console.log("im handle edit3");
        console.log("response", result.message);
        navigate("/allFoods");
      } else {
        console.log("food not add");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
  };
  const handleFieldChange = (fieldName, name) => {
    console.log("newValue", fieldName);
    console.log("name", name);
    setFoodItem((prevItemInfo) => ({
      ...prevItemInfo,
      [name]: fieldName,
    }));
  };

  return (
    <div className="addFoodContainer">
      <Grid container className="addFoodBox">
        {foodItem.food_name ? (
          <>
            <Grid container className="addFoodGrid">
              <CustomTypography
                text={"Edit Food Item"}
                fontSize={30}
                color={"#38A389"}
                marginLeft={31}
                fontWeight={"bold"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Food Name"
                type="text"
                name={"food_name"}
                color={"#FF7171"}
                value={foodItem.food_name || "0"}
                placeholder={"e.g. Apple pie"}
                onChange={(value) => {
                  setFoodName(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Food Weight(g)"
                type="text"
                color={"#FF7171"}
                value={foodItem.weight_g || "0"}
                placeholder={"e.g. 500"}
                onChange={(value, isValid) => {
                  setFoodWeight(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Quantity"
                type="text"
                color={"#FF7171"}
                value={foodItem.quantity || "0"}
                placeholder={"e.g. 1 cup"}
                onChange={(value, isValid) => {
                  setQuantity(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Calories(Kcal)"
                type="text"
                color={"#FF7171"}
                value={foodItem.calories_kcal || "0"}
                placeholder={"e.g. 100"}
                onChange={(value, isValid) => {
                  setCalories(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Water(g)"
                type="text"
                color={"#FF7171"}
                value={foodItem.water_g || "0"}
                placeholder={"e.g. 300"}
                onChange={(value, isValid) => {
                  setWater(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Protein(g)"
                type="text"
                color={"#FF7171"}
                value={foodItem.protein_g || "0"}
                placeholder={"e.g. 30"}
                onChange={(value, isValid) => {
                  setProtein(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Fiber(g)"
                type="text"
                color={"#FF7171"}
                value={foodItem.fiber_g || "0"}
                placeholder={"e.g. 40"}
                onChange={(value, isValid) => {
                  setFiber(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Carbs(g)"
                type="text"
                color={"#FF7171"}
                value={foodItem.carbs_g || "0"}
                placeholder={"e.g. 23"}
                onChange={(value, isValid) => {
                  setCarbs(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Fats(g)"
                type="text"
                color={"#FF7171"}
                value={foodItem.fats_g || "0"}
                placeholder={"e.g. 32"}
                onChange={(value, isValid) => {
                  setFats(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Iron(g)"
                type="text"
                color={"#FF7171"}
                value={foodItem.iron_fe_mg || "0"}
                placeholder={"e.g. 10"}
                onChange={(value, isValid) => {
                  setIron(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Magnesium(mg)"
                type="text"
                color={"#FF7171"}
                value={foodItem.magnesium || "0"}
                placeholder={"e.g. 110"}
                onChange={(value, isValid) => {
                  setMagnesium(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Potassium(mg)"
                type="text"
                color={"#FF7171"}
                value={foodItem.potassium_mg || "0"}
                placeholder={"e.g. 31"}
                onChange={(value, isValid) => {
                  setPotassium(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Sodium(mg)"
                type="text"
                color={"#FF7171"}
                placeholder={"e.g. 12"}
                value={foodItem.sodium_mg || "0"}
                onChange={(value, isValid) => {
                  setSodium(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Zinc(mg)"
                type="text"
                color={"#FF7171"}
                value={foodItem.zinc_mg || "0"}
                placeholder={"e.g. 12"}
                onChange={(value, isValid) => {
                  setZinc(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Vitamin A(µg)"
                type="text"
                color={"#FF7171"}
                placeholder={"e.g. 12"}
                value={foodItem.vitA_mic_g || "0"}
                onChange={(value, isValid) => {
                  setVitA(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Thiamin(mg)"
                type="text"
                color={"#FF7171"}
                value={foodItem.thia_mg || "0"}
                placeholder={"e.g. 12"}
                onChange={(value, isValid) => {
                  setThiamin(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Vitamin E(mg)"
                type="text"
                color={"#FF7171"}
                placeholder={"e.g. 12"}
                value={foodItem.vitE_mg || "0"}
                onChange={(value, isValid) => {
                  setVitE(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Riboflavin(mg)"
                type="text"
                color={"#FF7171"}
                value={foodItem.ribo_mg || "0"}
                placeholder={"e.g. 12"}
                onChange={(value, isValid) => {
                  setRiboflavin(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Vitamin B3 niacin(mg)"
                type="text"
                color={"#FF7171"}
                placeholder={"e.g. 12"}
                value={foodItem.b3_niac_mg || "0"}
                onChange={(value, isValid) => {
                  setB3(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Vitamin B6(mg)"
                type="text"
                color={"#FF7171"}
                value={foodItem.b6_mg || "0"}
                placeholder={"e.g. 12"}
                onChange={(value, isValid) => {
                  setB6(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Vitamin B9(µg)"
                type="text"
                color={"#FF7171"}
                placeholder={"e.g. 12"}
                value={foodItem.b9_micro_g || "0"}
                onChange={(value, isValid) => {
                  setB9(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Vitamin C(mg)"
                type="text"
                color={"#FF7171"}
                value={foodItem.vitC_mg || "0"}
                placeholder={"e.g. 12"}
                onChange={(value, isValid) => {
                  setVitC(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Vitamin B12(µg)"
                type="text"
                color={"#FF7171"}
                placeholder={"e.g. 12"}
                value={foodItem.vitb12_microg || "0"}
                onChange={(value, isValid) => {
                  setVitb12(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Selenium(µg)"
                type="text"
                color={"#FF7171"}
                value={foodItem.selenium_microg || "0"}
                placeholder={"e.g. 12"}
                onChange={(value, isValid) => {
                  setSelenium(value);
                }}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              <MuiTextField
                label="Choline(mg)"
                type="text"
                color={"#FF7171"}
                placeholder={"e.g. 12"}
                value={foodItem.choline || "0"}
                onChange={(value, isValid) => {
                  setCholine(value);
                }}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
              {error && (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "20px",
                    position: "relative",
                    left: 420,
                  }}
                >
                  {error}
                </div>
              )}

              <AddButton
                buttonVariant="contained"
                buttonColor="primary"
                buttonText="Edit Item"
                onClick={hanldeEdit}
                width={"25vh"}
                margin={"60px"}
                background={"rgba(56, 163, 137, 0.8)"}
              />
            </Grid>
            {success && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  paddingBottom: "50px",
                }}
              >
                <CustomTypography
                  text={"Food item updated successfully"}
                  fontSize={30}
                  color={"#38A389"}
                  marginLeft={31}
                  fontWeight={"bold"}
                />
              </div>
            )}
          </>
        ) : (
          <Typography>Loading profile data...</Typography>
        )}
      </Grid>
    </div>
  );
}

export default EditFood;
