import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import constants from "../../constants.json";

const useStyles = makeStyles((theme) => ({
  text5: {
    color: constants.color,
    fontFamily: constants.fontFamily,
    fontSize: "1.4rem",
    marginTop: "30%",
    // fontWeight: "bold",
    // marginTop: "-60px",
  },
}));

function MealPieChartComp({ protein, carbs, fats }) {
  const [pieChartDetails, setPieChartDetails] = useState([]);
  const [err, setErr] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    console.log("useEffect triggered");
    // Check if all values are zero
    if (protein === 0 && carbs === 0 && fats === 0) {
      setErr(true);
    } else {
      setErr(false); // Ensure err is set to false when at least one value is not zero
      // Hardcoded data
      const hardcodedData = [
        { title: "Protein", value: parseInt(protein), color: "#FF7171" },
        { title: "Carbs", value: parseInt(carbs), color: "#EFE0BB" },
        { title: "Fat", value: parseInt(fats), color: "#C7934E" },
      ];
      // Set the hardcoded data
      setPieChartDetails(hardcodedData);
    }
  }, [protein, carbs, fats, setErr]);

  return (
    <div>
      {err ? (
        <div>
          <Typography className={classes.text5}>No meal added</Typography>
        </div>
      ) : (
        <PieChart
          animate
          animationDuration={40}
          animationEasing="ease-in"
          center={[50, 30]}
          data={pieChartDetails}
          lineWidth={100}
          lengthAngle={360}
          paddingAngle={0}
          radius={29}
          startAngle={0}
          viewBoxSize={[100, 60]}
          labelPosition={62}
          labelStyle={{
            fontSize: "0.2rem",
            fontColor: "#FFFFFA",
            fontWeight: "bold",
            fontFamily: constants.fontFamily,
            padding: "10px",
          }}
          label={(data) =>
            `${data.dataEntry.title}\n${Math.round(data.dataEntry.percentage)}%`
          }
          reveal
        />
      )}
    </div>
  );
}

export default MealPieChartComp;
