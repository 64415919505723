import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "../../../../assets/css/fooddiary";
import constants from "../../../../constants.json";

const AccordionSection = ({ mealType, meals }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const capitalizedMealType = capitalizeFirstLetter(mealType);

  return (
    <Container className={classes.accordinContainer}>
      {meals.length > 0 && (
        <Typography className={classes.headertext}>
          {capitalizedMealType}
        </Typography>
      )}
      <Container>
        <Grid container spacing={2}>
          {meals.map((meal, index) => (
            <Grid
              item
              xs={12} // Full width on mobile
              sm={12} // Full width on tablets
              md={12} // Full width on medium screens
              lg={12} // Full width on large screens
              key={index}
            >
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                className={classes.accordionstyle}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.acc_text1}>
                        {meal.food_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.acc_text1}>
                        {constants.calories}:{" "}
                        <span className="font-bold">
                          {" "}
                          {meal.calories_kcal} Kcal
                        </span>
                      </Typography>
                      <Typography className={classes.acc_text1}>
                        {constants.quantity}:
                        <span className="font-bold"> {meal.quantity}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={4} md={3} lg={2}>
                      <Typography className={classes.acc_text1}>
                        {meal.fats_g} g
                      </Typography>
                      <Typography
                        className={[classes.acc_text1, classes.purpletext]}
                      >
                        {constants.fats}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} lg={2}>
                      <Typography className={classes.acc_text1}>
                        {meal.protein_g} g
                      </Typography>
                      <Typography
                        className={[classes.acc_text1, classes.purpletext]}
                      >
                        {constants.proteins}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} lg={2}>
                      <Typography className={classes.acc_text1}>
                        {meal.carbs_g} g
                      </Typography>
                      <Typography
                        className={[classes.acc_text1, classes.purpletext]}
                      >
                        {constants.carbs}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
};

export default AccordionSection;
