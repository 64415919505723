import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Typography, Button, Box, Card, CardContent } from "@material-ui/core";
import axios from "axios";
import { useState, useEffect } from "react";
import { useKalorie } from "../../userContext/context";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Grid } from "@material-ui/core";
import constants from "../../constants.json";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import useStyles from "../../assets/css/common.js";
var base64 = require("base-64");
const InfoPage2 = () => {
  const classes = useStyles();
  const { data, updateData } = useKalorie();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); // To track the selected option
  const handleOptionSelect = async (option) => {
    setSelectedOption(option);

    if (option) {
      updateData("payment_method", option === "1" ? "Cash" : "Online");

      const serverIp = process.env.REACT_APP_API_URI;
      const port = process.env.REACT_APP_PORT;

      const apiUrl = `${serverIp}:${port}/api/register`;
      const registerData = {
        email: data.email,
        password: base64.encode(data.password),
        role_id: 5,
        first_name: data.firstname,
        last_name: data.lastname,
        gender: data.gender,
        blood_group: "AB+",
        contact_number: "03001234567",
        dob: data.dob,
        weight: data.weight,
        weight_units: data.weight_units,
        height: data.height,
        height_units: data.height_units,
        marital_status: data.marital_status,
        num_of_kids: data.num_of_kids,
        dietitian_id: data.dietitian_id,
        duration: data.duration,
        physical_activity_level: data.physical_activity_level,
        kind_of_work: data.kind_of_work,
        kind_of_allergies: data.kind_of_allergies,
        medications: data.medications,
        family_history_of_diabetes: data.family_history_of_diabetes,
        family_history_of_heart_attack: data.family_history_of_heart_attack,
        gym_walk_routine: data.gym_walk_routine,
        dine_out_habits: data.dine_out_habits,
        frequency_of_vegetables_intake: data.frequency_of_vegetables_intake,
        frequency_of_fruit_intake: data.frequency_of_fruit_intake,
        flour_type: data.flour_type,
        rice_type: data.rice_type,
        amount_paid: data.amount_paid,
        payment_status: 0,
        num_of_kids: data.kids,
        payment_method: option === "1" ? "Cash" : "Online",
        goal: data.goal_id,
        water_intake: data.water_intake,
        amount_paid: 1000,
        required_weight: data.req_weight,
        required_weight_units: data.req_weight_units,
      };
      axios
        .post(apiUrl, registerData)
        .then((response) => {
          if (response.status) {
            console.log("response from register", response);
            console.log("response.data.user_id", response.data.user_id);
            updateData("userId", response.data.user_id);
            navigate("/wait");
            // Handle the successful response here
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.log(error);
        });
    }
    setError("");
  };

  useEffect(() => {
    console.log(data); // Log the updated data after state update
  }, [data]); // This effect runs whenever the 'data' state changes

  const goback = () => {
    navigate(-1);
  };

  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={classes.outercontainer_info2}
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            variant="h4"
            className={classes.text1}
          >
            <img
              style={{ marginRight: "10px" }}
              src={logo}
              alt="Logo is missing"
            />
            {constants.appTitle}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon size="small" />}
            className={classes.buttonstyle3}
            onClick={goback}
          >
            Back
          </Button>
        </Grid>
        <Grid item container>
          <Grid item className={classes.triangleRight}></Grid>
          <Grid item className={classes.triangleLeft}></Grid>
          <Grid className={classes.outerGrid} container>
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              className={`${classes.mainContainer} ${classes.cashContainer}`}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
                className={classes.innerBox}
              >
                <div style={{ marginBottom: "1.2rem" }}>
                  <Typography
                    style={{
                      fontSize: "30px",
                      marginBottom: "2rem",
                      fontFamily: constants.fontFamily,
                      color: constants.blue,
                      marginLeft: "2rem",
                    }}
                  >
                    Choose your preferred payment method
                  </Typography>
                  <Card
                    onClick={() => handleOptionSelect("1")}
                    style={{
                      backgroundColor:
                        selectedOption === "1"
                          ? constants.orange
                          : constants.blue,
                      cursor: "pointer",
                      width: "70%",
                      margin: "0 auto",
                      marginBottom: "1rem",
                    }}
                  >
                    <CardContent>
                      <Typography
                        style={{
                          color:
                            selectedOption === "1"
                              ? constants.white
                              : constants.white,
                          fontSize: "1.4rem",
                          textAlign: "center",
                        }}
                      >
                        Cash Payment
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    onClick={() => handleOptionSelect("0")}
                    style={{
                      backgroundColor:
                        selectedOption === "0"
                          ? constants.orange
                          : constants.blue,
                      cursor: "pointer",
                      width: "70%",
                      margin: "0 auto",
                    }}
                  >
                    <CardContent>
                      <Typography
                        style={{
                          color:
                            selectedOption === "0"
                              ? constants.white
                              : constants.white,
                          fontSize: "1.4rem",
                          textAlign: "center",
                        }}
                      >
                        Online Payment
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                {error && (
                  <Typography className={classes.errorText}>{error}</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage2;
