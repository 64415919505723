import React, { useState, useEffect } from "react";
import { IconButton, MenuItem, FormControl, Select } from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import profile from "../assets/images/user.png";
import userinfo from "../assets/images/diet.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ITEMS_PER_PAGE } from "../app/constants";
import Pagination from "../components/reusableComponents/Pagination";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@material-ui/core";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import constants from "../constants.json";
const theme = createTheme({
  palette: {
    primary: {
      main: "#38A389",
    },
    secondary: {
      main: "#daf8ec",
    },
  },
});
export default function DietUserRequests() {
  const navigate = useNavigate();
  const [page, setpage] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [data, setData] = useState([]);
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  const userObject = JSON.parse(localStorage.getItem("user"));
  const isMatch = useMediaQuery(
    theme.breakpoints.down("lg") || theme.breakpoints.down("md")
  );
  const handlePage = (page) => {
    setpage(page);
  };
  useEffect(() => {
    setpage(1);
  }, []);
  useEffect(() => {
    const getitems = `${serverIp}:${port}/api/get-all-diet-plan-requests/${userObject.user_id}`;
    axios
      .get(getitems)
      .then((response) => {
        if (response.status) {
          console.log("new code", response.data);
          const filtered = response.data.requests.filter(
            (user) => user.diet_plan_status_page === null
          );
          console.log("filtered data::::", filtered);
          setData(filtered);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const itemsPerPage = ITEMS_PER_PAGE;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataToDisplay = data.slice(startIndex, endIndex);

  const handleSort = () => {
    // Toggle sorting order when the user clicks the header
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    // Sort the data array based on user name and sorting order
    const sortedData = data.slice().sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.first_name.localeCompare(b.first_name);
      } else {
        return b.first_name.localeCompare(a.first_name);
      }
    });

    // Update the data state with the sorted data
    setData(sortedData);
  };

  return (
    <div className="ml-4 mr-4 mt-10 md:ml-20 md:mr-20">
      {/* Adjusted margins for mobile */}
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl md:text-2xl font-semibold text-[#31525B] mt-2">
            {constants.clientDietPlanRequests}
          </h1>
        </div>
      </div>
      <ToastContainer />
      <div className="mt-3 overflow-x-auto">
        {/* Added overflow to handle table overflow on mobile */}
        <table className="min-w-full table-auto">
          <thead>
            {!isMatch && (
              <tr className="bg-[#31525B] cursor-pointer text-white uppercase text-sm leading-normal">
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <th className="py-3 px-4 text-left">{constants.client}</th>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <th className="py-3 px-4 text-center">
                      {constants.payment}
                    </th>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <th className="py-3 px-4 text-center">
                      {constants.clientInfo}
                    </th>
                  </Grid>
                </Grid>
              </tr>
            )}
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {dataToDisplay.map((user, index) => (
              <tr
                key={index}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <Grid container>
                  <Grid item xs={12} md={4}>
                    {isMatch && <h3>{constants.client}</h3>}
                    <td className="py-3 px-4">
                      <div className="flex items-center">
                        <div className="mr-2">
                          <img
                            className="w-12 h-12 md:w-14 md:h-14 rounded-full"
                            src={profile}
                            alt="Profile"
                          />
                        </div>
                        <span>{user.first_name + " " + user.last_name}</span>
                      </div>
                    </td>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {isMatch && <h6>{constants.payment}</h6>}
                    <td className="py-3 px-4 text-center">
                      {user.payment_status === 1 ? (
                        <span className="flex justify-center items-center w-20 h-8 md:h-12 bg-green-200 text-green-950 py-1 px-2 rounded-full border-solid border-2 border-green-500">
                          {"Complete"}
                        </span>
                      ) : (
                        <span className="flex justify-center items-center w-20 h-8 md:h-12 bg-red-200 text-red-950 py-1 px-2 rounded-full border-solid border-2 border-red-500">
                          {"Pending"}
                        </span>
                      )}
                    </td>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {isMatch && <h6>{constants.clientInfo}</h6>}
                    <td className="py-3 px-4 text-center">
                      <IconButton
                        onClick={() => {
                          navigate("/displayuserinfo", {
                            state: user,
                          });
                        }}
                      >
                        <div className="flex justify-center items-center w-8 h-8 md:w-12 md:h-12 mr-2 transform cursor-pointer hover:text-purple-500 hover:scale-120">
                          <TextSnippetIcon
                            className="w-8 h-8 md:w-16 md:h-16"
                            sx={{ color: "#31525B" }}
                          />
                        </div>
                      </IconButton>
                    </td>
                  </Grid>
                </Grid>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          page={page}
          setpage={setpage}
          handlePage={handlePage}
          totalITems={data.length}
        />
      </div>
    </div>
  );
}
