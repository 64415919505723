import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core/";
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import constants from "../../../constants.json";
import logo from "../../../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  container_header: {
    minWidth: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    paddingRight: "4rem",
  },
  navbar_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "right",
  },
  text1_header: {
    color: "white",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "30px",
  },
  buttonstyle_header: {
    height: "40px",
    color: "white",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    outline: "none",
    "&:hover": {
      fontSize: "1.4rem",
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
  },
  divider_header: {
    color: "white",
    fontSize: "1.3rem",
    marginTop: "5px",
  },
  buttoncontainer_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "start",
  },
  text1: {
    color: "#FFFFFFE6",
    fontFamily: constants.fontFamily,
    fontSize: "1.875em",
    display: "flex",
    paddingLeft: "3.438rem",
  },
  text2: {
    color: "#FFFFFFE6",
    fontFamily: constants.fontFamily,
    fontSize: "1.875em",
    display: "flex",
    paddingLeft: "1rem",
  },
  leftAlignedGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    width: "100%",
  },
}));

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme(); // Use the theme hook
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Pass the theme object to useMediaQuery

  return (
    <Grid className={classes.container_header} container>
      <Grid
        item
        sm={12}
        md={6}
        lg={6}
        className={isMobile ? classes.leftAlignedGrid : ""}
      >
        {isMobile ? console.log("Mobile") : console.log("Desktop")}
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
          variant="h4"
          className={isMobile ? classes.text2 : classes.text1}
        >
          <img
            style={{ marginRight: "10px" }}
            src={logo}
            alt="Logo is missing"
          />
          {constants.appTitle}
        </Typography>
      </Grid>
      {!localStorage.getItem("accessToken") ? (
        <Grid
          item
          sm={12}
          md={3}
          lg={4}
          className={classes.navbar_container}
          container
        >
          <div className={classes.buttoncontainer_header}>
            <Button
              onClick={() => {
                navigate("/blogs");
              }}
              className={classes.buttonstyle_header}
            >
              Blogs
            </Button>
            <p className={classes.divider_header}>|</p>
            <Button
              className={classes.buttonstyle_header}
              onClick={() => {
                navigate("/login");
              }}
            >
              Sign In
            </Button>
          </div>
        </Grid>
      ) : (
        <Grid item sm={12} md={6} lg={6} className={classes.leftAlignedGrid}>
          <div className={classes.buttoncontainer_header}>
            <Button
              onClick={() => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("user");
                navigate("/");
                window.location.reload();
              }}
              className={classes.buttonstyle_header}
            >
              Logout
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
