import React, { useState, useEffect } from "react";
import FoodCard from "./foodCard";
import {
  useMediaQuery,
  useTheme,
  Card,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import CustomTypography from "../../components/reusableComponents/muiTypography";
import AddIcon from "@mui/icons-material/Add";
import AddButton from "../../commonScreens/signinButton";
import { useNavigate } from "react-router-dom";
import constants from "../../constants.json";
import Papa from "papaparse";

const chunkArray = (arr, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

export default function FoodCardList() {
  const [foodItems, setFoodItems] = useState([]);
  const [foodItemsExport, setFoodItemsExport] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemSelected = (value) => {
    const selectedFood = searchResults.find((food) => food.food_name === value);
    setSelectedItem(selectedFood);
  };

  const handleSearch = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(apiUrl + "/api/food-items", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (result.status === true) {
        setSearchResults(result.foodItems);
      } else {
        console.error("Error fetching food items.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery]);

  useEffect(() => {
    async function fetchData() {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const response = await fetch(apiUrl + "/api/food-items", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (result.status === true) {
          setFoodItemsExport(result.foodItems);
          const chunkedFoodData = chunkArray(result.foodItems, 4);
          setFoodItems(chunkedFoodData);
        } else {
          console.error("Error fetching all food items.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }

    fetchData();
  }, []);

  const navigate = useNavigate();

  const exportToCSV = () => {
    const flattenedData = foodItemsExport.map((food) => ({
      food_name: food.food_name,
      quantity: food.quantity,
      quantity_unit: food.quantity_unit,
      weight_g: food.weight_g,
      water_g: food.water_g,
      calories_kcal: food.calories_kcal,
      protein_g: food.protein_g,
      carbs_g: food.carbs_g,
      fiber_g: food.fiber_g,
      fats_g: food.fats_g,
      choline_mg: food.choline_mg,
      calcium: food.calcium,
      iron_fe_mg: food.iron_fe_mg,
      magnesium: food.magnesium,
      potassium_mg: food.potassium_mg,
      sodium_mg: food.sodium_mg,
      zinc_mg: food.zinc_mg,
      vitA_mic_g: food.vitA_mic_g,
      thia_mg: food.thia_mg,
      vitE_mg: food.vitE_mg,
      ribo_mg: food.ribo_mg,
      b3_niac_mg: food.b3_niac_mg,
      b6_mg: food.b6_mg,
      b9_micro_g: food.b9_micro_g,
      vitC_mg: food.vitC_mg,
      vitb12_microg: food.vitb12_microg,
      selenium_microg: food.selenium_microg,
    }));

    const csv = Papa.unparse(flattenedData, { header: true });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "exported_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(
        "Your browser does not support the download feature. Please use a different browser."
      );
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      className="bg-slate-400 px-1 py-1"
      sx={{
        maxHeight: "43rem",
        width: "100%",
        overflowY: "auto",
        padding: 2,
      }}
    >
      {isMobile ? (
        // Mobile view
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: 2,
            }}
          >
            <CustomTypography
              text={constants.foodDetails}
              fontSize={28}
              color={"#38A389"}
              fontWeight={"bold"}
              fontFamily={constants.fontFamily}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={searchResults.map((food) => food.food_name)}
                onChange={(event, newValue) => {
                  setSearchQuery(newValue);
                  handleItemSelected(newValue);
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={constants.search}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    margin="normal"
                    sx={{
                      width: "100%",
                      paddingRight: 0,
                      "& .MuiInputBase-input": {
                        paddingRight: "2.5rem",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            /* Handle search icon click */
                          }}
                          sx={{ padding: 0 }}
                        >
                          <SearchIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <AddButton
                buttonVariant="contained"
                buttonColor="primary"
                buttonText={constants.addFood}
                marginTop="0"
                width="100%"
                height="40px"
                startIcon={<AddIcon />}
                background={"rgba(56, 163, 137, 0.8)"}
                onClick={() => navigate("/addFood")}
                fontFamily={constants.fontFamily}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <AddButton
                buttonVariant="contained"
                buttonColor="primary"
                buttonText={constants.exportFoodItems}
                marginTop="0"
                width="100%"
                height="40px"
                startIcon={<AddIcon />}
                background={"rgba(56, 163, 137, 0.8)"}
                onClick={exportToCSV}
                fontFamily={constants.fontFamily}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        // Desktop view
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: -4,
          }}
        >
          <Grid
            item
            xs={4} // 1/3 width
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: -2,
              marginLeft: -8,
            }}
          >
            <CustomTypography
              text={constants.foodDetails}
              fontSize={28}
              color={"#38A389"}
              fontWeight={"bold"}
              fontFamily={constants.fontFamily}
            />
          </Grid>
          <Grid
            container
            item
            xs={8} // 2/3 width of outer grid
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between", // Adjust for spacing between items
            }}
          >
            <Grid item xs={6} sx={{ marginTop: -6 }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={searchResults.map((food) => food.food_name)}
                onChange={(event, newValue) => {
                  setSearchQuery(newValue);
                  handleItemSelected(newValue);
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={constants.search}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    margin="normal"
                    sx={{
                      width: "100%",
                      paddingRight: 0,
                      "& .MuiInputBase-input": {
                        paddingRight: "2.5rem",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            /* Handle search icon click */
                          }}
                          sx={{ padding: 0 }}
                        >
                          <SearchIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginTop: 6 }}>
              <AddButton
                buttonVariant="contained"
                buttonColor="primary"
                buttonText={constants.addFood}
                marginTop="0"
                width="100%"
                height="40px"
                startIcon={<AddIcon />}
                background={"rgba(56, 163, 137, 0.8)"}
                onClick={() => navigate("/addFood")}
                fontFamily={constants.fontFamily}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginTop: 6 }}>
              <AddButton
                buttonVariant="contained"
                buttonColor="primary"
                buttonText={constants.exportFoodItems}
                marginTop="0"
                width="100%"
                height="40px"
                startIcon={<AddIcon />}
                background={"rgba(56, 163, 137, 0.8)"}
                onClick={exportToCSV}
                fontFamily={constants.fontFamily}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <div style={{ paddingLeft: 12 }}>
        {foodItems.map((chunk, rowIndex) => (
          <Grid
            container
            spacing={2}
            key={rowIndex}
            sx={{ marginTop: "0.5rem" }}
          >
            {chunk
              .filter((food) =>
                food.food_name
                  ? food.food_name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  : false
              )
              .map((filteredFood, id) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                  <FoodCard
                    foodName={filteredFood.food_name}
                    calories={filteredFood.calories_kcal}
                    id={filteredFood.id}
                    fontFamily={constants.fontFamily}
                  />
                </Grid>
              ))}
          </Grid>
        ))}
      </div>
    </Card>
  );
}
