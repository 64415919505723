import { makeStyles } from "@material-ui/core/styles";
import constants from "../../constants.json";
const useStyles = makeStyles((theme) => ({
  container_experience: {
    width: "75%",
    height: "565px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //   margin: "auto",
    marginTop: "6rem",
    scrollbarShown: "false",
  },
  img_style: {
    width: "26.563rem",
    height: "20.875rem",
    position: "absolute",
    top: "93%",
    left: "14.7rem",
  },
  centeredGrid: {
    justifyContent: "center",
  },
  years_style: {
    alignSelf: "flex-end",
    marginLeft: "0.62rem",
    color: "#06647D",
    fontFamily: constants.fontFamily,
    fontSize: "2rem",
  },
  headertext_experience: {
    color: "#FFD171",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    marginBottom: "2.5rem",
  },
  cardcontainer_experience: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //   padding: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
  },
  horizontalScrollContainer_experience: {
    display: "flex",
    overflowX: "auto",
    width: "100%",
    marginTop: theme.spacing(2),
    scrollbarWidth: "none", // Hide the scrollbar for Firefox
    "-ms-overflow-style": "none", // Hide the scrollbar for IE and Edge
    "&::-webkit-scrollbar": {
      display: "none", // Hide the scrollbar for Webkit (Chrome, Safari, Opera)
    },
  },
  card_experience: {
    minWidth: "290px",
    minHeight: "230px",
    margin: theme.spacing(5),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    backgroundColor: "rgba(255, 209, 113, 0.2)",
    transition: "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.20)", // Enlarge the card on hover
      backgroundColor: "rgba(255, 113, 119, 0.4)", // Change background color on hover
    },
  },
  logoimg_experience: {
    height: "150px",
    width: "100px",
  },
  paratext_experience: {
    color: "#38A389",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    alignSelf: "center",
    fontSize: "0.9rem",
    fontFamily: constants.fontFamily,
    opacity: 0.8,
  },
  nametext_experience: {
    color: "#38A389",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    alignSelf: "right",
  },
  straightcontainer_experience: {
    display: "flex",
    flexDirection: "row",
  },

  container_footer: {
    width: "100%",
    marginBottom: "0px",
    height: "400px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: constants.primaryColor,
  },

  text1_footer: {
    color: "#FFFFFFE6",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "1.5rem",
    textAlign: "left",
  },
  text2_footer: {
    color: "rgba(177, 233, 249, 0.8)",
    paddingTop: "0.5rem",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    textAlign: "left",
  },
  textstyle_footer: {
    color: "rgba(177, 233, 249, 0.8)",
    fontFamily: constants.fontFamily,
    fontSize: "20px",
    textAlign: "center",
  },
  textstyle2_footer: {
    color: "rgba(177, 233, 249, 0.8)",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    textAlign: "left",
    paddingTop: "0.5rem",
  },
  verticalcontainer_footer: {
    width: "100%",
    height: "100%",
    margin: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  linestyle_footer: {
    width: "100%",
    height: "1px",
    backgroundColor: "#38A389",
  },
  horizontalcontainer_footer: {
    width: "100%",
    height: "20%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginBottom: "5rem",
  },
  horizontalcontainer2_footer: {
    width: "100%",
    height: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  smallcontainer_footer: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
  },
  smallcontainer2_footer: {
    width: "60%",
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
    // justifyContent: "center",
  },
  innercontainer_footer: {
    width: "30%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    padding: "2rem",
  },
  logoimg_footer: {
    width: "150px",
    height: "70px",
  },
  footerIcon: {
    fontSize: 40,
    color: "white",
    marginRight: "0.5rem",
  },

  container_starter: {
    width: "100%",
    height: "100%",

    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
    overflowY: "auto",
    flex: 1,
    scrollBehavior: "smooth",
    scrollbarGutter: "stable",
    display: "flex",
    backgroundColor: "#31525B",
  },
  outercontainer_starter: {
    minWidth: "100%",
    minHeight: "100vh",
    // justifyContent: "center",
    // alignItems: "center",
    height: "100%",
    backgroundColor: "#31525B",
  },
  innercontainer_starter: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "relative",
    color: "white",
    backgroundColor: "white",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  displayFlexJustified: {
    width: "140vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  displayFlex: {
    display: "flex",
  },
  displayRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  columnFlex: {
    display: "flex",
    flexDirection: "row",
  },
  displayFlex1: {
    display: "flex",
    alignItems: "flex-start",
    alignContent: "flex-start",
    justifyContent: "flex-start",
    border: "2px solid black",
  },
  width_50: {
    width: "50%",
  },
  TeeFormTextField: {
    width: "40%",
    color: "#06647D",
  },
  colorGreen: {
    color: "#06647D",
  },
  radioStyle: {
    color: "#06647D",
    marginLeft: "1.9rem",
  },
  green_caladea: {
    color: "#06647D",
    fontFamily: constants.fontFamily,
    fontSize: "1.5rem",
  },
  results_style: {
    color: "#06647D",
    fontSize: "2rem",
    font: "Caladea",
    fontWeight: "400",
    lineHeight: "36.8px",
  },
  results_style1: {
    color: "#06647D",
    fontSize: "2rem",
    font: "Caladea",
    fontWeight: "400",
    lineHeight: "27.6px",
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  margin_Right: {
    marginRight: "0.75rem",
  },

  container_intro: {
    width: "100%",
    backgroundColor: "#31525B",
    paddingTop: "50px",
  },
  outercontainer_info2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100vh", // Full viewport height
    border: "2px solid red",
  },
  container_info2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Center vertically
    alignItems: "center", // Center horizontally
    width: "100%", // Full width of the grid item
  },

  container_intro_tee: {
    width: "100%",
    minHeight: "100vh",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingBottom: "2rem",
  },
  innercontainer_intro: {
    height: "60%",
    width: "70%",
    border: "2px solid yellow",
    borderRadius: "50px",
    padding: "2.5rem",
    overflow: "hidden",
  },
  TEE_results: {
    marginTop: "1rem",
    minHeight: "8rem",
    backgroundColor: "#F5F5F5BD",
    borderRadius: "10px",
    padding: "2.5rem",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
  },
  TEE_form: {
    backgroundColor: "#E9EBEC",
    borderRadius: "1rem",
    padding: "1.5rem",
    marginTop: "1rem",
    marginBottom: "0.0125rem",
  },
  TEE_buttons: {
    marginRight: "2rem",
    backgroundColor: "#31525B",
    color: "white",
    borderRadius: "10px",
    height: "40px",
    width: "135px",
  },

  text1_intro: {
    color: "white",
    fontFamily: constants.fontFamily,
    textAlign: "left",
    margin: "auto",
    fontSize: "2.25rem",
  },
  input: {
    marginBottom: "0.8rem",
    width: "18.75rem",
    color: "#38A389",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    border: "none",
    boxShadow: "0px 4px 4px #00000040",
    borderRadius: "1rem !important",

    "& .MuiFilledInput-root": {
      background: "white",
      borderRadius: "1rem !important",
      border: "none",
    },

    "& input": {
      // Added CSS rule targeting input element
      fontFamily: constants.fontFamily,
    },
  },
  inputLabel: {
    color: "#38A389 !important", // Customize placeholder color
    borderColor: "#38A389",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",

    "&.Mui-focused": {
      color: "#38A389 !important", // Customize focus color
      borderColor: "#38A389",
      fontFamily: constants.fontFamily,
      fontSize: "1rem",
    },
  },
  notchedOutline: {
    borderColor: "#38A389 !important", // Change the border color of the outline
  },
  text1_red: {
    color: "#02306A",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    //    fontSize: "180%",
    // textAlign: "center",
    // marginBottom: '10%',
    fontSize: "32px",
  },

  text1_green: {
    fontFamily: constants.fontFamily,
    margin: "auto",
    fontSize: "24px",
    color: "#000000",
  },
  text2_intro: {
    color: "#FFC664",
    fontFamily: constants.fontFamily,
    fontSize: "1.5rem",
    textAlign: "left",
    marginTop: "2rem",
    margin: "auto",
  },
  buttonstyle_intro: {
    color: "black",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 20,
    height: "8vh",
    width: "25vh",
    margin: "4vh",
    textTransform: "none",
    // Media query for smaller screen sizes
    "@media (max-width: 600px)": {
      margin: "1.5vh",
      height: "8vh",
      width: "24vh",
    },

    transition: "background-color 0.3s", // Adding transition for smooth color change
    "&:hover": {
      backgroundColor: "#287D7B", // Color when hovered
    },
    "&:focus": {
      backgroundColor: "#287D7B", // Color when focused
      outline: "none", // Remove default outline on focus
    },
  },

  imagestyle_intro: {
    width: "19rem",
    height: "19rem",
    zIndex: 2,
    resizeMode: "contain",
    marginBottom: "4vh",
  },

  container_recommendations: {
    width: "70%",
    height: "470px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //   margin: "auto",
    //   marginTop: "6rem",
    //   scrollbarShown: "false",
  },
  headertext_recommendations: {
    marginTop: "6vh",
    color: "#02306A",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
  },
  headertext_recommendations1: {
    marginTop: "3vh",
    color: "constants.blueTextColor",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    color: "rgba(2, 48, 106, 0.6)",
    fontSize: "1.5rem",
  },

  cardcontainer_recommendations: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    alignSelf: "center",
    width: "90%",
    height: "90%",
  },
  verticalScrollContainer_recommendations: {
    display: "flex",
    overflowY: "auto", // Set the vertical overflow
    flexDirection: "column", // Change the flex direction to column
    height: "90%", // Use the full height available
    marginTop: theme.spacing(2),
    scrollbarWidth: "none", // Hide the scrollbar for Firefox
    "-ms-overflow-style": "none", // Hide the scrollbar for IE and Edge
    "&::-webkit-scrollbar": {
      display: "none", // Hide the scrollbar for Webkit (Chrome, Safari, Opera)
    },
    // boxShadow: "inset 0 0 15px rgba(168, 125, 180, 0.4)",
  },
  card_recommendations: {
    maxWidth: "350px",
    minHeight: "150px",
    margin: theme.spacing(3),
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "10px",
    backgroundColor: "rgba(255, 209, 113, 0.2)",

    transition: "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)", // Enlarge the card on hover
      backgroundColor: "rgba(255, 113, 119, 0.4)", // Change background color on hover
    },
  },
  logoimg_recommendations: {
    height: "150px",
    width: "100px",
    zIndex: 1,
  },
  paratext_recommendations: {
    color: "black",
    textAlign: "center",
    fontSize: "14px",
    //   marginBottom: theme.spacing(2),
    //   marginTop: theme.spacing(2),
    alignSelf: "center",
    //   fontSize: "0.9rem",
    fontFamily: constants.fontFamily,
    textAlign: "center",
    alignSelf: "center",
  },
  nametext_recommendations: {
    color: "#38A389",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
  },
  textcontainer_recommendations: {
    width: "85%",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: theme.spacing(2),
    backgroundColor: "rgba(255, 255, 255, 0.7608)",
    borderRadius: "10px",
    alignSelf: "center",
    alignSelf: "center",
    alignItems: "center",
    //   border: "2px solid #A87DB4",

    overflow: "hidden",
    alignSelf: "center",
    marginTop: "-5px",
    padding: "10px",
    justigyContent: "center",
    alignItems: "center",
  },
  straightcontainer_recommendations: {
    width: "90%",
    height: "70%",
    display: "flex",
    flexDirection: "row",
    borderRadius: "20px",
    // border: "2px solid #A87DB4",
    overflow: "hidden", // Hide any content that goes beyond the container
    position: "relative", // Set position to create a stacking context
    // boxShadow: "inset 0 0 15px rgba(168, 125, 180, 0.4)", // Blurred inside shadow
  },
  scrollableTabs: {
    backgroundColor: "#31525B", // Or any other color
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
    marginBottom: "2%",
    "& .MuiTabs-indicator": {
      backgroundColor: "white",
      color: "white",
    },
    "& .MuiTab-root": {
      // Style for each Tab
      color: "white",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      // Style for the selected Tab
      color: "white",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  centerAligned: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabContent: {
    padding: theme.spacing(2),
    // Add more styling as needed
  },
  smallcontainer_recommendations: {
    width: "35%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    justifyContent: "center",
  },
  text1_recommendations: {
    color: "#38A389",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    //    fontSize: "180%",
    textAlign: "center",
    // margin: "auto",
    fontSize: "1.5rem",
  },
  text2_recommendations: {
    color: "#38A389",

    fontFamily: constants.fontFamily,
    // fontSize: "130%",

    fontSize: "1rem",
    textAlign: "center",
    // margin: "auto",
  },

  container_services: {
    width: "70%",
    height: "565px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "1rem",
  },
  text_services: {
    color: "#FFD171",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    marginBottom: "2.5rem",
  },
  logoimg_services: {
    borderRadius: "5px",
    width: "80px",
    height: "80px",
    margin: "auto",
  },
  cardcontainer_services: {
    borderRadius: "50px",
    backgroundColor: "rgba(168, 125, 180, 0.3)",
    boxShadow: "0px 0 30px rgba(168, 125, 180, 0.1)",
    border: "3px solid rgba(168, 125, 180, 0.1)",
    width: "370px",
    height: "220px",
  },
  itemContent_services: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  headingtext_services: {
    color: "#FF7171",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "25px",
    textAlign: "center",
    marginLeft: theme.spacing(2),
    alignSelf: "center",
  },
  paratext_services: {
    color: "#38A389",
    fontFamily: constants.fontFamily,
    fontSize: "18px",
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  straightcontainer_services: {
    display: "flex",
    flexDirection: "row",
  },

  pentagonContainer: {
    margin: "40px",
    display: "flex",
    flexWrap: "wrap",
    gap: "10px 20px",
  },

  pentagon: {
    position: "relative",
    width: "107.5px",
    height: "170px",
    background: "#38A389",
    clipPath: "polygon( 50% 0, 90% 38%, 90% 100%, 0% 100%, 0 38%)",
    transform: "rotate(270deg)",
  },
  pentagonText: {
    margin: "40px",
    transform: "rotate(-270deg)", // Counter-rotation
  },
  transparentDrawer: {
    backgroundColor: "transparent",
  },
}));

export default useStyles;
