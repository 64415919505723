import { createContext, useContext, useState } from "react";

export const KalorieContext = createContext("");

export const KalorieProvider = (props) => {
  const [data, setData] = useState({
    userId: "5",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    c_password: "",
    hashed_password: "",
    gender: null,
    dob: null,
    height: null,
    weight: null,
    req_weight: null,
    image: null,
    goal_id: "",
    weight_units: "Kilograms",
    req_weight_units: "Kilograms",
    height_units: "Centimeters",
    dietitian_id: "",
    charges: null,
    kids: 0,
    marital_status: null,
    kind_of_allergies: "",
    physical_activity_level: "",
    kind_of_work: "",
    medications: "",
    family_history_of_diabetes: "",
    family_history_of_heart_attack: "",
    frequency_of_fruit_intake: "",
    frequency_of_vegetables_intake: "",
    flour_type: "",
    rice_type: "",
    water_intake: "",
    payment_method: "",
    duration: "1 week",
    day: 1,
    week: 1,
    diet_plan_id: "",
  });
  const [mealDiet, setMealDiet] = useState({});
  const [Token, setToken] = useState("");
  const [meals, setMeals] = useState([]);
  const [noMeals, setNoMeals] = useState(false);
  const [mealErr, setMealErrValue] = useState(false);
  const [proteins, setProteinValue] = useState("");
  const [dialyProtein, setDialyProteinValue] = useState("");
  const [fats, setFatValue] = useState("");
  const [dialyFats, setDialyFatValue] = useState("");
  const [carbs, setCarbValue] = useState("");
  const [dialyCarbs, setDialyCarbValue] = useState("");
  const [totalCalories, setTotalCalories] = useState(0);
  const [recCalories, setRecCal] = useState(0);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  const [startTrackingDiary, setStartTrackingDiary] = useState("");

  const updateStartTrackingDiary = (newToken) => {
    setStartTrackingDiary(newToken);
  };
  const [endTrackingDiary, setEndTrackingDiary] = useState("");
  const updateEndTrackingDiary = (newToken) => {
    setEndTrackingDiary(newToken);
  };
  const [dateTrackingDiary, setDateTrackingDiary] = useState(
    formattedDate.toString()
  );
  const updateDateTrackingDiary = (newToken) => {
    setDateTrackingDiary(newToken);
  };
  // Method to set the token
  const setAuthToken = (newToken) => {
    setToken(newToken);
  };

  // Method to get the token
  const getAuthToken = () => {
    return Token;
  };

  const updateData = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const addMeal = (meal) => {
    setMeals([meal]);
  };
  const setProteins = (value) => {
    setProteinValue(value);
  };
  const setDialyProtein = (value) => {
    setDialyProteinValue(value);
  };
  const setCarbs = (value) => {
    setCarbValue(value);
  };
  const setDialyCarbs = (value) => {
    setDialyCarbValue(value);
  };
  const setFats = (value) => {
    setFatValue(value);
  };
  const setDialyFats = (value) => {
    setDialyFatValue(value);
  };

  // Method to set the noMeals variable
  const setNoMealsValue = (value) => {
    setNoMeals(value);
  };
  const setMealErr = (value) => {
    setMealErrValue(value);
  };
  const setTotalCal = (value) => {
    setTotalCalories(value);
  };
  const setRecCalories = (value) => {
    setRecCal(value);
  };
  const [menuValue, settMenuValue] = useState("weekly");
  const setMenuValue = (value) => {
    settMenuValue(value);
  };

  return (
    <KalorieContext.Provider
      value={{
        data,
        updateData,
        setAuthToken,
        getAuthToken,
        meals,
        addMeal,
        noMeals,
        setNoMeals: setNoMealsValue,
        proteins,
        setProteins,
        dialyProtein,
        setDialyProtein,
        fats,
        setFats,
        dialyFats,
        setDialyFats,
        carbs,
        setCarbs,
        dialyCarbs,
        setDialyCarbs,
        mealErr,
        setMealErr,
        totalCalories,
        setTotalCal,
        recCalories,
        setRecCalories,
        startTrackingDiary,
        updateStartTrackingDiary,
        endTrackingDiary,
        updateEndTrackingDiary,
        dateTrackingDiary,
        updateDateTrackingDiary,
        menuValue,
        setMenuValue,
      }}
    >
      {props.children}
    </KalorieContext.Provider>
  );
};

export const useKalorie = () => {
  return useContext(KalorieContext);
};
