import clsx from "clsx";
import { Container, Button, Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "axios";
import AccordionSection from "./foodDiaryAccordin";
import FoodDiaryAdder from "./foodDiaryAdder";
import styled from "styled-components";
import constants from "../../constants.json";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useStyles from "../../assets/css/dietPlan";
import { useKalorie } from "../../userContext/context";
const userObject = JSON.parse(localStorage.getItem("user"));
const Section = styled.section`
  margin-top: 0.55rem;

  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .testimonials {
    display: flex;
    justify-content: center;
    // margin: 0 0.025rem;
    gap: 0.55rem;
    .testimonial {
      background-color: ${constants.blue};
      padding: 1rem;
      border-radius: 0;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 19px 0px;
      transition: 0.3s ease-in-out;
      // border: 3px solid black;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px;
      }
      .info {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        align-items: center;
        margin-top: 0.25rem;
        img {
          border-radius: 3rem;
          height: 3rem;
        }
        .details {
          span {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .testimonials {
      flex-direction: column;
      margin: 0;
      .testimonial {
        justify-content: center;
        .info {
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
`;

const FoodDiary = ({
  setDurationtype,
  setActiveButton,
  durationtype,
  activeButton,
}) => {
  const classes = useStyles();
  const [selectedFormattedDate, setSelectedFormattedDate] = useState("");
  const { data, updateData } = useKalorie();
  const [reload, setReload] = useState(false);
  const [meals, setMeals] = useState();
  const [diet, setDiet] = useState();
  const [tee, setTEE] = useState();
  const [rec, setRec] = useState(0);
  const [userDetails, setUserDetails] = useState();
  const [firstDayObject, setFirstDayObject] = useState();
  const [diffDays, setDiffDays] = useState();
  const [weekOfToday, setWeekOfToday] = useState();

  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;

  const [currentDate, setCurrentDate] = useState(new Date());
  // Helper function to format a date as 'MMM-DD'
  const formatDate = (date) => {
    // Format the date with month and date only
    const formattedDate = `${date.toLocaleString("en-US", {
      month: "short",
    })}-${String(date.getDate()).padStart(2, "0")}`;

    // Return an object containing the formatted date and the original year
    return {
      formattedDate,
      year: date.getFullYear(),
    };
  };

  const renderWeekDates = () => {
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Move to start of week (Sunday)
    let dates = [];
    for (let i = 0; i < 7; i++) {
      let date = new Date(startOfWeek);
      date.setDate(date.getDate() + i); // Increment the date by 'i' days
      const formattedDate = formatDate(date);
      dates.push(formattedDate);
    }
    return dates.map((date, index) => (
      <Button
        key={date.formattedDate}
        variant="outlined"
        className={clsx(classes.weekButton, {
          [classes.activeButton]:
            formatDate(currentDate).formattedDate === date.formattedDate,
        })}
        onClick={() =>
          setCurrentDate(new Date(date.formattedDate + " " + date.year))
        }
      >
        {date.formattedDate}
      </Button>
    ));
  };

  const handlePreviousDate = () => {
    let newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
  };

  const handleNextDate = () => {
    let newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentDate(newDate);
  };

  function getCurrentDate(date) {
    if (date) {
      const year = date.getFullYear();
      console.log("this is date:::", date);
      console.log("year:::", year);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return null; // or handle the case where date is undefined
  }
  function fetchDietDetails() {
    const formattedDate = getCurrentDate(currentDate);
    console.log("check", {
      user_id: userObject.user_id,
      week: durationtype,
      day: data.day,
      formattedDate: formattedDate,
    });
    const apiUrl = `${serverIp}:${port}/api/get-diet-diary-schedule-client`;
    console.log("im data:::::", data);
    const req_data = {
      user_id: userObject.user_id,
      date: formattedDate,
    };
    console.log("im data:::::", req_data);

    axios
      .post(apiUrl, req_data)
      .then((response) => {
        console.log("response from dietdetails", response.data);
        if (response.status === 200) {
          console.log("checking", response.data);
          const filteredMeals = {
            breakfast: response.data.breakfast,
            dinner: response.data.dinner,
            snack: response.data.snack,
            lunch: response.data.lunch,
          };
          console.log("faaaaa", response.data.breakfast);
          setDiet(response.data);
          setMeals(filteredMeals);
          updateData("diet_plan_id", response.data.diet_plan_id);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.log("error from dietdetials", error);
        console.log("waittttt", error.response.status);
      });
  }

  useEffect(() => {
    function fetchRec() {
      console.log("check", {
        user_id: userObject.user_id,
        week: data.week,
        day: data.day,
      });
      const apiUrl = `${serverIp}:${port}/api/get-required-weight/${userObject.user_id}`;

      axios
        .get(apiUrl)
        .then((response) => {
          console.log("Nooooo", response.data);
          if (response.status === 200) {
            console.log("checking", response.data);
            setUserDetails(response.data.user_detail[0]);
            const weightInKg =
              response.data.user_detail[0].weight_units === "lb"
                ? response.data.user_detail[0].weight / 2.20462
                : response.data.user_detail[0].weight;

            // Convert body height to centimeters
            const heightInCentimeters =
              response.data.user_detail[0].height_units === "m"
                ? response.data.user_detail[0].height * 100
                : response.data.user_detail[0].height;
            if (response.data.user_detail[0].gender === "male") {
              //   BEE = 66 + (13.7 x weight in kg) + (5 x height in cm) – (6.8 x age in years)
              let bee =
                66 +
                weightInKg * 13.7 +
                heightInCentimeters * 5 -
                6.8 * response.data.user_detail[0].age;
              console.log("beee::", bee);
              console.log("activityFactor::", 50);
              let tdee = bee + (bee * 50) / 100;
              setTEE(tdee);
              setRec(tdee - 1000);
              console.log("TEE::::", tdee);
            } else if (response.data.user_detail[0].gender === "female") {
              // 655.0955 + (9.5634 x weight in kg) + (1.8496 x height in cm) – (4.6756 x age in years)
              let bee =
                655.0955 +
                parseInt(weightInKg) * 9.5634 +
                parseInt(heightInCentimeters) * 1.8496 -
                parseInt(response.data.user_detail[0].age) * 4.6756;
              // TDEE = BEE + (BEE x activity level)
              console.log(typeof bee);
              let tdee = bee + (bee * 50) / 100;
              setTEE(tdee);
              setRec(tdee - 1000);
              console.log("TEE::::", tdee);
            }
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.log("error from dietdetials", error);
          console.log("waittttt", error.response.status);
        });
    }

    fetchRec();
  }, [durationtype, reload, weekOfToday]);
  useEffect(() => {
    fetchDietDetails();
  }, [activeButton, currentDate, reload]);
  useEffect(() => {
    function fetchFirstDateOfMeal() {
      const apiUrl = `${serverIp}:${port}/api/get-first-diary-day/${userObject.user_id}`;
      axios
        .get(apiUrl)
        .then((response) => {
          console.log("abcd", response.data);
          setFirstDayObject(response.data);
          const firstDate = new Date(response.data?.first_day);
          const secondDate = new Date(currentDate);
          const daysDiff = Math.round(
            Math.abs((firstDate - secondDate) / 86400000)
          );
          console.log("diff", daysDiff, firstDayObject);
          // Set the difference in days between the current date and the first day of the diary
          setDiffDays(daysDiff);
          // Set the active button based on the difference in days
          setActiveButton(daysDiff);
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          setFirstDayObject(error.response.data);
          console.log("error from dietdetails", error);
          console.log("waittttt", error.response.status);
        });
    }

    fetchFirstDateOfMeal();
  }, []);

  const formattedDate = getCurrentDate(currentDate);

  return (
    <>
      <div>
        <Grid className={classes.mainGrid} container>
          <Container className={classes.centeredColumnFlex}>
            {firstDayObject && (
              <Grid style={{ paddingLeft: "20%" }} container>
                <div className={classes.buttonContainer}>
                  <Button onClick={handlePreviousDate}>
                    <ArrowBackIosIcon />
                  </Button>
                  {renderWeekDates()}
                  <Button onClick={handleNextDate}>
                    <ArrowForwardIosIcon />
                  </Button>
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              {userDetails && (
                <Grid container className={classes.centeredColumnFlex}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "100%",
                      borderRadius: "5%",
                      marginBottom: "1rem",
                    }}
                  >
                    <Section id="testimonials">
                      <div className="testimonials">
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: constants.blue,
                            color: "white",
                            marginBottom: "1rem",
                          }}
                          className="testimonial"
                        >
                          <div className="info">
                            <div className="details">
                              <h6>
                                {constants.current_Weight}:
                                <strong>
                                  {userDetails.weight}{" "}
                                  {userDetails.weight_units}
                                </strong>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: constants.blue,
                            color: "white",
                            marginBottom: "1rem",
                          }}
                          className="testimonial"
                        >
                          <div className="info">
                            <div className="details">
                              <h6>
                                {constants.requiredWeight}:
                                <strong>
                                  {userDetails.required_weight}{" "}
                                  {userDetails.required_weight_units}
                                </strong>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: constants.blue,
                            color: "white",
                            marginBottom: "1rem",
                          }}
                          className="testimonial"
                        >
                          <div className="info">
                            <div className="details">
                              <h6>
                                {constants.caloriesIn1KG}:{" "}
                                <strong>7700 calories</strong>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Section>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "100%",
                      borderRadius: "5%",
                      marginBottom: "1rem",
                    }}
                  >
                    <Section id="testimonials">
                      <div className="testimonials">
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: constants.grey,
                            marginBottom: "1.25rem",
                            padding: "1rem",
                          }}
                          className="testimonial"
                        >
                          <div className="info">
                            <h4>
                              <strong>{constants.recommendation}: </strong>
                              {constants.recommendationTextFirstPart}{" "}
                              {userDetails.weight - userDetails.required_weight}{" "}
                              {userDetails.required_weight_units}
                              {constants.recommendationTextSecondPart}{" "}
                              {(userDetails.weight -
                                userDetails.required_weight) *
                                7700}{" "}
                              {constants.calories}
                              {constants.recommendationTextThirdPart}
                              <br />
                              <br />
                              <strong>
                                {constants.recommendedCaloriesPerDay}:{" "}
                                {Math.round(rec)} {constants.calories}
                              </strong>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Section>
                  </Grid>
                </Grid>
              )}
              <FoodDiaryAdder
                durationtype={durationtype}
                selectedButtonContent={{
                  label: getCurrentDate(currentDate),
                  index: activeButton + 1,
                }}
                userObject={userObject}
                rec={rec}
                setReload={setReload}
                reload={reload}
                diet={diet}
              />
            </Grid>

            {meals &&
              Object.keys(meals).map((mealType) => (
                <Grid
                  item
                  xs={12}
                  key={mealType}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                >
                  <AccordionSection
                    mealType={mealType}
                    meals={meals[mealType]}
                    setReload={setReload}
                    reload={reload}
                  />
                </Grid>
              ))}
          </Container>
        </Grid>
      </div>
    </>
  );
};
export default FoodDiary;
