import React from "react";
import { Col } from "reactstrap";
import "../styles/blog-item.css";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import GlobalStyles from "@mui/material/GlobalStyles";
import constants from "../constants.json";
const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      ".ck.ck-balloon-panel": {
        zIndex: "1400 !important", // Put a higher value that your MUI Dialog (generaly 1300)
      },
    }}
  />
);
const userObject = JSON.parse(localStorage.getItem("user"));
const FoodBlogsList = ({ reload, setReload }) => {
  const [data, setData] = useState([]);
  const [restart, setRestart] = useState(false);
  useEffect(() => {
    const getitems = `https://jbq4swurmf.execute-api.eu-north-1.amazonaws.com/dev/foodBlogs/${userObject.user_id}`;
    axios
      .get(getitems, {
        headers: {
          authorizationToken:
            "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
        },
      })
      .then((response) => {
        if (response.data) {
          setData(response.data.body);
          console.log("tryyy", response.data.body);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [reload]);
  return (
    <>
      {data.map((item) => (
        <FoodBlogsItem
          item={item}
          key={item.id}
          reload={reload}
          setReload={setReload}
        />
      ))}
    </>
  );
};

const FoodBlogsItem = ({ item, setReload, reload }) => {
  const [openResponsiveDialog, setOpenResponsiveDialog] = useState(false);
  const [openResponsiveDeleteDialog, setOpenResponsiveDeleteDialog] =
    useState(false);
  // const [openResponsiveDeleteConfirmDialog, setOpenResponsiveDeleteConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const { title, description, date, time, author } = item;
  const dateNew = new Date();
  let day = dateNew.getDate();
  let month = dateNew.getMonth() + 1;
  let year = dateNew.getFullYear();
  let hour = dateNew.getHours();
  let minutes = dateNew.getMinutes();

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  // const ResponsiveDeleteConfirmDialog = () => {

  //   const theme = useTheme();
  //   const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  //   const handleClose = () => {
  //     setOpenResponsiveDeleteConfirmDialog(false);
  //     setReload(!reload);
  //   };
  //   return (
  //     <React.Fragment>
  //       <Dialog
  //         fullScreen={fullScreen}
  //         open={openResponsiveDeleteConfirmDialog}
  //         onClose={handleClose}
  //         aria-labelledby="responsive-dialog-title"
  //       >
  //         <DialogTitle id="responsive-dialog-title">
  //           {"Food Facts"}
  //         </DialogTitle>
  //         <DialogContent>
  //           <DialogContentText>
  //            Food Fact Has Been Deleted.
  //           </DialogContentText>
  //         </DialogContent>
  //         <DialogActions>
  //           <Button onClick={handleClose} autoFocus>
  //             Close
  //           </Button>
  //         </DialogActions>
  //       </Dialog>
  //     </React.Fragment>
  //   );
  // }
  const ResponsiveDeleteDialog = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const handleClose = () => {
      setOpenResponsiveDeleteDialog(false);
      setReload(!reload);
    };
    const removeFoodBlogs = async (id) => {
      const URL = `https://jbq4swurmf.execute-api.eu-north-1.amazonaws.com/dev/foodBlogs/${id}`;
      await axios
        .delete(URL, {
          headers: {
            authorizationToken:
              "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
          },
        })
        .then((response) => {
          setReload(!reload);
        })
        .catch((error) => {
          // event.preventDefault();
        });
    };

    return (
      <React.Fragment>
        <Dialog
          fullScreen={fullScreen}
          open={openResponsiveDeleteDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Food Blogs"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {constants.areYouSureYouWantsToDeleteThisFoodBlog}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              {constants.close}
            </Button>
          </DialogActions>
          <DialogActions>
            <Button
              onClick={() => {
                removeFoodBlogs(item.id);
                setOpenResponsiveDeleteDialog(false);
              }}
              autoFocus
            >
              {constants.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };
  const ResponsiveDialog = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const handleClose = () => {
      setOpenResponsiveDialog(false);
      setReload(!reload);
    };

    return (
      <React.Fragment>
        <Dialog
          fullScreen={fullScreen}
          open={openResponsiveDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Food Blogs"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {constants.foodBlogHasBeenUpdated}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              {constants.close}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };
  const FullScreenEditDialog = () => {
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState(item.description);
    const [title, setTitle] = useState(item.title);
    const [foodBlogsData, setFoodBlogsData] = useState(item.foodBlogsData);
    const [category, setCategory] = useState(item.category);
    const [tags, setTags] = useState(item.tags);
    console.log("what", item.foodBlogsdata);
    const handleChange = (event) => {
      setCategory(event.target.value);
    };
    const updateFoodBlogs = async (id) => {
      const updateitems = `https://jbq4swurmf.execute-api.eu-north-1.amazonaws.com/dev/foodBlogs/${id}`;
      const data = {
        title: title,
        description: description,
        foodBlogsata: foodBlogsData,
        date: `${day}/${month}/${year}`,
        time: `${hour}:${minutes}`,
        category: category,
        tags: tags,
      };
      axios
        .put(updateitems, data, {
          headers: {
            authorizationToken:
              "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
          },
        })
        .then((response) => {
          if (response.status) {
            console.log(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      if (
        document.getElementById("title").value == "" ||
        document.getElementById("description").value == "" ||
        category == "" ||
        tags.length == 0
      ) {
        toast("Kindly fill all fields");
      } else {
        updateFoodBlogs(item.id);
        setOpen(false);
        setReload(!reload);
        setOpenResponsiveDialog(true);
      }
    };
    const tagList = [
      "Food",
      "Fitness",
      " Software",
      "Diet",
      " Beauty",
      "nutrition",
      "weight loss",
    ];
    return (
      <React.Fragment>
        <div>
          <ToastContainer />
        </div>
        <div>
          <Tooltip title={constants.edit}>
            <IconButton
              class="ml-2 items-center bg-white-100 hover:bg-green-100  text-sm font-bold rounded-full px-2 py-1"
              variant="outlined"
              onClick={handleClickOpen}
              aria-label={constants.edit}
            >
              <ModeEditIcon style={{ color: "black" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={constants.delete}>
            <IconButton
              class="ml-2 items-center bg-white-100 hover:bg-red-200  text-sm font-bold rounded-full px-2 py-1"
              variant="outlined"
              onClick={() => {
                setOpenResponsiveDeleteDialog(true);
              }}
              aria-label={constants.delete}
            >
              <DeleteForeverIcon style={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          disableEnforceFocus
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setOpen(false);
                }}
                aria-label={constants.close}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.foodBlogs}
              </Typography>
              <Button
                autoFocus
                class="ml-2 items-center bg-green-400 hover:bg-red-400  text-lg font-bold rounded px-2 py-1"
                variant="outlined"
                onClick={handleClose}
              >
                {constants.save}
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.title}
              </Typography>
            </ListItem>
            <ListItem>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label={constants.foodBlogTitle}
                type="text"
                fullWidth
                variant="standard"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </ListItem>
            <ListItem>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.shortDescription}
              </Typography>
            </ListItem>
            <ListItem>
              <TextField
                multiline
                autoFocus
                margin="dense"
                id="description"
                label={constants.foodBlogShortDescription}
                type="text"
                fullWidth
                variant="standard"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </ListItem>
            <ListItem>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.category}
              </Typography>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {constants.category}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="category"
                  value={category}
                  label={constants.category}
                  onChange={handleChange}
                >
                  <MenuItem value={"Weight Concerns & Emotional Eating"}>
                    {constants.weightConcernsAndEmotionalEating}
                  </MenuItem>
                  <MenuItem value={"Digestion Issues"}>
                    {constants.digestionIssues}
                  </MenuItem>
                  <MenuItem value={"Diabetes & Blood Sugar Concerns"}>
                    {constants.diabetesAndBloodSugarConcerns}
                  </MenuItem>
                  <MenuItem value={"Heart Health"}>
                    {constants.heartHealth}
                  </MenuItem>
                  <MenuItem value={"Kids Nutrition"}>
                    {constants.kidsNutrition}
                  </MenuItem>
                  <MenuItem value={"Meal Planning & Health Prevention"}>
                    {constants.preventiveHealthcare}
                  </MenuItem>
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {constants.tags}
              </Typography>
            </ListItem>
            <ListItem>
              <Autocomplete
                multiple
                id="tags"
                onChange={(event, newValue) => {
                  setTags(newValue);
                  console.log("new value", newValue);
                  // Update the context's foodName
                }}
                options={tagList}
                getOptionLabel={(option) => option}
                defaultValue={tags}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={constants.blogTags}
                    placeholder="Favorites"
                  />
                )}
                sx={{ width: "100%" }}
              />
            </ListItem>
            <ListItem>
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }} className="App">
                  {open && (
                    <>
                      {inputGlobalStyles}
                      <CKEditor
                        editor={Editor}
                        data={foodBlogsData}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFoodBlogsData(data);
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </ListItem>
          </List>
        </Dialog>
      </React.Fragment>
    );
  };

  return (
    <Col lg="4" md="6" sm="6" className="mb-5">
      <div className="blog__item" style={{ borderRadius: "20px" }}>
        <div className="blog__info p-3">
          <div className="d-flex justify-content-between">
            <p
              className="blog__title"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/foodblogsdetails", { state: item })}
            >
              {title}
            </p>
            <div className="d-flex">
              <FullScreenEditDialog />
              <ResponsiveDialog />
              <ResponsiveDeleteDialog />
              {/* <ResponsiveDeleteConfirmDialog /> */}
            </div>
          </div>
          <p className="section__description mt-3">
            {description.length > 100
              ? `${description.substr(0, 100)}...`
              : description}
          </p>

          <p
            className="read__more"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/foodblogsdetails", { state: item })}
          >
            {constants.readMore}
          </p>

          <div className="blog__time pt-3 mt-3 d-flex align-items-center justify-content-between">
            <span className="blog__author">
              <i className="ri-user-line"></i> {author}
            </span>

            <div className="d-flex align-items-center gap-3">
              <span className="d-flex align-items-center gap-1 section__description">
                <i className="ri-calendar-line"></i> {date}
              </span>

              <span className="d-flex align-items-center gap-1 section__description">
                <i className="ri-time-line"></i> {time}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default FoodBlogsList;
