/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/rules-of-hooks */
import { Container, Typography, Button, Grid } from "@material-ui/core";
import React from "react";
import { useState, useEffect } from "react";
import { useKalorie } from "../../userContext/context";
import AreaChart from "../../components/kalorieKompassComponents/main/charts/areachart";
import PieChart from "../../components/kalorieKompassComponents/main/charts/piechart";
import BarChart from "../../components/kalorieKompassComponents/main/charts/barchart";
import LinearProgressBar from "../../components/kalorieKompassComponents/main/charts/linearProgressBar";
import axios from "axios";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@material-ui/core";
import { createTheme } from "@mui/material/styles";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import constants from "../../constants.json";
import Papa from "papaparse";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import useStyles from "../../assets/css/progressTracker";
import TrackingDates from "../../components/reusableComponents/TrackingDates";
const userObject = JSON.parse(localStorage.getItem("user"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#38A389",
    },
    secondary: {
      main: "#daf8ec",
    },
  },
});
const ProgressTracker = ({
  setDurationtype,
  setActiveButton,
  durationtype,
  activeButton,
}) => {
  const classes = useStyles();
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  const [successful, setSuccessful] = useState(
    sessionStorage.getItem("successful")
  );
  const isMatch = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("md")
  );
  const [selectedFormattedDate, setSelectedFormattedDate] = useState("");
  const {
    data,
    updateData,
    setTotalCal,
    startTrackingDiary,
    endTrackingDiary,
    dateTrackingDiary,
  } = useKalorie();
  const [day1Cal, setDay1Cal] = useState(0);
  const [day2Cal, setDay2Cal] = useState(0);
  const [day3Cal, setDay3Cal] = useState(0);
  const [day4Cal, setDay4Cal] = useState(0);
  const [day5Cal, setDay5Cal] = useState(0);
  const [day6Cal, setDay6Cal] = useState(0);
  const [day7Cal, setDay7Cal] = useState(0);
  const [dietSummary, setDietSummary] = useState([]);
  const [microNutrients, setMicroNutrients] = useState([]);
  const [recMicroNutrients, setRecMicroNutrients] = useState();
  const [rec, setRec] = useState();
  useEffect(() => {
    const fetchRecommendedCalories = async () => {
      console.log("check", {
        user_id: userObject.user_id,
        week: data.week,
        day: data.day,
      });
      const apiUrl = `${serverIp}:${port}/api/get-required-weight/${userObject.user_id}`;

      axios
        .get(apiUrl)
        .then((response) => {
          console.log("Nooooo", response.data);
          if (response.status === 200) {
            console.log("checking", response.data);
            const weightInKg =
              response.data.user_detail[0].weight_units === "lb"
                ? response.data.user_detail[0].weight / 2.20462
                : response.data.user_detail[0].weight;

            // Convert body height to centimeters
            const heightInCentimeters =
              response.data.user_detail[0].height_units === "m"
                ? response.data.user_detail[0].height * 100
                : response.data.user_detail[0].height;
            if (response.data.user_detail[0].gender === "male") {
              //   BEE = 66 + (13.7 x weight in kg) + (5 x height in cm) – (6.8 x age in years)
              let bee =
                66 +
                weightInKg * 13.7 +
                heightInCentimeters * 5 -
                6.8 * response.data.user_detail[0].age;
              console.log("beee::", bee);
              console.log("activityFactor::", 50);
              let tdee = bee + (bee * 50) / 100;
              setRec(tdee - 1000);
              console.log("TEE::::", tdee);
            } else if (response.data.user_detail[0].gender === "female") {
              // 655.0955 + (9.5634 x weight in kg) + (1.8496 x height in cm) – (4.6756 x age in years)
              let bee =
                655.0955 +
                parseInt(weightInKg) * 9.5634 +
                parseInt(heightInCentimeters) * 1.8496 -
                parseInt(response.data.user_detail[0].age) * 4.6756;
              // TDEE = BEE + (BEE x activity level)
              console.log(typeof bee);
              let tdee = bee + (bee * 50) / 100;
              setRec(tdee - 1000);
              console.log("TEE::::", tdee);
            }
            const apiUrl = `${serverIp}:${port}/api/get-recommended-micro-nutrients`;
            console.log("im data:::::", data);
            const req_data = {
              age: response.data.user_detail[0].age,
              gender: response.data.user_detail[0].gender,
            };
            axios
              .post(apiUrl, req_data)
              .then((response) => {
                console.log("user", userObject);
                console.log(
                  "response rec micros",
                  response.data.microNutrients
                );
                if (response.status === 200) {
                  setRecMicroNutrients(response.data.microNutrients);
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {
          console.log("error from dietdetials", error);
          console.log("waittttt", error.response.status);
        });
    };
    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    }
    function getStartAndEndDateOfWeek(date) {
      const currentDate = new Date(date);
      const currentDay = currentDate.getDay();
      const startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - currentDay + 1);

      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      return { startDate: formattedStartDate, endDate: formattedEndDate };
    }

    const fetchDietSummary = async () => {
      const currentDate = new Date();
      const result = getStartAndEndDateOfWeek(currentDate);

      // Print the result
      console.log("Start Date:", result.startDate);
      console.log(
        "End Datettttttttttttttttttttttt:",
        currentDate,
        result.endDate
      );
      const apiUrl = `${serverIp}:${port}/api/weekly-total-diary-meal-calories`;
      console.log("im data:::::", data);

      const req_data = {
        user_id: userObject.user_id,
        // week: durationtype.charAt(durationtype.length - 1),
        start_date: result.startDate,
        end_date: result.endDate,
      };
      await axios
        .post(apiUrl, req_data)
        .then((response) => {
          console.log("user", userObject);
          console.log("response from dietdetailsssss", response.data);
          if (response.status === 200) {
            console.log("original data before sorting", response.data.results);

            const sortedData = response.data.results.sort((a, b) =>
              a.date.localeCompare(b.date)
            );
            console.log("sorted data", sortedData);
            console.log("original data", response.data.results);

            setDietSummary(sortedData);
            const totalCaloriesPerDate = fillMissingDates(
              sortedData,
              startTrackingDiary,
              endTrackingDiary
            );
            console.log("not day its date", totalCaloriesPerDate);
            totalCaloriesPerDate.forEach((calories, index) => {
              switch (index) {
                case 0:
                  setDay1Cal(calories);
                  break;
                case 1:
                  setDay2Cal(calories);
                  break;
                case 2:
                  setDay3Cal(calories);
                  break;
                case 3:
                  setDay4Cal(calories);
                  break;
                case 4:
                  setDay5Cal(calories);
                  break;
                case 5:
                  setDay6Cal(calories);
                  break;
                case 6:
                  setDay7Cal(calories);
                  break;
                default:
                  break;
              }
            });
          }
        })
        .catch((error) => {});
    };
    const fillMissingDates = (data, startDate, endDate) => {
      const filledData = [];
      const currentDate = new Date(startDate);
      const endDateObj = new Date(endDate);
      while (currentDate <= endDateObj) {
        const matchingEntry = data.find(
          (entry) => entry.date === currentDate.toISOString().split("T")[0]
        );

        if (matchingEntry) {
          filledData.push(matchingEntry.total_calories);
        } else {
          filledData.push(0);
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }
      return filledData;
    };
    const fetchMicroNutrients = async () => {
      const currentDate = new Date();
      const result = getStartAndEndDateOfWeek(currentDate);

      // Print the result
      console.log("Start Date:", result.startDate);
      console.log(
        "End Datettttttttttttttttttttttt:",
        currentDate,
        startTrackingDiary,
        endTrackingDiary,
        result.endDate
      );
      const apiUrl = `${serverIp}:${port}/api/weekly-total-diary-meal-micronutrients`;
      console.log("im data:::::", data);

      const req_data = {
        user_id: userObject.user_id,
        start_date: startTrackingDiary,
        end_date: endTrackingDiary,
      };
      await axios
        .post(apiUrl, req_data)
        .then((response) => {
          console.log("user", userObject);
          console.log("response micros", response.data);
          if (response.status === 200) {
            console.log(
              "original data before sorting in micro",
              response.data.results
            );

            const sortedData = response.data.results.sort((a, b) =>
              a.date.localeCompare(b.date)
            );
            console.log("sorted data", sortedData);
            console.log("original data", response.data.results);

            setMicroNutrients(sortedData);
            console.log("response.data.results", response.data.results);
          }
        })
        .catch((error) => {});
    };
    fetchRecommendedCalories();
    fetchDietSummary();
    // fetchDietDetails();
    fetchMicroNutrients();
  }, [
    // data.week,
    startTrackingDiary,
    endTrackingDiary,
    dateTrackingDiary,
    durationtype,
  ]);

  useEffect(() => {
    // Check if successful is true
    if (successful === "true") {
      // Display the message for 2 seconds
      const timeout = setTimeout(() => {
        setSuccessful(null); // Hide the message
      }, 4000);
      sessionStorage.setItem("successful", "false");

      return () => {
        clearTimeout(timeout); // Clear the timeout if the component unmounts
      };
    }
  }, [successful]);

  const handleChange = (event) => {
    setDurationtype(event.target.value);
    console.log("event.target.value", event.target.value + "week");
    updateData("week", event.target.value);
    console.log("datat", data);
    // setActiveButton(null); // Reset active button when changing duration type
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
    console.log("index", index);
    updateData("day", index + 1);

    if (durationtype === "week1") {
      const day = index + 1;
      const date = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        day
      );
      const formattedDate = date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
      setSelectedFormattedDate(formattedDate);
    }
  };
  const exportToCSV = () => {
    const flattenedData = dietSummary.map((day, index) => {
      const breakfastMicronutrients =
        microNutrients[index].breakfast_micronutrients || {};
      const lunchMicronutrients =
        microNutrients[index].lunch_micronutrients || {};
      const dinnerMicronutrients =
        microNutrients[index].dinner_micronutrients || {};
      const snacksMicronutrients =
        microNutrients[index].snacks_micronutrients || {};
      return {
        day: day.day,
        breakfast_foods: day.breakfast_foods
          ? day.breakfast_foods
              .map((food) => `${food.food_name} (${food.calories_kcal} kcal)`)
              .join(", ")
          : "",
        breakfast_total_calories: day.breakfast_total_calories,
        lunch_foods: day.lunch_foods
          ? day.lunch_foods
              .map((food) => `${food.food_name} (${food.calories_kcal} kcal)`)
              .join(", ")
          : "",
        lunch_total_calories: day.lunch_total_calories,
        dinner_foods: day.dinner_foods
          ? day.dinner_foods
              .map((food) => `${food.food_name} (${food.calories_kcal} kcal)`)
              .join(", ")
          : "",
        dinner_total_calories: day.dinner_total_calories,
        snacks_foods: day.snacks_foods
          ? day.snacks_foods
              .map((food) => `${food.food_name} (${food.calories_kcal} kcal)`)
              .join(", ")
          : "",
        snacks_total_calories: day.snacks_total_calories || 0,
        total_calories: day.total_calories || 0,
        total_carbs: day.total_carbs || 0,
        total_fats: day.total_fats || 0,
        total_protein: day.total_protein || 0,

        // Breakfast
        breakfast_total_b3_niac: breakfastMicronutrients.total_b3_niac || 0,
        breakfast_total_b6: breakfastMicronutrients.total_b6 || 0,
        breakfast_total_b9_micro: breakfastMicronutrients.total_b9_micro || 0,
        breakfast_total_calcium: breakfastMicronutrients.total_calcium || 0,
        breakfast_total_carbs: breakfastMicronutrients.total_carbs || 0,
        breakfast_total_choline: breakfastMicronutrients.total_choline || 0,
        breakfast_total_fats: breakfastMicronutrients.total_fats || 0,
        breakfast_total_fiber: breakfastMicronutrients.total_fiber || 0,
        breakfast_total_iron: breakfastMicronutrients.total_iron || 0,
        breakfast_total_magnesium: breakfastMicronutrients.total_magnesium || 0,
        breakfast_total_potassium: breakfastMicronutrients.total_potassium || 0,
        breakfast_total_protein: breakfastMicronutrients.total_protein || 0,
        breakfast_total_ribo: breakfastMicronutrients.total_ribo || 0,
        breakfast_total_selenium_micro:
          breakfastMicronutrients.total_selenium_micro || 0,
        breakfast_total_sodium: breakfastMicronutrients.total_sodium || 0,
        breakfast_total_thia: breakfastMicronutrients.total_thia || 0,
        breakfast_total_vitA: breakfastMicronutrients.total_vitA || 0,
        breakfast_total_vitC: breakfastMicronutrients.total_vitC || 0,
        breakfast_total_vitE: breakfastMicronutrients.total_vitE || 0,
        breakfast_total_vitb12_micro:
          breakfastMicronutrients.total_vitb12_micro || 0,
        breakfast_total_zinc: breakfastMicronutrients.total_zinc || 0,

        // Lunch
        lunch_total_b3_niac: lunchMicronutrients.total_b3_niac || 0,
        lunch_total_b6: lunchMicronutrients.total_b6 || 0,
        lunch_total_b9_micro: lunchMicronutrients.total_b9_micro || 0,
        lunch_total_calcium: lunchMicronutrients.total_calcium || 0,
        lunch_total_carbs: lunchMicronutrients.total_carbs || 0,
        lunch_total_choline: lunchMicronutrients.total_choline || 0,
        lunch_total_fats: lunchMicronutrients.total_fats || 0,
        lunch_total_fiber: lunchMicronutrients.total_fiber || 0,
        lunch_total_iron: lunchMicronutrients.total_iron || 0,
        lunch_total_magnesium: lunchMicronutrients.total_magnesium || 0,
        lunch_total_potassium: lunchMicronutrients.total_potassium || 0,
        lunch_total_protein: lunchMicronutrients.total_protein || 0,
        lunch_total_ribo: lunchMicronutrients.total_ribo || 0,
        lunch_total_selenium_micro:
          lunchMicronutrients.total_selenium_micro || 0,
        lunch_total_sodium: lunchMicronutrients.total_sodium || 0,
        lunch_total_thia: lunchMicronutrients.total_thia || 0,
        lunch_total_vitA: lunchMicronutrients.total_vitA || 0,
        lunch_total_vitC: lunchMicronutrients.total_vitC || 0,
        lunch_total_vitE: lunchMicronutrients.total_vitE || 0,
        lunch_total_vitb12_micro: lunchMicronutrients.total_vitb12_micro || 0,
        lunch_total_zinc: lunchMicronutrients.total_zinc || 0,

        // Dinner
        dinner_total_b3_niac: dinnerMicronutrients.total_b3_niac || 0,
        dinner_total_b6: dinnerMicronutrients.total_b6 || 0,
        dinner_total_b9_micro: dinnerMicronutrients.total_b9_micro || 0,
        dinner_total_calcium: dinnerMicronutrients.total_calcium || 0,
        dinner_total_carbs: dinnerMicronutrients.total_carbs || 0,
        dinner_total_choline: dinnerMicronutrients.total_choline || 0,
        dinner_total_fats: dinnerMicronutrients.total_fats || 0,
        dinner_total_fiber: dinnerMicronutrients.total_fiber || 0,
        dinner_total_iron: dinnerMicronutrients.total_iron || 0,
        dinner_total_magnesium: dinnerMicronutrients.total_magnesium || 0,
        dinner_total_potassium: dinnerMicronutrients.total_potassium || 0,
        dinner_total_protein: dinnerMicronutrients.total_protein || 0,
        dinner_total_ribo: dinnerMicronutrients.total_ribo || 0,
        dinner_total_selenium_micro:
          dinnerMicronutrients.total_selenium_micro || 0,
        dinner_total_sodium: dinnerMicronutrients.total_sodium || 0,
        dinner_total_thia: dinnerMicronutrients.total_thia || 0,
        dinner_total_vitA: dinnerMicronutrients.total_vitA || 0,
        dinner_total_vitC: dinnerMicronutrients.total_vitC || 0,
        dinner_total_vitE: dinnerMicronutrients.total_vitE || 0,
        dinner_total_vitb12_micro: dinnerMicronutrients.total_vitb12_micro || 0,
        dinner_total_zinc: dinnerMicronutrients.total_zinc || 0,

        // Snacks
        snacks_total_b3_niac: snacksMicronutrients.total_b3_niac || 0,
        snacks_total_b6: snacksMicronutrients.total_b6 || 0,
        snacks_total_b9_micro: snacksMicronutrients.total_b9_micro || 0,
        snacks_total_calcium: snacksMicronutrients.total_calcium || 0,
        snacks_total_carbs: snacksMicronutrients.total_carbs || 0,
        snacks_total_choline: snacksMicronutrients.total_choline || 0,
        snacks_total_fats: snacksMicronutrients.total_fats || 0,
        snacks_total_fiber: snacksMicronutrients.total_fiber || 0,
        snacks_total_iron: snacksMicronutrients.total_iron || 0,
        snacks_total_magnesium: snacksMicronutrients.total_magnesium || 0,
        snacks_total_potassium: snacksMicronutrients.total_potassium || 0,
        snacks_total_protein: snacksMicronutrients.total_protein || 0,
        snacks_total_ribo: snacksMicronutrients.total_ribo || 0,
        snacks_total_selenium_micro:
          snacksMicronutrients.total_selenium_micro || 0,
        snacks_total_sodium: snacksMicronutrients.total_sodium || 0,
        snacks_total_thia: snacksMicronutrients.total_thia || 0,
        snacks_total_vitA: snacksMicronutrients.total_vitA || 0,
        snacks_total_vitC: snacksMicronutrients.total_vitC || 0,
        snacks_total_vitE: snacksMicronutrients.total_vitE || 0,
        snacks_total_vitb12_micro: snacksMicronutrients.total_vitb12_micro || 0,
        snacks_total_zinc: snacksMicronutrients.total_zinc || 0,

        total_b3_niac: microNutrients[index].total_b3_niac || 0,
        total_b6: microNutrients[index].total_b6 || 0,
        total_b9_micro: microNutrients[index].total_b9_micro || 0,
        total_calcium: microNutrients[index].total_calcium || 0,
        total_choline: microNutrients[index].total_choline || 0,
        total_fiber: microNutrients[index].total_fiber || 0,
        total_iron: microNutrients[index].total_iron || 0,
        total_magnesium: microNutrients[index].total_magnesium || 0,
        total_potassium: microNutrients[index].total_potassium || 0,
        total_ribo: microNutrients[index].total_ribo || 0,
        total_selenium_micro: microNutrients[index].total_selenium_micro || 0,
        total_sodium: microNutrients[index].total_sodium || 0,
        total_thia: microNutrients[index].total_thia || 0,
        total_vitA: microNutrients[index].total_vitA || 0,
        total_vitC: microNutrients[index].total_vitC || 0,
        total_vitE: microNutrients[index].total_vitE || 0,
        total_vitb12_micro: microNutrients[index].total_vitb12_micro || 0,
        total_zinc: microNutrients[index].total_zinc || 0,
      };
    });

    const csv = Papa.unparse(flattenedData, {
      header: true,
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "exported_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(
        "Your browser does not support the download feature. Please use a different browser."
      );
    }
  };
  useEffect(() => {
    // Calculate today's date
    const today = new Date();
    const todayIndex = today.getDate() - 1;
    const todayWeek = Math.ceil(todayIndex / 7); // Calculate the current week
    const todayMonth = today.getMonth(); // Get the current month index

    // Set the active button based on the current duration type
    if (durationtype === "days") {
      setActiveButton(todayIndex);
    } else if (durationtype === "weeks") {
      setActiveButton(todayWeek);
    } else if (durationtype === "months") {
      setActiveButton(todayMonth);
    }

    // Format today's date
    const formattedDate = today.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
    });
    setSelectedFormattedDate(formattedDate);
  }, [durationtype]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const VerticalTabs = () => {
    const [value, setValue] = React.useState(0);
    const [chartType, setChartType] = React.useState("area");
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "#F5F5F5",
          display: !isMatch ? "flex" : "",
          height: "100%",
          width: "100%",
        }}
      >
        <Tabs
          orientation={!isMatch ? "vertical" : "horizontal"}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label={constants.totalCalories} {...a11yProps(0)} />
          <Tab label={constants.macroNutrients} {...a11yProps(1)} />
          <Tab label={constants.microNutrients} {...a11yProps(2)} />
        </Tabs>
        <TabPanel style={{ width: "100%" }} value={value} index={0}>
          {chartType === "area" ? (
            <>
              <Button
                onClick={() => {
                  setChartType("bar");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.barChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("pie");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.pieChart}
              </Button>
              <AreaChart
                rec={Math.round(rec)}
                dietSummary={dietSummary}
                type="totalCal"
                microNutrients={microNutrients}
              />
            </>
          ) : chartType === "pie" ? (
            <>
              <Button
                onClick={() => {
                  setChartType("bar");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.barChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("area");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.areaChart}
              </Button>
              <PieChart
                rec={Math.round(rec)}
                dietSummary={dietSummary}
                type="totalCal"
              />
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setChartType("pie");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.pieChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("area");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.areaChart}
              </Button>
              <div>
                <BarChart
                  rec={Math.round(rec)}
                  microNutrients={microNutrients}
                  type="totalCal"
                />
              </div>
            </>
          )}
        </TabPanel>
        <TabPanel style={{ width: "100%" }} value={value} index={1}>
          {chartType === "area" ? (
            <>
              <Button
                onClick={() => {
                  setChartType("bar");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.barChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("pie");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.pieChart}
              </Button>
              <AreaChart
                rec={Math.round(rec)}
                dietSummary={dietSummary}
                type="macro"
                microNutrients={microNutrients}
              />
            </>
          ) : chartType === "pie" ? (
            <>
              <Button
                onClick={() => {
                  setChartType("bar");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.barChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("area");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.areaChart}
              </Button>
              <PieChart
                rec={Math.round(rec)}
                dietSummary={dietSummary}
                type="macro"
              />
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setChartType("pie");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.pieChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("area");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.areaChart}
              </Button>
              <div>
                <BarChart
                  rec={Math.round(rec)}
                  microNutrients={microNutrients}
                  type="macro"
                />
              </div>
            </>
          )}
        </TabPanel>
        <TabPanel style={{ width: "100%" }} value={value} index={2}>
          <div
            style={{ height: "100%", width: "100%", padding: "10px" }}
            className="bg-white rounded-xl shadow-md"
          >
            <LinearProgressBar
              microNutrients={microNutrients}
              recMicroNutrients={recMicroNutrients}
            />
          </div>
        </TabPanel>
      </Box>
    );
  };
  return (
    <>
      <div
        style={{
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={classes.innercontainer}>
          <div>
            <Grid
              style={{
                width: "100%",
                justifyContent: "space-around",
              }}
              container
            >
              <Container className={classes.container}>
                <Container
                  className={classes.horizontalContainer}
                  style={{
                    display: "flex",
                    flexDirection: "column", // Stack items vertically on mobile
                    alignItems: "center", // Center items horizontally
                    width: "100%",
                    maxWidth: "none",
                  }}
                >
                  <Typography
                    style={{
                      width: "100%",
                      maxWidth: "250px",
                      fontSize: "1.2rem", // Adjust font size for mobile
                      textAlign: "center", // Center align text on mobile
                      marginBottom: "10px", // Add margin for spacing on mobile
                    }}
                    className={classes.inputLabel}
                  >
                    {constants.weeklyProgressTracker}
                  </Typography>
                  <TrackingDates />
                  <div>
                    <button
                      style={{
                        width: "100%",
                        maxWidth: "100px",
                        justifyContent: "center",
                        marginTop: "10px", // Add margin for spacing on mobile
                        fontSize: "0.9rem", // Adjust font size for mobile
                      }}
                      className="ml-2 items-center bg-[#06647D] hover:text-white text-white bg-[#31525B] text-sm rounded px-2 py-1"
                      onClick={exportToCSV}
                    >
                      Export Report
                    </button>
                  </div>
                </Container>

                {dietSummary && (
                  <Grid
                    style={{
                      padding: "10px",
                      fontSize: "1.5rem", // Adjust font size for mobile
                      textAlign: "center", // Center align for better readability on small screens
                    }}
                    item
                    xs={12}
                  >
                    <VerticalTabs />
                  </Grid>
                )}
                <Grid
                  container
                  style={{
                    width: "100%",
                    padding: "0.5em",
                    backgroundColor: "#F5F5F5",
                    marginTop: "1em", // Adjust margin for spacing
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4} // Adjust width for larger screens
                    style={{
                      padding: "0.5em",
                      fontSize: "1.2rem", // Adjust font size for mobile
                      textAlign: "center", // Center align for better readability on small screens
                    }}
                  >
                    <div style={{ color: constants.blue }}>
                      {constants.week} {constants.calories} (Kcl)
                    </div>
                    <div>
                      {day1Cal +
                        day2Cal +
                        day3Cal +
                        day4Cal +
                        day5Cal +
                        day6Cal +
                        day7Cal}
                    </div>
                  </Grid>

                  {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                    <Grid
                      key={day}
                      item
                      xs={12}
                      sm={12}
                      md={1} // Adjust width for larger screens
                      style={{
                        padding: "10px",
                        fontSize: "1rem", // Adjust font size for mobile
                        textAlign: "center", // Center align for better readability on small screens
                      }}
                    >
                      <div>
                        {constants.day} {day}
                      </div>
                      <div>
                        {day === 1
                          ? day1Cal
                          : day === 2
                          ? day2Cal
                          : day === 3
                          ? day3Cal
                          : day === 4
                          ? day4Cal
                          : day === 5
                          ? day5Cal
                          : day === 6
                          ? day6Cal
                          : day7Cal}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Grid>

            <div
              style={{
                height: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid style={{ fontSize: "20px" }} item sm={12} md={3} lg={3}>
                <Typography
                  style={{
                    width: "200px",
                    paddingTop: "10%",
                    color: constants.blue,
                    fontWeight: "bold",
                  }}
                  className={classes.inputLabel}
                >
                  {constants.weeklySummary}
                </Typography>
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "90%",
                  // marginLeft: "2%",
                  justifyContent: "space-around",
                  alignSelf: "center",
                  alignItems: "center",
                  padding: "10px",
                  // border: "2px solid blue",
                  flexWrap: "wrap",
                }}
                container
              >
                {dietSummary.map((items, index) => {
                  const backgroundColor =
                    index % 2 === 0 ? "#31525B" : "#FFC664";
                  return (
                    <Grid
                      container
                      sm={12}
                      md={5}
                      lg={5}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // border: "1px solid pink",
                        width: "100%",
                      }}
                    >
                      <Grid
                        item
                        style={{
                          padding: "20px",
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: constants.blue,
                          display: "flex",
                          justifyContent: "space-between",
                          display: "flex", // Added to enable flex properties
                          flexDirection: "column", // Added for proper alignment
                        }}
                      >
                        {" "}
                        <Typography className={classes.textClass}>
                          {constants.day} {index + 1}{" "}
                          {items.date.split("-").reverse().join("-")}
                        </Typography>
                        <Typography className={classes.textClass}>
                          {constants.totalCalories} (KCl):{items.total_calories}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{
                          backgroundColor,
                          borderRadius: "5%",
                          height: "650px",
                          overflowY: "auto",
                          flexWrap: "nowrap",
                          width: "100%",
                          display: "flex", // Added to enable flex properties
                          flexDirection: "column", // Added for proper alignment
                        }}
                        container
                      >
                        <Grid
                          style={{
                            padding: "20px",
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: constants.white,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div>
                              <p>{constants.mealType}</p>
                              <p>{constants.breakfast}</p>
                              {items.breakfast_foods !== null ? (
                                items.breakfast_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "white",
                                        }}
                                      >
                                        {item.food_name}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <Grid
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "white",
                                    }}
                                  >
                                    {constants.noMealAddedYet}
                                  </Grid>
                                </>
                              )}
                              <p>{constants.lunch}</p>
                              {items.lunch_foods !== null ? (
                                items.lunch_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "white",
                                        }}
                                      >
                                        {item.food_name}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <Grid
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "white",
                                    }}
                                  >
                                    {constants.noMealAddedYet}
                                  </Grid>
                                </>
                              )}
                              <p>{constants.snack}</p>
                              {items.snacks_foods !== null ? (
                                items.snacks_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "white",
                                        }}
                                      >
                                        {item.food_name}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <Grid
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "white",
                                    }}
                                  >
                                    {constants.noMealAddedYet}
                                  </Grid>
                                </>
                              )}
                              <p>{constants.dinner}</p>
                              {items.dinner_foods !== null ? (
                                items.dinner_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "white",
                                        }}
                                      >
                                        {item.food_name}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <Grid
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "white",
                                    }}
                                  >
                                    {constants.noMealAddedYet}
                                  </Grid>
                                </>
                              )}
                            </div>
                            <div>
                              <p>{constants.calories} (Kcl)</p>
                              <p>{items.breakfast_total_calories}</p>
                              {items.breakfast_foods !== null ? (
                                items.breakfast_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "white",
                                        }}
                                      >
                                        {item.calories_kcal}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <Grid
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "white",
                                    }}
                                  >
                                    0
                                  </Grid>
                                </>
                              )}
                              <p>{items.lunch_total_calories}</p>
                              {items.lunch_foods !== null ? (
                                items.lunch_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "white",
                                        }}
                                      >
                                        {item.calories_kcal}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <Grid
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "white",
                                    }}
                                  >
                                    0
                                  </Grid>
                                </>
                              )}
                              <p>{items.snacks_total_calories}</p>
                              {items.snacks_foods !== null ? (
                                items.snacks_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "white",
                                        }}
                                      >
                                        {item.calories_kcal}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <Grid
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "white",
                                    }}
                                  >
                                    0
                                  </Grid>
                                </>
                              )}
                              <p>{items.dinner_total_calories}</p>
                              {items.dinner_foods !== null ? (
                                items.dinner_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "10px",
                                          fontSize: "20px",
                                          color: "white",
                                        }}
                                      >
                                        {item.calories_kcal}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <Grid
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "white",
                                    }}
                                  >
                                    0
                                  </Grid>
                                </>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressTracker;
