import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import "../adminScreens/userRegistration.css";
import { ToastContainer, toast } from "react-toastify";
import { OutlinedInput, TextField, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";
export default function EditUser() {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const param = useParams();
  const id = param.id;
  console.log(param.id);
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  const [data, setData] = useState([]);
  const [userdata, setuserdata] = useState([]);

  useEffect(() => {
    const getuser = `${serverIp}:${port}/api/users/${id}`;
    if (id) {
      axios
        .get(getuser)
        .then((response) => {
          console.log(response.data);
          setuserdata(response.data.user);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);
  useEffect(() => {
    if (userdata) {
      console.log("userdata", userdata);
      setValue("email", userdata.email || "");
      setValue("role", userdata.role || "");
      setValue("firstname", userdata.first_name || "");
      setValue("lastname", userdata.last_name || "");
      setValue("degree", userdata.degree || "");
      setValue("gender", userdata.gender || "");
      setValue("blood_group", userdata.blood_group || "");
      setValue("contact", userdata.contact_number || "");
      setValue("address", userdata.address || "");
      setValue("charges", userdata.charges || "");
      // setValue("charges_one_week", userdata.charges_one_week || "");
      // setValue("charges_one_month", userdata.charges_one_month || "");
      setValue("brief_info", userdata.brief_info || "");
      setValue("experience", userdata.experience || "");
    }
  }, [userdata, setValue]);

  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    const apiUrl = `${serverIp}:${port}/api/roles`;

    axios
      .get(apiUrl)
      .then((response) => {
        console.log("response", response);

        setData(response.data.roles);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="ml-10 mr-10 mt-10">
      <div className="flex justify-center items-center ">
        <form
          noValidate
          className="bg-white  py-3 image rounded-sm mt-3 mb-2 w-5/6 border-[E3CFF6]"
          onSubmit={handleSubmit(async (data) => {
            console.log("11data:::", data);
            const userinfo = {
              email: data.email,
              password: data.password,
              role_id: data.role,
              first_name: data.firstname,
              last_name: data.lastname,
              degree: data.degree,
              gender: data.gender,
              blood_group: data.blood_group,
              contact_number: data.contact,
              address: data.address,
              charges: data.charges,
              // charges_one_week: data.charges_one_week,
              // charges_one_month: data.charges_one_month,
              brief_info: data.brief_info,
              experience: data.experience,
            };

            const formData = new FormData();
            formData.append("email", data.email);
            formData.append("password", data.password);
            formData.append("role_id", data.usertype);
            formData.append("first_name", data.firstname);
            formData.append("last_name", data.lastname);
            formData.append("degree", data.degree);
            formData.append("gender", data.gender);
            formData.append("blood_group", data.blood_group);
            formData.append("contact_number", data.contact);
            formData.append("address", data.address);
            formData.append("charges", data.charges);
            // formData.append("charges_one_week", data.charges_one_week);
            // formData.append("charges_one_month", data.charges_one_month);
            formData.append("brief_info", data.brief_info);
            formData.append("experience", data.experience);

            if (id) {
              const updateUrl = `${serverIp}:${port}/api/users/${id}`;
              axios
                .put(updateUrl, userinfo)
                .then((response) => {
                  if (response.status) {
                    console.log("PUT request successful:", response.data);
                    toast(response.data.message, {
                      icon: (
                        <svg
                          color="green"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      ),
                      position: "top-left",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      style: {
                        color: "#03045E",
                        fontSize: "15px",
                      },
                    });
                  }
                })
                .catch((error) => {
                  toast(error.response.data.message, {
                    icon: (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          color="red"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>
                    ),
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    style: {
                      color: "#03045E",
                      fontSize: "17px",
                    },
                  });
                });
            } else {
              const postUrl = `${serverIp}:${port}/api/users`;

              axios
                .post(postUrl, userinfo)
                .then((response) => {
                  console.log("POST request successful:", response.data);
                  navigate("/displayUsers");
                  toast(response.data.message, {
                    icon: (
                      <svg
                        color="green"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    ),
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    style: {
                      color: "#03045E",
                      fontSize: "15px",
                    },
                  });
                  reset();
                })
                .catch((error) => {
                  toast(error.response.data.message, {
                    icon: (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          color="red"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>
                    ),
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    style: {
                      color: "#03045E",
                      fontSize: "17px",
                    },
                  });
                });
            }
          })}
        >
          <div className="space-y-4 ">
            <div className="border-b border-gray-900/10 pb-3 px-3 max-w-full ">
              <h1 className="text-2xl py-3 font-semibold leading-7 text-[#06647D]">
                Edit User
              </h1>
              <div className=" grid grid-cols-12 gap-x-6 gap-y-2 sm:grid-cols-6 ">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="firstname"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    FirstName
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      type="text"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("firstname", {
                        required: "FirstName is required",
                      })}
                      id="firstname"
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5" />
                    {errors.firstname && (
                      <p className="text-red-500 text-xs">
                        {errors.firstname.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="lastname"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    LastName
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      type="text"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("lastname", {
                        required: "LastName is required",
                      })}
                      id="lastname"
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5" />
                    {errors.lastname && (
                      <p className="text-red-500 text-xs">
                        {errors.lastname.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    User Role
                  </label>
                  <div className="mt-1 ">
                    <TextField
                      id="role"
                      size="small"
                      select
                      InputProps={{
                        style: { borderRadius: "10px" }, // Adjust the border radius value as needed
                      }}
                      {...register("role", {
                        required: "User type is required",
                      })}
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5">
                      <MenuItem disabled selected value="">
                        Choose User
                      </MenuItem>

                      {data.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.title}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.usertype && (
                      <p className="text-red-500 text-xs">
                        {errors.usertype.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Gender
                  </label>
                  <div className="mt-1 ">
                    <TextField
                      id="gender"
                      size="small"
                      select
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      {...register("gender", {
                        required: "Gender is required",
                      })}
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5">
                      <MenuItem disabled selected value="">
                        Choose Gender
                      </MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                    {errors.gender && (
                      <p className="text-red-500 text-xs">
                        {errors.gender.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="contact"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Contact No
                  </label>
                  <div className="mt-1 ">
                    <OutlinedInput
                      id="contact"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("contact", {
                        required: "Contact No is required",
                        pattern: {
                          value: /^0[3-9]\d{9}$/, // Adjust the regex pattern for your specific contact number format
                          message:
                            "Please enter a valid Pakistani contact number (11 digits starting with 0)",
                        },
                      })}
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5" />
                    {errors.contact && (
                      <p className="text-red-500 text-xs">
                        {errors.contact.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      id="email"
                      {...register("email", {
                        required: "email is required",
                        pattern: {
                          value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi,
                          message:
                            "Please enter a valid email address (e.g., example@example.com)",
                        },
                      })}
                      type="email"
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5" sx={{ height: "42px", borderRadius: "10px" }}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* <div className="sm:col-span-3">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      id="password"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      type={showPassword ? "text" : "password"}
                      {...register("password", {
                        required: "password is required",
                        pattern: {
                          value:
                            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                          message: `-at least 8 characters\n
                -must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number\n
                -Can contain special characters`,
                        },
                      })}
                      className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.password && (
                      <p className="text-red-500 text-xs">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                </div> */}
                <div className="sm:col-span-3">
                  <label
                    htmlFor="degree"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Degree Name
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      id="degree"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("degree", {
                        required: "Degree Name is required",
                      })}
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5" />
                    {errors.degreen && (
                      <p className="text-red-500 text-xs">
                        {errors.degree.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="charges"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Charges
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      id="charges"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("charges", {
                        required: "Charges are required",
                      })}
                      type="text"
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5" />
                    {errors.charges && (
                      <p className="text-red-500 text-xs">
                        {errors.charges.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Address
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      id="address"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("address", {
                        required: "Address is required",
                      })}
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5" />
                    {errors.address && (
                      <p className="text-red-500 text-xs">
                        {errors.address.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* <div className="sm:col-span-2">
                  <label
                    htmlFor="charges_one_week"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Charges (One Week)
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      id="charges_one_week"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("charges_one_week", {
                        required: "Charges are required",
                      })}
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    {errors.charges_one_week && (
                      <p className="text-red-500 text-xs">
                        {errors.charges_one_week.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="charges_one_month"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Charges (One Month)
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      id="charges_one_month"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("charges_one_month", {
                        required: "Charges are required",
                      })}
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    {errors.charges_one_month && (
                      <p className="text-red-500 text-xs">
                        {errors.charges_one_month.message}
                      </p>
                    )}
                  </div>
                </div> */}
                <div className="sm:col-span-6">
                  <label
                    htmlFor="brief_info"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Brief Info
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      id="brief_info"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("brief_info", {
                        required: "Brief Info is required",
                      })}
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5" />
                    {errors.brief_info && (
                      <p className="text-red-500 text-xs">
                        {errors.brief_info.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="experience"
                    className="block text-sm font-medium leading-6 text-blue-950"
                  >
                    Experience
                  </label>
                  <div className="mt-1">
                    <OutlinedInput
                      id="experience"
                      sx={{ height: "42px", borderRadius: "10px" }}
                      {...register("experience", {
                        required: "Experience is required",
                      })}
                      className="border border-gray-300 text-sm rounded-lg w-full p-2.5" />
                    {errors.experience && (
                      <p className="text-red-500 text-xs">
                        {errors.experience.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 flex items-center justify-end">
            {id ? (
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#24768C",
                  ":hover": {
                    backgroundColor: "#06647D",
                  },
                }}
                className=" px-3 py-2 tracking-widest text-sm font-semibold text-white shadow-sm  "
              >
                Edit
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#24768C",
                  ":hover": {
                    backgroundColor: "#06647D",
                  },
                }}
                className=" px-3 py-2 tracking-widest text-sm font-semibold text-white shadow-sm  "
              >
                Register
              </Button>
            )}
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}
