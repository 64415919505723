import React from "react";
import UnapprovedFoodItems from "../../adminScreens/unapprovedfoodItems";
import ApprovedFoodItems from "../../adminScreens/approvedFoodItems";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import { useMediaQuery } from "@material-ui/core";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import constants from "../../constants.json";
import { Delete } from "@material-ui/icons";
const theme = createTheme({
  palette: {
    primary: {
      main: "#38A389",
    },
    secondary: {
      main: "#daf8ec",
    },
  },
});
const serverIp = process.env.REACT_APP_SERVERIP;
const port = process.env.REACT_APP_PORT;
const apiUrl = process.env.REACT_APP_API_URL;
const userObject = JSON.parse(localStorage.getItem("user"));
export default function FoodtoAddAvoid() {
  const [unapitems, setunapitems] = useState([]);
  const [apitems, setapitems] = useState([]);
  const [foodItems, setfoodItems] = useState([]);
  const [updateAllowedFood, setUpdateAllowedFood] = useState(true);
  const [updateNotAllowedFood, setUpdateNotAllowedFood] = useState(true);
  const [updateFoodToEat, setupdateFoodToEat] = useState(apitems);
  const [updateFoodToAvoid, setupdateFoodToAvoid] = useState(apitems);
  const [foodNames, setFoodNames] = useState([]);
  const [allowedfoodItemsNames, setAllowedFoodItemsNames] = useState("");
  const [unAllowedfoodItemsNames, setUnAllowedFoodItemsNames] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dietPlanIdObject = location.state.result;
  console.log("woowowow", dietPlanIdObject);
  const userData = location.state.userData;
  const isMatch = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("md")
  );
  useEffect(() => {
    setapitems(updateFoodToEat);
  }, [updateFoodToEat]);
  useEffect(() => {
    setunapitems(updateFoodToAvoid);
  }, [updateFoodToAvoid]);
  useEffect(() => {
    const getitems = `${serverIp}:${port}/api/food-items`;
    axios
      .get(getitems)
      .then((response) => {
        if (response.status) {
          console.log(response.data.foodItems);
          setfoodItems(response.data.foodItems);
          response.data.foodItems.map(async (food) => {
            setFoodNames((foodNames) => [...foodNames, food.food_name]);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    const getitems = `${serverIp}:${port}/api/get-allowed-food-items/${dietPlanIdObject.diet_plan_id}`;
    axios
      .get(getitems)
      .then((response) => {
        if (response.status) {
          setapitems(response.data.foodNames);
          console.log("tryyy111", response.data.foodNames);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [updateAllowedFood]);
  useEffect(() => {
    const getitems = `${serverIp}:${port}/api/get-unallowed-food-items/${dietPlanIdObject.diet_plan_id}`;
    axios
      .get(getitems)
      .then((response) => {
        if (response.status) {
          setunapitems(response.data.unAllowedFoodNames);
          console.log("tryyy", response.data.unAllowedFoodNames);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [updateNotAllowedFood]);
  const addAllowedAndNotAllowedFood = async (foodName, option) => {
    var check = false;
    var newFoodCheck = false;
    apitems.map((items, index) => {
      if (items.food_name === foodName) {
        check = true;
      }
    });
    unapitems.map((items, index) => {
      if (items.food_name === foodName) {
        check = true;
      }
    });
    if (check === false) {
      if (option === "food to eat") {
        foodNames.map((items, index) => {
          if (items === foodName) {
            newFoodCheck = true;
          }
        });
        if (newFoodCheck === false) {
          console.log("super");
          try {
            const response = await fetch(apiUrl + "/api/add-new-food-item", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                food_name: foodName,
                message: "any message",
                dietition_id: userObject.user_id,
              }),
            });

            const result = await response.json();
            await addAllowedFood(result.food_item_id);
            console.log("check111111111", result);
            const URL =
              "https://api.ravenhub.io/company/R1mZPd6xWo/subscribers/64e7f7397a784904801d0ad0" +
              2 +
              "/events/C7SM2VpQrQ";
            const data = {
              foodName: foodName,
              urlVar: "/addNewFoodMacronutrient/" + foodName,
            };
            console.log("urlll", URL);
            axios
              .post(URL, data)
              .then((response) => {
                console.log("Response is wow", response.data);
              })
              .catch((error) => {});
          } catch (error) {
            console.error("An error occurred:", error);
            // Handle error scenario
            throw error;
          }
        } else {
          const allowedFoodObject = foodItems.filter(
            (item) => item.food_name === foodName
          );
          console.log("new", allowedFoodObject[0].id);
          await addAllowedFood(allowedFoodObject[0].id);
          // setapitems([]);
        }
        setUpdateAllowedFood(!updateAllowedFood);
      } else if (option === "food to avoid") {
        foodNames.map((items, index) => {
          if (items === foodName) {
            newFoodCheck = true;
          }
        });
        if (newFoodCheck === false) {
          console.log("super");
          try {
            const response = await fetch(apiUrl + "/api/add-new-food-item", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                food_name: foodName,
                message: "any message",
                dietition_id: userObject.user_id,
              }),
            });

            const result = await response.json();
            await addAvoidFood(result.food_item_id);
            console.log("check111111111", result);
            const URL =
              "https://api.ravenhub.io/company/R1mZPd6xWo/subscribers/64e7f7397a784904801d0ad0" +
              2 +
              "/events/C7SM2VpQrQ";
            const data = {
              foodName: foodName,
              urlVar: "/addNewFoodMacronutrient/" + foodName,
            };
            console.log("urlll", URL);
            axios
              .post(URL, data)
              .then((response) => {
                console.log("Response is wow", response.data);
              })
              .catch((error) => {});
          } catch (error) {
            console.error("An error occurred:", error);
            // Handle error scenario
            throw error;
          }
        } else {
          const notAllowedFoodObject = foodItems.filter(
            (item) => item.food_name === foodName
          );
          await addAvoidFood(notAllowedFoodObject[0].id);
          // setunapitems([]);
        }
        setUpdateNotAllowedFood(!updateNotAllowedFood);
      }
    } else {
      toast("Food Item Already Added");
      console.log("already added///");
    }
  };
  const handleRemoveFoodToEatItem = (id) => {
    const deleteItems = `${serverIp}:${port}/api/remove-food-to-eat/${id}`;
    axios
      .delete(deleteItems)
      .then((response) => {
        if (response.status) {
          console.log("delete", response.data);
          setUpdateAllowedFood(!updateAllowedFood);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // setupdateFoodToEat(apitems.filter((item) => item.id !== id));
  };
  const handleRemoveFoodToAvoidItem = (id) => {
    const deleteItems = `${serverIp}:${port}/api/remove-food-to-avoid/${id}`;
    axios
      .delete(deleteItems)
      .then((response) => {
        if (response.status) {
          console.log("delete", response.data);
          setUpdateNotAllowedFood(!updateNotAllowedFood);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // setupdateFoodToAvoid(unapitems.filter((item) => item.id !== id));
  };
  const addAllowedFood = async (foodItemId) => {
    console.log({
      diet_plan_id: dietPlanIdObject.diet_plan_id,
      food_item_id: foodItemId,
    });

    try {
      const response = await fetch(apiUrl + "/api/add-food-to-eat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          diet_plan_id: dietPlanIdObject.diet_plan_id,
          food_item_id: foodItemId,
        }),
      });

      const result = await response.json();
      console.log("check", result);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
      throw error;
    }
  };
  const addAvoidFood = async (foodItemId) => {
    console.log({
      diet_plan_id: dietPlanIdObject.diet_plan_id,
      food_item_id: foodItemId,
    });
    try {
      const response = await fetch(apiUrl + "/api/add-food-to-avoid", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          diet_plan_id: dietPlanIdObject.diet_plan_id,
          food_item_id: foodItemId,
        }),
      });

      const result = await response.json();
      console.log("check", result);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
      throw error;
    }
  };

  const handleSubmit = async () => {
    var newAddedFood = false;
    apitems.map((items, index) => {
      if (items.calories_kcal === null) {
        newAddedFood = true;
      }
    });
    unapitems.map((items, index) => {
      if (items.calories_kcal === null) {
        newAddedFood = true;
      }
    });
    if (newAddedFood === true) {
      toast("Wait for nutritionist to add new food macronutrient");
    } else {
      try {
        const response = await fetch(
          apiUrl + "/api/change-diet-plan-request-status",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              diet_plan_request_id: userData.request_id,
              status: "diet-plan",
            }),
          }
        );

        const result = await response.json();
        console.log(result);
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
        throw error;
      }
      navigate("/dietitionDietTracker", {
        state: {
          allowedFood: apitems,
          notAllowedFood: unapitems,
          dietplanObject: dietPlanIdObject,
          userData: userData,
        },
      });
    }
  };
  return (
    <div className="pl-10">
      <div>
        <ToastContainer />
      </div>
      <h1 className="text-3xl mb-5 text-[#31525B] tracking-wide font-semibold pt-8">
        {constants.foodToEat}
      </h1>
      <div
        style={{
          height: "50%",
          display: "flex",
          width: "70%",
          marginBottom: "2%",
          justifyContent: "space-around",
        }}
      >
        <div className="w-[70%]">
          <Autocomplete
            onChange={(event, newValue) => {
              setAllowedFoodItemsNames(newValue);
              console.log("valuee", newValue);
            }}
            options={[...foodNames]}
            sx={{
              width: ["100%"],
              backgroundColor: "constants.offwhite",
              boxShadow: "0 0 5px 5px constants.offwhite",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={allowedfoodItemsNames}
                onChange={(e) => setAllowedFoodItemsNames(e.target.value)}
                id="foodToEat"
                label="Food..."
                type="text"
              />
            )}
          />
        </div>
        <Button
          onClick={() => {
            addAllowedAndNotAllowedFood(allowedfoodItemsNames, "food to eat");
            // document.getElementById("foodToEat").value = "";
          }}
          style={{
            width: 120,
            backgroundColor: constants.primaryColor,
            borderRadius: "10%",
          }}
          variant="contained"
        >
          {constants.add}
        </Button>
      </div>
      {/* <div style={{ height: "50%", display: "flex" }}>
        <div className="w-[100%]">
          <datalist className="px-20" id="suggestions">
            {foodItems.map((items, index) => {
              return <option value={items.food_name}>{items.food_name}</option>;
            })}
          </datalist>
          <input
            placeholder="Food..."
            className="rounded w-[95%] border-2 h-12 border-black"
            autoComplete="on"
            list="suggestions"
            id="foodToEat"
          />
        </div>
        <Button
          onClick={() => {
            addAllowedAndNotAllowedFood(
              document.getElementById("foodToEat").value,
              "food to eat"
            );
            document.getElementById("foodToEat").value = "";
          }}
          style={{ width: 150 }}
          variant="contained"
        >
          Add
        </Button>
      </div> */}
      {apitems.map((items, index) => {
        return items.calories_kcal ? (
          <div
            style={{
              display: !isMatch ? "flex" : "",
            }}
            className="pt-1"
          >
            <ul
              style={{ display: !isMatch ? "flex" : "" }}
              className="w-[85%] sm:justify-around bg-green-100 rounded-2xl items-center p-1"
            >
              <li
                className="text-2xl text-black-700 pl-4"
                style={{ width: "30%" }}
              >
                {items.food_name}
              </li>
              <li className="text-2xl text-[#2583EF]" style={{ width: "25%" }}>
                {constants.calories}:{items.calories_kcal}Kcal
              </li>
              <li className="text-2xl text-[#FDA101]" style={{ width: "20%" }}>
                {constants.proteins}:{items.protein_g}g
              </li>
              <li className="text-2xl text-[#00B569]" style={{ width: "15%" }}>
                {constants.fats}:{items.fats_g}g
              </li>
              {/* <li className="text-2xl text-black-700" style={{ width: "10%" }}>
                <a href="/">{constants.details}</a>
              </li> */}
              {isMatch ? null : (
                <IconButton
                  onClick={() => {
                    handleRemoveFoodToEatItem(items.id);
                  }}
                >
                  <Delete
                    className="p-1 mx-3 rounded-full "
                    fontSize="large"
                    style={{ color: "red" }}
                  />
                </IconButton>
              )}
              {isMatch ? (
                <li>
                  <IconButton
                    onClick={() => {
                      handleRemoveFoodToEatItem(items.id);
                    }}
                  >
                    <Delete
                      className="sm:justify-around rounded-2xl items-center p-2"
                      fontSize="large"
                      style={{ color: "red" }}
                    />
                  </IconButton>
                </li>
              ) : null}
            </ul>
          </div>
        ) : (
          <div style={{ display: !isMatch ? "flex" : "" }} className="pt-1">
            <ul
              style={{ display: !isMatch ? "flex" : "" }}
              className="w-[85%] sm:justify-around bg-red-100 rounded-2xl items-center p-1"
            >
              <li
                className="text-2xl text-black-700 pl-4"
                style={{ width: "30%" }}
              >
                {items.food_name}
              </li>
              <li className="text-2xl text-[#2583EF]" style={{ width: "25%" }}>
                {constants.calories}:{items.calories_kcal}Kcal
              </li>
              <li className="text-2xl text-[#FDA101]" style={{ width: "20%" }}>
                {constants.proteins}:{items.protein_g}g
              </li>
              <li className="text-2xl text-[#00B569] " style={{ width: "15%" }}>
                {constants.fats}:{items.fats_g}g
              </li>
              {/* <li className="text-2xl text-black-700" style={{ width: "10%" }}>
                <a href="/">{constants.details}</a>
              </li> */}
              {isMatch ? (
                <li>
                  <IconButton
                    onClick={() => {
                      handleRemoveFoodToEatItem(items.id);
                    }}
                  >
                    <Delete
                      className="p-1 mx-3 rounded-full bg-violet-200"
                      fontSize="large"
                      style={{ color: "red" }}
                    />
                  </IconButton>
                </li>
              ) : null}
              {isMatch ? null : (
                <IconButton
                  onClick={() => {
                    handleRemoveFoodToEatItem(items.id);
                  }}
                >
                  <Delete
                    className="p-1 mx-3 rounded-full"
                    fontSize="large"
                    style={{ color: "red" }}
                  />
                </IconButton>
              )}
            </ul>
          </div>
        );
      })}
      <h1 className="text-3xl mt-5 mb-5 text-[#31525B] tracking-wide font-semibold">
        {constants.foodToAvoid}
      </h1>

      <div
        className="mt-2"
        style={{
          height: "50%",
          display: "flex",
          justifyContent: "space-around",
          width: "70%",
          marginBottom: "2%",
        }}
      >
        <div className="w-[70%]">
          <Autocomplete
            onChange={(event, newValue) => {
              setUnAllowedFoodItemsNames(newValue);
              console.log("valuee", newValue);
            }}
            options={[...foodNames]}
            sx={{
              width: ["100%"],
              backgroundColor: "constants.offwhite",
              boxShadow: "0 0 5px 5px constants.offwhite",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={unAllowedfoodItemsNames}
                onChange={(e) => setUnAllowedFoodItemsNames(e.target.value)}
                id="foodToEat"
                label="Food..."
                type="text"
              />
            )}
          />
        </div>
        <Button
          onClick={() => {
            addAllowedAndNotAllowedFood(
              unAllowedfoodItemsNames,
              "food to avoid"
            );
            // document.getElementById("foodToAvoid").value = "";
          }}
          style={{
            width: 120,
            backgroundColor: constants.primaryColor,
            borderRadius: "10%",
          }}
          variant="contained"
        >
          {constants.add}
        </Button>
      </div>
      {unapitems.map((items, index) => {
        return items.calories_kcal ? (
          <div style={{ display: !isMatch ? "flex" : "" }} className="pt-1">
            <ul
              style={{ display: !isMatch ? "flex" : "" }}
              className="w-[85%] sm:justify-around bg-green-100 rounded-2xl items-center p-1"
            >
              <li
                className="text-2xl text-black-700 pl-4"
                style={{ width: "30%" }}
              >
                {items.food_name}
              </li>
              <li className="text-2xl text-[#2583EF]" style={{ width: "25%" }}>
                {constants.calories}:{items.calories_kcal}Kcal
              </li>
              <li className="text-2xl text-[#FDA101]" style={{ width: "20%" }}>
                {constants.proteins}:{items.protein_g}g
              </li>
              <li className="text-2xl text-[#00B569]" style={{ width: "15%" }}>
                {constants.fats}:{items.fats_g}g
              </li>
              {/* <li className="text-2xl text-black-700" style={{ width: "10%" }}>
                <a href="/">{constants.details}</a>
              </li> */}
              {isMatch ? (
                <li>
                  <IconButton
                    onClick={() => {
                      handleRemoveFoodToAvoidItem(items.id);
                    }}
                  >
                    <Delete
                      className="p-1 mx-3 rounded-full"
                      fontSize="large"
                      style={{ color: "red" }}
                    />
                  </IconButton>
                </li>
              ) : null}
              {isMatch ? null : (
                <IconButton
                  onClick={() => {
                    handleRemoveFoodToAvoidItem(items.id);
                  }}
                >
                  <Delete
                    className="p-1 mx-3 rounded-full"
                    fontSize="large"
                    style={{ color: "red" }}
                  />
                </IconButton>
              )}
            </ul>
          </div>
        ) : (
          <div style={{ display: !isMatch ? "flex" : "" }} className="pt-1">
            <ul
              style={{ display: !isMatch ? "flex" : "" }}
              className="w-[85%] sm:justify-around bg-red-100 rounded-2xl items-center p-1"
            >
              <li
                className="text-2xl text-black-700 pl-4"
                style={{ width: "30%" }}
              >
                {items.food_name}
              </li>
              <li className="text-2xl text-[#2583EF]" style={{ width: "25%" }}>
                {constants.calories}:{items.calories_kcal}Kcal
              </li>
              <li className="text-2xl text-[#FDA101]" style={{ width: "20%" }}>
                {constants.proteins}:{items.protein_g}g
              </li>
              <li className="text-2xl text-[#00B569]" style={{ width: "15%" }}>
                {constants.fats}:{items.fats_g}g
              </li>
              {/* <li className="text-2xl text-black-700" style={{ width: "10%" }}>
                <a href="/">{constants.details}</a>
              </li> */}
              {isMatch ? null : (
                <IconButton
                  onClick={() => {
                    handleRemoveFoodToAvoidItem(items.id);
                  }}
                >
                  <Delete
                    className="p-1 mx-3 rounded-full "
                    fontSize="large"
                    style={{ color: "red" }}
                  />
                </IconButton>
              )}
              {isMatch ? (
                <li>
                  <IconButton
                    onClick={() => {
                      handleRemoveFoodToAvoidItem(items.id);
                    }}
                  >
                    <Delete
                      className="p-1 mx-3 rounded-full "
                      fontSize="large"
                      style={{ color: "red" }}
                    />
                  </IconButton>
                </li>
              ) : null}
            </ul>
          </div>
        );
      })}
      <div className="mt-[3%] flex items-center justify-end">
        <Button
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
          sx={{
            backgroundColor: constants.orange,
            ":hover": {
              backgroundColor: constants.orange,
            },
          }}
          className=" px-3 py-2 tracking-widest text-sm font-semibold text-white shadow-sm  "
        >
          {constants.continue}
        </Button>
      </div>
    </div>
  );
}
