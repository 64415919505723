import React from "react";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
export default function SearchBar({ handledata }) {
  const [user, setuser] = useState("");
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  console.log(user);
  const handleuser = async (e) => {
    setuser(e.target.value);
    const data = {
      data: user,
    };
    const searchuser = `${serverIp}:${port}/api/search-user`;
    const response = await axios.post(searchuser, data);
    if (response.status) {
      console.log("search", response.data);
      handledata(response.data.users);
    } else {
      alert(response.error.message);
    }
  };

  // const handlebuttonclick=()=>{
  //   handleflag(true);
  // }

  return (
    <div>
      <form class="flex items-center ">
        <label for="simple-search" class="sr-only">
          Search
        </label>
        <div class="relative w-full">
          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
            <SearchIcon className="text-[#D9D9D9]" />
          </span>
          <input
            type="text"
            id="simple-search"
            onChange={handleuser}
            value={user}
            style={{ backgroundColor: "#F5F5F5" }}
            class=" bg-gray-50 outline-0 border border-gray-300 text-gray-900 
        text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full 
        pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
         dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search User..."
            required
          />
        </div>
        {/* <button onClick={handlebuttonclick} type="submit" class="p-2.5 ml-2 text-sm font-medium text-white bg-[#B4EADD] rounded-lg border border-[#B4EADD] hover:bg-[#B4EADD] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
        <span class="sr-only">Search</span>
    </button> */}
      </form>
    </div>
  );
}
