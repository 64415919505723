import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import PostAddSharpIcon from "@material-ui/icons/PostAddSharp";
import AllInboxSharpIcon from "@material-ui/icons/AllInboxSharp";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Link from "@mui/material/Link";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
export const mainListItems = (
  <div>
    <Link href="/nutritionistDashboard" underline="none" color="#3BD684">
      <ListItem button>
        <ListItemIcon>
          <DashboardOutlinedIcon
            className="w-6 h-6 mr-2 mb-1 "
            sx={{ color: "#FFFFFFE6" }}
          />
        </ListItemIcon>
        <ListItemText
          className="text-lg text-[#FFFFFFE6] font-medium"
          primary="Nutritionist Portal"
        />
      </ListItem>
    </Link>
    <Link href="/addFood" underline="none" color="#3BD684">
      <ListItem button>
        <ListItemIcon>
          <FactCheckOutlinedIcon
            className="w-6 h-6 mr-2 mb-1 "
            sx={{ color: "#FFFFFFE6" }}
          />
        </ListItemIcon>
        <ListItemText
          className="text-lg text-[#FFFFFFE6] font-medium"
          primary="Add Food"
        />
      </ListItem>
    </Link>
    <Link href="/allFoods" underline="none" color="#3BD684">
      <ListItem button>
        <ListItemIcon>
          <BookOutlinedIcon
            className="w-6 h-6 mr-2 mb-1 "
            sx={{ color: "#FFFFFFE6" }}
          />
        </ListItemIcon>
        <ListItemText
          className="text-lg text-[#FFFFFFE6] font-medium"
          primary="All Food"
        />
      </ListItem>
    </Link>
  </div>
);
