import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Typography, Button, Box } from "@material-ui/core";
import { useEffect } from "react";
import { useKalorie } from "../../userContext/context";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import constants from "../../constants.json";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import useStyles from "../../assets/css/common.js";
import logo from "../../assets/images/logo.png";

const InfoPage2 = () => {
  const classes = useStyles();
  const { data } = useKalorie();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(data); // Log the updated data after state update
  }, [data]); // This effect runs whenever the 'data' state changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Navigate to the "/dietaryHabits" page
    navigate("/");
  };

  const goback = () => {
    navigate(-1);
  };
  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={classes.outercontainer_info2}
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            variant="h4"
            className={classes.text1}
          >
            <img
              style={{ marginRight: "10px" }}
              src={logo}
              alt="Logo is missing"
            />
            {constants.appTitle}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon size="small" />}
            className={classes.buttonstyle3}
            onClick={goback}
          >
            Back
          </Button>
        </Grid>
        <Grid item container>
          <Grid item className={classes.triangleRight}></Grid>
          <Grid item className={classes.triangleLeft}></Grid>
          <Grid className={classes.outerGrid} container>
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              className={`${classes.mainContainer} ${classes.physicalContainer}`}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit} // This handles the form submission
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography
                    style={{
                      fontSize: "30px",
                      marginBottom: "2rem",
                      fontFamily: constants.fontFamily,
                      color: "#38A389 !important",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Once your payment is verified your diet plan request will be
                    sent to the dietitian and it will take upto 72 hours to
                    finalize your customized diet plan
                  </Typography>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  align="center"
                  className={[
                    classes.buttonstyle_info2,
                    classes.continueStyle1,
                  ]}
                >
                  Back to Home Page
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage2;
