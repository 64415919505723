import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import constants from "../../../../constants.json";
function PieChartComp({ apiData, foodWeight }) {
  const [pieChartDetails, setPieChartDetails] = useState([]);

  useEffect(() => {
    const filteredPicData = {
      Carbs: apiData.carbs_g,
      Fats: apiData.fats_g,
      // Fiber: apiData.fiber_g,
      Proteins: apiData.protein_g,
    };

    const scaledPieChartData = {};
    for (const key in filteredPicData) {
      const scaledValue =
        (filteredPicData[key] * foodWeight) / apiData.weight_g;
      scaledPieChartData[key] = scaledValue.toFixed(2);
    }

    const filterNonZeroAndNaN1 = (nutrientDetails) => {
      const filteredDetails = {};
      for (const key in nutrientDetails) {
        if (
          key !== "food_name" &&
          isFinite(nutrientDetails[key]) &&
          key !== "weight_g" &&
          nutrientDetails[key] !== "0" &&
          nutrientDetails[key] !== "0.00"
        ) {
          filteredDetails[key] = nutrientDetails[key];
        }
      }
      return filteredDetails;
    };

    const filteredDetails1 = filterNonZeroAndNaN1(scaledPieChartData);

    const colors = ["#EFE0BB", "#C7934E", "#38A389", "#FF7171"];
    const data = Object.keys(filteredDetails1).map((key, index) => ({
      title: key,
      value: parseFloat(filteredDetails1[key]),
      color: colors[index % colors.length],
    }));

    setPieChartDetails(data);
  }, [apiData, foodWeight]);
  console.log("pieChartDetails", pieChartDetails);
  return (
    <div>
      <PieChart
        animate
        animationDuration={40}
        animationEasing="ease-in"
        center={[50, 50]}
        data={pieChartDetails}
        lineWidth={100}
        lengthAngle={360}
        paddingAngle={0}
        radius={50}
        startAngle={10}
        viewBoxSize={[100, 100]}
        labelPosition={70}
        labelStyle={{
          fontSize: "6px",
          fontColor: "#FFFFFA",
          fontWeight: "bold",
          fontFamily: constants.fontFamily,
          padding: 20,
        }}
        label={(data) =>
          `${data.dataEntry.title}\n${Math.round(data.dataEntry.percentage)}%`
        }
        reveal
      />
    </div>
  );
}

export default PieChartComp;
