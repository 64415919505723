import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Typography,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Box,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@material-ui/core";
import logo from "../../assets/images/logo.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKalorie } from "../../userContext/context";
import { useLocation } from "react-router-dom";
import constants from "../../constants.json";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import useStyles from "../../assets/css/common.js";
import axios from "axios";
import PropTypes from "prop-types";
var base64 = require("base-64");
const serverIp = process.env.REACT_APP_API_URI;
const port = process.env.REACT_APP_PORT;

const InfoPage2 = () => {
  const classes = useStyles();
  const location = useLocation();
  const screenStatus = location.state;
  console.log("duniya", screenStatus);
  const { data, updateData } = useKalorie();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const shouldShowRequiredWeightField = () => {
    console.log("data", data);
    console.log("data.goal", data.goal_id);
    return data.goal_id !== "Maintain Weight";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateData(name, value);
  };
  const handleMaritalStatusChange = (e) => {
    const { name, value } = e.target;
    if (value === "Married") {
      setShowChildrenField(true);
    } else {
      // Hide the number of children field when "Not Married" is selected
      setShowChildrenField(false);
    }
    updateData(name, value);
  };

  function calculateBMR(gender, weight, height, age) {
    if (gender === "male") {
      return 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
    } else if (gender === "female") {
      return 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
    }
    return null; // handle non-binary or unspecified gender if needed
  }

  function isBMRHealthy(bmr) {
    // Define your criteria for a healthy BMR
    return bmr > 1200 && bmr < 2400; // Example range, adjust as needed
  }
  useEffect(() => {
    console.log(data); // Log the updated data after state update
  }, [data]); // This effect runs whenever the 'data' state changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = []; // Array to store errors

    // Add all validation checks, pushing errors into the array
    if (!data.gender) {
      errors.push("Please select a gender");
    }
    if (!data.dob) {
      errors.push("Please select a date of birth");
    }
    let isError = false; // Flag to track if there's an error
    // Validate required weight
    if (!shouldShowRequiredWeightField && data.req_weight <= 0) {
      errors.push("Please enter a valid required weight greater than 0");
      isError = true;
    }
    // Validate weight
    if (!data.weight || data.weight <= 0) {
      errors.push("Please enter a valid weight greater than 0");
      isError = true;
    }
    switch (data.goal_id) {
      case "Lose Weight":
        if (data.req_weight >= data.weight) {
          errors.push(
            "For weight loss, the required weight should be less than your current weight."
          );
          isError = true;
        }
        break;
      case "Gain Weight":
        if (data.req_weight <= data.weight) {
          errors.push(
            "For weight gain, the required weight should be more than your current weight."
          );
          isError = true;
        }
        break;
      case "Maintain Weight":
        // If the goal is to maintain weight, you might want to remove or disable the required weight field in your UI
        break;
      default:
      // Handle other goals or default case if necessary
    }

    // Validate height
    if (!data.height || data.height <= 0) {
      errors.push("Please enter a valid height greater than 0");
      isError = true;
    }

    // Calculate BMR here (after getting gender, weight, height, age)
    const bmr = calculateBMR(data.gender, data.weight, data.height, age);

    // Check if BMR is less than 15
    if (bmr < 15) {
      errors.push("Your BMR is too low, please consult your doctor.");
      isError = true;
    }

    if (!data.gender) {
      errors.push("Please select a gender");
      isError = true;
    } // Inside the handleSubmit function
    else if (!data.dob) {
      errors.push("Please select a date of birth");
      isError = true;
    } else {
      const currentDate = new Date();
      const selectedDate = new Date(data.dob);
      console.log("Current Date:", currentDate);
      console.log("Selected Date:", selectedDate);

      // Check if selectedDate is greater than the current date
      if (selectedDate >= currentDate) {
        errors.push("Please select a valid date of birth");
        isError = true;
      }
      if (!isBMRHealthy) {
        errors.push(
          "Your BMR is in the unhealthy range, please consult with your doctor."
        );
        return;
      }
    }

    if (!data.weight) {
      errors.push("Please enter your weight");
      isError = true;
    } else if (!/^\d+(\.\d{1,2})?$/.test(data.weight)) {
      errors.push("Please enter a valid weight");
      isError = true;
    }

    if (!data.height) {
      errors.push("Please enter your height");
      isError = true;
    } else if (!/^\d+(\.\d{1,2})?$/.test(data.height)) {
      errors.push("Please enter a valid height");
      isError = true;
    }

    if (!data.marital_status) {
      errors.push("Please select your marital status");
      isError = true;
    } else if (data.marital_status === "Married" && data.kids === "") {
      errors.push("Please enter the number of kids");
      isError = true;
    } else if (data.marital_status === "Married" && data.kids < 0) {
      errors.push("Please enter a valid number of kids");
      isError = true;
    }
    // Check if there are any errors
    if (errors.length > 0) {
      setError(errors[0]); // Set the first error message
      return; // Stop the submission
    }
    if (!isError) {
      setError("");

      if (screenStatus === "Get a Diet Plan") {
        navigate("/physicalHealthInfo");
      } else {
        console.log("hii");
        console.log("hiii", {
          email: data.email,
          password: base64.encode(data.password),
          role_id: 5,
          first_name: data.firstname,
          last_name: data.lastname,
          gender: data.gender,
          dob: data.dob,
          weight: data.weight,
          weight_units: data.weight_units,
          height: data.height,
          height_units: data.height_units,
          marital_status: data.marital_status,
          num_of_kids: data.kids,
          goal: data.goal_id,
        });

        axios
          .post(`${serverIp}:${port}/api/register`, {
            email: data.email,
            password: base64.encode(data.password),
            role_id: 6,
            first_name: data.firstname,
            last_name: data.lastname,
            gender: data.gender,
            dob: data.dob,
            weight: data.weight,
            weight_units: data.weight_units,
            height: data.height,
            height_units: data.height_units,
            marital_status: data.marital_status,
            num_of_kids: data.kids,
            goal: data.goal_id,
            required_weight: data.req_weight,
            required_weight_units: data.req_weight_units,
          })
          .then((response) => {
            if (response.status) {
              var now = new Date().getTime();
              console.log("response from register", response);
              console.log("response.data.user_id", response.data.user_id);
              updateData("userId", response.data.user_id);
              // Handle the successful response here

              const req_data = {
                email: data.email,
                password: base64.encode(data.password),
              };
              axios
                .post(`${serverIp}:${port}/api/login`, req_data)
                .then((response) => {
                  if (response.status) {
                    console.log("response", response);

                    navigate("/userdashboard");
                    localStorage.setItem("setupTime", now);
                    localStorage.setItem("accessToken", response.data.token);
                    localStorage.setItem("user", JSON.stringify(response.data));
                    window.location.reload();
                    // Handle the successful response here
                  }
                })
                .catch((error) => {
                  // Handle any errors that occurred during the request
                  console.log(error);
                  setError("Invalid password or email");
                });
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the request
            console.log(error);
          });
      }
    }
  };

  const weightunits = ["Kilograms", "Pounds"];
  const reqweightunits = ["Kilograms", "Pounds"];
  const heightunits = ["Centimeters", "Meters"];

  function SimpleDialog(props) {
    const { onClose, selectedValue, open, units } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
      onClose(value);
    };
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            minWidth: "15.625rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          },
        }}
      >
        <DialogTitle className={classes.plaintextstyle}>
          --Change Units--
        </DialogTitle>
        <List
          sx={{
            pt: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {units.map((unit) => (
            <ListItem
              disableGutters
              key={unit}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Button
                onClick={() => handleListItemClick(unit)}
                className={classes.plaintextstyle2}
              >
                <ListItemText primary={unit} />
              </Button>
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }

  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };
  const [wopen, setWOpen] = useState(false);
  const [rwopen, setReqWOpen] = useState(false);
  const [hopen, setHOpen] = useState(false);
  const [wSelectedValue, setWSelectedValue] = useState("Kilograms");
  const [rwSelectedValue, setReqWSelectedValue] = useState("Kilograms");
  const [hSelectedValue, setHSelectedValue] = useState("Centimeters");

  const handleWeightClickOpen = () => {
    setWOpen(true);
  };
  const handleReqWeightClickOpen = () => {
    setReqWOpen(true);
  };

  const handleHeightClickOpen = () => {
    setHOpen(true);
  };

  const handleWeightClose = (value) => {
    updateData("weight_units", value);
    setWOpen(false);
    setWSelectedValue(value);
  };
  const handleReqWeightClose = (value) => {
    updateData("req_weight_units", value);
    setReqWOpen(false);
    setReqWSelectedValue(value);
  };
  const handleHeightClose = (value) => {
    updateData("height_units", value);
    setHOpen(false);
    setHSelectedValue(value);
  };

  function calculateAge(dob) {
    if (!dob) return null; // Handle the case where dateOfBirth is not provided

    const currentDate = new Date();
    const birthDate = new Date(dob);

    const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
    const isSameMonthAndDay =
      currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate();
    const age = isSameMonthAndDay ? yearsDiff - 1 : yearsDiff;

    return age;
  }

  const age = calculateAge(data.dob);
  const goback = () => {
    navigate(-1);
  };

  const [showChildrenField, setShowChildrenField] = useState(false); // State to show the number of children field

  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={classes.outercontainer_info2}
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            variant="h4"
            className={classes.text1}
          >
            <img
              style={{ marginRight: "0.6rem" }}
              src={logo}
              alt="Logo is missing"
            />
            {constants.appTitle}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon size="small" />}
            className={classes.buttonstyle3}
            onClick={goback}
          >
            Back
          </Button>
        </Grid>
        <Grid item container>
          <Grid item className={classes.triangleRight}></Grid>
          <Grid item className={classes.triangleLeft}></Grid>
          <Grid className={classes.outerGrid} container>
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              className={`${classes.mainContainer} ${classes.infoContainer}`}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit} // This handles the form submission
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel
                      id="demo-simple-select-standard-label"
                      className={classes.inputLabel}
                    >
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={data.gender}
                      onChange={handleChange}
                      label="Gender"
                      className={classes.input}
                      name="gender"
                      MenuProps={{
                        classes: { icon: classes.dropdownIcon },
                      }}
                    >
                      <MenuItem value="male" className={classes.dropdownItem}>
                        Male
                      </MenuItem>
                      <MenuItem value="female" className={classes.dropdownItem}>
                        Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch", marginTop: "12px" }}>
                    <InputLabel
                      htmlFor="adornment-birthday"
                      className={classes.inputLabel} // Apply custom styles to the label
                      shrink // This will prevent the label from overlapping with placeholder
                    >
                      Date of birth
                    </InputLabel>
                    <Input
                      id="adornment-birthday"
                      type="date"
                      name="dob"
                      value={data.dob}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                      // inputProps={{ style: { paddingTop: "24px" } }} // Adjust padding for input content
                    />
                  </FormControl>
                </div>

                <>
                  <div>
                    <div className={classes.horizontalcontainer}>
                      <FormControl
                        sx={{ m: 1, width: "25ch" }}
                        variant="standard"
                      >
                        <InputLabel
                          htmlFor="standard-adornment-weight"
                          className={[
                            classes.inputLabel,
                            classes.inputLabelsmallerFont,
                          ]}
                        >
                          Weight ({wSelectedValue})
                        </InputLabel>
                        <Input
                          id="standard-adornment-weight"
                          type="number"
                          name="weight"
                          value={data.weight}
                          onChange={handleChange}
                          className={[classes.input, classes.weightInput]}
                        />
                      </FormControl>
                      <Button
                        variant="standard"
                        onClick={handleWeightClickOpen}
                        className={classes.plainbuttonstyle}
                      >
                        Change Units
                      </Button>
                    </div>
                    <SimpleDialog
                      variant="standard"
                      selectedValue={wSelectedValue}
                      open={wopen}
                      onClose={handleWeightClose}
                      units={weightunits}
                    />
                  </div>

                  {shouldShowRequiredWeightField() && (
                    <div>
                      <div className={classes.horizontalcontainer}>
                        <FormControl
                          sx={{ m: 1, width: "15ch" }}
                          variant="standard"
                        >
                          <InputLabel
                            htmlFor="standard-adornment-weight"
                            className={[
                              classes.inputLabel,
                              classes.inputLabelsmallerFont,
                            ]}
                          >
                            {constants.requiredWeight} ({rwSelectedValue})
                          </InputLabel>
                          <Input
                            id="standard-adornment-weight"
                            type="number"
                            name="req_weight"
                            value={data.req_weight}
                            onChange={handleChange}
                            className={[classes.input, classes.weightInput]}
                          />
                        </FormControl>
                        <Button
                          variant="standard"
                          onClick={handleReqWeightClickOpen}
                          className={classes.plainbuttonstyle}
                        >
                          Change Units
                        </Button>
                      </div>
                      <SimpleDialog
                        variant="standard"
                        selectedValue={rwSelectedValue}
                        open={rwopen}
                        onClose={handleReqWeightClose}
                        units={reqweightunits}
                      />
                    </div>
                  )}

                  <div>
                    <div className={classes.horizontalcontainer}>
                      <FormControl
                        sx={{ m: 1, width: "25ch" }}
                        variant="standard"
                      >
                        <InputLabel
                          htmlFor="standard-adornment-height"
                          className={[
                            classes.inputLabel,
                            classes.inputLabelsmallerFont,
                          ]}
                        >
                          Height ({hSelectedValue})
                        </InputLabel>
                        <Input
                          id="standard-adornment-height"
                          type="number"
                          name="height"
                          value={data.height}
                          onChange={handleChange}
                          className={[classes.input, classes.weightInput]}
                        />
                      </FormControl>
                      <Button
                        variant="standard"
                        onClick={handleHeightClickOpen}
                        className={classes.plainbuttonstyle}
                      >
                        Change Units
                      </Button>
                    </div>
                    <SimpleDialog
                      variant="standard"
                      selectedValue={hSelectedValue}
                      open={hopen}
                      onClose={handleHeightClose}
                      units={heightunits}
                    />
                  </div>
                </>
                {/* )}
              {value === "Upload a picture" && (
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    className={classes.input}
                    onChange={handleImageUpload}
                  />
                </div>
              )} */}
                <div>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel
                      id="demo-simple-select-standard-label"
                      className={classes.inputLabel}
                    >
                      Marital Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={data.marital_status}
                      onChange={handleMaritalStatusChange}
                      label="Marital Status"
                      className={classes.input}
                      name="marital_status"
                      MenuProps={{
                        classes: { icon: classes.dropdownIcon },
                      }}
                    >
                      <MenuItem
                        value="Married"
                        className={classes.dropdownItem}
                      >
                        Married
                      </MenuItem>
                      <MenuItem
                        value="Not Married"
                        className={classes.dropdownItem}
                      >
                        Not Married
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {showChildrenField && (
                  <div>
                    <FormControl
                      sx={{ m: 1, width: "25ch" }}
                      variant="standard"
                    >
                      <InputLabel
                        htmlFor="standard-adornment-weight"
                        className={classes.inputLabel}
                      >
                        Nnumber of kids
                      </InputLabel>
                      <Input
                        id="standard-adornment-weight"
                        type="number"
                        name="kids"
                        value={data.kids}
                        onChange={handleChange}
                        className={classes.input}
                      />
                    </FormControl>
                  </div>
                )}
                {error && (
                  <div style={{ width: "18rem" }}>
                    <Typography className={classes.errorText}>
                      {error}
                    </Typography>
                  </div>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  align="center"
                  disabled={!isBMRHealthy}
                  className={[classes.buttonstyle_info2, classes.continueStyle]}
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage2;
