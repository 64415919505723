import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import Link from "@mui/material/Link";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
export const mainListItems = (
  <div>
    <Link href="/bloggerdashboard" underline="none" color="#3BD684">
      <ListItem button>
        <ListItemIcon>
          <DashboardOutlinedIcon
            className="w-6 h-6 mr-2 mb-1 "
            sx={{ color: "#38A389" }}
          />
        </ListItemIcon>
        <ListItemText
          className="text-lg text-[#29715f] font-medium"
          primary="Blogger Portal"
        />
      </ListItem>
    </Link>
    <Link href="/foodfacts" underline="none" color="#3BD684">
      <ListItem button>
        <ListItemIcon>
          <FactCheckOutlinedIcon
            className="w-6 h-6 mr-2 mb-1 "
            sx={{ color: "#38A389" }}
          />
        </ListItemIcon>
        <ListItemText
          className="text-lg text-[#29715f] font-medium"
          primary="Food Facts"
        />
      </ListItem>
    </Link>
    <Link href="/foodblogs" underline="none" color="#3BD684">
      <ListItem button>
        <ListItemIcon>
          <BookOutlinedIcon
            className="w-6 h-6 mr-2 mb-1 "
            sx={{ color: "#38A389" }}
          />
        </ListItemIcon>
        <ListItemText
          className="text-lg text-[#29715f] font-medium"
          primary="Food Blogs"
        />
      </ListItem>
    </Link>
  </div>
);
