import { Paper, Grid, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import profile from "../assets/images/userProfile.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import constants from "../constants.json";
export default function DisplayUserInfo() {
  const location = useLocation();
  const userData = location.state;
  const serverIp = process.env.REACT_APP_SERVERIP;
  const port = process.env.REACT_APP_PORT;
  const [data, setData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const getitems = `${serverIp}:${port}/api/client-diet-plan-survey/${userData.user_id}`;
    axios
      .get(getitems)
      .then((response) => {
        if (response.status) {
          console.log(response.data.survey);
          setData(response.data.survey);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <Paper
        elevation={1}
        sx={{
          height: "95vh",
          width: "80%",
          maxWidth: "100%", // Ensure it doesn't exceed the viewport width on mobile
          padding: "1.875em",
          marginTop: "2.875em",
          marginLeft: "auto", // Center the Paper horizontally
          marginRight: "auto",
          backgroundColor: constants.offwhite,
          overflowY: "auto", // Allow vertical scrolling
        }}
      >
        <Button
          onClick={() => navigate(-1)} // Navigate to the previous page
          variant="outlined"
          sx={{
            backgroundColor: constants.primaryColor,
            color: "#fff", // Ensures text is visible
            mb: 2, // Margin-bottom for spacing
          }}
        >
          Back
        </Button>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={9}>
            <div className="flex items-center">
              <div className="mr-2">
                <img
                  className="w-28 h-24 rounded-full"
                  src={profile}
                  alt="Profile"
                />
              </div>
              <div>
                <span className="ml-4 text-xl flex text-[#02306A] font-semibold tracking-wider">
                  {data.first_name + " " + data.last_name}
                </span>
                <p className="ml-4 text-[#02306A]">
                  {constants.dietPlan}: {data.duration}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              onClick={() => {
                navigate("/createdietplan", {
                  state: userData,
                });
              }}
              sx={{
                backgroundColor: constants.primaryColor,
                color: "#fff", // Ensure the text is visible
                width: "100%", // Full width on mobile
              }}
              variant="contained"
            >
              {constants.createDietPlan}
            </Button>
          </Grid>

          {/* Responsive Details Grid */}
          {[
            { label: constants.gender, value: data.gender },
            { label: constants.age, value: `${data.age} years` },
            {
              label: constants.weight,
              value: `${data.weight} ${data.weight_units}`,
            },
            {
              label: constants.height,
              value: `${data.height} ${data.height_units}`,
            },
            { label: constants.maritalStatus, value: data.marital_status },
            { label: constants.numberOfKids, value: data.num_of_kids || "0" },
            { label: constants.kindOfWork, value: data.kind_of_work },
            {
              label: constants.levelOfPhysicalActivity,
              value: data.physical_activity_level,
            },
            { label: constants.kindOfAllergies, value: data.kind_of_allergies },
            { label: constants.medication, value: data.medications },
            { label: constants.goal, value: data.goal },
            {
              label: constants.familyHistoryOfDiabetes,
              value: data.family_history_of_diabetes === 1 ? "Yes" : "No",
            },
            {
              label: constants.familyhistoryOfHeartAttack,
              value: data.family_history_of_heart_attack === 1 ? "Yes" : "No",
            },
            { label: constants.gymOrWalkRoutine, value: data.gym_walk_routine },
            { label: constants.dineOuthabits, value: data.dine_out_habits },
            {
              label: constants.frequencyOfVegIntake,
              value: data.frequency_of_vegetables_intake,
            },
            {
              label: constants.frequencyOfFruitsIntake,
              value: data.frequency_of_fruit_intake,
            },
            { label: constants.waterIntake, value: data.water_intake },
            { label: constants.flourType, value: data.flour_type },
            { label: constants.riceType, value: data.rice_type },
          ].map((item, index) => (
            <Grid className="flex" item xs={12} sm={6} key={index}>
              <p>{item.label}:</p>
              <p className="ml-2">{item.value}</p>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  );
}
