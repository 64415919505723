import React, { useState, useEffect } from "react";
import { Button, Box, Modal, Grid } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import ReportIcon from "@mui/icons-material/Report";
import profile from "../assets/images/profile.jpg";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/reusableComponents/searchBar";
import { ITEMS_PER_PAGE } from "../app/constants";
import Pagination from "../components/reusableComponents/Pagination";
import constants from "../constants.json";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #A87BD4",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};
export default function DisplayUsers() {
  const [open, setOpen] = useState(false);
  const [delopen, setdelopen] = useState(false);
  const [userid, setuserid] = useState("");
  const [userdata, setuserdata] = useState([]);
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  const handledata = async (newuser) => {
    setuserdata(newuser);
    setData(
      newuser.slice().sort((a, b) => {
        return a.first_name.localeCompare(b.first_name);
      })
    );
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handledelopen = (id) => {
    setuserid(id);
    setdelopen(true);
  };
  const handledelclose = () => {
    setdelopen(false);
  };
  const [name, setname] = useState("");
  const [role, setrole] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [degree, setdegree] = useState("");
  const [address, setaddress] = useState("");
  const [gender, setgender] = useState("");
  const navigate = useNavigate();
  const [page, setpage] = useState(1);
  const [sort, setsort] = useState({});
  const [sortOrder, setSortOrder] = useState("asc");
  const [data, setData] = useState([]);

  const apiUrl = `${serverIp}:${port}/api/users`;
  const handlePage = (page) => {
    setpage(page);
  };
  const handleOpen = (user) => {
    console.log("im user", user);
    setname(user.first_name);
    setrole(user.role);
    setemail(user.email);
    setcontact(user.contact);
    setdegree(user.degree);
    setaddress(user.address);
    setgender(user.gender);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (userid) {
      // Ensure that userid is not empty before making the effect
      const newUserData = data.filter((user) => user.id !== userid);
      setData(newUserData);
    }
  }, [userid]);
  const handleDelete = async (id) => {
    try {
      const delUrl = `${serverIp}:${port}/api/users/${id}`;
      const response = await axios.delete(delUrl);

      if (response.status) {
        console.log(response);
        handledelclose();
        console.log("DELETE request successful:", response.data.message);

        setuserid(id); // Set the userid for the useEffect to trigger

        toast(response.data.message, {
          icon: (
            <svg
              color="green"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          ),
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            color: "#03045E",
            fontSize: "15px",
          },
        });
      }
    } catch (error) {
      console.error("DELETE request error:", error);
    }
  };

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        console.log("response from display all users", response);
        console.log(response.data.nutritionists);
        setData(
          response.data.nutritionists.slice().sort((a, b) => {
            return a.first_name.localeCompare(b.first_name);
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    setpage(1);
  }, []);
  const itemsPerPage = ITEMS_PER_PAGE;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  console.log("dataaaa", data);
  const dataToDisplay = data.slice(startIndex, endIndex);
  console.log("dataToDisplay", dataToDisplay);

  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    const sortedData = data.slice().sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.first_name.localeCompare(b.first_name);
      } else {
        return b.first_name.localeCompare(a.first_name);
      }
    });
    setData(sortedData);
  };
  const buttonStylered = {
    backgroundColor: "#06647D",
    fontFamily: constants.fontFamily,
    color: "white",
    textTransform: "none",
    width: "115px",
    height: "40px",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px 0px",
    "&:hover": {
      backgroundColor: "#B4EADD", // Ensures hover doesn't change the color
    },
  };
  return (
    <div className="ml-10 mr-10 mt-10">
      <div style={{ display: "flex", width: "100%" }}>
        <div className="mb-3 w-[90%] pr-4">
          <SearchBar handledata={handledata} />
        </div>
        <div className="flex justify-end w-[10%]">
          <Button
            variant="contained"
            style={buttonStylered}
            onClick={() => navigate("/userRegistration")}
          >
            Add User
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="bg-[#31525B] cursor-pointer text-white uppercase text-sm leading-normal">
              <th className="py-3 px-20 text-left" onClick={handleSort}>
                User{" "}
                {sortOrder === "asc" ? (
                  <ArrowUpwardIcon className="w-2 h-3 mb-1 inline" />
                ) : (
                  <ArrowDownwardIcon className="w-2 h-3 mb-1 inline" />
                )}
              </th>
              <th className="py-3 px-6 text-center">Email</th>
              <th className="py-3 px-6 text-center">Contact</th>
              <th className="py-3 px-6 text-center">Type</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {dataToDisplay.map((user) => (
              <tr
                key={user.id}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <td className="py-3 px-6 ">
                  <div className="flex items-center">
                    <div className="mr-2">
                      <img
                        className="w-14 h-14 rounded-full"
                        src={user.profile_pic_link || profile}
                        alt="none"
                      />
                    </div>
                    <span>{user.first_name}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-center text-sm">
                  {user.email || "@gmail.com"}
                </td>
                <td className="py-3 px-6 text-center">
                  <span className=" py-1 px-3 text-sm">
                    {user.contact_number || "0337766889"}
                  </span>
                </td>
                <td className="py-3 px-6 text-center">
                  {/* {user.id===editableUserId? ( */}
                  {/* <select onChange={e=>handleUpdate(e,user)} value={user.type}>
                   <option value='Nutritionist'>Nutritionist</option>
                   <option value='Content-Creator'>Content-Creator</option>
                   <option value='Blogger'>Blogger</option>
                   <option value='Customer'>Customer</option>
                 </select> */}
                  {/* ):( */}
                  <span
                    className={`text-pink-950 py-1 px-2 rounded-full text-xs sm:text-sm ${
                      user.role_id === 1
                        ? "bg-purple-100" // Super Admin
                        : user.role_id === 2
                        ? "bg-green-100" // Nutritionist
                        : user.role_id === 3
                        ? "bg-lime-100" // Dietitian
                        : user.role_id === 4
                        ? "bg-orange-100" // Blogger
                        : ""
                    }`}
                  >
                    {user.role_id === 1
                      ? "Super Admin"
                      : user.role_id === 2
                      ? "Nutritionist"
                      : user.role_id === 3
                      ? "Dietitian"
                      : user.role_id === 4
                      ? "Blogger"
                      : user.role_id === 5
                      ? "Client"
                      : user.role_id === 6
                      ? "User"
                      : "Unknown"}
                  </span>

                  {/* )} */}
                </td>
                <td className="py-3 px-6 text-center">
                  <div className="flex item-center justify-center">
                    <div className="flex justify-center items-center w-8 h-8  rounded-full bg-[#F8F3E9] mr-2 transform cursor-pointer hover:text-purple-500 hover:scale-120">
                      <EditIcon
                        className="w-10 h-10"
                        onClick={() => navigate(`/userRegistration/${user.id}`)}
                        sx={{ color: "#38A389" }}
                      />
                    </div>
                    <div className="flex justify-center items-center w-8 h-8  rounded-full bg-[#F8F3E9] mr-2 transform cursor-pointer hover:text-purple-500 hover:scale-120">
                      <VisibilityIcon
                        onClick={() => handleOpen(user)}
                        className="w-10 h-10"
                        sx={{ color: "#A87BD4" }}
                      />
                    </div>
                    <div className="flex justify-center items-center w-8 h-8  rounded-full mr-2 transform cursor-pointer bg-[#F8F3E9] hover:text-purple-500 hover:scale-120">
                      <ClearIcon
                        onClick={() => handledelopen(user.id)}
                        className="w-10 h-10"
                        sx={{ color: "red" }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {userid ? (
          <Modal
            open={delopen}
            onClose={handledelclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>
                <IconButton
                  onClick={() => {
                    handledelclose();
                  }}
                  color="primary"
                  aria-label="Delete"
                >
                  <CloseOutlinedIcon style={{ color: "red" }} />
                </IconButton>
              </div>
              <p className="text-[#FF7171] text-xl">
                <span className="">
                  <ReportIcon className="w-12 h-12 mb-2" />
                </span>
                Are you sure? you want to delete it.
              </p>
              <div className="flex justify-end mt-2">
                <button
                  onClick={() => handleDelete(userid)}
                  className="py-1 rounded-2xl px-3 bg-[#06647D] text-white font-bold tracking-widest"
                >
                  Delete
                </button>
              </div>
            </Box>
          </Modal>
        ) : (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <ClearIcon
                onClick={handleCloseModal}
                className="w-10 h-10 absolute top-3 right-3 cursor-pointer"
                sx={{ color: "red" }}
              />
              <Grid container>
                <Grid item xs={5}>
                  <img
                    sx={{ width: "20%", height: "20%" }}
                    src={profile}
                    alt="None"
                  ></img>
                </Grid>
                <Grid item xs={7}>
                  <Grid item>
                    <p
                      style={{
                        color: "#02306A",
                        fontFamily: constants.fontFamily,
                        fontSize: "30px",
                      }}
                    >
                      {name}
                      {/* <span className="bg-rose-100 text-pink-950 py-1 px-3 ml-5 rounded-full text-sm">
                        {role}
                      </span> */}
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      {email}
                      <span className="ml-5">{contact}</span>
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      {degree}
                      <span className="ml-5">{address}</span>
                    </p>
                  </Grid>
                  <Grid item>
                    <p>{gender}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        )}
        <Pagination
          page={page}
          setpage={setpage}
          handlePage={handlePage}
          totalITems={data.length}
        />
      </div>
      <ToastContainer />
    </div>
  );
}
