import React, { useState } from "react";
import SigninButton from "../commonScreens/signinButton";
import MuiTextField from "../components/reusableComponents/customTextfield";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import loginImg from "../assets/images/loginImg.png";
import logo1 from "../assets/images/logo1.png";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, useTheme } from "@mui/material";
import "../commonScreens/commonScreensCss/login.css";
import axios from "axios";
import { useKalorie } from "../userContext/context";
import CustomTypography from "../components/reusableComponents/muiTypography";
import constants from "../constants.json";
import { TextField, Typography } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? " #D9D9D9" : "#D9D9D9",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light"
        ? "constants.blueTextColor"
        : "constants.blueTextColor",
    fontSize: 16,
    padding: 8,
    width: "45vh",
    height: "2rem",

    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function validateEmail(value) {
  // Basic email validation regex
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(value);
}

function validatePassword(value) {
  // Password validation: At least 8 characters
  return value.length >= 8;
}

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: "#FFA62E",
    width: "70wh",
    height: "80vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // border: "1px solid black",
  },
  containerMobile: {
    // backgroundColor: "#FFA62E",
    width: "70wh",
    height: "80vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "2rem",
    // border: "1px solid black",
  },
  grid1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFA62E",
  },
  title: {
    fontFamily: constants.fontFamily,
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#31525B",
  },
  loginImg: {
    height: "35ch",
    width: "70%",
    margin: "2%",
  },
  logo: {
    height: "10ch",
    width: "10ch",
    margin: "2%",
  },
  welcome: {
    fontFamily: constants.fontFamily,
    fontSize: "2rem",
    // fontWeight: "bold",
    color: "black",
  },
  text1: {
    fontFamily: constants.fontFamily,
    fontSize: "1.4rem",
    // fontWeight: "bold",
    color: "#31525B",
  },
  forgotPassword: {
    color: "#FD0101",
    fontFamily: constants.fontFamily,
    cursor: "pointer",
  },
  mainContainer: {
    padding: "5rem 5rem",
    backgroundColor: "#F5FEFD",
    height: "100vh",
    // border: "9px solid blue",
  },
  inputSignin: {
    color: "#31525B",
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
  },
}));

var base64 = require("base-64");
function LoginScreen() {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [email, setemail] = useState("");
  const [role, setrole] = useState("");
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const apiUrl = `${serverIp}:${port}/api/login`;
  const { data, updateData } = useKalorie();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      handleLogin();
    }
  };

  function fetchDietDetails(user_id) {
    const apiUrl = `${serverIp}:${port}/api/diet-plan-schedule-client`;
    const req_data = {
      user_id: user_id,
      week: data.week,
      day: data.day,
    };

    axios
      .post(apiUrl, req_data)
      .then((response) => {
        console.log("response from dietdetails", response.data);
        if (response.status === 200) {
          navigate("/dietPlan");
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.log("error from dietdetials", error);
        console.log("waittttt", error.response.status);

        if (error.response.status) {
          console.log("waittttt");
          navigate("/wait");
        }
      });
  }

  const handleChange = (event) => {
    // Handle changes in the email input field
    setemail(event.target.value);
    setError(""); // Clear any previous error message
  };
  const handleLogin = () => {
    // const isEmailValid = validateEmail(email);

    // if (!isEmailValid) {
    //   setError("Invalid email format");
    //   return;
    // }
    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);

    if (!isEmailValid && !isPasswordValid) {
      setError("Invalid email and password");
      return;
    }

    if (!isEmailValid) {
      setError("Invalid email format");
      return;
    }

    if (!error) {
      var now = new Date().getTime();
      const req_data = {
        email: email,
        password: base64.encode(password),
      };
      console.log("passworddata", req_data);
      axios
        .post(apiUrl, req_data)
        .then((response) => {
          if (response.status) {
            console.log("response", response);
            if (response.data.role === "Super Admin") {
              navigate("/adminDashboard");
            } else if (response.data.role === "Nutritionist") {
              navigate("/nutritionistDashboard");
            } else if (response.data.role === "Dietitian") {
              navigate("/dietitiondashboard");
            } else if (response.data.role === "Client") {
              updateData("userId", response.data.user_id);
              fetchDietDetails(response.data.user_id);
              navigate("/dietPlan");
            } else if (response.data.role === "User") {
              updateData("userId", response.data.user_id);
              fetchDietDetails(response.data.user_id);
              navigate("/userdashboard");
            } else if (response.data.role === "Blogger") {
              navigate("/bloggerdashboard");
            }
            localStorage.setItem("setupTime", now);
            localStorage.setItem("accessToken", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data));
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          setError("Invalid password or email");
        });
    }
  };

  return (
    <>
      {isMobile ? (
        <Container className={classes.mainContainer} maxWidth={false}>
          <Grid container className={classes.container}>
            {/* <Grid
              item
              sm={12}
              md={5}
              lg={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFA62E",
                height: "40%",
                boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                padding: "2rem",
                borderRadius: "8px",
              }}
            >
              <img src={logo1} className={classes.logo} alt="Logo" />
              <a href="/" style={{ textDecoration: "none" }}>
                <Typography className={classes.title}>
                  {constants.appTitle}
                </Typography>
              </a>
              <Typography className={classes.welcome}>
                {constants.welcomeBack}
              </Typography>
              <Typography className={classes.text1}>
                {constants.niceToSeeYou}
              </Typography>
            </Grid> */}

            <Grid
              item
              sm={12}
              md={5}
              lg={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 1)",
                height: "137%",
                boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                marginTop: "-9rem",
                paddingTop: "6rem",
              }}
            >
              <img src={logo1} className={classes.logo} alt="Logo" />
              <a href="/" style={{ textDecoration: "none" }}>
                <Typography className={classes.title}>
                  {constants.appTitle}
                </Typography>
              </a>
              <Typography className={classes.welcome}>
                {constants.welcomeBack}
              </Typography>
              <Typography className={classes.text1}>
                {constants.niceToSeeYou}
              </Typography>
              <Box
                component="span"
                sx={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Grid item sx={{}}>
                  <CustomTypography
                    text={"Sign In"}
                    fontSize={30}
                    color={"#31525B"}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Center horizontally
                    justifyContent: "center", // Center vertically
                    marginLeft: "1rem",
                  }}
                >
                  <FormControl variant="standard" style={{ width: "80%" }}>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      style={{
                        color: "#31525B",
                        fontSize: "24px",
                        fontWeight: "bold",
                        fontFamily: constants.fontFamily,
                      }}
                    >
                      Email Id
                    </InputLabel>
                    <BootstrapInput
                      id="bootstrap-input"
                      value={email}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      type="text"
                    />
                    {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <MuiTextField
                    style={{ marginLeft: "1rem" }}
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(value, isValid) => setPassword(value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") handleLogin();
                    }}
                  />
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  <Typography
                    className={classes.forgotPassword}
                    style={{ marginTop: "1rem" }}
                  >
                    Forgot Password?
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "1rem" }}>
                  <SigninButton
                    buttonVariant="contained"
                    background="#31525B"
                    buttonText="Sign In"
                    onClick={handleLogin}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          <Container className={classes.mainContainer} maxWidth={false}>
            <Grid container className={classes.container}>
              <Grid
                item
                sm={12}
                md={5}
                lg={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFA62E",
                  height: "100%",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                }}
              >
                <img src={logo1} className={classes.logo} />
                <a href="/" style={{ textDecoration: "none" }}>
                  <Typography className={classes.title}>
                    {constants.appTitle}
                  </Typography>
                </a>
                <Typography className={classes.welcome}>
                  {constants.welcomeBack}
                </Typography>
                <Typography className={classes.text1}>
                  {constants.niceToSeeYou}
                </Typography>
              </Grid>

              <Grid
                item
                sm={12}
                md={5}
                lg={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  height: "100%",
                  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    flex: "1",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid item sx={{ paddingTop: 10 }}>
                    <CustomTypography
                      text={"Sign In"}
                      fontSize={30}
                      color={"#31525B"}
                    />
                  </Grid>
                  <Grid item sx={{ margin: "1rem" }}>
                    <FormControl variant="standard">
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        style={{
                          color: "#31525B",
                          fontSize: "24px",
                          fontWeight: "bold",
                          fontFamily: constants.fontFamily,
                          marginLeft: "1rem",
                        }}
                      >
                        Email Id
                      </InputLabel>
                      <BootstrapInput
                        style={{ marginLeft: "1rem" }}
                        id="bootstrap-input"
                        value={email}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        type="text"
                      />
                      {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ marginLeft: "1rem" }}>
                    <MuiTextField
                      style={{ marginLeft: "1rem" }}
                      label="Password"
                      type="password"
                      value={password}
                      onChange={(value, isValid) => setPassword(value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") handleLogin();
                      }}
                    />
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <Typography className={classes.forgotPassword}>
                      Forgot Password?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SigninButton
                      buttonVariant="contained"
                      background="#31525B"
                      buttonText="Sign In"
                      onClick={handleLogin}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}
export default LoginScreen;
