import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import constants from "../../constants.json";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
    fontFamily: constants.fontFamily,
    display: "flex",
    flexDirection: "row", // Display label and input in the same row
    alignItems: "center", // Align them vertically in the center
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(22),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "" : "",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#FFD171" : "#FFD171",
    fontSize: 16,
    marginLeft: "0.2rem", // Adjusted margin to separate label and input
    width: "40vh",
    height: "2rem",
    padding: 10,
    fontFamily: constants.fontFamily,

    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `rgba(56, 163, 137, 0.5) 0 0 0 0.1rem`,
      borderColor: "#38A389",
    },
  },
}));

function MuiTextField(props) {
  const [value, setValue] = useState(props.value || "");
  const [error, setError] = useState(false);

  function validateEmail(value) {
    // Basic email validation regex
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  }

  function validatePassword(value) {
    // Password validation: At least 8 characters
    return value.length >= 8;
  }

  function validateText(value) {
    // Name validation: Alphanumeric characters and spaces
    const nameRegex = /^[a-zA-Z0-9\s]+$/;
    return nameRegex.test(value);
  }
  function validatePhoneNumber(value) {
    // Phone number validation: Only digits, 10 characters
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(value);
  }

  function validateNumber(value) {
    // Number validation: Accepts both integers and floats
    const numberRegex = /^-?\d*\.?\d*$/;
    return numberRegex.test(value);
  }

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    // Validation based on the type prop
    switch (props.type) {
      case "email":
        setError(!validateEmail(newValue));
        break;
      case "password":
        setError(!validatePassword(newValue));
        break;
      case "text":
        setError(!validateText(newValue));
        break;
      case "phone":
        setError(!validatePhoneNumber(newValue));
        break;
      case "number":
        setError(!validateNumber(newValue));
        break;
      default:
        setError(false);
    }
    // Check for empty value
    if (newValue.trim() === "") {
      setError(true);
    }

    if (props.onChange) {
      props.onChange(newValue, !error && newValue.trim() !== ""); // Pass isValid as true only if both validations pass
    }
  };

  return (
    <FormControl variant="standard">
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        style={{
          fontFamily: constants.fontFamily,
          color: props.color || "black",
          fontSize: "24px",
          fontWeight: "bold",
          marginTop: "1rem",
        }}
      >
        {props.label}
      </InputLabel>
      <BootstrapInput
        id="bootstrap-input"
        name={props.name || ""}
        placeholder={props.placeholder || ""}
        value={value}
        onChange={handleChange}
        type={props.type === "password" ? "password" : "text"}
      />
      {error && (
        <p style={{ color: "red" }}>
          Value for {props.label} is invalid or empty. Please fill the field and
          try again
        </p>
      )}
    </FormControl>
  );
}

export default MuiTextField;
