import React from "react";
import teamwork from "../../../assets/images/Teamwork.png";
import healthcare from "../../../assets/images/Healthcare.png";
import food from "../../../assets/images/Food.png";
import constants from "../../../constants.json";
import Header from "../../../components/kalorieKompassComponents/starterpage/headerPage";
import Footer from "../../../components/kalorieKompassComponents/starterpage/footer";

const AboutUsPage = () => {
  return (
    <>
      <Header />
      <h1 className="text-3xl md:text-4xl font-bold text-center p-3">
        {constants.about}
      </h1>
      <div className="hero bg-base-200 p-0">
        <div className="hero-content flex-col lg:flex-row-reverse p-4 lg:p-8">
          <img
            src={teamwork}
            className="w-full max-w-md mx-auto"
            alt="Teamwork"
          />
          <div className="text-center lg:text-left lg:pl-8">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">
              {constants.ourVision}
            </h1>
            <p className="py-2 text-base lg:text-lg">{constants.vision}</p>
          </div>
        </div>
      </div>
      <div className="hero bg-base-200 p-0">
        <div className="hero-content flex-col lg:flex-row p-4 lg:p-8">
          <img
            src={healthcare}
            className="w-full max-w-md mx-auto"
            alt="Healthcare"
          />
          <div className="text-center lg:text-left lg:pr-8">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">
              {constants.ourApproach}
            </h1>
            <p className="py-2 text-base lg:text-lg">{constants.approach}</p>
          </div>
        </div>
      </div>
      <div className="hero bg-base-200 p-0">
        <div className="hero-content flex-col lg:flex-row-reverse p-4 lg:p-8">
          <img src={food} className="w-full max-w-md mx-auto" alt="Food" />
          <div className="text-center lg:text-left lg:pl-8">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">
              {constants.ourProcess}
            </h1>
            <p className="py-2 text-base lg:text-lg">{constants.process}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUsPage;
