import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import "../adminScreens/userRegistration.css";
import { ToastContainer, toast } from "react-toastify";
import {
  OutlinedInput,
  TextField,
  Button,
  TextareaAutosize,
  Grid,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import constants from "../constants.json";
export default function CreatePlan() {
  const dietitionUserObject = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;
  console.log("check", dietitionUserObject, userData);
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [idealBodyWeight, setIdealBodyWeight] = useState(0);
  const [totalCaloryIntakePerDay, setTotalCaloryIntakePerDay] = useState(0);
  const [carbsPercent, setCarbsPercent] = useState(0);
  const [proteinsPercent, setProteinsPercent] = useState(0);
  const [fatPercent, setFatPercent] = useState(0);
  const [teePerDay, setTeePerDay] = useState(0);
  const [recommendation, setRecommenadation] = useState("");
  const [noOfWeeks, setNoOfWeeks] = useState("");
  const [title, setTitle] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const createPreDietPlanInfo = async () => {
    if (
      document.getElementById("title").value == "" ||
      document.getElementById("idealbodyweight").value == 0 ||
      document.getElementById("totalcalory").value == 0 ||
      document.getElementById("carbs").value == 0 ||
      document.getElementById("fats").value == 0 ||
      document.getElementById("proteins").value == 0 ||
      document.getElementById("TEE").value == 0 ||
      document.getElementById("recommend").value == "" ||
      document.getElementById("NoOfWeeks").value == ""
    ) {
      toast("Invalid Input");
    } else {
      if (
        parseInt(document.getElementById("carbs").value) +
          parseInt(document.getElementById("fats").value) +
          parseInt(document.getElementById("proteins").value) !==
        100
      ) {
        toast(
          "Proteins, carbs and fats combine percentages should be equal to 100%"
        );
      } else {
        try {
          const response = await fetch(
            apiUrl + "/api/change-diet-plan-request-status",
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                diet_plan_request_id: userData.request_id,
                status: "food-to-eat",
              }),
            }
          );

          const result = await response.json();
          console.log(result);
        } catch (error) {
          console.error("An error occurred:", error);
          // Handle error scenario
          throw error;
        }
        try {
          const response = await fetch(apiUrl + "/api/predietplaninfo", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              client_id: userData.user_id,
              dietitian_id: dietitionUserObject.user_id,
              diet_plan_request_id: userData.request_id,
              title: title,
              recommended_calories_intake: totalCaloryIntakePerDay,
              ideal_weight: idealBodyWeight,
              tee_bmr: teePerDay,
              carbs_percentage: carbsPercent,
              proteins_percentage: proteinsPercent,
              fats_percentage: fatPercent,
              duration: noOfWeeks,
              recommendations: recommendation,
              is_public: 0,
            }),
          });

          const result = await response.json();
          console.log(result);
          navigate("/avoidfooditems", {
            state: { result: result, userData: userData },
          });
        } catch (error) {
          console.error("An error occurred:", error);
          // Handle error scenario
          throw error;
        }
      }
    }
  };
  return (
    <div>
      <div>
        <ToastContainer />
      </div>
      <div className="flex justify-center items-center ">
        <form
          noValidate
          className="bg-white  py-1 image rounded-sm mt-1 mb-1 w-5/6 border-[E3CFF6]"
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h1 className="text-2xl py-3 font-semibold leading-7 text-[#02306A]">
                {constants.createDietPlan}
              </h1>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10.25}>
              <label
                htmlFor="title"
                className="block text-sm font-medium leading-6 text-blue-950"
              >
                {constants.titleOfPlan}
              </label>
              <TextField
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                size="small"
                id="title"
                type="text"
                className="bg-gray-50 border-black border-2 rounded-lg text-gray-900 text-sm block w-full p-2.5 "
              />
              {errors.title && (
                <p className="text-red-500 text-xs">{errors.title.message}</p>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              style={{ marginRight: "2%" }}
            >
              <label
                htmlFor="idealbodyweight"
                className="block text-sm font-medium leading-6 text-blue-950 mt-2"
              >
                {constants.idealBodyWeight}
              </label>
              <TextField
                value={idealBodyWeight}
                onChange={(e) => {
                  setIdealBodyWeight(e.target.value);
                }}
                size="small"
                type="number"
                id="idealbodyweight"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              />
              {errors.idealbodyweight && (
                <p className="text-red-500 text-xs">
                  {errors.idealbodyweight.message}
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <label
                htmlFor="totalcalory"
                className="block text-sm font-medium leading-6 text-blue-950 mt-2"
              >
                {constants.totalCaloriesIntakePerDay}
              </label>
              <TextField
                value={totalCaloryIntakePerDay}
                onChange={(e) => {
                  setTotalCaloryIntakePerDay(e.target.value);
                }}
                size="small"
                type="number"
                sx={{ height: "42px", borderRadius: "20px" }}
                id="totalcalory"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              />
              {errors.totalcalory && (
                <p className="text-red-500 text-xs">
                  {errors.totalcalory.message}
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="mt-2 mb-2">
                <p className="text-xl text-[#02306A] font-semibold">
                  {constants.adequateDailyNutrientsIntake}
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3.25}
              lg={3.25}
              style={{ marginRight: "2%" }}
            >
              <label
                htmlFor="carbs"
                className="block text-sm font-medium leading-6 text-blue-950"
              >
                {constants.carbsPercentage}
              </label>
              <TextField
                value={carbsPercent}
                onChange={(e) => {
                  setCarbsPercent(e.target.value);
                }}
                type="number"
                id="carbs"
                size="small"
                placeholder="0 g"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              ></TextField>
              <p className="py-1">
                {carbsPercent
                  ? (
                      ((parseFloat(carbsPercent) / 100) *
                        totalCaloryIntakePerDay) /
                      4
                    ).toFixed(2)
                  : 0}{" "}
                {constants.gmCarbs}
              </p>
              {errors.carbs && (
                <p className="text-red-500 text-xs">{errors.carbs.message}</p>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3.25}
              lg={3.25}
              style={{ marginRight: "2%" }}
            >
              <label
                htmlFor="proteins"
                className="block text-sm font-medium leading-6 text-blue-950"
              >
                {constants.proteinsPercentage}
              </label>
              <TextField
                value={proteinsPercent}
                onChange={(e) => {
                  setProteinsPercent(e.target.value);
                }}
                size="small"
                id="proteins"
                type="number"
                placeholder="0 g"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              />
              <p className="py-1">
                {proteinsPercent
                  ? (
                      (parseFloat(proteinsPercent / 100) *
                        totalCaloryIntakePerDay) /
                      4
                    ).toFixed(2)
                  : 0}{" "}
                {constants.gmProteins}
              </p>
              {errors.proteins && (
                <p className="text-red-500 text-xs">
                  {errors.proteins.message}
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={3.25} lg={3.25}>
              <label
                htmlFor="fats"
                className="block text-sm font-medium leading-6 text-blue-950"
              >
                {constants.fatsPercentage}
              </label>
              <TextField
                value={fatPercent}
                onChange={(e) => {
                  setFatPercent(e.target.value);
                }}
                type="number"
                size="small"
                id="fats"
                placeholder="0 g"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              />
              <p className="py-1">
                {fatPercent
                  ? (
                      parseFloat((fatPercent / 100) * totalCaloryIntakePerDay) /
                      9
                    ).toFixed(2)
                  : 0}{" "}
                {constants.gmFats}
              </p>
              {errors.fats && (
                <p className="text-red-500 text-xs">{errors.fats.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={10.25}>
              <label
                htmlFor="TEE"
                className="block text-sm font-medium leading-6 text-blue-950"
              >
                {constants["totalEnergyExpenditurePerDay(TEE)"]}
              </label>
              <TextField
                value={teePerDay}
                onChange={(e) => {
                  setTeePerDay(e.target.value);
                }}
                size="small"
                id="TEE"
                type="number"
                className="bg-gray-50  border-black border-2 rounded-lg text-gray-900 text-sm block w-full p-2.5"
              />
              {errors.TEE && (
                <p className="text-red-500 text-xs">{errors.TEE.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={10.25}>
              <label
                htmlFor="TEE"
                className="block text-sm font-medium leading-6 text-blue-950 mt-2"
              >
                {constants.numberOfWeeks}
              </label>
              <TextField
                value={noOfWeeks}
                onChange={(e) => {
                  setNoOfWeeks(e.target.value);
                }}
                size="small"
                id="NoOfWeeks"
                type="text"
                className="bg-gray-50  border-black border-2 rounded-lg text-gray-900 text-sm block w-full p-2.5"
              />
              {errors.NoOfWeeks && (
                <p className="text-red-500 text-xs">
                  {errors.NoOfWeeks.message}
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={10.25}>
              <label
                htmlFor="recommend"
                className="block text-sm font-medium leading-6 text-blue-950 mt-2"
              >
                {constants.recommendations}
              </label>
              <TextareaAutosize
                minRows={3}
                value={recommendation}
                onChange={(e) => {
                  setRecommenadation(e.target.value);
                }}
                id="recommend"
                placeholder="Type something..."
                className="bg-gray-50 border-black border-2 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
              />
              {errors.recommend && (
                <p className="text-red-500 text-xs">
                  {errors.recommend.message}
                </p>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                marginTop: "2%",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  createPreDietPlanInfo();
                }}
                sx={{
                  backgroundColor: constants.orange,
                  ":hover": {
                    backgroundColor: constants.orange,
                  },
                }}
                className=" px-3 py-2 tracking-widest text-sm font-semibold text-white shadow-sm  "
              >
                {constants.next}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
