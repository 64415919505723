import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Box } from "@material-ui/core/";
import { styled } from "@mui/material/styles";
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import constants from "../../../constants.json";
import Logo from "../../../assets/images/logo.png";
const useStyles = makeStyles((theme) => ({
  container_header: {
    width: "100%",
    height: "6rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    paddingLeft: "15%",
    paddingRight: "15%",
    backgroundColor: "#31525B",
  },

  text1_header: {
    color: "#FFFFFFE6",
    fontFamily: constants.fontFamily,
    fontSize: "1.875em",
    display: "flex",
  },
  buttonstyle_header: {
    height: "2.5rem",
    color: "#FFFFFFE6",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "1em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    outline: "none",
    "&:hover": {
      fontSize: "1.4em",
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
  },
  divider_header: {
    color: "#FFFFFFE6",
    fontSize: "1.3em",
    marginTop: "0.65rem",
  },
  buttoncontainer_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
  },
  grid_style: {
    display: "flex",
    alignItems: "center",
  },
  grid_style_mobile: {
    display: "flex",
    alignItems: "center",
    paddingTop: "1rem",
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  background: "#1976d2",
  width: "100%",
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.up("lg")]: {},
}));

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Wrapper>
      <div className={classes.container_header}>
        <Grid container>
          <Grid
            item
            sm={12}
            md={6}
            lg={8}
            className={
              isMobile ? classes.grid_style_mobile : classes.grid_style
            }
          >
            <Typography
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
              variant="h4"
              className={classes.text1_header}
            >
              <img
                style={{ marginRight: "10px" }}
                src={Logo}
                alt="Logo is missing"
              />
              {constants.appTitle}
            </Typography>
          </Grid>
          {!localStorage.getItem("accessToken") ? (
            <Grid item sm={12} md={6} lg={4}>
              <div className={classes.buttoncontainer_header}>
                <Button
                  onClick={() => {
                    navigate("/calculateTEE");
                  }}
                  className={classes.buttonstyle_header}
                >
                  {constants.calculateTEE}
                </Button>
                <p className={classes.divider_header}>|</p>
                <Button
                  onClick={() => {
                    navigate("/blogs");
                  }}
                  className={classes.buttonstyle_header}
                >
                  {constants.blogs}
                </Button>
                <p className={classes.divider_header}>|</p>
                <Button
                  className={classes.buttonstyle_header}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  {constants.signIn}
                </Button>
              </div>
            </Grid>
          ) : (
            <Grid item sm={12} md={6} lg={4}>
              <div className={classes.buttoncontainer_header}>
                <Button
                  onClick={() => {
                    navigate("/calculateTEE");
                  }}
                  className={classes.buttonstyle_header}
                >
                  {constants.calculateTEE}
                </Button>
                <p className={classes.divider_header}>|</p>
                <Button
                  onClick={() => {
                    navigate("/blogs");
                  }}
                  className={classes.buttonstyle_header}
                >
                  {constants.blogs}
                </Button>
                <p className={classes.divider_header}>|</p>
                <Button
                  onClick={() => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("user");
                    navigate("/");
                    window.location.reload();
                  }}
                  className={classes.buttonstyle_header}
                >
                  {constants.logout}
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </Wrapper>
  );
};

export default Header;
