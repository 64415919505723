/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  Container,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import React from "react";
import { useState, useEffect } from "react";
import { useKalorie } from "../userContext/context";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import PieChart from "../components/kalorieKompassComponents/main/charts/piechart";
import BarChart from "../components/kalorieKompassComponents/main/charts/barchart";
import LinearProgressBar from "../components/kalorieKompassComponents/main/charts/linearProgressBar";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import constants from "../constants.json";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AreaChart from "../components/kalorieKompassComponents/main/charts/areachart";
import Box from "@mui/material/Box";
import useStyles from "../assets/css/progressTracker";
const theme = createTheme({
  palette: {
    primary: {
      main: "#38A389",
    },
    secondary: {
      main: "#daf8ec",
    },
  },
});

const ProgressTracker = () => {
  const classes = useStyles();
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  const [successful, setSuccessful] = useState(
    sessionStorage.getItem("successful")
  );
  const [durationtype, setDurationtype] = useState("1");
  const [activeButton, setActiveButton] = useState(0);
  const [selectedFormattedDate, setSelectedFormattedDate] = useState("");
  const [weekCal, setWeekCal] = useState(0);
  const { data, updateData } = useKalorie();
  const [day1Cal, setDay1Cal] = useState(0);
  const [day2Cal, setDay2Cal] = useState(0);
  const [day3Cal, setDay3Cal] = useState(0);
  const [day4Cal, setDay4Cal] = useState(0);
  const [day5Cal, setDay5Cal] = useState(0);
  const [day6Cal, setDay6Cal] = useState(0);
  const [day7Cal, setDay7Cal] = useState(0);
  const [microNutrients, setMicroNutrients] = useState([]);
  const [recMicroNutrients, setRecMicroNutrients] = useState();
  const [dietSummary, setDietSummary] = useState([]);
  const [rec, setRec] = useState();
  const isMatch = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("md")
  );
  const location = useLocation();
  const clientObject = location.state;
  useEffect(() => {
    const fetchRecommendedCalories = async () => {
      var response = await axios.get(
        `${serverIp}:${port}/api/get-recommended-calories/${clientObject.user_id}`
      );
      console.log("response1111", response.data.RecommendedCalories);

      setRec(response.data.RecommendedCalories);

      const apiUrl = `${serverIp}:${port}/api/get-required-weight/${clientObject.user_id}`;

      axios
        .get(apiUrl)
        .then((response) => {
          console.log("Nooooo", response.data);
          if (response.status === 200) {
            console.log("checking", response.data);
            const apiUrl = `${serverIp}:${port}/api/get-recommended-micro-nutrients`;
            console.log("im data:::::", data);
            const req_data = {
              age: response.data.user_detail[0].age,
              gender: response.data.user_detail[0].gender,
            };
            axios
              .post(apiUrl, req_data)
              .then((response) => {
                console.log("user", clientObject);
                console.log(
                  "response rec micros",
                  response.data.microNutrients
                );
                if (response.status === 200) {
                  setRecMicroNutrients(response.data.microNutrients);
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {
          console.log("waittttt", error.response.status);
        });
    };
    const fetchDietSummary = async () => {
      const apiUrl = `${serverIp}:${port}/api/weekly-total-meal-calories`;
      console.log("im data:::::", data);

      const req_data = {
        user_id: clientObject.user_id,
        week: durationtype.charAt(durationtype.length - 1),
      };
      await axios
        .post(apiUrl, req_data)
        .then((response) => {
          console.log("user", clientObject);
          console.log("response from dietdetailsssss", response.data);
          if (response.status === 200) {
            setDietSummary(response.data.results);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const fetchDietDetails = async () => {
      setDay1Cal(0);
      setDay2Cal(0);
      setDay3Cal(0);
      setDay4Cal(0);
      setDay5Cal(0);
      setDay6Cal(0);
      setDay7Cal(0);
      const apiUrl = `${serverIp}:${port}/api/weekly-meal-calories`;
      console.log("im data:::::", data);

      const req_data = {
        user_id: clientObject.user_id,
        week: durationtype.charAt(durationtype.length - 1),
      };
      await axios
        .post(apiUrl, req_data)
        .then((response) => {
          console.log("user", clientObject);
          console.log("response from dietdetails", response.data);
          if (response.status === 200) {
            response.data.results.map((items, index) => {
              if (items.day === 1) {
                setDay1Cal(items.total_calories);
              }
              if (items.day === 2) {
                setDay2Cal(items.total_calories);
              }
              if (items.day === 3) {
                setDay3Cal(items.total_calories);
              }
              if (items.day === 4) {
                setDay4Cal(items.total_calories);
              }
              if (items.day === 5) {
                setDay5Cal(items.total_calories);
              }
              if (items.day === 6) {
                setDay6Cal(items.total_calories);
              }
              if (items.day === 7) {
                setDay7Cal(items.total_calories);
              }
            });
          }
        })
        .catch((error) => {});
    };
    const fetchMicroNutrients = async () => {
      const getUrl = `${serverIp}:${port}/api/dp-req-to-dp/${clientObject.request_id}`;
      axios
        .get(getUrl)
        .then((response) => {
          if (response.status) {
            console.log("new codeeee", response.data);
            const apiUrl = `${serverIp}:${port}/api/weekly-total-meal-micronutrients`;
            console.log("im data:::::", data);

            const req_data = {
              diet_plan_id: response.data.diet_plan_id,
              week: durationtype.charAt(durationtype.length - 1),
            };
            axios
              .post(apiUrl, req_data)
              .then((response) => {
                console.log("user", clientObject.user_id);
                console.log("response micros", response.data);
                if (response.status === 200) {
                  setMicroNutrients(response.data.results);
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchRecommendedCalories();
    fetchDietSummary();
    fetchDietDetails();
    fetchMicroNutrients();
  }, [data.week, durationtype]);

  useEffect(() => {
    // Check if successful is true
    if (successful === "true") {
      // Display the message for 2 seconds
      const timeout = setTimeout(() => {
        setSuccessful(null); // Hide the message
      }, 4000);
      sessionStorage.setItem("successful", "false");

      return () => {
        clearTimeout(timeout); // Clear the timeout if the component unmounts
      };
    }
  }, [successful]);

  const handleChange = (event) => {
    setDurationtype(event.target.value);
    console.log("event.target.value", event.target.value + "week");
    updateData("week", event.target.value);
    console.log("datat", data);
    setActiveButton(null); // Reset active button when changing duration type
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
    console.log("index", index);
    updateData("day", index + 1);

    if (durationtype === "week1") {
      const day = index + 1;
      const date = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        day
      );
      const formattedDate = date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
      setSelectedFormattedDate(formattedDate);
    }
  };

  useEffect(() => {
    // Calculate today's date
    const today = new Date();
    const todayIndex = today.getDate() - 1;
    const todayWeek = Math.ceil(todayIndex / 7); // Calculate the current week
    const todayMonth = today.getMonth(); // Get the current month index

    // Set the active button based on the current duration type
    if (durationtype === "days") {
      setActiveButton(todayIndex);
    } else if (durationtype === "weeks") {
      setActiveButton(todayWeek);
    } else if (durationtype === "months") {
      setActiveButton(todayMonth);
    }

    // Format today's date
    const formattedDate = today.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
    });
    setSelectedFormattedDate(formattedDate);
  }, [durationtype]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  const VerticalTabs = () => {
    const [value, setValue] = React.useState(0);
    const [chartType, setChartType] = React.useState("pie");
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "#F5F5F5",
          display: !isMatch ? "flex" : "",
          height: "100%",
          width: "100%",
        }}
      >
        <Tabs
          orientation={!isMatch ? "vertical" : "horizontal"}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{}}
        >
          <Tab label={constants.totalCalories} {...a11yProps(0)} />
          <Tab label={constants.macroNutrients} {...a11yProps(1)} />
          <Tab label={constants.microNutrients} {...a11yProps(2)} />
        </Tabs>
        <TabPanel style={{ width: "100%" }} value={value} index={0}>
          {chartType === "area" ? (
            <>
              <Button
                onClick={() => {
                  setChartType("bar");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.barChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("pie");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.pieChart}
              </Button>
              <AreaChart
                rec={rec?.recommended_calories_intake}
                dietSummary={dietSummary}
                type="totalCal"
              />
            </>
          ) : chartType === "pie" ? (
            <>
              <Button
                onClick={() => {
                  setChartType("bar");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#31525B] text-white text-sm rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.barChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("area");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#31525B] text-white text-sm  rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.areaChart}
              </Button>
              <PieChart
                rec={rec?.recommended_calories_intake}
                dietSummary={dietSummary}
                type="totalCal"
              />
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setChartType("pie");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#31525B] text-white text-sm rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.pieChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("area");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#31525B] text-white text-sm rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.areaChart}
              </Button>
              <div>
                <BarChart
                  rec={rec?.recommended_calories_intake}
                  microNutrients={microNutrients}
                  type="totalCal"
                />
              </div>
            </>
          )}
        </TabPanel>
        <TabPanel style={{ width: "100%" }} value={value} index={1}>
          {chartType === "area" ? (
            <>
              <Button
                onClick={() => {
                  setChartType("bar");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.barChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("pie");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#06647D]  text-sm font-bold rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.pieChart}
              </Button>
              <AreaChart
                rec={rec?.recommended_calories_intake}
                dietSummary={dietSummary}
                type="macro"
                microNutrients={microNutrients}
              />
            </>
          ) : chartType === "pie" ? (
            <>
              <Button
                onClick={() => {
                  setChartType("bar");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#31525B] text-white text-sm rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.barChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("area");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#31525B] text-white text-sm  rounded px-2 py-1"
                variant="outlined"
                startIcon={<BarChartOutlinedIcon />}
              >
                {constants.areaChart}
              </Button>
              <PieChart
                rec={rec?.recommended_calories_intake}
                dietSummary={dietSummary}
                type="macro"
              />
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setChartType("pie");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#31525B] text-white text-sm rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.pieChart}
              </Button>
              <Button
                onClick={() => {
                  setChartType("area");
                }}
                class="ml-2 items-center bg-[#06647D] hover:bg-[#31525B] text-white text-sm rounded px-2 py-1"
                variant="outlined"
                startIcon={<PieChartOutlineOutlinedIcon />}
              >
                {constants.areaChart}
              </Button>
              <div>
                <BarChart
                  rec={rec?.recommended_calories_intake}
                  microNutrients={microNutrients}
                  type="macro"
                />
              </div>
            </>
          )}
        </TabPanel>
        <TabPanel style={{ width: "100%" }} value={value} index={2}>
          <div
            style={{ height: "100%", width: "100%", padding: "10px" }}
            className="bg-white rounded-xl shadow-md"
          >
            <LinearProgressBar
              microNutrients={microNutrients}
              recMicroNutrients={recMicroNutrients}
            />
          </div>
        </TabPanel>
      </Box>
    );
  };
  return (
    <>
      <div
        style={{
          height: "80%",
        }}
      >
        <div className={classes.innercontainer}>
          <div>
            <Grid
              style={{
                width: "100%",
                justifyContent: "space-around",
              }}
              container
            >
              <Container className={classes.container}>
                <Container className={classes.horizontalContainer}>
                  <Typography
                    style={{ width: "250px" }}
                    className={classes.inputLabel}
                  >
                    {constants.weeklyProgressTracker}
                  </Typography>

                  <div>
                    <Grid
                      style={{ padding: "20px", fontSize: "20px" }}
                      item
                      sm={12}
                      md={1}
                      lg={1}
                    >
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={durationtype}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          className={classes.inputLabel}
                          MenuProps={{
                            classes: { icon: classes.dropdownIcon },
                          }}
                        >
                          <MenuItem
                            value={"1"}
                            className={`${classes.dropdownItem} ${
                              durationtype === "1" && activeButton === null
                                ? classes.activeButton
                                : ""
                            }`}
                          >
                            Week-1
                          </MenuItem>
                          <MenuItem
                            value={"2"}
                            className={classes.dropdownItem}
                          >
                            Week-2
                          </MenuItem>
                          <MenuItem
                            value={"3"}
                            className={classes.dropdownItem}
                          >
                            Week-3
                          </MenuItem>
                          <MenuItem
                            value={"4"}
                            className={classes.dropdownItem}
                          >
                            Week-4
                          </MenuItem>
                          <MenuItem
                            value={"5"}
                            className={classes.dropdownItem}
                          >
                            Week-5
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </div>
                </Container>
                {dietSummary && rec && (
                  <Grid
                    style={{
                      padding: "20px",
                      fontSize: "25px",
                    }}
                    item
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <VerticalTabs />
                  </Grid>
                )}

                <Grid
                  style={{
                    width: "97%",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "0.5em",
                    backgroundColor: "#F5F5F5",
                    marginLeft: "1.5%",
                  }}
                  container
                >
                  <Grid
                    style={{
                      padding: "0.5em",
                      fontSize: "1.5rem",
                    }}
                    item
                    sm={12}
                    md={3}
                    lg={3}
                  >
                    <Grid style={{ color: constants.blue }}>
                      {" "}
                      Week {data.week} Calories (Kcl)
                    </Grid>
                    <Grid>
                      {day1Cal +
                        day2Cal +
                        day3Cal +
                        day4Cal +
                        day5Cal +
                        day6Cal +
                        day7Cal}
                    </Grid>
                  </Grid>

                  <Grid
                    style={{ padding: "20px", fontSize: "20px" }}
                    item
                    sm={12}
                    md={1}
                    lg={1}
                  >
                    <Grid> Day 1</Grid>
                    <Grid>{day1Cal}</Grid>
                  </Grid>
                  <Grid
                    style={{ padding: "20px", fontSize: "20px" }}
                    item
                    sm={12}
                    md={1}
                    lg={1}
                  >
                    <Grid> Day 2</Grid>
                    <Grid>{day2Cal}</Grid>
                  </Grid>
                  <Grid
                    style={{ padding: "20px", fontSize: "20px" }}
                    item
                    sm={12}
                    md={1}
                    lg={1}
                  >
                    <Grid> Day 3</Grid>
                    <Grid>{day3Cal}</Grid>
                  </Grid>
                  <Grid
                    style={{ padding: "20px", fontSize: "20px" }}
                    item
                    sm={12}
                    md={1}
                    lg={1}
                  >
                    <Grid> Day 4</Grid>
                    <Grid>{day4Cal}</Grid>
                  </Grid>
                  <Grid
                    style={{ padding: "20px", fontSize: "20px" }}
                    item
                    sm={12}
                    md={1}
                    lg={1}
                  >
                    <Grid> Day 5</Grid>
                    <Grid>{day5Cal}</Grid>
                  </Grid>
                  <Grid
                    style={{ padding: "20px", fontSize: "20px" }}
                    item
                    sm={12}
                    md={1}
                    lg={1}
                  >
                    <Grid> Day 6</Grid>
                    <Grid>{day6Cal}</Grid>
                  </Grid>
                  <Grid
                    style={{ padding: "20px", fontSize: "20px" }}
                    item
                    sm={12}
                    md={1}
                    lg={1}
                  >
                    <Grid> Day 7</Grid>
                    <Grid>{day7Cal}</Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            {/* <Grid
              style={{
                width: "100%",
                paddingTop: "50px",
              }}
              container
            > */}

            <Grid
              style={{ marginLeft: "10%", fontSize: "20px" }}
              item
              sm={12}
              md={3}
              lg={3}
            >
              <Typography
                style={{
                  width: "200px",
                  paddingTop: "10%",
                  color: constants.blue,
                  fontWeight: "bold",
                }}
                className={classes.inputLabel}
              >
                {constants.weeklySummary}
              </Typography>
            </Grid>

            <Grid
              style={{
                width: "90%",
                marginLeft: "5%",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "40px",
              }}
              container
            >
              {dietSummary.map((items) => {
                return (
                  <>
                    <Grid
                      container
                      style={{
                        backgroundColor: constants.offwhite,
                        borderRadius: "5%",
                        margin: "10px",
                      }}
                    >
                      <Grid item sm={12} md={3} lg={3}>
                        <Grid
                          style={{
                            padding: "20px",
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: constants.blue,
                          }}
                        >
                          Day {items.day}
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={9} lg={9}>
                        <Grid
                          style={{
                            padding: "20px",
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: constants.blue,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div>
                              Meal Type
                              <p>Breakfast</p>
                              {items.breakfast_foods !== null ? (
                                items.breakfast_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        {item.food_name}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <p
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "black",
                                    }}
                                  >
                                    No Meal Added Yet
                                  </p>
                                </>
                              )}
                              <p>Lunch</p>
                              {items.lunch_foods !== null ? (
                                items.lunch_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        {item.food_name}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <p
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "black",
                                    }}
                                  >
                                    No Meal Added Yet
                                  </p>
                                </>
                              )}
                              <p>Snack</p>
                              {items.snacks_foods !== null ? (
                                items.snacks_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        {item.food_name}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <p
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "black",
                                    }}
                                  >
                                    No Meal Added Yet
                                  </p>
                                </>
                              )}
                              <p>Dinner</p>
                              {items.dinner_foods !== null ? (
                                items.dinner_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        {item.food_name}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <p
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "black",
                                    }}
                                  >
                                    No Meal Added Yet
                                  </p>
                                </>
                              )}
                            </div>
                            <div>
                              <p>Calories (Kcl)</p>
                              <p>{items.breakfast_total_calories}</p>
                              {items.breakfast_foods !== null ? (
                                items.breakfast_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        {item.calories_kcal}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <p
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "black",
                                    }}
                                  >
                                    0
                                  </p>
                                </>
                              )}
                              <p>{items.lunch_total_calories}</p>
                              {items.lunch_foods !== null ? (
                                items.lunch_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        {item.calories_kcal}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <p
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "black",
                                    }}
                                  >
                                    0
                                  </p>
                                </>
                              )}
                              <p>{items.snacks_total_calories}</p>
                              {items.snacks_foods !== null ? (
                                items.snacks_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        {item.calories_kcal}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <p
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: "black",
                                    }}
                                  >
                                    0
                                  </p>
                                </>
                              )}
                              <p>{items.dinner_total_calories}</p>
                              {items.dinner_foods !== null ? (
                                items.dinner_foods?.map((item) => {
                                  return (
                                    <>
                                      <Grid
                                        style={{
                                          paddingTop: "15px",
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        {item.calories_kcal}
                                      </Grid>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <p
                                    style={{
                                      paddingTop: "15px",
                                      fontSize: "20px",
                                      color: constants.blueTextColor,
                                    }}
                                  >
                                    0
                                  </p>
                                </>
                              )}
                            </div>
                            <div>
                              Total Calories (KCl)
                              <p>{items.total_calories}</p>
                            </div>
                          </div>
                          {/* <Grid
                          style={{ fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                        >
                          Breakfast
                          {
                            items.breakfast_foods?.map((item) => {

                              return (<>
                                <Grid style={{paddingTop:"15px", fontSize: "20px", color: "black" }}>
                                  {item.food_name}
                                </Grid>
                              </>
                              )
                            })

                          }
                        </Grid>
                        <Grid
                          style={{ fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                        >
                          Lunch
                          {
                            items.lunch_foods?.map((item) => {

                              return (<>
                                <Grid style={{ paddingTop:"15px", fontSize: "20px", color: "black" }}>
                                  {item.food_name}
                                </Grid>
                              </>
                              )
                            })

                          }
                        </Grid>
                        <Grid
                          style={{ fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                        >
                          Snack
                          {
                            items.snacks_foods?.map((item) => {

                              return (<>
                                <Grid style={{paddingTop:"15px", fontSize: "20px", color: "black" }}>
                                  {item.food_name}
                                </Grid>
                              </>
                              )
                            })

                          }
                        </Grid>
                        <Grid
                          style={{ fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                        >
                          Dinner
                          {
                            items.dinner_foods?.map((item) => {

                              return (<>
                                <Grid style={{paddingTop:"15px", fontSize: "20px", color: "black" }}>
                                  {item.food_name}
                                </Grid>
                              </>
                              )
                            })

                          }
                          
                        </Grid> */}
                        </Grid>
                        {/* <Grid>
                        <Grid
                          style={{ padding: "20px", fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                        >
                          Breakfast
                        </Grid>
                        <Grid
                          style={{ padding: "20px", fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                        >
                          Lunch
                        </Grid>
                        <Grid
                          style={{ padding: "20px", fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                        >
                          Snack
                        </Grid>
                        <Grid
                          style={{ padding: "20px", fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                        >
                          Dinner
                        </Grid>
                      </Grid> */}
                      </Grid>
                      {/* <Grid
                      item
                      sm={12}
                      md={3}
                      lg={3}>
                      <Grid
                        style={{ padding: "20px", fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                      >
                        Calories (Kcl)
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={3}
                      lg={3}>
                      <Grid
                        style={{ padding: "20px", fontSize: "25px", fontWeight: "bold", color: "#38A389" }}
                      >
                        Total Calories (Kcl)
                      </Grid>
                    </Grid> */}
                    </Grid>
                  </>
                );
              })}
            </Grid>

            {/* </Grid> */}
          </div>
          {/* {successful === "true" && (
            <div
              style={{
                marginTop: "10px",
                backgroundColor: "rgba(144, 238, 144,0.8)",
                width: "25%",
                marginLeft: "1050px",

                textAlign: "center",
              }}
            >
              <Typography variant="h5" style={{ color: "white" }}>
                Sign up has been successful.
              </Typography>
            </div>
          )}
          <ProgressTracker /> */}
          {/* <PieChartComp apiData={data} foodWeight={100} /> */}
        </div>
      </div>
    </>
  );
};

export default ProgressTracker;
