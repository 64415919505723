import Button from "@mui/material/Button";
import constants from "../constants.json";
import { useMediaQuery, useTheme } from "@material-ui/core";
const SigninButton = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Button
      variant={props.buttonVariant}
      startIcon={props.endIcon}
      sx={{
        marginBottom: props.margin || 10,
        marginTop: props.marginTop || 5,
        background: props.background || "rgba(255, 113, 113, 0.5)",
        color: "white",
        width: isMobile ? "15vh" : props.width || "45vh",
        height: props.height || "inherit",
        "&:hover": {
          background: props.background || "rgba(56, 163, 137, 1)", // Change the background color on hover
        },
        marginLeft: props.marginLeft || 0,
        marginRight: props.marginRight || 0,
        fontFamily: constants.fontFamily,
        textTransform: props.textTransform || false,
      }}
      onClick={props.onClick}
    >
      {props.buttonText}
    </Button>
  );
};

export default SigninButton;
