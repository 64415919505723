import React, { useState, useEffect } from "react";
import { IconButton, MenuItem, FormControl, Select } from "@mui/material";
import profile from "../assets/images/user.png";
import userinfo from "../assets/images/diet.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ITEMS_PER_PAGE } from "../app/constants";
import Pagination from "../components/reusableComponents/Pagination";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material"; // Correcting useMediaQuery import
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import constants from "../constants.json";

const theme = createTheme({
  palette: {
    primary: {
      main: "#38A389",
    },
    secondary: {
      main: "#daf8ec",
    },
  },
});

export default function DietitionClients() {
  const navigate = useNavigate();
  const [page, setpage] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [data, setData] = useState([]);
  const [dietPlanId, setDietPlanId] = useState(0);
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  const userObject = JSON.parse(localStorage.getItem("user"));
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const handlePage = (page) => {
    setpage(page);
  };

  useEffect(() => {
    setpage(1);
  }, []);

  useEffect(() => {
    const getitems = `${serverIp}:${port}/api/get-all-diet-plan-requests/${userObject.user_id}`;
    axios
      .get(getitems)
      .then((response) => {
        if (response.status) {
          console.log("new code", response.data);
          const filtered = response.data.requests.filter(
            (user) => user.diet_plan_status_page !== null
          );
          setData(filtered);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmit = (user) => {
    const getUrl = `${serverIp}:${port}/api/dp-req-to-dp/${user.request_id}`;
    axios
      .get(getUrl)
      .then((response) => {
        if (response.status) {
          console.log("new codeeee", response.data);
          user.diet_plan_status_page === "diet-plan"
            ? navigate("/dietitionDietTracker", {
                state: {
                  dietplanObject: { diet_plan_id: response.data.diet_plan_id },
                  userData: user,
                },
              })
            : navigate("/avoidfooditems", {
                state: {
                  result: { diet_plan_id: response.data.diet_plan_id },
                  userData: user,
                },
              });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmitForProgressTracker = (user) => {
    navigate("/clientProgressSummary", {
      state: user,
    });
  };

  const itemsPerPage = ITEMS_PER_PAGE;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataToDisplay = data.slice(startIndex, endIndex);

  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    const sortedData = data.slice().sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.first_name.localeCompare(b.first_name);
      } else {
        return b.first_name.localeCompare(a.first_name);
      }
    });

    setData(sortedData);
  };

  return (
    <div style={{ margin: "10px", overflowX: "hidden" }}>
      <div className="flex justify-between mb-3">
        <h1 className="text-2xl font-semibold text-[#31525B] mt-2">
          {constants.clients}
        </h1>
      </div>
      <ToastContainer />
      <div className="mt-3">
        <table className="min-w-full table-auto">
          <thead>
            {!isMatch && (
              <tr className="bg-[#31525B] cursor-pointer text-white uppercase text-sm leading-normal">
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <th className="py-3 px-6 text-left" onClick={handleSort}>
                      {constants.clients}
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon className="w-2 h-2 mb-1 inline" />
                      ) : (
                        <ArrowDownwardIcon className="w-2 h-2 mb-1 inline" />
                      )}
                    </th>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <th className="py-3 px-6 text-center">
                      {constants.dietPlanRequestStatus}
                    </th>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <th className="py-3 px-6 text-center">
                      {constants.viewDietPlan}
                    </th>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <th className="py-3 px-6 text-center">
                      {constants.viewProgressTracker}
                    </th>
                  </Grid>
                </Grid>
              </tr>
            )}
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {dataToDisplay.map((user, index) => (
              <tr
                key={index}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <Grid container>
                  <Grid item xs={12} md={3}>
                    {isMatch && <h3>{constants.client}</h3>}
                    <td className="py-3 px-6">
                      <div className="flex items-center">
                        <img
                          className="w-14 h-14 rounded-full mr-2"
                          src={profile}
                          alt="profile"
                        />
                        <span>{`${user.first_name} ${user.last_name}`}</span>
                      </div>
                    </td>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {isMatch && <h6>{constants.dietPlanRequestStatus}</h6>}
                    <td className="py-3 px-6 text-center">
                      <span
                        className={`flex justify-center items-center w-20 h-12 py-1 px-2 rounded-full border-2 ${
                          user.diet_plan_status_page === "diet-plan"
                            ? "bg-green-200 text-green-950 border-green-500"
                            : "bg-red-200 text-red-950 border-red-500"
                        }`}
                      >
                        {user.diet_plan_status_page === "diet-plan"
                          ? "Complete"
                          : "Pending"}
                      </span>
                    </td>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {isMatch && <h6>{constants.viewDietPlan}</h6>}
                    <td className="py-3 px-6 text-center">
                      <IconButton onClick={() => handleSubmit(user)}>
                        <TextSnippetIcon
                          className="w-16 h-16"
                          sx={{ color: "#31525B" }}
                        />
                      </IconButton>
                    </td>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {isMatch && <h6>{constants.viewProgressTracker}</h6>}
                    <td className="py-3 px-6 text-center">
                      <IconButton
                        onClick={() => handleSubmitForProgressTracker(user)}
                      >
                        <TextSnippetIcon
                          className="w-16 h-16"
                          sx={{ color: "#31525B" }}
                        />
                      </IconButton>
                    </td>
                  </Grid>
                </Grid>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination page={page} setpage={setpage} items={data.length} />
      </div>
    </div>
  );
}
