import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FlakyIcon from "@mui/icons-material/Flaky";
import GroupIcon from "@mui/icons-material/Group";
import List from "@mui/material/List";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import constants from "../constants.json";
import ProfileIcon from "../assets/images/profileIcon.png";
const drawerWidth = 240;
const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF", // Change the color of icons and text
    },
    secondary: {
      main: "#06647D", // Change the color of sidebar and navbar
    },
  },
});

export default function AdminProtected({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeRoute, setActiveRoute] = React.useState(location.pathname);

  React.useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location.pathname]);

  const handleRouteChange = (route) => {
    setActiveRoute(route);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => navigate("/adminprofile")}>Profile</MenuItem>
      <MenuItem
        onClick={() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          navigate("/");
          window.location.reload();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <Toolbar>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
              className="text-3xl font-bold tracking-wide text-[#FFFFFF]"
            >
              {constants.appTitle}
            </p>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon
                    className="h-10 w-10"
                    sx={{ color: theme.palette.primary.main }}
                  />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <img
                  // className="h-12 w-12 rounded-full "
                  src={ProfileIcon}
                  alt="Profile Icon"
                />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Drawer
          variant="permanent"
          sx={{
            width: { xs: 60, sm: drawerWidth }, // Adjust width for mobile and larger screens
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: { xs: 60, sm: drawerWidth }, // Adjust width for mobile and larger screens
              boxSizing: "border-box",
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto", marginTop: "1rem" }}>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  style={{
                    background:
                      activeRoute === "/adminDashboard" ? "#D9D9D9" : "#24768C",
                    borderBottom: "1px solid #000",
                  }}
                  component={Link}
                  to={"/adminDashboard"}
                >
                  <ListItemIcon>
                    <DashboardIcon
                      className="w-6 h-6"
                      sx={{
                        color:
                          activeRoute === "/adminDashboard"
                            ? "#06647DE0"
                            : "#FFFFFF",
                      }}
                    />
                  </ListItemIcon>
                  {/* Hide text for mobile */}
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <p
                      style={{
                        color:
                          activeRoute === "/adminDashboard"
                            ? "#06647DE0"
                            : "#FFFFFF",
                      }}
                      className="text-lg font-medium"
                    >
                      Admin Portal
                    </p>
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  style={{
                    background:
                      activeRoute === "/displayUsers" ? "#D9D9D9" : "#24768C",
                    borderBottom: "1px solid #000",
                  }}
                  component={Link}
                  to={"/displayUsers"}
                >
                  <ListItemIcon>
                    <GroupIcon
                      className="w-6 h-6"
                      sx={{
                        color:
                          activeRoute === "/displayUsers"
                            ? "#06647DE0"
                            : "#FFFFFF",
                      }}
                    />
                  </ListItemIcon>
                  {/* Hide text for mobile */}
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <p
                      style={{
                        color:
                          activeRoute === "/displayUsers"
                            ? "#06647DE0"
                            : "#FFFFFF",
                      }}
                      className="text-lg font-medium"
                    >
                      User Manager
                    </p>
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  style={{
                    background:
                      activeRoute === "/requestlist" ? "#D9D9D9" : "#24768C",
                    borderBottom: "1px solid #000",
                  }}
                  component={Link}
                  to={"/requestlist"}
                >
                  <ListItemIcon>
                    <FlakyIcon
                      className="w-6 h-6"
                      sx={{
                        color:
                          activeRoute === "/requestlist"
                            ? "#06647DE0"
                            : "#FFFFFF",
                      }}
                    />
                  </ListItemIcon>
                  {/* Hide text for mobile */}
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <p
                      style={{
                        color:
                          activeRoute === "/requestlist"
                            ? "#06647DE0"
                            : "#FFFFFF",
                      }}
                      className="text-lg font-medium"
                    >
                      Approve Request
                    </p>
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  style={{
                    background:
                      activeRoute === "/roleManager" ? "#D9D9D9" : "#24768C",
                    borderBottom: "1px solid #000",
                  }}
                  component={Link}
                  to={"/roleManager"}
                >
                  <ListItemIcon>
                    <EngineeringIcon
                      className="w-6 h-6"
                      sx={{
                        color:
                          activeRoute === "/roleManager"
                            ? "#06647DE0"
                            : "#FFFFFF",
                      }}
                    />
                  </ListItemIcon>
                  {/* Hide text for mobile */}
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <p
                      style={{
                        color:
                          activeRoute === "/roleManager"
                            ? "#06647DE0"
                            : "#FFFFFF",
                      }}
                      className="text-lg font-medium"
                    >
                      Role Manager
                    </p>
                  </Box>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
