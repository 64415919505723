import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import NotificationCenter from "react-notification-center-component";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import profile from "../../assets/images/appbarProfile.png";
import constants from "../../constants.json";
const drawerWidth = 240;

const userObject = JSON.parse(localStorage.getItem("user"));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.

const theme = createTheme({
  palette: {
    primary: {
      main: "#38A389",
    },
    secondary: {
      main: "#daf8ec",
    },
  },
});

export default function Dashboard({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const isMatch = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("md")
  );

  const handleClick = (event) => {
    console.log("wowow", Boolean(event.currentTarget));
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          style={{
            backgroundColor: "white",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <Toolbar>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
                className="text-3xl font-bold tracking-wide text-[#06647D]"
              >
                {constants.appTitle}
              </p>
            </Typography>
            {!isMatch ? (
              <>
                {/* <MenuItem>
                  <NotificationCenter
                    className="checking"
                    appId="R1mZPd6xWo"
                    subscriberId={
                      "64e7f7397a784904801d0ad0" + userObject.user_id
                    }
                  />
                </MenuItem> */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit"
                >
                  <img
                    className="h-12 w-24 rounded-full "
                    src={profile}
                    alt=""
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  id="primary-search-account-menu"
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Link
                      href="/Profile"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <Typography variant="inherit" noWrap>
                        User Settings
                      </Typography>
                    </Link>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("user");
                      navigate("/");
                      window.location.reload();
                    }}
                  >
                    <Typography variant="inherit" noWrap>
                      Sign Out
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <IconButton>
                  <NotificationCenter
                    className="checking"
                    appId="R1mZPd6xWo"
                    subscriberId={
                      "64e7f7397a784904801d0ad0" + userObject.user_id
                    }
                  />
                </IconButton>

                <IconButton onClick={handleClick} color="inherit">
                  <MoreVertIcon style={{ color: "black" }} />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  id="primary-search-account-menu"
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Link
                      href="/ProfileSettings"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <Typography variant="inherit" noWrap>
                        User Settings
                      </Typography>
                    </Link>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("user");
                      navigate("/");
                      window.location.reload();
                    }}
                  >
                    <Typography variant="inherit" noWrap>
                      Sign Out
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            width: "100wh",
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 1.25, mb: 1 }} disableGutters>
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
