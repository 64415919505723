import { makeStyles } from "@material-ui/core/styles";
import constants from "../../constants.json";
import backgroundImage from "../../assets/images/homepageBg.png";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  innercontainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    borderTopLeftRadius: "100px",
  },
  horizontalContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingTop: "0%",
    paddingLeft: "0",
    paddingRight: "0",
  },
  inputLabel: {
    color: constants.primaryColor, // Customize placeholder color
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    width: "150px",

    "&.Mui-focused": {
      color: constants.blueTextColor, // Customize focus color
    },

    // Add this section to change underline color
    "&.MuiFormLabel-root.Mui-focused": {
      color: constants.blueTextColor,
    },
    "&.MuiInput-underline:before": {
      borderBottomColor: constants.blueTextColor, // Change to your desired underline color
    },
    "&.MuiInput-underline:after": {
      borderBottomColor: constants.blueTextColor, // Change to your desired underline color
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: constants.blueTextColor, // Change to your desired underline color
    },
  },
  dropdownIcon: {
    fill: constants.blueTextColor, // Use 'fill' property to change the color of SVG icons
  },

  dropdownItem: {
    color: constants.blueTextColor,
  },

  weekButton: {
    color: "white",
    borderColor: constants.blue,
    margin: "0.25rem",
    marginLeft: "1rem",
    backgroundColor: constants.blue,
    height: "4.3rem",
    width: "0.313rem",
    fontSize: "0.8rem",
    borderRadius: "35%",
    transition: "transform 0.2s, borderBottom 0.2s",
    "&:hover": {
      transform: "scale(1.1)",
      backgroundColor: constants.blue,
    },
  },
  weekButtonDate: {
    color: "#FFD171",
    borderColor: "#FFD171",
  },

  underline: {
    textDecoration: "underline",
  },
  buttonContainer: {
    display: "flex",
    overflowX: "auto",
    width: "100%",
    marginTop: theme.spacing(2),
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  activeButton: {
    backgroundColor: constants.orange,
    color: "white",
    borderColor: constants.blue,
    margin: "5px",
    marginBotton: "10px",
    borderRadius: "35%",
    transition: "transform 0.2s, margin-bottom 0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
    "&.active": {
      position: "relative",
      top: "-5px",
      borderBottom: "2px solid #FFD171",
      marginBotton: "20px",
    },
  },
  navButton: {
    margin: theme.spacing(1),
  },
  info_div: {},

  topContainer: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
  },

  //ingredientstacker
  integredientStackerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "300px",
    borderRadius: "2%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
    // border: "3px solid red",
  },
  offwhiteContainer: {
    backgroundColor: constants.offwhite,
  },
  innerContainer1: {
    // display: "flex",
    flexDirection: "row",
    width: "50%",
    height: "100%",
    backgroundColor: constants.offwhite,
    backgroundPosition: "center",
    borderRadius: "10px",
    textAlign: "center",
    flex: "0.5",
    // border: "2px solid purple",
  },
  innerContainer2: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    // marginLeft: "2%",
    // backgroundColor: constants.offwhite,
    // border: "2px solid red",
  },
  labeltext: {
    color: constants.primaryColor,
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
    fontWeight: "bold",
    marginTop: "1%",
  },
  labeltext3: {
    color: "#31525B !important",
    fontFamily: constants.fontFamily,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  labeltext2: {
    color: "#31525B !important",
    fontFamily: constants.fontFamily,
    fontSize: "1.4rem",
    // border: "2px solid red",
  },
  smallContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "18%",
    justifyContent: "space-around",
    alignItems: "center",
    // border: "2px solid #FFD171",
    marginLeft: "-20px",
    padding: 10,
  },
  text1: {
    color: "#A87DB4 !important",
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
    textAlign: "center",
  },

  text2: {
    color: "black",
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
  },
  imgstyle: {
    width: "50px",
    height: "50px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: "170px",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
  },
  sliderContainer: {
    width: "100%",
    height: "1.5rem",
    borderRadius: "10px",
    backgroundColor: constants.grey,
    color: "yellow",
    // border: "2px solid rgba(255, 209, 113, 0.6)",
  },
  barColor: {
    backgroundColor: constants.primaryColor,
  },

  labeltextRed: {
    fontWeight: "bold",
    fontSize: "1.4rem",
    color: "red",
  },
  linearProgressBarRed: {
    backgroundColor: "red",
  },

  protein: {
    backgroundColor: "#00B569",
    color: "#00B569",
  },
  protienText: {
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    color: "#00B569",
  },
  fatText: {
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    color: "#2583EF",
  },
  carbsText: {
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    color: "#FDA101",
  },
  fats: {
    backgroundColor: "#2583EF",
    color: "#2583EF",
  },
  fiber: {
    backgroundColor: "#38A389",
  },
  carb: {
    backgroundColor: "#FDA101",
    color: "#FDA101",
  },
  alignContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 0,
  },
  barsDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end", // Align items to the bottom
    height: "200px",
    justifyContent: "center",
    // border: "2px solid blue",
  },
  bars: {},
  barText: {
    position: "absolute",
    top: -20, // Adjust this value to position the text
    color: "black", // Text color
    fontSize: "0.95rem",
    left: 0,
  },
  barContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centeredColumnFlex: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  barLabel: {
    marginTop: "5px", // Adjust the margin as needed
    fontSize: "0.8rem",
    color: "#000000", // Set the color for the label
  },
  mainGrid: {
    width: "100%",
    minHeight: "100%",
    backgroundColor: "white",
    display: "flex",
    backgroundImage: `url(${backgroundImage})`, // Set background image
    backgroundSize: "100% 98%",
    backgroundPosition: "center",
  },
  dietTrackerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
    // border: "2px solid black",
  },
  ingredientStackerGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginBottom: "2%",
    alignContent: "flex-start",
    // border: "2px solid black",
  },
  ingredientStackerGrid1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "2%",
    alignContent: "flex-start",
    flex: "0.5",
    // border: "2px solid black",
  },
  progressGrid: {
    // border: "2px solid pink",
    width: "100vh",
    flex: "1",
    // boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  },
  topGrid: {
    display: "flex",
    flexDirection: "row",
    // border: "2px solid pink",
    justifyContent: "center",
    padding: "3%",
  },
  totalCal: {
    color: constants.totalColor,
  },
  consumedCal: {
    color: constants.consumedColor,
  },
  remainingCal: {
    color: constants.remainingColor,
  },
  chartText: {
    fontFamily: constants.fontFamily,
    fontSize: "1.4rem",
  },
  chartText1: {
    color: constants.primaryColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginTop: "1%",
  },
}));

export default useStyles;
