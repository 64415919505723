import * as React from "react";
import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import constants from "../../constants.json";
import MenuItem from "@mui/material/MenuItem";
import { useKalorie } from "../../userContext/context";
const getWeekDates = (currentDate) => {
  // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
  const currentDayOfWeek = currentDate.getDay();

  // Calculate the start of the week
  const weekStart = new Date(currentDate);
  if (currentDayOfWeek === 0) {
    // If it's Sunday
    weekStart.setDate(weekStart.getDate() - 6); // Subtract 6 days to get to the start of the week
  } else {
    weekStart.setDate(weekStart.getDate() - currentDayOfWeek + 1);
  }

  // Calculate the end of the week
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekEnd.getDate() + 6);

  // Format the dates as needed
  return {
    startWeek: weekStart.toISOString().split("T")[0],
    weekStart: weekStart.getDate(),
    month: weekStart.toLocaleString("default", { month: "long" }),
    endWeek: weekEnd.toISOString().split("T")[0],
    weekEnd: weekEnd.getDate(),
  };
};

const formatDate = (date) => {
  // Format the date to DD-MM-YYYY
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
export default function TrackingDates() {
  const {
    updateStartTrackingDiary,
    updateEndTrackingDiary,
    updateDateTrackingDiary,
    dateTrackingDiary,
    menuValue,
    setMenuValue,
  } = useKalorie();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [currentDate, setCurrentDate] = useState(new Date());

  const { weekStart, weekEnd, startWeek, endWeek, month } = getWeekDates(
    new Date(currentDate)
  );

  const goToPreviousWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
  };

  const goToNextWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
  };
  const goToPreviousDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };
  useEffect(() => {
    // Update the context state only when startWeek or endWeek changes
    updateStartTrackingDiary(startWeek);
    updateEndTrackingDiary(endWeek);
  }, [startWeek, endWeek]);
  useEffect(() => {
    // Update the context state only when startWeek or endWeek changes

    updateDateTrackingDiary(selectedDate.toISOString().split("T")[0]);
  }, [selectedDate, dateTrackingDiary]);

  return (
    <div className="px-4">
      <div className="flex justify-center items-center">
        {menuValue === "weekly" ? (
          <>
            <button className="m-2" onClick={goToPreviousWeek}>
              {"<"}
            </button>
            <button className="m-4">
              {`${weekStart} - ${weekEnd} ${month}`}
            </button>
            <button className=" m-2" onClick={goToNextWeek}>
              {">"}
            </button>
          </>
        ) : (
          <>
            <button className="m-2" onClick={goToPreviousDate}>
              {"<"}
            </button>
            <button className=" m-4">
              {formatDate(selectedDate)}
              {/* Display selected date */}
            </button>
            <button className=" m-2" onClick={goToNextDate}>
              {">"}
            </button>
          </>
        )}

        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
          {/* <InputLabel id="demo-select-small-label">Duration</InputLabel> */}
          <Select
            sx={{ height: 30 }}
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={menuValue}
            // label="Weekly"
            onChange={console.log("hello")}
          >
            <MenuItem
              onClick={() => {
                setMenuValue("weekly");
              }}
              value={"weekly"}
            >
              {constants.weekly}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setMenuValue("daily");
              }}
              value={"daily"}
            >
              {constants.daily}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
