import { createTheme } from "@material-ui/core/styles";
import constants from "../constants.json";

const theme = createTheme({
  typography: {
    fontFamily: constants.fontFamily,
  },
});

export default theme;
