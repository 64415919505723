import React, { useState } from "react";
import CustomTypography from "../../components/reusableComponents/muiTypography";
import MuiTextField from "../../components/reusableComponents/textfieldForm";
import AddButton from "../../commonScreens/signinButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import "../../assets/css/addFood.css";
import constants from "../../constants.json";
import CSVReader from "react-csv-reader";
import axios from "axios";
function AddFood() {
  const [foodName, setFoodName] = useState("");
  const [foodWeight, setFoodWeight] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantity_unit, setQuantity_unit] = useState("");
  const [water, setWater] = useState("");
  const [protein, setProtein] = useState("");
  const [fats, setFats] = useState("");
  const [fiber, setFiber] = useState("");
  const [carbs, setCarbs] = useState("");
  const [zinc, setZinc] = useState("");
  const [potassium, setPotassium] = useState("");
  const [magnesium, setMagnesium] = useState("");
  const [iron, setIron] = useState("");
  const [calories, setCalories] = useState("");
  const [sodium, setSodium] = useState("");
  const [thiamin, setThiamin] = useState("");
  const [vitE, setVitE] = useState("");
  const [vitA, setVitA] = useState("");
  const [riboflavin, setRiboflavin] = useState("");
  const [b3, setB3] = useState("");
  const [b6, setB6] = useState("");
  const [b9, setB9] = useState("");
  const [vitC, setVitC] = useState("");
  const [vitb12, setVitb12] = useState("");
  const [selenium, setSelenium] = useState("");
  const [choline, setCholine] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [importError, setImportError] = useState("");
  const [importSuccess, setImportSuccess] = useState("");
  const navigate = useNavigate();
  const handleLogin = async (event) => {
    // Check for empty fields
    if (
      !foodName ||
      !foodWeight ||
      !quantity ||
      !quantity_unit ||
      !water ||
      !protein ||
      !fats ||
      !fiber ||
      !carbs ||
      !zinc ||
      !potassium ||
      !magnesium ||
      !iron ||
      !calories ||
      !sodium ||
      !thiamin ||
      !vitE ||
      !vitA ||
      !riboflavin ||
      !b3 ||
      !b6 ||
      !b9 ||
      !vitC ||
      !vitb12 ||
      !selenium ||
      !choline
    ) {
      setError("All fields are required");
      return;
    }
    event.preventDefault();
    const userObject = JSON.parse(localStorage.getItem("user"));
    console.log("im user from add food:::", userObject);
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(apiUrl + "/api/food-items", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userObject.user_id,
          // user_id: 2,
          food_name: foodName,
          quantity: quantity,
          quantity_unit: quantity_unit,
          weight_g: foodWeight,
          water_g: water,
          calories_kcal: calories,
          protein_g: protein,
          carbs_g: carbs,
          fiber_g: fiber,
          fats_g: fats,
          calcium: "10",
          iron_fe_mg: iron,
          magnesium: magnesium,
          potassium_mg: potassium,
          sodium_mg: sodium,
          zinc_mg: zinc,
          vitA_mic_g: vitA,
          thia_mg: thiamin,
          vitE_mg: vitE,
          ribo_mg: riboflavin,
          b3_niac_mg: b3,
          b6_mg: b6,
          b9_micro_g: b9,
          vitC_mg: vitC,
          vitb12_microg: vitb12,
          selenium_microg: selenium,
          choline_mg: choline,
          food_item_pic_link: "path/to/pic",
        }),
      });

      const result = await response.json();
      console.log("result from add food", result);

      if (result.status === true) {
        console.log("response", result.message);
        setSuccess(true);
        navigate("/allFoods");
      } else {
        console.log("food not add");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
  };
  const handleFile = (data, fileInfo) => {
    setImportSuccess("");
    setImportError("");
    let importSuccessCount = 0;
    let importErrorCount = 0;
    data.map((item) => {
      // Handle the parsed CSV data
      if (
        !item.food_name ||
        !item.quantity ||
        !item.quantity_unit ||
        !item.weight_g ||
        !item.water_g ||
        !item.calories_kcal ||
        !item.protein_g ||
        !item.carbs_g ||
        !item.fiber_g ||
        !item.fats_g ||
        !item.choline_mg ||
        !item.calcium ||
        !item.iron_fe_mg ||
        !item.magnesium ||
        !item.potassium_mg ||
        !item.sodium_mg ||
        !item.zinc_mg ||
        !item.vitA_mic_g ||
        !item.thia_mg ||
        !item.vitE_mg ||
        !item.ribo_mg ||
        !item.b3_niac_mg ||
        !item.b6_mg ||
        !item.b9_micro_g ||
        !item.vitC_mg ||
        !item.vitb12_microg ||
        !item.selenium_microg
      ) {
        setImportSuccess("");
        setImportError("File not imported,Data is not complete");
      } else {
        const userObject = JSON.parse(localStorage.getItem("user"));
        console.log("im user from add food:::", userObject);
        const apiUrl = process.env.REACT_APP_API_URL;
        // try {
        axios
          .post(apiUrl + "/api/food-items", {
            user_id: userObject.user_id,
            // user_id: 2,
            food_name: item.food_name,
            quantity: item.quantity,
            quantity_unit: item.quantity_unit,
            weight_g: item.weight_g,
            water_g: item.water_g,
            calories_kcal: item.calories_kcal,
            protein_g: item.protein_g,
            carbs_g: item.carbs_g,
            fiber_g: item.fiber_g,
            fats_g: item.fats_g,
            calcium: item.calcium,
            iron_fe_mg: item.iron_fe_mg,
            magnesium: item.magnesium,
            potassium_mg: item.potassium_mg,
            sodium_mg: item.sodium_mg,
            zinc_mg: item.zinc_mg,
            vitA_mic_g: item.vitA_mic_g,
            thia_mg: item.thia_mg,
            vitE_mg: item.vitE_mg,
            ribo_mg: item.ribo_mg,
            b3_niac_mg: item.b3_niac_mg,
            b6_mg: item.b6_mg,
            b9_micro_g: item.b9_micro_g,
            vitC_mg: item.vitC_mg,
            vitb12_microg: item.vitb12_microg,
            selenium_microg: item.selenium_microg,
            choline_mg: item.choline_mg,
            food_item_pic_link: "path/to/pic",
          })
          .then((response) => {
            console.log("Response is wow", response.data);
            if (response.data.status === true) {
              importSuccessCount = importSuccessCount + 1;
              setImportError("");
              setImportSuccess(
                importSuccessCount + " Food Items Imported Successfully"
              );
            }
          })
          .catch((error) => {
            importErrorCount = importErrorCount + 1;
            setImportError(importErrorCount + " Food items not imported");
            console.error("An error occurred:", error);
          });
        // if (
        //   response.data.status === true
        // ) {

        //   setImportSuccess("Food Item Imported Successfully")

        // }

        // console.log("result from add food", response);
        // } catch (error) {
        //   setImportError("Food items not imported");
        //   console.error("An error occurred:", error);
        //   // Handle error scenario
        // }
      }
      console.log("food items import", data);
    });
  };
  return (
    <div className="addFoodContainer">
      <Grid container className="addFoodBox">
        <Grid container className="addFoodGrid">
          <CustomTypography
            text={constants.addFood}
            fontSize={30}
            color={"#38A389"}
            className="addFoodTitle"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            paddingBottom: "50px",
            paddingLeft: "60px",
            paddingTop: "30px",
          }}
          className="addFoodForm"
        >
          <CSVReader
            onFileLoaded={handleFile}
            onClick={() => {
              console.log("woww");
            }}
            parserOptions={{ header: true, skipEmptyLines: true }}
          />
          <p style={{ color: "red" }}>{importError}</p>
          <p style={{ color: "green" }}>{importSuccess}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ paddingBottom: "50px", paddingTop: "30px" }}
          className="addFoodForm"
        >
          <a
            style={{ textDecoration: "none", color: "black" }}
            class="ml-2 items-center bg-green-100 hover:bg-green-400  text-sm font-bold rounded px-2 py-1"
            href="https://s3.amazonaws.com/www.kaloriekompass.com/foodItemImport.csv"
          >
            Download Sample File
          </a>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.foodName}
            type="text"
            value={foodName}
            placeholder={"e.g. Apple pie"}
            onChange={(value, isValid) => {
              setFoodName(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.foodWeight + "(g)"}
            type="text"
            value={foodWeight}
            placeholder={"in grams"}
            onChange={(value, isValid) => {
              setFoodWeight(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.quantity}
            type="number"
            value={quantity}
            placeholder={"e.g. 1.0"}
            onChange={(value, isValid) => {
              setQuantity(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.unitOfQuantity}
            type="text"
            value={quantity_unit}
            placeholder={"e.g. cup or plate"}
            onChange={(value, isValid) => {
              setQuantity_unit(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.calories + "(kcal)"}
            type="number"
            value={calories}
            placeholder={"in Kcal"}
            onChange={(value, isValid) => {
              setCalories(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.water + "(g)"}
            type="number"
            value={water}
            placeholder={"in grams"}
            onChange={(value, isValid) => {
              setWater(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.proteins + "(g)"}
            type="number"
            value={protein}
            placeholder={"in grams"}
            onChange={(value, isValid) => {
              setProtein(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.fiber + "(g)"}
            type="number"
            value={fiber}
            placeholder={"in grams"}
            onChange={(value, isValid) => {
              setFiber(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.carbs + "(g)"}
            type="number"
            value={carbs}
            placeholder={"in grams"}
            onChange={(value, isValid) => {
              setCarbs(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.fats + "(g)"}
            type="number"
            value={fats}
            placeholder={"in grams"}
            onChange={(value, isValid) => {
              setFats(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.iron + "(mg)"}
            type="number"
            value={iron}
            placeholder={"in milli-grams"}
            onChange={(value, isValid) => {
              setIron(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.magnesium + "(mg)"}
            type="number"
            value={magnesium}
            placeholder={"in milli-grams"}
            onChange={(value, isValid) => {
              setMagnesium(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.sodium + "(mg)"}
            type="number"
            placeholder={"in milli-grams"}
            value={sodium}
            onChange={(value, isValid) => {
              setSodium(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.zinc + "(mg)"}
            type="number"
            value={zinc}
            placeholder={"in milli-grams"}
            onChange={(value, isValid) => {
              setZinc(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.vitaminA + "(µg)"}
            type="number"
            placeholder={"in micro-grams"}
            value={vitA}
            onChange={(value, isValid) => {
              setVitA(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.thiamin + "(mg)"}
            type="number"
            value={thiamin}
            placeholder={"in milli-grams"}
            onChange={(value, isValid) => {
              setThiamin(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.vitaminE + "(mg)"}
            type="number"
            placeholder={"in milli-grams"}
            value={vitE}
            onChange={(value, isValid) => {
              setVitE(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.riboflavin + "(mg)"}
            type="number"
            value={riboflavin}
            placeholder={"in milli-grams"}
            onChange={(value, isValid) => {
              setRiboflavin(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.vitaminB3Niacin + "(mg)"}
            type="number"
            placeholder={"in milli-grams"}
            value={b3}
            onChange={(value, isValid) => {
              setB3(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.vitaminB6 + "(mg)"}
            type="number"
            value={b6}
            placeholder={"in milli-grams"}
            onChange={(value, isValid) => {
              setB6(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.vitaminB9 + "(µg)"}
            type="number"
            placeholder={"in micro-grams"}
            value={b9}
            onChange={(value, isValid) => {
              setB9(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.vitaminC + "(mg)"}
            type="number"
            value={vitC}
            placeholder={"in milli-grams"}
            onChange={(value, isValid) => {
              setVitC(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.vitaminB12 + "(µg)"}
            type="number"
            placeholder={"in micro-grams"}
            value={vitb12}
            onChange={(value, isValid) => {
              setVitb12(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.selenium + "(µg)"}
            type="number"
            value={selenium}
            placeholder={"in micro-grams"}
            onChange={(value, isValid) => {
              setSelenium(value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.cholines + "(mg)"}
            type="number"
            placeholder={"in milli-grams"}
            value={choline}
            onChange={(value, isValid) => {
              setCholine(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="addFoodForm">
          <MuiTextField
            label={constants.potassium + "(mg)"}
            type="number"
            value={potassium}
            placeholder={"in milli-grams"}
            onChange={(value, isValid) => {
              setPotassium(value);
            }}
          />
        </Grid>{" "}
        {error && <div className="addFoodErrorMessage">{error}</div>}
        <Grid
          container
          justifyContent="flex-end"
          className="addFoodButtonContainer"
          marginRight={6}
        >
          <Grid item className="addFoodButton">
            <AddButton
              buttonVariant="contained"
              buttonColor="primary"
              buttonText={constants.cancel}
              onClick={() => {
                navigate(-1);
              }}
              width={"15vh"}
              margin={"60px"}
              background={"rgba(56, 163, 137, 0.8)"}
            />
          </Grid>
          <Grid item className="addFoodButton">
            <AddButton
              buttonVariant="contained"
              buttonColor="primary"
              buttonText={constants.addFood}
              onClick={handleLogin}
              width={"20vh"}
              margin={"60px"}
              marginLeft={"10px"}
              background={"rgba(56, 163, 137, 0.8)"}
            />
          </Grid>
        </Grid>
        {success && (
          <div className="addFoodSuccessMessage">
            <CustomTypography
              text={constants.foodAddedSuccessfully}
              fontSize={30}
              color={"#38A389"}
              marginLeft={31}
              fontWeight={"bold"}
            />
          </div>
        )}
      </Grid>
    </div>
  );
}

export default AddFood;
