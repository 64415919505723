import { Typography, Container, Button, Grid, Box } from "@material-ui/core/";
import frontimg from "../../../assets/images/introImg.png";
import { useNavigate } from "react-router-dom";
import useStyles from "../../../assets/css/style.js";
import { useEffect, useState } from "react";
import axios from "axios";
import Hidden from "@material-ui/core/Hidden";
import { styled } from "@mui/material/styles";
import constants from "../../../constants.json";

const serverIp = process.env.REACT_APP_SERVERIP;
const port = process.env.REACT_APP_PORT;

const Wrapper = styled(Box)(({ theme }) => ({
  background: "#1976d2",
  width: "100%",
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.up("lg")]: {},
}));

const IntroSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userObject = JSON.parse(localStorage.getItem("user"));
  const [dietPlanReqStatus, setDietPlanReqStatus] = useState();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      const getitems = `${serverIp}:${port}/api/diet-plan-request-exists`;
      axios
        .post(getitems, {
          user_id: userObject.user_id,
        })
        .then((response) => {
          if (response.status) {
            console.log("status", response.data.dietplan);
            setDietPlanReqStatus(response.data.dietplan);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <Wrapper>
      <Container maxWidth={false} disableGutters>
        <Grid
          container
          direction="row"
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#31525B",
            paddingLeft: "10%",
            paddingRight: "5%",
          }}
        >
          <Grid
            item
            sm={12}
            md={5}
            lg={5}
            xs={12}
            container
            direction="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "2",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: "2%",
              }}
              className={classes.container_info2}
              size="lg"
            >
              <Typography
                variant="h4"
                className={classes.text1_intro}
                sx={{
                  fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }, // Responsive font size
                  mb: { xs: 2, sm: 2, md: 3 }, // Responsive margin bottom
                  fontFamily: constants.fontFamily, // Consistent font family
                }}
              >
                {constants.stayHealthyAndMaintainCalorieNeedsWithKalorieKompass}
              </Typography>
              <Typography
                variant="h4"
                className={classes.text2_intro}
                sx={{
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" }, // Responsive font size
                  mb: { xs: 1, sm: 2, md: 3 }, // Responsive margin bottom
                  fontFamily: constants.fontFamily, // Consistent font family
                }}
              >
                {
                  constants.takeControlOfYourCaloriesGoalBreakDownIngredientsAndLongActivitiesWithKalorieKompass
                }
              </Typography>
            </div>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              sm={12}
              md={3}
              lg={3}
              xs={12}
              container
              justifyContent="center"
            >
              <img
                className={classes.imagestyle_intro}
                src={frontimg}
                alt="foodimg"
              />
            </Grid>
          </Hidden>
          <Grid
            container
            direction="row"
            xs={12}
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              backgroundColor: "#31525B",
              maxWidth: "70%",
            }}
          >
            {!localStorage.getItem("accessToken") ? (
              <>
                <Grid item>
                  <Button
                    variant="contained"
                    size="medium"
                    align="center"
                    className={classes.buttonstyle_intro}
                    style={{
                      width: "200px",
                      height: "60px",
                      fontFamily: constants.fontFamily, // Consistent font family
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                    onClick={() => {
                      navigate("/selectGoal", {
                        state: "Get a Diet Plan",
                      });
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: constants.fontFamily, // Consistent font family
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                    >
                      {constants.getaDietPlan}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="medium"
                    align="center"
                    className={classes.buttonstyle_intro}
                    style={{
                      width: "200px",
                      height: "60px",
                      fontFamily: constants.fontFamily, // Consistent font family
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                    onClick={() => {
                      navigate("/selectGoal", {
                        state: "Get a Food Diary",
                      });
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: constants.fontFamily, // Consistent font family
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                    >
                      {constants.getaFoodDiary}
                    </Typography>
                  </Button>
                </Grid>
              </>
            ) : dietPlanReqStatus ? (
              <>
                <Grid>
                  <Button
                    variant="contained"
                    size="medium"
                    align="center"
                    className={classes.buttonstyle_intro}
                    style={{
                      width: "200px",
                      height: "60px",
                      fontFamily: constants.fontFamily, // Consistent font family
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                    onClick={() => {
                      navigate("/dietPlan", {
                        state: "Get a Diet Plan",
                      });
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: constants.fontFamily, // Consistent font family
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                    >
                      {constants.trackMyDietPlan}
                    </Typography>
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Button
                    variant="contained"
                    size="medium"
                    align="center"
                    className={classes.buttonstyle_intro}
                    style={{
                      width: "200px",
                      height: "60px",
                      fontFamily: constants.fontFamily, // Consistent font family
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                    onClick={() => {
                      navigate("/fooddiary", {
                        state: "Get a Food Diary",
                      });
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: constants.fontFamily, // Consistent font family
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                    >
                      {constants.getaFoodDiary}
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default IntroSection;
