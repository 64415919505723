import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useKalorie } from "../../../../userContext/context";

import constants from "../../../../constants.json";
const SimpleBarChart = ({ rec, microNutrients, type }) => {
  const { dateTrackingDiary, menuValue, setMenuValue } = useKalorie();
  // const [menuValue, setMenuValue] = useState("weekly");
  const [day, setday] = useState(1);
  const data = [];
  console.log("check", microNutrients, "the data checking hania", rec, type);
  if (type === "macro") {
    if (menuValue === "weekly") {
      microNutrients.map((items, index) => {
        data.push({
          name: items.date.split("-").reverse().join("-"),
          "Protiens (g)": items.total_protein,
          "Carbs (g)": items.total_carbs,
          "Fats (g)": items.total_fats,
        });
      });
    } else if (menuValue === "daily") {
      microNutrients.map((items, index) => {
        if (items.date === dateTrackingDiary) {
          data.push({
            name: constants.breakfast,
            "Protiens (g)": items?.breakfast_micronutrients?.total_protein,
            "Carbs (g)": items?.breakfast_micronutrients?.total_carbs,
            "Fats (g)": items?.breakfast_micronutrients?.total_fats,
          });
          data.push({
            name: constants.lunch,
            "Protiens (g)": items?.lunch_micronutrients?.total_protein,
            "Carbs (g)": items?.lunch_micronutrients?.total_carbs,
            "Fats (g)": items?.lunch_micronutrients?.total_fats,
          });
          data.push({
            name: constants.snack,
            "Protiens (g)": items?.snacks_micronutrients?.total_protein,
            "Carbs (g)": items?.snacks_micronutrients?.total_carbs,
            "Fats (g)": items?.snacks_micronutrients?.total_fats,
          });
          data.push({
            name: constants.dinner,
            "Protiens (g)": items?.dinner_micronutrients?.total_protein,
            "Carbs (g)": items?.dinner_micronutrients?.total_carbs,
            "Fats (g)": items?.dinner_micronutrients?.total_fats,
          });
        }
      });
    }
  } else if (type === "totalCal") {
    if (menuValue === "weekly") {
      microNutrients.map((items, index) => {
        data.push({
          name: items.date.split("-").reverse().join("-"),
          "Taken Calories (g)": items.total_calories,
          "Recommended Calories (g)": rec,
        });
      });
    } else if (menuValue === "daily") {
      microNutrients.map((items, index) => {
        if (items.date === dateTrackingDiary) {
          data.push({
            name: constants.breakfast,
            "Taken Calories (g)":
              items?.breakfast_micronutrients?.total_calories,
            "Recommended Calories (g)": rec / 4,
          });
          data.push({
            name: constants.lunch,
            "Taken Calories (g)": items?.lunch_micronutrients?.total_calories,
            "Recommended Calories (g)": rec / 4,
          });
          data.push({
            name: constants.snack,
            "Taken Calories (g)": items?.snacks_micronutrients?.total_calories,
            "Recommended Calories (g)": rec / 4,
          });
          data.push({
            name: constants.dinner,
            "Taken Calories (g)": items?.dinner_micronutrients?.total_calories,
            "Recommended Calories (g)": rec / 4,
          });
        }
      });
    }
  }
  console.log("hania here in bar chart", data);

  return (
    <div
      style={{ height: "400px", width: "100%" }}
      className="bg-white rounded-xl shadow-md"
    >
      {/* <div className="px-4">
        <div style={{ justifyContent: "space-around" }} className="flex"> */}
      {/* <p className="font-semibold text-violet-500 py-2 w-10 h-10 align-middle bg-purple-2 rounded-full m-1 p-1 mb-2 text-sm">
                        KWD
                    </p> */}
      {/* <FormControl sx={{ m: 1, minWidth: 100 }} size="small"> */}
      {/* <InputLabel id="demo-select-small-label">Duration</InputLabel> */}
      {/* <Select
              sx={{ height: 30 }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={menuValue}
              // label="Weekly"
              onChange={console.log("hello")}
            >
              <MenuItem
                onClick={() => {
                  setMenuValue("weekly");
                }}
                value={"weekly"}
              >
                {constants.weekly}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuValue("daily");
                }}
                value={"daily"}
              >
                {constants.daily}
              </MenuItem>
            </Select>
          </FormControl>
          {menuValue === "daily" ? (
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small"> */}
      {/* <InputLabel id="demo-select-small-label">Duration</InputLabel> */}
      {/* <Select
                sx={{ height: 30 }}
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={day}
                // label="Weekly"
                onChange={console.log("hello", day)}
              >
                {microNutrients.map((items) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setday(items.day);
                      }}
                      value={items.day}
                    >
                      {constants.day} {items.day}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : null}
        </div> */}
      {/* <h1 className="font-bold text-start text-2xl mb-2">1,234.54 KWD</h1>
                <p className="flex text-sm ">
                    Revenue{" "}
                    <p className="text-lime-600 px-3">
                        <ShowChartIcon /> 7.2%{" "}
                    </p>
                </p> */}
      {/* </div> */}
      <ResponsiveContainer width="100%" height="88%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {type === "macro" ? (
            <>
              <Bar dataKey="Protiens (g)" fill="#FF8042" />
              <Bar dataKey="Carbs (g)" fill="#00C49F" />
              <Bar dataKey="Fats (g)" fill="#FFBB28" />
            </>
          ) : (
            <>
              <Bar dataKey="Taken Calories (g)" fill="#FF8042" />
              <Bar dataKey="Recommended Calories (g)" fill="#00C49F" />
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default SimpleBarChart;
