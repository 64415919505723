import Header from "../../components/kalorieKompassComponents/starterpage/headerTEE";
import IntroSection from "../../components/kalorieKompassComponents/starterpage/formTEE";

import useStyles from "../../assets/css/style";

const StarterPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.outercontainer_starter}>
      <Header />
      <IntroSection />
    </div>
  );
};

export default StarterPage;
