import ProgressTracker from "../../components/kalorieKompassComponents/main/home/progresstracker";

const Home = ({
  setDurationtype,
  setActiveButton,
  durationtype,
  activeButton,
}) => {
  return (
    <>
      <ProgressTracker
        setDurationtype={setDurationtype}
        setActiveButton={setActiveButton}
        durationtype={durationtype}
        activeButton={activeButton}
      />
    </>
  );
};

export default Home;
