import React from "react";
import teamwork from "../../../assets/images/Teamwork.png";
import healthcare from "../../../assets/images/Healthcare.png";
import food from "../../../assets/images/Food.png";
import constants from "../../../constants.json";
import howitworks1 from "../../../assets/images/howitworks1.png";
import howitworks2 from "../../../assets/images/howitworks2.png";
import howitworks3 from "../../../assets/images/howitworks3.png";
import Header from "../../../components/kalorieKompassComponents/starterpage/headerPage";
import Footer from "../../../components/kalorieKompassComponents/starterpage/footer";

const HowItWorks = () => {
  return (
    <>
      <Header />
      <div className="max-w-screen self-center border-t border-gray-400 flex flex-col items-center">
        <h1 className="text-3xl md:text-4xl font-bold text-center p-3 text-primaryColor-light">
          {constants.howitworks}
          <div className="max-w-sm border-t-2 border-orangeColor mt-2"></div>
        </h1>
      </div>

      <div className="divider divider-primary"></div>

      <div className="hero bg-base-200 py-6 lg:py-12">
        <div className="hero-content flex flex-col lg:flex-row-reverse items-center lg:items-start p-4 lg:p-8">
          <img
            src={howitworks1}
            className="w-full max-w-md mb-4 lg:mb-0"
            alt="How It Works 1"
          />
          <div className="text-center lg:text-left lg:pl-6">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-2">
              {constants.howitworksSubHeading1}
            </h1>
            <p className="text-base lg:text-lg">{constants.howitworkspara1}</p>
          </div>
        </div>
      </div>

      <div className="hero bg-base-200 py-6 lg:py-12">
        <div className="hero-content flex flex-col lg:flex-row items-center lg:items-start p-4 lg:p-8">
          <img
            src={howitworks2}
            className="w-full max-w-md mb-4 lg:mb-0"
            alt="How It Works 2"
          />
          <div className="text-center lg:text-left lg:pr-6">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-2">
              {constants.howitworksSubHeading2}
            </h1>
            <p className="text-base lg:text-lg">{constants.howitworkspara2}</p>
          </div>
        </div>
      </div>

      <div className="hero bg-base-200 py-6 lg:py-12">
        <div className="hero-content flex flex-col lg:flex-row-reverse items-center lg:items-start p-4 lg:p-8">
          <img
            src={howitworks3}
            className="w-full max-w-md mb-4 lg:mb-0"
            alt="How It Works 3"
          />
          <div className="text-center lg:text-left lg:pl-6">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-2">
              {constants.howitworksSubHeading3}
            </h1>
            <p className="text-base lg:text-lg">{constants.howitworkspara3}</p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default HowItWorks;
