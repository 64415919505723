/* eslint-disable no-sequences */
import React from "react";
import { Col } from "reactstrap";
import "../styles/blog-item.css";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { WhatsappShareButton } from "react-share";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Container, Row } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import Header from "../components/kalorieKompassComponents/starterpage/headerPage";
import constants from "../constants.json";
import blogBackGround from "../assets/images/blogBackImg.png";
import Footer from "../components/kalorieKompassComponents/starterpage/footer";

import blogs1 from "../assets/images/blogs1.png";
const serverIp = process.env.REACT_APP_SERVERIP;
const port = process.env.REACT_APP_PORT;
const useStyles = makeStyles((theme) => ({
  container_header: {
    width: "90%",
    height: "65px",
    margin: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
  },

  text1_header: {
    color: "#38A389",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "30px",
  },
  buttonstyle_header: {
    height: "40px",
    color: "#38A389",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent", // Set the default background color
    outline: "none",
    "&:hover": {
      fontSize: "1.4rem",
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:active": {
      backgroundColor: "transparent", // Set the background color when the button is pressed
    },
  },
  divider_header: {
    color: "#38A389",
    fontSize: "1.3rem",
    marginTop: "15px",
  },
  buttoncontainer_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  modalContent: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  shareButton: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const FoodBlogsList = ({ reload, setReload, showHeader }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [restart, setRestart] = useState(false);
  const [topTenProteinsObject, setTopTenProteinsObject] = useState();
  const [topTenCalObject, setTopTenCalObject] = useState();
  const [isHeaderVisible, setIsHeaderVisible] = useState();
  const isMatch = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("md")
  );
  console.log("the blogg", showHeader);
  console.log("isHeadervisible", isHeaderVisible);
  useEffect(() => {
    setIsHeaderVisible(showHeader);
    console.log("the blogg 101", showHeader);
  }, [showHeader]);
  useEffect(() => {
    const getitems =
      "https://jbq4swurmf.execute-api.eu-north-1.amazonaws.com/dev/foodBlogs";
    axios
      .get(getitems, {
        headers: {
          authorizationToken:
            "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
        },
      })
      .then((response) => {
        if (response.data) {
          setData(response.data.body);
          console.log("tryyy 101", response.data.body);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    console.log("url", window.location.href.slice(0, -6));

    const getProtienitems = `${serverIp}:${port}/api/top-10-protein`;
    axios
      .get(getProtienitems)
      .then((response) => {
        if (response.status) {
          setTopTenProteinsObject(response.data.topProteinFoods);
          console.log("tryyy111", response.data.topProteinFoods);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const getCalitems = `${serverIp}:${port}/api/top-10-calories`;
    axios
      .get(getCalitems)
      .then((response) => {
        if (response.status) {
          setTopTenCalObject(response.data.topCalorieFoods);
          console.log("tryyy111", response.data.topCalorieFoods);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [reload]);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div
      style={{
        backgroundColor: constants.white,
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          width: "100%",
        }}
      >
        {/* <div style={{ margin: "20px", marginBottom: "50px", display: "flex", justifyContent: "space-between" }}>
                <div>
                    <Button onClick={() => { navigate(-1) }} class="ml-2 items-center bg-green-100 hover:bg-green-400  text-sm font-bold rounded px-2 py-1" variant="outlined" startIcon={<ArrowBackOutlinedIcon />}>
                        Back
                    </Button>
                </div>

            </div> */}
        {/* <div style={{ justifyContent: "center", alignItems: "center" }}>
                <a style={{ textDecoration: "none" }} href="/">
                    <h1 style={{ fontFamily: constants.fontFamily, color: "#06647D", fontSize: "40px", fontWeight: "700" }} className="blog__info p-3">
                        {constants.appTitle}
                    </h1>
                </a>
            </div> */}
        {!localStorage.getItem("user") && (<Header />)}
        <div className="text-5xl text-center mt-10 px-4 sm:px-6 lg:px-8">
          <span className="font-bold">Discover</span>
          <span className="italic font-medium">Wellness</span>
        </div>
        <div className="text-2xl text-center mt-2 font-bold px-4 sm:px-6 lg:px-8">
          Fueling Your Future: A Calorie Counting Lifestyle Blog
        </div>

        <Box
          sx={{
            flexGrow: 1,
            height: "auto",
            width: "100%",
            padding: "0 15px",
          }}
        >
          <TabPanel value={value} index={0}>
            <div className="hero bg-base-200 mb-8">
              <div className="hero-content max-w-9xl mx-auto shadow-md flex flex-col lg:flex-row lg:items-center">
                <img src={blogs1} className="w-full max-w-md rounded-sm" />
                <div className="p-4 lg:pl-6">
                  <div className="text-md text-center lg:text-left">
                    Article
                  </div>
                  <h1 className="text-2xl md:text-3xl lg:text-4xl text-primaryColor-light font-bold text-center lg:text-left">
                    You Don’t Need a Dietitian Until You Do
                  </h1>
                  <p className="py-2 text-center lg:text-left max-w-sm md:max-w-lg mx-auto lg:mx-0">
                    Hitting your 30th Birthday is the best time for you to start
                    looking for a dietitian
                  </p>
                  <div className="text-center lg:text-left max-w-sm md:max-w-md mx-auto lg:mx-0">
                    <span className="font-bold">Category: </span>
                    <span>Meal Planning & Health Prevention</span>
                  </div>
                  <div className="text-center lg:text-left max-w-sm md:max-w-md mx-auto lg:mx-0 my-2">
                    <span className="font-bold">Tags: </span>
                    <span>Food, Fitness</span>
                  </div>
                  <div className="text-center lg:text-left my-4">
                    <button
                      className="bg-primaryColor-light text-whiteColor font-bold py-2 px-4 rounded-full"
                      onClick={() => {
                        navigate(
                          `/userBlogsdetails/420fe386-96bf-46bd-98d0-ff9ba925750f`
                        );
                      }}
                    >
                      View More
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-3/4 w-full">
                <Row>
                  {data.map((item) => (
                    <FoodBlogsItem
                      item={item}
                      key={item.id}
                      reload={reload}
                      setReload={setReload}
                    />
                  ))}
                </Row>
              </div>

              <div
                className="hidden lg:block bg-gray-200 m-4 p-4"
                style={{ backgroundColor: "rgba(245, 245, 245, 0.75)" }}
              >
                <div
                  style={{ backgroundColor: "#FFA62E" }}
                  className="text-center font-bold text-whiteColor text-xl p-2"
                >
                  Top Five Insights
                </div>
                <div className="p-2">
                  <div className="flex">
                    <div className="p-2 max-h-10 text-whiteColor  bg-orangeColor-blogs text-center mr-2 rounded-sm mt-2">
                      1
                    </div>
                    <p className=" text-md font-bold text-justify  max-w-sm p-2">
                      Unlocking the Secrets of Mindful Eating for Weight
                      Management
                    </p>
                  </div>
                  <div class="  border-t-2 border-orangeColor-blogs mt-1 "></div>
                </div>
                <div className="p-2">
                  <div className="flex">
                    <div className="p-2 max-h-10 text-whiteColor  bg-orangeColor-blogs text-center mr-2 rounded-sm mt-2">
                      2
                    </div>
                    <p className="text-justify text-md font-bold p-2">
                      Navigating the Paleo Diet: A Beginner's Comprehensive
                      Guide
                    </p>
                  </div>
                  <div class="  border-t-2 border-orangeColor-blogs mt-1 "></div>
                </div>
                <div className="p-2">
                  <div className="flex">
                    <div className="p-2 max-h-10 text-whiteColor  bg-orangeColor-blogs text-center mr-2 rounded-sm mt-2">
                      3
                    </div>
                    <p className="text-justify text-md font-bold p-2">
                      The Science of Sleep: How Quality Sleep Impacts Your
                      Health and Weight Loss Goals
                    </p>
                  </div>
                  <div class="  border-t-2 border-orangeColor-blogs mt-1 "></div>
                </div>
                <div className="p-2">
                  <div className="flex">
                    <div className="p-2 max-h-10 text-whiteColor  bg-orangeColor-blogs text-center mr-2 rounded-sm mt-2">
                      4
                    </div>
                    <p className="text-justify text-md font-bold p-2">
                      Top 5 Lifestyle Changes for Better Heart Health
                    </p>
                  </div>
                  <div class="  border-t-2 border-orangeColor-blogs mt-1 "></div>
                </div>
                <div className="p-2">
                  <div className="flex">
                    <div className="p-2 max-h-10 text-whiteColor  bg-orangeColor-blogs text-center mr-2 rounded-sm mt-2">
                      5
                    </div>
                    <p className="text-jsutify text-md font-bold p-2">
                      Nourish to Flourish: How a Strategic Diet Plan Can
                      Transform Your Health
                    </p>
                  </div>
                  <div class="  border-t-2 border-orangeColor-blogs mt-1 "></div>
                </div>
              </div>
            </div>
          </TabPanel>

          {topTenProteinsObject && (
            <TabPanel
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              value={value}
              index={1}
            >
              <div className="text-center font-bold text-3xl mb-2 text-primaryColor-light px-4 sm:px-6 lg:px-8">
                Protein Enriched Food Items
              </div>
              <div className="flex justify-center">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-4 sm:px-6 lg:px-8">
                  {topTenProteinsObject.map((items) => (
                    <div className="border-r-primaryColor shadow-md p-2">
                      <div className="text-2xl text-center font-bold">
                        {items.food_name}
                      </div>
                      <div className="flex justify-between items-center py-3">
                        <div className="text-lg text-blueText-blogsCaloriesText">
                          Calories: {items.calories_kcal}Kcal
                        </div>
                        <div className="text-lg text-orangeColor text-center">
                          Proteins: {items.protein_g}g
                        </div>
                        <div className="text-lg text-greenText text-center">
                          Fats: {items.fats_g}g
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>
          )}
          {topTenCalObject && (
            <TabPanel
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              value={value}
              index={2}
            >
              <div className="text-center font-bold text-3xl mb-2 text-primaryColor-light px-4 sm:px-6 lg:px-8">
                Calories Enriched Food Items
              </div>
              <div className="flex justify-center">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-4 sm:px-6 lg:px-8">
                  {topTenCalObject.map((items) => (
                    <div className="border-r-primaryColor shadow-md p-2">
                      <div className="text-2xl text-center font-bold">
                        {items.food_name}
                      </div>
                      <div className="flex justify-between items-center py-3">
                        <div className="text-lg text-blueText-blogsCaloriesText">
                          Calories: {items.calories_kcal}Kcal
                        </div>
                        <div className="text-lg text-orangeColor text-center">
                          Proteins: {items.protein_g}g
                        </div>
                        <div className="text-lg text-greenText text-center">
                          Fats: {items.fats_g}g
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>
          )}

          <div className="text-4xl text-center text-primaryColor-light px-4 sm:px-6 lg:px-8">
            Explore Over 1000 posts!
          </div>
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={{
              borderRight: "1px solid #F5F5F5",
              borderColor: "divider",
              bgcolor: "transparent",
              paddingTop: "3%",
              marginBottom: "10%",
            }}
          >
            <Tab
              style={{
                alignItems: "start",
                color: value === 0 ? "#31525B" : "#31525B",
                backgroundColor:
                  value === 0 ? "rgba(0,181,105,0.2)" : "rgba(0,181,105,0.2)",
                marginBottom: "10px",
                marginLeft: "10px",
                boxShadow: "0px 4px 6px rgba(0.5, 0.5, 0.5, 0.5)",
              }}
              label="All Blogs"
              {...a11yProps(0)}
            />
            <Tab
              style={{
                alignItems: "start",
                color: value === 1 ? "#31525B" : "#31525B",
                backgroundColor:
                  value === 1 ? "rgba(6,100,125,0.19)" : "rgba(6,100,125,0.19)",
                marginBottom: "10px",
                marginLeft: "10px",
                boxShadow: "0px 4px 6px rgba(0.5, 0.5, 0.5, 0.5)",
              }}
              label="Protein Enriched Food Items"
              {...a11yProps(1)}
            />
            <Tab
              style={{
                alignItems: "start",
                color: value === 2 ? "#31525B" : "#31525B",
                backgroundColor:
                  value === 2
                    ? "rgba(255,198,100,0.76)"
                    : "rgba(255,198,100,0.76)",
                boxShadow: "0px 4px 6px rgba(0.5, 0.5, 0.5, 0.5)",
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              label="Calorie Enriched Food Items"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

const FoodBlogsItem = ({ item, setReload, reload }) => {
  const blogDetailsUrl =
    window.location.href.slice(0, -6) + "/userBlogsdetails";
  console.log("url", blogDetailsUrl);
  const [openResponsiveDialog, setOpenResponsiveDialog] = useState(false);
  const [openResponsiveDeleteDialog, setOpenResponsiveDeleteDialog] =
    useState(false);
  // const [openResponsiveDeleteConfirmDialog, setOpenResponsiveDeleteConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const { title, description, date, time, author, category, tags } = item;
  const [commentsCount, setCommentsCount] = useState(0);

  function getFoodBlogsComments(blogId) {
    console.log("joke");
    const getitems = `https://j3xs9qhcnk.execute-api.eu-north-1.amazonaws.com/dev/foodBlogsComments/${blogId}`;
    axios
      .get(getitems, {
        headers: {
          authorizationToken:
            "eyJpdiI6Ik04Rjd1TENYVm5JdlYwcThBVkF6TWc9PSIsCiJ2IjoxLAoiaXRlciI6MTAwMCwKImtzIjoyNTYsCiJ0cyI6NjQsCiJtb2RlIjoiY2NtIiwKImFkYXRhIjoiIiwKImNpcGhlciI6ImFlcyIsCiJzYWx0Ijoib1M1am1kQmJGSnM9IiwKImN0IjoiL0wvZS9vL0dzU0U3UXVvZjdWTTN2eEdWRXp5bSJ9",
        },
      })
      .then((response) => {
        if (response.data) {
          console.log("tryyy125", response.data.body.length);
          setCommentsCount(response.data.body.length);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Col lg="4" md="6" sm="12" className="mb-5">
      <div
        onClick={() => {
          navigate(`/userBlogsdetails/${item.id}`);
        }}
        style={{
          borderRadius: "10px",
          height: "100%",
          backgroundColor: "white",
          cursor: "pointer",
        }}
        className="blog__item shadow-xl"
      >
        <div
          className="blog__info p-3"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%", // Ensure the container takes up the full height available
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <p className="font-bold text-2xl">{title}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <p>
              <strong className="text-primaryColor-light">Category:</strong>{" "}
              <span className="text-primaryColor-light">{category}</span>
            </p>
          </div>

          <div
            className="pt-3"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span className="blog__author">
              <i className="ri-user-line"></i> By {author}
            </span>
            <span className="mt-2">
              <i className="ri-calendar-line"></i> {date}
            </span>
            <span className="mt-2">
              <i className="ri-time-line"></i> {time}
            </span>
          </div>

          <div style={{ marginTop: "auto", textAlign: "right" }}>
            <p className="read__more">Read More{">>"}</p>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default FoodBlogsList;
