import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import _ from "lodash";
function FlipCard({ frontContent, backContent }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const cardStyle = {
    width: "90%",
    height: "8rem",
    background: "white",
    fontSize: "2rem",
    color: "black",
    margin: "20px",
    borderRadius: "10px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  };

  const backStyle = {
    ...cardStyle,
    fontSize: "20px",
  };
  const handleMouseEnter = _.debounce(() => setIsFlipped(true), 200);
  const handleMouseLeave = _.debounce(() => setIsFlipped(false), 200);
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      <div
        style={cardStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {frontContent}
      </div>
      <div
        style={backStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {backContent}
      </div>
    </ReactCardFlip>
  );
}

export default FlipCard;
