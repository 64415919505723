import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Button,
  IconButton,
  TextField,
  Grid,
  Card,
  CardContent,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import CreateIcon from "@material-ui/icons/Create";
import AccordionSection from "./accordion";
import PieChartComp from "./pieChart";
import MealPieChartComp from "./mealCardPieChart";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import useStyles from "../../../../assets/css/fooddiary";
import { useKalorie } from "../../../../userContext/context";
import { useEffect, useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import { ReplyAllOutlined } from "@material-ui/icons";
import axios from "axios";
// import { constants } from "crypto";
import constants from "../../../../constants.json";
// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     flexDirection: "column",
//     width: "100%",
//     marginTop: "20px",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   horizontalcontainer: {
//     display: "flex",
//     flexDirection: "row",
//     width: "100%",
//     marginTop: "30px",
//     alignItems: "center",
//   },
//   horizontalcontainer2: {
//     display: "flex",
//     flexDirection: "row",
//     width: "100%",
//     alignItems: "center",
//     fontFamily: constants.fontFamily,
//   },

//   text1: {
//     color: "#FF7171 !important",
//     fontFamily: constants.fontFamily,
//     fontSize: "1.5rem",
//     margin: "0px 10px 0px 0px",
//   },
//   text2: {
//     color: "#FF7171 !important",
//     fontFamily: constants.fontFamily,
//     fontSize: "1.1rem",
//     margin: "0px 10px 0px 0px",
//   },
//   disabled: {
//     color: "#EBEBE4 !important",
//     fontFamily: constants.fontFamily,
//     fontSize: "1.1rem",
//     margin: "0px 10px 0px 0px",
//   },

//   text3: {
//     color: "#FF7171 !important",
//     fontFamily: constants.fontFamily,
//     fontSize: "1rem",
//     justifyItems: "center",
//     justifyContent: "center",
//     alignItems: "center",
//     marginRight: "15px",
//   },
//   text4: {
//     color: "#A87DB4 !important",
//     fontFamily: constants.fontFamily,
//     fontSize: "1rem",
//     alignItems: "center",
//     inlineSize: "200px",
//   },
//   text5: {
//     color: "#A87DB4 !important",
//     fontFamily: constants.fontFamily,
//     fontSize: "1.1rem",
//   },
//   cardstyle: {
//     width: "95%",
//     height: "100%",
//     borderRadius: "10px",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "2px solid #FFD171",
//     marginLeft: "15px",
//     marginRight: "15px",
//     fontFamily: constants.fontFamily,
//   },
//   verticalcontainer: {
//     display: "flex",
//     flexDirection: "column",
//     width: "250px",
//     justifyContent: "center",
//     alignItems: "center",
//     fontFamily: constants.fontFamily,
//   },
//   verticalcontainer2: {
//     display: "flex",
//     flexDirection: "column",
//   },
//   verticalcontainer3: {
//     display: "flex",
//     flexDirection: "row",
//   },
//   verticalcontainer4: {
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   input: {
//     marginBottom: "0.8rem",
//     width: "300px",
//     color: "#38A389",
//     fontFamily: constants.fontFamily,
//     fontSize: "1.2rem",
//     "&:before": {
//       borderColor: "#38A389",
//     },
//     "&:after": {
//       borderColor: "#38A389",
//     },
//     "&:hover:not($disabled):not($focused):not($error):before": {
//       borderColor: "#38A389 !important",
//     },
//   },
//   notchedOutline: {
//     borderColor: "#38A389 !important",
//   },

//   inputLabel: {
//     color: "#38A389 !important",
//     borderColor: "#38A389",
//     fontFamily: constants.fontFamily,
//     fontSize: "1.2rem",
//     "&.Mui-focused": {
//       color: "#38A389 !important",
//       borderColor: "#38A389",
//       fontFamily: constants.fontFamily,
//       fontSize: "1rem",
//       borderColor: "#38A389",
//     },
//   },
//   focusedStyle: {
//     borderColor: "#38A389 !important",
//     labelColor: "#38A389 !important",
//   },
//   buttonstyle: {
//     alignSelf: "center",
//     marginTop: "1rem",
//     color: "white",
//     fontWeight: "bold",
//     border: "1px solid white",
//     fontFamily: constants.fontFamily,
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: "#FF7171",
//     borderRadius: "30px",
//     fontSize: "12px",
//     height: "30px",

//     transition: "background-color 0.3s",
//     "&:hover": {
//       backgroundColor: constants.blueTextColor,
//     },
//     "&:focus": {
//       backgroundColor: constants.blueTextColor,
//       outline: "none",
//     },
//   },
//   getdetailsbutton: {
//     alignSelf: "flex-end",
//   },
//   nutrientKey: {
//     color: "#333",
//   },

//   nutrientValue: {
//     color: "#38A389",
//   },
// }));
const FoodAdder = ({
  durationtype,
  selectedButtonContent,
  rec,
  setReload,
  reload,
  diet,
  foodObjects,
}) => {
  const classes = useStyles();
  console.log("Duration Type:", durationtype);
  console.log("Selected Button Content:", selectedButtonContent);
  const [expanded, setExpanded] = useState(false);
  const [meal, setMeal] = useState("");
  const {
    data,
    dialyProtein,
    proteins,
    setProteins,
    fats,
    setFats,
    dialyFats,
    setCarbs,
    dialyCarbs,
    setDialyCarbs,
  } = useKalorie();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const date = `${year}-${month}-${day}`;
  const [showaccordion, setShowAccordion] = useState(false);
  const [selectedMealType, setSelectedMealType] = useState(null);
  const [mealInfo, setMealInfo] = useState([]);
  const [err, setErr] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAccordion = async (mealtype) => {
    console.log("Clicked Meal Type:", diet.breakfasts);

    setMeal(mealtype);
    if (selectedMealType === mealtype) {
      // If the clicked meal type is the same as the selected one, close the accordion
      setShowAccordion(false);
      setSelectedMealType(null); // Reset selected meal type
    } else {
      // If the clicked meal type is different, open the accordion
      setShowAccordion(true);
      setSelectedMealType(mealtype); // Set the selected meal type
    }
  };

  const MealCard = ({
    selectedButtonContent,
    mealType,
    onAddClick,
    rec,
    diet,
  }) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pieChartData, setPieChartData] = useState({});
    const [takenCal, setTakenCal] = useState(0);
    const [recCal, setRecCal] = useState(0);
    console.log("mealType", mealType);

    const handleCloseDialog = () => {
      setDialogOpen(false);
    };
    useEffect(() => {
      console.log("newww1", diet);
      setRecCal(rec.recommended_calories_intake);
      let sum = 0;
      console.log("how", diet);
      if (mealType === "Breakfast") {
        diet.breakfast?.map((items, index) => {
          sum += items.calories_kcal;
        });
      } else if (mealType === "Snack") {
        diet.snack?.map((items, index) => {
          sum += items.calories_kcal;
        });
      } else if (mealType === "Lunch") {
        diet.lunch?.map((items, index) => {
          sum += items.calories_kcal;
        });
      } else if (mealType === "Dinner") {
        diet.dinner?.map((items, index) => {
          sum += items.calories_kcal;
        });
      }
      console.log("heeloo", sum);
      setTakenCal(sum);
    }, []);

    return (
      <Card className={classes.cardstyle}>
        <CardContent>
          <div className={classes.horizontalcontainer2}>
            <IconButton
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              <AddCircleIcon className={classes.text1} />
            </IconButton>
            <Typography variant="h5" component="div" className={classes.text1}>
              {selectedButtonContent}
            </Typography>
          </div>
          <Typography sx={{ mb: 1.5 }} className={classes.text5}>
            {constants.recommendedCalories}: {recCal / 4} kcl
          </Typography>
          <Typography sx={{ mb: 1.5 }} className={classes.text5}>
            {constants.takenCalories}: {takenCal} kcl
          </Typography>
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <MealPieChartComp diet={diet} mealType={mealType} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              className={[classes.buttonstyle, classes.getdetailsbutton]}
              onClick={() => {
                handleAccordion(mealType);
              }}
            >
              {constants.getDetails}
            </Button>
          </div>
        </CardContent>
        {foodObjects && (
          <MealDialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            meal={mealType}
            foodObjects={foodObjects}
          />
        )}
      </Card>
    );
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("enterManually");

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const MealDialog = ({ open, onClose, meal, foodObjects, setFoodObjects }) => {
    const [manualDialogOpen, setManualDialogOpen] = useState(true);
    const [pictureDialogOpen, setPictureDialogOpen] = useState(false);
    const [foodName, setFoodName] = useState("");
    const [foodWeight, setFoodWeight] = useState("1");
    const [foodId, setFoodId] = useState();
    const [displayIngredients, setDisplayIngredients] = useState(false);
    const [nutrientData, setNutrientData] = useState(null);
    const [mess, setMess] = useState(false);
    const [pieChartDetails, setPieChartDetails] = useState(null);
    // const [foodObjects, setFoodObjects] = useState([]);
    const [uploadPictureDisabled, setUploadPictureDisabled] = useState(true);
    const [portionList, setPortionList] = useState([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
    ]);

    const fetchFoodId = async (foodName) => {
      try {
        const response = await fetch(apiUrl + "/api/get-food-details", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            food_name: foodName,
          }),
        });

        const result = await response.json();
        setFoodId(result.foodDetails.id);
        console.log("foodID", result);
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
        throw error;
      }
    };

    const handleAddManually = () => {
      // setFoodName("");
      // fetchFoodNames();
      // setFoodWeight("1");
      // setManualDialogOpen(true);
      // setDisplayIngredients(false);
    };
    useEffect(() => {
      handleAddManually();
    });
    const handleCloseManualDialog = () => {
      setFoodWeight("1");
      // setManualDialogOpen(false);
      setDisplayIngredients(false);
      onClose();
    };

    var scaledNutrientDetails = {};
    const handleConfirmManual = async () => {
      console.log("Food Name:", foodName);
      console.log("Food Weight:", foodWeight);

      // Calculate scaled nutrient details based on entered weight
      // try {
      //   scaledNutrientDetails = await fetchScaledNutrientDetails(
      //     foodName,
      //     foodWeight
      //   );
      //   console.log("Scaled Nutrient Details:", scaledNutrientDetails);
      // } catch (error) {
      //   console.error("Error:", error.message);
      //   // Handle the error scenario here
      // }
      console.log("meal typeee", data.diet_plan_id, {
        diet_plan_id: data.diet_plan_id,
        month: 1,
        week: data.week,
        day: data.day,
        meal_type: meal,
        food_item_id: foodId,
        quantity: foodWeight,
      });
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const response = await fetch(apiUrl + "/api/add-meal", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            diet_plan_id: data.diet_plan_id,
            month: 1,
            week: durationtype.charAt(durationtype.length - 1),
            day: selectedButtonContent.index,
            meal_type: meal,
            food_item_id: foodId,
            quantity: foodWeight,
          }),
        });

        const result = await response.json();
        // console.log(result.status);

        if (result.status === true) {
          console.log("response", result);
          setMess(true);

          console.log("message", mess);

          async function nutrientInfo() {
            try {
              const response = await fetch(apiUrl + "/api/meal-tracker", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: data.email,
                }),
              });

              const result = await response.json();
              console.log(result);

              if (result.status === true) {
                console.log(
                  "result protein food adder ",
                  result.results[0].total_protein
                );
                console.log("proootein food adder", dialyProtein);
                const protein_percent =
                  (result.results[0].total_protein /
                    (dialyProtein * 0.129598)) *
                  100;
                console.log("percentage protein food adder :", protein_percent);
                setProteins(protein_percent);

                console.log(
                  "result fats food adder ",
                  result.results[0].total_fats
                );
                console.log("fats food adder", dialyFats);
                const fat_percent =
                  (result.results[0].total_fats / (dialyFats * 0.129598)) * 100;
                console.log("percentage fats food adder :", fat_percent);
                setFats(fat_percent);

                console.log(
                  "result carbs food adder ",
                  result.results[0].total_carbs
                );
                console.log("carbs food adder", dialyCarbs);
                const carbs_percent =
                  (result.results[0].total_carbs / (dialyCarbs * 0.129598)) *
                  100;
                console.log("percentage carbs food adder :", carbs_percent);
                setCarbs(carbs_percent);
              } else {
                setProteins(0);
                console.log("error");
              }
            } catch (error) {
              console.error("An error occurred:", error);
              // Handle error scenario
            }
          }
          nutrientInfo();
        } else {
          // setError(result.message);
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
      }

      // handleCloseManualDialog();
    };

    const handleUploadPicture = () => {
      setPictureDialogOpen(true);
    };

    const handleClosePictureDialog = () => {
      setPictureDialogOpen(false);
    };

    const handleConfirmPicture = () => {
      // Add your logic for handling "Confirm" button in picture dialog
      console.log("Confirm Picture clicked");
      handleClosePictureDialog(); // Close the picture dialog after handling
    };

    const handleImageUpload = (event) => {
      const uploadedImage = event.target.files[0];
      // Perform actions with the uploaded image, e.g., display a preview, send to the server, etc.
      console.log("Uploaded Image:", uploadedImage);
    };

    const fetchScaledNutrientDetails = async (foodName, foodWeight) => {
      try {
        const response = await fetch(apiUrl + "/api/get-food-details", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            food_name: foodName,
          }),
        });

        const result = await response.json();
        console.log("jog", result);

        if (result.status === true) {
          const foodDetails = result.foodDetails;
          console.log("foodDetails", foodDetails);
          setFoodId(foodDetails.id);

          // Calculate scaled nutrient details based on entered weight
          const scaledNutrientDetails = {};
          for (const key in foodDetails) {
            if (key !== "food_name") {
              const scaledValue = foodDetails[key];
              scaledNutrientDetails[key] = foodDetails[key]; // Round to 2 decimal places
            }
            if (key === "quantity" && foodDetails["quantity_unit"]) {
              scaledNutrientDetails[
                key
              ] = `${foodDetails[key]} ${foodDetails["quantity_unit"]}`;

              console.log("heyy", foodDetails["quantity_unit"]);
              console.log("heyy", scaledNutrientDetails[key]);
            }
          }

          return scaledNutrientDetails;
        } else {
          // Handle the case where the API request was not successful
          throw new Error(result.message);
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
        throw error;
      }
    };

    const filterNonZeroAndNaN = (nutrientDetails) => {
      const filteredDetails = {};
      for (const key in nutrientDetails) {
        if (
          key !== "food_name" &&
          isFinite(nutrientDetails[key]) &&
          nutrientDetails[key] !== 0 &&
          nutrientDetails[key] !== 0.0
        ) {
          filteredDetails[key] = nutrientDetails[key];

          // Check if the key is "quantity" and update quantity_unit accordingly
        }
        if (key === "quantity") {
          filteredDetails[key] = nutrientDetails[key];
        }
      }
      return filteredDetails;
    };
    var scaledNutrientDetails = {};
    const handleGetIngredients = async () => {
      console.log("Food Name:", foodName);
      console.log("Food Weight:", foodWeight);

      try {
        const scaledNutrientDetails = await fetchScaledNutrientDetails(
          foodName,
          foodWeight
        );
        console.log("Scaled Nutrient Details:", scaledNutrientDetails);
        delete scaledNutrientDetails.id;
        delete scaledNutrientDetails.user_id;
        // Update the nutrientData state with the fetched data
        setNutrientData(scaledNutrientDetails);
        setPieChartDetails(scaledNutrientDetails);

        // Update the filtered nutrient details by filtering out non-zero and non-NaN values
        const filteredDetails = filterNonZeroAndNaN(scaledNutrientDetails);
        console.log("Filtered Nutrient Details:", filteredDetails);
        setNutrientData(filteredDetails);
        setDisplayIngredients(true); // Display the nutrient details
      } catch (error) {
        console.error("Error:", error.message);
        // Handle the error scenario here
      }
      console.log("meal typeee", meal);
    };

    const IngredientsInfoComponent = ({
      foodName,
      foodWeight,
      nutrientData,
      pieChartData,
    }) => {
      console.log("pieChartData", pieChartData);
      console.log("foodName", foodName);
      console.log("foodWeight", foodWeight);
      const formatNutrientKey = (key) => {
        // Remove "_g" suffix if it exists
        let formattedKey = key.replace(/_g$/, "");

        // Capitalize the first letter
        formattedKey =
          formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);

        // Display only the first word for keys with underscores
        if (formattedKey.includes("_")) {
          formattedKey = formattedKey.split("_")[0];
        }

        return formattedKey;
      };

      const formatNutrientValue = (value) => {
        // Add " g" after the value
        return `${value} g`;
      };

      return (
        <Container>
          <Grid container spacing={0.5} className={classes.verticalcontainer3}>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              // className={classes.verticalcontainer3}
            >
              <Typography className={classes.text1}>
                Nutrient Composition
              </Typography>

              {nutrientData && (
                <div>
                  {Object.entries(nutrientData).map(([key, value]) => (
                    <div key={key}>
                      <span className={classes.nutrientKey}>
                        {formatNutrientKey(key)}:{"  "}
                      </span>
                      <span className={classes.nutrientValue}>
                        {formatNutrientValue(value)}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              className={classes.verticalcontainer4}
            >
              <PieChartComp apiData={pieChartData} foodWeight={foodWeight} />
            </Grid>
          </Grid>
        </Container>
      );
    };

    return (
      <Dialog open={open} onClose={onClose}>
        {mess ? (
          <Dialog open={open} onClose={onClose}>
            <DialogTitle className={classes.text1}>
              {constants.addFood}
            </DialogTitle>
            <DialogContent className={classes.verticalcontainer}>
              <Typography>{constants.mealAddedSuccessfully}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose();
                  setReload(!reload);
                }}
                className={classes.buttonstyle}
              >
                {constants.close}
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <>
            {/* <DialogTitle className={classes.text1}>Add Food</DialogTitle> */}
            <DialogTitle className={classes.text1}>
              {constants.searchFood}
            </DialogTitle>
            <div>
              <ToastContainer />
            </div>
            <DialogContent className={classes.verticalcontainer2}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[...foodObjects]}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setFoodName(newValue);
                    fetchFoodId(newValue);
                    handleGetIngredients();
                  }
                  // Update the context's foodName
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Food Name"
                    variant="standard"
                    type="text"
                    margin="normal"
                    value={foodName}
                    onChange={(e) => setFoodName(e.target.value)}
                    className={classes.input}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: classes.inputLabel,
                      },
                    }}
                    //   InputProps={{
                    //     classes: {
                    //       notchedOutline: classes.notchedOutline,
                    //     },
                    //   }}
                  />
                )}
              />
              <TextField
                label="Food Weight (in grams)"
                variant="filled"
                value={foodWeight}
                onChange={(e) => setFoodWeight(e.target.value)}
                type="number"
                fullWidth
                margin="normal"
                className={classes.input}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: classes.inputLabel,
                  },
                }}
                // InputProps={{
                //   classes: {
                //     notchedOutline: classes.notchedOutline,
                //   },
                // }}
                style={{ backgroundColor: "#F5F5F5", borderRadius: "2%" }}
              />
              {displayIngredients && (
                <>
                  <IngredientsInfoComponent
                    foodName={foodName}
                    foodWeight={foodWeight}
                    nutrientData={nutrientData}
                    pieChartData={pieChartDetails}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseManualDialog}
                className={classes.buttonstyle}
              >
                {constants.cancel}
              </Button>
              {/* {displayIngredients === true ? ( */}
              <Button
                onClick={handleConfirmManual}
                className={classes.buttonstyle}
              >
                {constants.addMeal}
              </Button>
              {/* ) : ( */}
              <Button
                onClick={handleGetIngredients}
                className={classes.buttonstyle}
              >
                {constants.getNutrients}
              </Button>
              {/* )} */}
            </DialogActions>
            {/* <DialogContent className={classes.verticalcontainer}>
                              <Button onClick={handleAddManually} className={classes.text2}>
                                  <CreateIcon />
                                  Enter Manually
                              </Button>
                              <Button
                                  onClick={handleUploadPicture}
                                  className={classes.disabled}
                                  disabled={uploadPictureDisabled}
                              >
                                  <PublishIcon />
                                  Upload Picture
                              </Button>
                          </DialogContent>
                          <DialogActions>
                              <Button onClick={onClose} className={classes.buttonstyle}>
                                  Cancel
                              </Button>
                          </DialogActions> */}

            {/* Manual Entry Dialog */}

            {/* Picture Upload Dialog */}
            <Dialog open={pictureDialogOpen} onClose={handleClosePictureDialog}>
              <DialogTitle className={classes.text1}>
                {constants.uploadPicture}
              </DialogTitle>
              <DialogContent>
                <input
                  type="file"
                  accept="image/*"
                  className={classes.input}
                  onChange={handleImageUpload} // Replace with your image upload handler
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClosePictureDialog}
                  className={classes.buttonstyle}
                >
                  {constants.cancel}
                </Button>
                <Button
                  onClick={handleConfirmPicture}
                  className={classes.buttonstyle}
                >
                  {constants.confirm}
                </Button>
                <Button className={classes.buttonstyle}>
                  {constants.getNutrients}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Dialog>
    );
  };
  const handleAddIconClick = (mealType) => {
    console.log("Meal Type Clicked:", mealType);
    setMeal(mealType);
  };

  return (
    <Container>
      <Grid
        style={{ justifyContent: "space-around", alignItems: "center" }}
        spacing={0.5}
        container
      >
        <Grid item sm={12} md={5} lg={3} style={{ marginBottom: "15px" }}>
          <MealCard
            selectedButtonContent="Breakfast"
            mealType="Breakfast"
            onAddClick={handleAddIconClick}
            day={selectedButtonContent.index}
            rec={rec}
            diet={diet}
          />
        </Grid>
        <Grid item sm={12} md={5} lg={3} style={{ marginBottom: "15px" }}>
          <MealCard
            selectedButtonContent="Lunch" /* Example value for selectedButtonContent */
            mealType="Lunch"
            onAddClick={handleAddIconClick}
            day={selectedButtonContent.index}
            rec={rec} /* Pass the recommendedCalories value */
            diet={diet}
          />
        </Grid>
        <Grid item sm={12} md={5} lg={3} style={{ marginBottom: "15px" }}>
          <MealCard
            selectedButtonContent="Dinner" /* Example value for selectedButtonContent */
            mealType="Dinner"
            onAddClick={handleAddIconClick}
            day={selectedButtonContent.index}
            rec={rec} /* Pass the recommendedCalories value */
            diet={diet}
          />
        </Grid>
        <Grid item sm={12} md={5} lg={3} style={{ marginBottom: "15px" }}>
          <MealCard
            selectedButtonContent="Snack" /* Example value for selectedButtonContent */
            mealType="Snack"
            onAddClick={handleAddIconClick}
            day={selectedButtonContent.index}
            rec={rec} /* Pass the recommendedCalories value */
            diet={diet}
          />
        </Grid>
      </Grid>
      {showaccordion && (
        <>
          {diet && (
            <>
              <AccordionSection
                mealType={diet} // Pass the selected meal type
                selectedButtonContent={meal}
                setReload={setReload}
                reload={reload}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default FoodAdder;
