import React, { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, TextField, Modal, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ReportIcon from "@mui/icons-material/Report";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Typography } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #A87BD4",
  borderRadius: "15px",
  boxShadow: 24,
  p: 1,
};

export default function RoleManager() {
  const [role, setrole] = useState("");
  const [open, setOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roletitle, setroletitle] = useState("");
  const [roleid, setroleid] = useState(0);
  const [error, setError] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [delopen, setdelopen] = useState(false);
  const [userid, setuserid] = useState("");
  const handledelopen = (id) => {
    setuserid(id);
    setdelopen(true);
  };
  const handledelclose = () => {
    setdelopen(false);
  };
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  const url = process.env.REACT_APP_URL;
  console.log(url);
  const apiUrl = `${serverIp}:${port}/api/roles`;
  const handlesubmit = () => {
    const postData = {
      title: role,
    };
    axios
      .post(apiUrl, postData)
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          console.log("POST request successful:", response.data);
          axios
            .get(apiUrl)
            .then((response) => {
              console.log("response", response);
              // Handle the successful response here
              setData(response.data.roles);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
              // Handle any errors that occurred during the request
              setError(error);
              setLoading(false);
            });
          // alert(response.data.message);
          toast(response.data.message, {
            icon: (
              <svg
                color="green"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            ),
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
              // Replace #ff0000 with your desired background color
              color: "#03045E",
              fontSize: "15px", // Replace #ffffff with your desired text color
            },
          });
        }
      })
      .catch((error) => {
        toast(error.response.data.message, {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                color="red"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
          ),
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            // Replace #ff0000 with your desired background color
            color: "#03045E",
            fontSize: "17px", // Replace #ffffff with your desired text color
          },
        });
        // Handle any errors that occurred during the request
        console.log("POST request error:", error);
      });
    setrole("");

    handleClose();
  };
  useEffect(() => {
    const apiUrl = `${serverIp}:${port}/api/roles`;

    axios
      .get(apiUrl)
      .then((response) => {
        console.log("response", response);
        // Handle the successful response here
        setData(response.data.roles);
        setLoading(false);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        setError(error);
        setLoading(false);
      });
  }, []);
  const handleDelete = (id) => {
    console.log(id);
    const delUrl = `${serverIp}:${port}/api/roles/${id}`;
    axios
      .delete(delUrl)
      .then((response) => {
        if (response.status) {
          handledelclose();
          console.log(response);
          // Handle the successful response here
          console.log("DELETE request successful:", response.data);
          axios
            .get(apiUrl)
            .then((response) => {
              console.log("response", response);
              // Handle the successful response here
              setData(response.data.roles);
              setLoading(false);
            })
            .catch((error) => {
              // Handle any errors that occurred during the request
              setError(error);
              setLoading(false);
            });
          toast(response.data.message, {
            icon: (
              <svg
                color="green"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            ),
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
              // Replace #ff0000 with your desired background color
              color: "#03045E",
              fontSize: "15px", // Replace #ffffff with your desired text color
            },
          });
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("DELETE request error:", error);
      });
  };
  console.log(data);
  const handlesavechanges = () => {
    const updateUrl = `${serverIp}:${port}/api/roles/${roleid}`;
    const updatedRole = { title: roletitle };
    axios
      .put(updateUrl, updatedRole)
      .then((response) => {
        console.log("PUT request successful:", response.data);
        // alert(response.data.message);
        axios
          .get(apiUrl)
          .then((response) => {
            console.log("response", response);
            // Handle the successful response here
            setData(response.data.roles);
            setLoading(false);
          })
          .catch((error) => {
            // Handle any errors that occurred during the request
            setError(error);
            setLoading(false);
          });
        toast(response.data.message, {
          icon: (
            <svg
              color="green"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          ),
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            // Replace #ff0000 with your desired background color
            color: "#03045E",
            fontSize: "15px", // Replace #ffffff with your desired text color
          },
        });
      })
      .catch((error) => {
        console.error("PUT request error:", error);
      });
    setEditModalOpen(false);
  };
  const handleModalClose = () => setEditModalOpen(false);
  const handleModalOpen = (role) => {
    if (role) {
      setroleid(role.id);
      setroletitle(role.title);
      setEditModalOpen(true);
    }
  };
  const buttonStylered = {
    backgroundColor: "#06647D",
    color: "white",
    "&:hover": {
      backgroundColor: "#B4EADD", // Ensures hover doesn't change the color
    },
  };
  return (
    <div className="ml-10 mr-10 mt-10">
      <div>
        <h1 className="text-xl font-semibold text-[#31525B] mt-2">
          Role Manager
        </h1>
      </div>
      <div className="flex justify-end">
        <Button variant="contained" style={buttonStylered} onClick={handleOpen}>
          Add Role
        </Button>
      </div>
      <ToastContainer />
      <div className="mt-3">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="bg-[#31525B] cursor-pointer text-white uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">ID </th>
              <th className="py-3 px-6 text-center">Role</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {data.map((role) => (
              <tr
                key={role.id}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <td className="py-3 px-6 text-left text-sm">
                  {/* <div className="ml-2"> */}
                  {role.id}
                  {/* </div> */}
                </td>
                <td className="py-3 px-6 text-center">
                  <span
                    style={{ minWidth: "100px" }}
                    className="bg-[#b9eed8] text-[#00B569] border-[#00B569] border-2 py-1 px-3 rounded-full text-sm"
                  >
                    {role.title}
                  </span>
                </td>
                <td className="py-3 px-6 text-center">
                  <div className="flex item-center justify-center">
                    <div className="flex justify-center items-center w-8 h-8  rounded-full bg-[#F8F3E9] mr-2 transform cursor-pointer hover:text-purple-500 hover:scale-120">
                      <EditIcon
                        className="w-10 h-10"
                        onClick={() => handleModalOpen(role)}
                        sx={{ color: "#38A389" }}
                      />
                    </div>
                    <div className="flex justify-center items-center w-8 h-8  rounded-full mr-2 transform cursor-pointer bg-[#F8F3E9] hover:text-purple-500 hover:scale-120">
                      <ClearIcon
                        className="w-10 h-10"
                        onClick={() => handledelopen(role.id)}
                        sx={{ color: "red" }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {editModalOpen ? (
          <Modal
            open={editModalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>
                <IconButton
                  onClick={() => {
                    setEditModalOpen(false);
                  }}
                  color="primary"
                  aria-label="Delete"
                >
                  <CloseOutlinedIcon style={{ color: "red" }} />
                </IconButton>
              </div>
              <div className="row p-4">
                <div class="col space-y-4">
                  <Typography>Enter Role *</Typography>
                  <TextField
                    required
                    className="mb-4 inputfield"
                    size="small"
                    color="secondary"
                    type="text"
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    value={roletitle}
                    onChange={(e) => setroletitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12 d-flex justify-content-center">
                  <Button
                    onClick={handlesavechanges}
                    variant="contained"
                    style={buttonStylered}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        ) : (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="row">
                <div class="col">
                  <TextField
                    required
                    className="mb-4 inputfield"
                    size="small"
                    color="secondary"
                    type="text"
                    fullWidth
                    id="outlined-basic"
                    label="Enter Role"
                    variant="outlined"
                    value={role}
                    onChange={(e) => setrole(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12 d-flex justify-content-center">
                  <Button
                    onClick={handlesubmit}
                    variant="contained"
                    style={buttonStylered}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        )}
        {userid && (
          <Modal
            open={delopen}
            onClose={handledelclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>
                <IconButton
                  onClick={() => {
                    handledelclose();
                  }}
                  color="primary"
                  aria-label="Delete"
                >
                  <CloseOutlinedIcon style={{ color: "red" }} />
                </IconButton>
              </div>
              <div className="p-2">
                <p className="text-[#FF7171] text-xl">
                  <span className=" mr-1">
                    <ReportIcon className="w-12 h-12 mb-2 mr-1" />
                  </span>
                  Are you sure? you want to delete it.
                </p>
                <div className="flex justify-end mt-2">
                  <button
                    onClick={() => handleDelete(userid)}
                    className="py-1 rounded-2xl px-3 bg-[#06647D] text-white font-bold tracking-widest"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </div>
  );
}
