import { makeStyles } from "@material-ui/core/styles";
import constants from "../../constants.json";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    backgroundColor: "#31525B",
    position: "relative",
    zIndex: 2,
    overflowX: "hidden",
  },

  outercontainer_info2: {
    height: "auto",
    maxHeight: "5rem",
    maxWidth: "20rem",
    backgroundColor: "#31525B",
    padding: 30,

    // position: "relative",
    // zIndex: 2,
  },
  // Triangle on the left
  triangleLeft: {
    position: "absolute",
    bottom: 0,
    left: 0,

    width: 0,
    height: 0,
    borderBottom: "35rem solid #FFC664",
    borderRight: "90rem solid transparent",
    zIndex: -3,
  },

  // Triangle on the right
  triangleRight: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: 0,
    height: 0,
    borderBottom: "43rem solid #FDA101",
    borderLeft: "102rem solid transparent",

    zIndex: -1,
  },
  container_info2: {
    width: "100vh",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  innercontainer_info2: {
    width: "85%",
    height: "85%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    marginTop: "4rem",
  },
  largecontainer_info2: {
    width: "600px",
    height: "90%",
    justifyContent: "center",
    alignItems: "center",

    display: "flex",
    flexDirection: "column",

    alignSelf: "center",
    backgroundColor: "rgba(255, 209, 113, 0.8)",
    borderRadius: "40px",
  },
  smallcontainer_info2: {
    width: "400px",
    height: "65%",
    justifyContent: "center",

    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    backgroundColor: "rgba(255, 113, 119, 0.7)",
    zIndex: 1,
    borderRadius: "40px",
    marginRight: "-7rem",
  },
  text1_info2: {
    color: "#38A389",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "30px",
    textAlign: "center",
  },
  text2_info2: {
    color: "#38A389",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "28px",
    textAlign: "center",
    marginBottom: "-2rem",
  },
  labelstyle_info2: {
    color: constants.blueTextColor,
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
    // textAlign: "center",
  },
  labelstyle2_info2: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "2rem",
    maxWidth: 270,
    marginLeft: "-1rem",
    marginBottom: "1.5rem",
    marginTop: "2%",
  },
  inputLabelStyle: {
    maxWidth: 290,
    "&.Mui-focused": {
      color: constants.blueTextColor,
      borderColor: constants.blueTextColor,
      // fontFamily: constants.fontFamily,
      fontSize: "1.2rem",
      // borderColor: constants.blueTextColor,
    },
  },
  labelstyle2_info3: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "2rem",
    // paddingBottom: "2.5rem",
    // marginTop: "3%",
  },
  topMargin: {
    marginTop: "-28%",
  },
  itemStyle: {
    marginBottom: "2rem",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: "15px",
    padding: "10px",
    width: "18rem",
    height: "55vh",
    // border: "2px solid black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 2%",
    boxShadow: "2px 2px 2px 2px #D9D9D9",
  },
  itemDietPlanStyle: {
    marginBottom: "1.2rem",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: "15px",
    padding: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  profileImg: {
    height: "13rem",
    width: "13rem",
    flex: "0.3",
  },
  outerItemStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
  },
  text4: {
    color: constants.black,
    fontFamily: constants.fontFamily,
    fontSize: "2rem",
    height: "10%",
    flex: "0.15",
  },
  text5: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
    textAlign: "center",
    marginTop: "5%",
    flex: "0.43",
  },
  labelstyle3_info2: {
    color: "rgba(6, 100, 125, 0.86) !important",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "1.22rem",
    textAlign: "center",
  },

  radio_info2: {
    // Adjust radio circle size
    width: "10px",
    height: "10px",
    color: "rgba(6, 100, 125, 0.86) !important", // White color for radio buttons
    marginRight: "1rem", // Margin between different labels
    "&:hover": {
      backgroundColor: "transparent", // Remove hover background color
      label: {
        color: constants.blueTextColor, // Change color of the hovered circle
      },
    },
    "&.Mui-checked": {
      color: constants.blueTextColor, // Change color of the checked circle
    },
  },

  logo_info2: {
    width: "100px",
    height: "100px",
    alignSelf: "center",
  },
  signUpButton: {
    color: "white",
    fontFamily: constants.fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: constants.blue,
    borderRadius: "1rem",
    fontSize: "1.25rem",
    height: "3rem",
    width: "18.5rem",
    alignSelf: "center",
    marginTop: "2.5rem",
    "&:hover": {
      backgroundColor: constants.orange,
      color: "white",
    },
  },
  buttonstyle_info2: {
    color: constants.blueTextColor,
    fontWeight: "bold",
    border: "1px solid white",
    fontFamily: constants.fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FDA101",
    borderRadius: "1.5rem",
    fontSize: "1.2rem",
    height: "3rem",
    width: "9rem",
    alignSelf: "center",
    marginTop: "2.5rem",
    "&:hover": {
      backgroundColor: constants.blue,
      color: "white",
    },
  },
  continueStyle: {
    width: "12rem",
    color: constants.white,
    backgroundColor: constants.primaryColor,
    border: "none",
    borderRadius: "1rem",
  },
  continueStyle1: {
    width: "14rem",
    color: constants.white,
    backgroundColor: constants.primaryColor,
    border: "none",
    borderRadius: "1rem",
  },
  margincontainer_info2: {
    padding: "0.5rem 0",
  },
  margincontainer_info3: {
    padding: "0.5rem 0.7rem",
  },
  margincontainer_info4: {
    padding: "0.2rem 0.7rem",
  },
  buttonstyle_info4: {
    width: "11rem",
    backgroundColor: constants.orange,
  },
  buttonstyle3: {
    alignSelf: "center",
    marginTop: "1rem",
    color: constants.blueTextColor,
    // fontWeight: "bold",
    border: "1px solid #38A389",
    fontFamily: constants.fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFC664",
    borderRadius: "10px",
    fontSize: "15px",
    height: "40px",
    width: "100px",

    transition: "background-color 0.3s", // Adding transition for smooth color change
    "&:hover": {
      backgroundColor: constants.blueTextColor, // Color when hovered
      color: "white",
    },
    "&:focus": {
      // backgroundColor: "#287D7B", // Color when focused
      outline: "none", // Remove default outline on focus
    },
    marginLeft: "10.813rem",
  },
  buttonMargin: {
    marginTop: "8.5%",
  },
  outerGrid: {
    width: "100%",
    justifyContent: "center",
    // border: "2px solid pink",
  },
  buttonstyle4: {
    // alignSelf: "center",
    // marginTop: "rem",
    color: "white",
    // fontWeight: "bold",
    border: "1px solid #38A389",
    fontFamily: constants.fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: constants.primaryColor,
    fontSize: "1rem",
    height: "2.9rem",
    width: "12rem",
    flex: "0.1",

    "&:hover": {
      backgroundColor: constants.primaryColor, // Color when hovered
      // color: "rgba(255, 255, 255, 0.9)",
    },
    "&:focus": {
      // backgroundColor: "#287D7B", // Color when focused
      outline: "none", // Remove default outline on focus
    },
    // marginLeft: 65,
  },
  buttonstyle5: {
    marginTop: "1rem",
    color: "white",

    fontFamily: constants.fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: constants.primaryColor,
    fontSize: "1rem",
    height: "2.9rem",
    width: "4rem",
    flex: "0.15",

    "&:hover": {
      backgroundColor: constants.primaryColor, // Color when hovered
      // color: "rgba(255, 255, 255, 0.9)",
    },
    "&:focus": {
      // backgroundColor: "#287D7B", // Color when focused
      outline: "none", // Remove default outline on focus
    },
    // marginLeft: 65,
  },
  text1: {
    color: "#FFFFFFE6",
    fontFamily: constants.fontFamily,
    fontSize: "1.875em",
    display: "flex",
    paddingLeft: "3.438rem",
  },
  dietPlanText1: {
    color: constants.black,
    fontFamily: constants.fontFamily,
    fontSize: "2rem",
    flex: "0.17",
  },
  dietPlanText2: {
    color: constants.lightBlue,
    fontFamily: constants.fontFamily,
    fontSize: "1.5rem",
    flex: "0.2",
  },
  dietPlanText3: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.25rem",
    flex: "0.5",
  },

  mainContainer: {
    height: "29rem",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D9D9D9",
    borderRadius: "2%",
    marginBottom: "2rem",
    width: "40.5rem",
  },
  selectDieticianMainContainer: {
    height: "35rem",
    padding: "4%",
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "2%",
    marginBottom: "2rem",
    paddingTop: "3rem",
    // border: "2px solid green",
  },
  selectDieticianInnerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  signUpContainer: {
    height: "30rem",
  },
  infoContainer: {
    height: "35rem",
    marginBottom: "2%",
  },
  physicalContainer: {
    height: "25rem",
  },
  familyHistoryContainer: {
    height: "33rem",
  },
  dietaryContainer: {
    height: "38rem",
  },
  cashContainer: {
    height: "32rem",
  },
  plainbuttonstyle: {
    color: constants.red,
    // fontWeight: "bold",
    fontFamily: constants.fontFamily,
    marginTop: "0rem",
    marginRight: "-10rem",
    textTransform: "none !important",
  },
  input: {
    // marginTop: "3rem",
    marginBottom: "0.8rem",
    width: "18rem",
    color: "constants.blueTextColor",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    "&:before": {
      borderColor: "constants.blueTextColor", // Change the border color of the outline before input
    },
    "&:after": {
      borderColor: "constants.blueTextColor", // Change the border color of the outline after input (when focused)
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderColor: "constants.blueTextColor !important", // Change the border color on hover
    },
  },
  notchedOutline: {
    borderColor: "constants.blueTextColor !important", // Change the border color of the outline
  },
  weightInput: {
    width: "12rem",
  },
  weightInputLabel: {
    fontSize: "0.5rem",
  },

  inputLabel: {
    color: constants.blueTextColor,
    borderColor: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",

    "&.Mui-focused": {
      color: constants.blueTextColor,
      borderColor: constants.blueTextColor,
      fontFamily: constants.fontFamily,
      fontSize: "1rem",
      borderColor: constants.blueTextColor,
    },
    iconstyle: {
      color: constants.blueTextColor,
    },
  },

  inputLabel1: {
    color: constants.blueTextColor,
    borderColor: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    maxWidth: 290,
  },
  inputLabel2: {
    color: constants.blueTextColor,
    borderColor: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    minWidth: 150,
  },
  inputLabelsmallerFont: {
    fontSize: ".98rem",
    marginBottom: 0,
    paddingBottom: 0,
  },
  buttonstyle: {
    alignSelf: "center",
    height: "40px",
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    textDecoration: "none", // Remove the default underline
    outline: "none",
    "&:hover": {
      textDecoration: "underline", // Add underline on hover
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:focus": {
      textDecoration: "underline", // Add underline when focused
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:active": {
      textDecoration: "none", // Remove underline when pressed
      backgroundColor: "transparent",
    },
  },
  buttonstyle2: {
    alignSelf: "center",
    marginTop: "1rem",
    color: "white",
    fontWeight: "bold",
    border: "1px solid white",
    fontFamily: constants.fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "constants.blueTextColor",
    borderRadius: "30px",
    fontSize: "18px",
    height: "40px",
    width: "140px",
    transition: "background-color 0.3s", // Adding transition for smooth color change
    "&:hover": {
      backgroundColor: "#287D7B", // Color when hovered
    },
    "&:focus": {
      backgroundColor: "#287D7B", // Color when focused
      outline: "none", // Remove default outline on focus
    },
  },
  errorText: {
    color: "red",
    width: "20rem",
  },
  text2: {
    color: constants.black,
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
  },
  buttonstyle: {
    alignSelf: "center",
    height: "40px",
    color: "constants.blueTextColor",
    fontFamily: constants.fontFamily,
    fontSize: "1.1rem",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    textDecoration: "none", // Remove the default underline
    outline: "none",
    "&:hover": {
      textDecoration: "underline", // Add underline on hover
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:focus": {
      textDecoration: "underline", // Add underline when focused
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:active": {
      textDecoration: "none", // Remove underline when pressed
      backgroundColor: "transparent",
    },
  },
  horizontalcontainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default useStyles;
