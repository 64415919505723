import React, { useState, useEffect } from "react";
import { IconButton, MenuItem, FormControl, Select } from "@mui/material";
import profile from "../assets/images/profile.jpg";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ITEMS_PER_PAGE } from "../app/constants";
import Pagination from "../components/reusableComponents/Pagination";
import axios from "axios";
export default function ListofRequests() {
  const navigate = useNavigate();
  const [page, setpage] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [data, setData] = useState([]);
  const [option, setoption] = useState(2);
  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  const handleChange = (event) => {
    setoption(event.target.value);
    console.log(option);
    if (option) {
      const Url2 = `${serverIp}:${port}/api/unapprovedreq/${option}`;
      axios
        .get(Url2)
        .then((response) => {
          console.log(response.data);
          // if(response.status){
          //   if(response.data.results>1){
          //     setData(response.data.results.slice().sort((a,b)=>{
          //       return a.first_name.localeCompare(b.first_name);
          //     }));
          //     }
          //     setData(response.data.results[0]);
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handlePage = (page) => {
    setpage(page);
  };
  useEffect(() => {
    setpage(1);
  }, []);
  useEffect(() => {
    const id = 2;
    const apiUrl = `${serverIp}:${port}/api/unapprovedreq/${id}`;
    axios
      .get(apiUrl)
      .then((response) => {
        console.log(response.data);
        setData(
          response.data.results.slice().sort((a, b) => {
            return a.first_name.localeCompare(b.first_name);
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const itemsPerPage = ITEMS_PER_PAGE;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataToDisplay = data.slice(startIndex, endIndex);

  const handleSort = () => {
    // Toggle sorting order when the user clicks the header
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    // Sort the data array based on user name and sorting order
    const sortedData = data.slice().sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.first_name.localeCompare(b.first_name);
      } else {
        return b.first_name.localeCompare(a.first_name);
      }
    });

    // Update the data state with the sorted data
    setData(sortedData);
  };

  return (
    <div className="ml-10 mr-10 mt-10">
      {/* <h1 className='text-xl font-semibold text-[#38A389] 
      tracking-wider'>Approval Requests</h1> */}
      <div className="flex justify-between mb-5">
        <div>
          <h1 className="text-xl font-semibold text-[#31525B] mt-2">
            Approve Requests
          </h1>
        </div>
        <div>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              id="demo-simple-select-required"
              className="mb-2 w-52"
              value={option}
              size="small"
              onChange={handleChange}
            >
              <MenuItem value={2}>Nutritionist's</MenuItem>
              <MenuItem value={3}>Dietition's</MenuItem>
              <MenuItem value={4}>Blogger's</MenuItem>
              <MenuItem value={5}>Content-Creator's</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <ToastContainer />
      <div className="mt-3">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="bg-[#31525B] cursor-pointer text-white uppercase text-sm leading-normal">
              <th className="py-3 px-20 text-left" onClick={handleSort}>
                User{" "}
                {sortOrder === "asc" ? (
                  <ArrowUpwardIcon className="w-2 h-2 mb-1 inline" />
                ) : (
                  <ArrowDownwardIcon className="w-2 h-2 mb-1 inline" />
                )}
              </th>
              <th className="py-3 px-6 text-center">Email</th>
              <th className="py-3 px-6 text-center">Contact</th>
              <th className="py-3 px-6 text-center">Type</th>
              <th className="py-3 px-6 text-center">Requests</th>
              <th className="py-3 px-6 text-center">Action</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {dataToDisplay.length > 0 ? (
              dataToDisplay.map((user) => (
                <tr
                  key={user.id}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="py-3 px-6 ">
                    <div className="flex items-center">
                      <div className="mr-2">
                        <img
                          className="w-14 h-14 rounded-full"
                          src={user.profile_pic || profile}
                        />
                      </div>
                      <span>{user.name}</span>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-center text-sm">
                    {user.email}
                  </td>
                  <td className="py-3 px-6 text-center">
                    <span className=" py-1 px-3 text-sm">
                      {user.contact_number}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <span className="bg-rose-100 text-pink-950 py-1 px-3 rounded-full text-sm">
                      {"Nutritionist"}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <span className="bg-[#59c3a8] text-white py-1 px-3 rounded-full text-sm">
                      {user.unapproved_req_count}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <IconButton
                      onClick={() =>
                        navigate(`/approvedrequests/${user.id}/${user.name}`)
                      }
                    >
                      <VisibilityIcon
                        className="w-12 h-12 cursor-pointer"
                        sx={{ color: "#A87BD4" }}
                      />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="py-3 px-6 text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          page={page}
          setpage={setpage}
          handlePage={handlePage}
          totalITems={data.length}
        />
      </div>
    </div>
  );
}
