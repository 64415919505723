import React, {useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import '../components/reusableComponentsCss/slider.css';
import {  Grid, Modal, Typography, Box, Card } from "@mui/material";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #A87BD4',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};
export default function ApprovedFoodFactCardSlider({apfacts}){
  const [open, setOpen] = useState(false);
  const [name,setname]=useState('');
  const [content,setcontent]=useState('');
  const [imge,setimge]=useState();
  const handleOpen = (facts) => {
setname(facts.name);
setcontent(facts.description);
setimge(facts.food_image);
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
    return (
      <div>
        <Grid container >
          {apfacts.map((facts,index)=>
           <Grid item xs={12} sm={6} md={4} lg={3} 
           key={index}
          >
          <div class="m-1 flip-card" >
          <div class="flip-card-inner">
          <div  className="flip-card-front  " >
          <Card
           sx={{width:"12rem",height:"9rem",backgroundColor:`${facts.card_color}` }}>
  <div className="mt-1"><img className="imagesize" src={facts.food_image}/></div>
  <div className="flex items-center justify-center">
  <h3 className="m-2 text-lg text-[#38A389]">
            {facts.name}
          </h3>
          <span className="inline mb-2"> 
   <div className=" items-center w-7 h-7 rounded-full mt-1 mr-2 transform bg-[#f1eef3] hover:text-purple-500 hover:scale-120"><CheckIcon sx={{color:'#38A389'}}/></div>
</span>
          </div>
    </Card>
          </div>
          <div  class="flip-card-back">
          <h1 className="text-[#38A389] text-lg">{facts.name}</h1> 
      <p onClick={()=>handleOpen(facts)}>{facts.title}</p> 
          </div>
          </div>
          </div>
          </Grid>
    
          )}
            </Grid>
 
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center">
            <div>
              <img  src={imge}></img>
            </div>
            <div className="ml-3 justify-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {/* {name} */}
            {name}
          </Typography>
          <Typography  id="modal-modal-description" sx={{ mt: 1 }}>
           {content}
          </Typography>
          </div>
          </div>
        </Box>
      </Modal>
      </div>
    );
  }