import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import Popover from "@material-ui/core/Popover";
import {
  Container,
  Typography,
  Button,
  Menu,
  MenuItem,
  Grid
} from "@material-ui/core";
import logo from "../../../../assets/images/logo1.png";
import { NavLink, useNavigate } from "react-router-dom";
import constants from "../../../../constants.json";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "20%",
    backgroundColor: "#38A389",
  },
  navcontainer: {

    height: "100%",
    width: "100%",
  },
  smallcontainer1: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "22%",
    alignItems: "center",
    paddingLeft: "40px",
    cursor: "pointer",
  },
  smallcontainer2: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logoimg: {
    width: "85px",
    height: "80px",
  },
  text1: {
    fontFamily: constants.fontFamily,
    color: "white",
    fontWeight: "bold",
    fontSize: "32px",
  },
  buttonstyle: {
    marginLeft: "40px",
    marginTop: "20px",
    height: "40px",
    color: "white",
    fontSize: "1.3rem",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    textDecoration: "none",
    outline: "none",
  },
  buttonstyle1: {
    height: "40px",
    color: "white",
    fontSize: "1.3rem",
  },
  activeLink: {
    textDecoration: "underline", // Underline style for active link
  },
  right: {
    right: "90",
  },
  popoverContent: {
    padding: theme.spacing(2),
    backgroundColor: "#fff", // Background color
    borderRadius: "4px", // Rounded corners
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Box shadow\
    fontFamily: constants.fontFamily,
  },
  menuItem: {
    color: "#333", // Text color
    fontFamily: constants.fontFamily,
    fontWeight: "bold",
    color: "#38A389",
    "&:hover": {
      backgroundColor: "#f0f0f0", // Background color on hover
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const [activeNavLink, setActiveNavLink] = useState("home"); // Default to "home"
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handleNavLinkClick = (link) => {
    setActiveNavLink(link);
  };

  const handleMeButtonClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleLogoClick = () => {
    // Manually navigate to the "Home" route
    navigate("/main");
    // Set the activeNavLink to "home" to maintain styling
    setActiveNavLink("home");
  };

  return (
    <div >
      <Grid
        style={{
          width: "100%",
          alignItems: "center",
          backgroundColor: "#38A389",
          paddingLeft: "10px"
        }}
        container
      >
        <Grid item sm={12} md={12} lg={3} >
          <img src={logo} className={classes.logoimg} alt="logo" />
          <Typography style={{ cursor: "pointer" }} onClick={() => { navigate("/") }} className={classes.text1}>{constants.appTitle}</Typography>
        </Grid>

        {/* </Container> */}
        {/* <Container className={classes.smallcontainer2}> */}
        {/* <Grid style={{ display: "flex" }} item sm={12} md={12} lg={11} > */}
        {/* <Grid item sm={12} md={6} lg={1}>
          <NavLink
            exact
            to="/fooddiary"
            className={`${classes.buttonstyle} ${activeNavLink === "foodDiary" ? classes.activeLink : ""
              }`}
            onClick={() => handleNavLinkClick("foodDiary")}
          >
            <p style={{ fontSize: "20px" }}>Food Diary</p>
          </NavLink>
        </Grid> */}
        <Grid item sm={12} md={6} lg={1}>
          <NavLink
            exact
            to="/dietPlan"
            className={`${classes.buttonstyle} ${activeNavLink === "dietPlan" ? classes.activeLink : ""
              }`}
            onClick={() => handleNavLinkClick("dietPlan")}
          >
            <p style={{ fontSize: "20px" }}>Diet Plan</p>
          </NavLink>
        </Grid>

        <Grid item sm={12} md={6} lg={1}>
          <NavLink
            exact
            to="/dietTracker"
            className={`${classes.buttonstyle} ${activeNavLink === "dietTracker" ? classes.activeLink : ""
              }`}
            onClick={() => handleNavLinkClick("dietTracker")}
          >
            <p style={{ fontSize: "20px" }}>Diet Tracker</p>
          </NavLink>
        </Grid>
        <Grid item sm={12} md={6} lg={2}>
          <NavLink
            exact
            to="/progresstracker"
            className={`${classes.buttonstyle} ${activeNavLink === "progresstracker" ? classes.activeLink : ""
              }`}
            onClick={() => handleNavLinkClick("progresstracker")}
          >
            <p style={{ fontSize: "20px" }}>Progress Tracker</p>
          </NavLink>
        </Grid>
        <Grid style={{ paddingLeft: "30%" }} item sm={12} md={12} lg={4} >
          <NavLink
            className={classes.buttonstyle1}
            onClick={handleMeButtonClick}
            aria-controls="me-menu"
            aria-haspopup="true"
          >
            <AccountCircleSharpIcon fontSize="large" />
          </NavLink>
          <Popover
            open={Boolean(popoverAnchorEl)}
            anchorEl={popoverAnchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className={classes.popoverContent}>
              <MenuItem
                onClick={() => navigate("/profile")}
                className={classes.menuItem}
              >
                Edit Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("user");
                  navigate("/");
                  window.location.reload();
                }}
                className={classes.menuItem}
              >
                Logout
              </MenuItem>
            </div>
          </Popover>
        </Grid>

        {/* </Grid> */}

        {/* </Container> */}

      </Grid >
    </div>
  );
};

export default Header;
