import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import CustomTypography from "../../components/reusableComponents/muiTypography";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AddButton from "../../commonScreens/signinButton";
import "./nutritionist.css";
import Grid from "@mui/material/Grid";
export default function FoodCardList(props) {
  const [foodItem, setFoodItem] = useState([]);
  const location = useLocation();
  const { foodDetails } = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const response = await fetch(
          apiUrl + `/api/food-items/${foodDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        console.log("result from all food", result);

        if (result.status === true) {
          console.log("response", result.message);
          setFoodItem(result.foodItem);
        } else {
          console.log("food not add");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenario
      }
    }

    fetchData();
  }, []);

  const handleBackClick = () => {
    navigate(-1); // This will navigate back one step in the history stack
  };

  return (
    <div className="addFoodContainer">
      <Grid container className="addFoodBox">
        <Grid container className="addFoodGrid">
          <CustomTypography
            text={"Food Details"}
            fontSize={28}
            color={"#06647D"}
            marginLeft={5}
            marginBottom={30}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Food Name:`}
            fontSize={20}
            color={"black"}
            marginLeft={10}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.food_name}`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Calories:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.calories_kcal} kcal`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Quantity:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.quantity}`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Unit of Quantity:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.quantity_unit}`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Weight:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.weight_g} g`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Water:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.water_g} g`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Protein:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.protein_g} g`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Carbs:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.carbs_g} g`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Fiber:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.fiber_g} g`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Fats:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.fats_g} g`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Calcium:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.calcium} g`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Iron:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            minWidth={"30%"}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.iron_fe_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Potassium:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            minWidth={"30%"}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.potassium_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Sodium:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            minWidth={"30%"}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.sodium_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Zinc:`}
            fontSize={20}
            minWidth={"30%"}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.zinc_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Vitamin A:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            minWidth={"30%"}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.vitA_mic_g} g`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Thiamin:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            minWidth={"30%"}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.thia_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Choline:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            minWidth={"30%"}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.choline_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Riboflavin:`}
            fontSize={20}
            minWidth={"30%"}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.ribo_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Vitamin B3:`}
            fontSize={20}
            color={"black"}
            minWidth={"30%"}
            marginLeft={5}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.b3_niac_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Vitamin B6:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            minWidth={"30%"}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.b6_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Vitamin B9:`}
            fontSize={20}
            minWidth={"30%"}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.b9_micro_g} micrograms`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Vitamin C:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            minWidth={"30%"}
            fontWeight={"bold"}
          />
          <CustomTypography
            text={`${foodItem.vitC_mg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
            <CustomTypography
              text={`Vitamin B12:`}
              fontSize={20}
              color={"black"}
              marginLeft={5}
              fontWeight={"bold"}
              minWidth={"30%"}
            />
            <CustomTypography
              text={`${foodItem.vitb12_microg} micrograms`}
              fontSize={20}
              color={"#24768C"}
              marginLeft={10}
              fontWeight={"bold"}
            />
         </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Selenium:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.selenium_microg} mg`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start", marginBottom: 10 }}
          className="addFoodForm"
        >
          <CustomTypography
            text={`Magnesium:`}
            fontSize={20}
            color={"black"}
            marginLeft={5}
            fontWeight={"bold"}
            minWidth={"30%"}
          />
          <CustomTypography
            text={`${foodItem.magnesium} g`}
            fontSize={20}
            color={"#24768C"}
            marginLeft={10}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ justifyContent: "start" }}
          className="addFoodForm"
        >
          <AddButton
            buttonVariant="contained"
            buttonColor="#F4E0B4"
            buttonText="Back"
            onClick={handleBackClick}
            width={"15vh"}
            margin={"0px"}
            background={"#06647D"}
            textTransform={false}
          />
        </Grid>
      </Grid>
    </div>
  );
}
