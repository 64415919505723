import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ProgressTracker from "./progresstracker";
import { useState, useEffect } from "react";
import PieChartComp from "./pieChart";
import { useLocation } from "react-router-dom";
import constants from "../constants.json";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "80%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    //  border: "2px solid #A87DB4",
    // backgroundColor: "rgba(255, 209, 113, 0.6)",
  },
  innercontainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    // border: "2px solid #A87DB4",
    backgroundColor: "white",
    marginTop: "auto",
    borderTopLeftRadius: "100px",
    zIndex: 1,
    marginBottom: "300px",
  },
  navcontainer: {
    display: "flex",
    flexDirection: "row",
    height: "15%",
    width: "100%",
    // border: "2px solid #A87DB4",
  },
  smallcontainer1: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "35%",
    // border: "2px solid #A87DB4",
    // justifyContent: "center",
    alignItems: "center",
    paddingLeft: "40px",
  },
  smallcontainer2: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "65%",
    // border: "2px solid #A87DB4",
  },
  logoimg: {
    width: "85px",
    height: "80px",
  },
  text1: {
    color: "white",
    fontWeight: "bold",
    fontFamily: constants.fontFamily,
    fontSize: "32px",
  },
  buttonstyle: {
    alignSelf: "center",
    height: "40px",
    color: "white",
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    textDecoration: "none", // Remove the default underline
    outline: "none",
    "&:hover": {
      textDecoration: "underline", // Add underline on hover
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:focus": {
      textDecoration: "underline", // Add underline when focused
      backgroundColor: "transparent",
      outline: "none",
    },
    "&:active": {
      textDecoration: "none", // Remove underline when pressed
      backgroundColor: "transparent",
    },
  },

  horizontalcontainer: {
    display: "flex",
    flexDirection: "row",
    width: "85%",
    height: "100px",
    border: "2px solid #A87DB4",
  },
}));

const Home = () => {
  const location = useLocation();
  const dietPlanDataWithFoodAllowedAndNotAllowed = location.state;
  const classes = useStyles();
  const data = [
    { title: "Carbs", value: 17.19, color: "#77fe6f" },
    { title: "Fats", value: 5.73, color: "#797f33" },
    { title: "fiber", value: 0.52, color: "#6cca08" },
    { title: "Proteins", value: 6.77, color: "#FF7171" },
  ];

  return (
    <>
      <div
        style={{
          // width: "100%",
          height: "80%",
          // border: "2px solid #A87DB4",
          // backgroundColor: "#38A389",
          // zIndex: -1,
        }}
      >
        <div className={classes.innercontainer}>
          <ProgressTracker
            dietPlanDataWithFoodAllowedAndNotAllowed={
              dietPlanDataWithFoodAllowedAndNotAllowed
            }
          />
          {/* <PieChartComp apiData={data} foodWeight={100} /> */}
        </div>
      </div>
    </>
  );
};

export default Home;
