import {
  Container,
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { useKalorie } from "../../userContext/context";
import axios from "axios";
import { useState, useEffect } from "react";
import DietDetails from "./dietDetails";
import constants from "../../constants.json";
import useStyles from "../../assets/css/dietPlan";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const serverIp = process.env.REACT_APP_SERVERIP;
const port = process.env.REACT_APP_PORT;
const userObject = JSON.parse(localStorage.getItem("user"));

const ProgressTracker = ({
  setDurationtype,
  setActiveButton,
  durationtype,
  activeButton,
}) => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currentDate = year + "-" + month + "-" + day;
  const classes = useStyles();
  const [selectedFormattedDate, setSelectedFormattedDate] = useState("");
  const { data, updateData } = useKalorie();
  const [firstDayObject, setFirstDayObject] = useState();
  const [oneTimeRun, setOneTimeRun] = useState(true);
  const [diffDays, setDiffDays] = useState();
  const [weekOfToday, setWeekOfToday] = useState();
  const [meals, setMeals] = useState({});

  const handleChange = (event) => {
    console.log("kooooo", event.target.value);
    setDurationtype(event.target.value);
    updateData("week", event.target.value);
  };

  const handleButtonClick = (index) => {
    setActiveButton(index); // Update the active button index
    updateData("day", index + 1);

    if (durationtype === "week1") {
      const day = index + 1;
      const date = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        day
      );
      const formattedDate = date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
      setSelectedFormattedDate(formattedDate);
    }
  };

  useEffect(() => {
    function fetchDietDetails() {
      console.log("check", {
        user_id: userObject.user_id,
        week: durationtype,
        day: activeButton + 1,
      });
      const apiUrl = `${serverIp}:${port}/api/diet-plan-schedule-client`;
      console.log("im data:::::", data);
      const req_data = {
        user_id: userObject.user_id,
        week: durationtype,
        day: activeButton + 1,
      };

      axios
        .post(apiUrl, req_data)
        .then((response) => {
          console.log("response from dietdetails", response.data);
          if (response.status === 200) {
            const filteredMeals = {
              breakfasts: response.data.breakfasts,
              dinners: response.data.dinners,
              snacks: response.data.snacks,
              lunchs: response.data.lunchs,
            };
            console.log("faaaaa", response.data.diet_plan_id);
            setMeals(filteredMeals);
            updateData("diet_plan_id", response.data.diet_plan_id);
            const apiUrl = `${serverIp}:${port}/api/get-first-diet-plan-day/${response.data.diet_plan_id}`;
            if (oneTimeRun === true) {
              setOneTimeRun(false);
              axios
                .get(apiUrl)
                .then((response) => {
                  console.log("abcd", response.data);
                  setFirstDayObject(response.data);
                  // const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

                  let firstDate = new Date(response.data?.first_day);
                  let secondDate = new Date(currentDate);
                  let daysDiff = Math.round(
                    Math.abs((firstDate - secondDate) / 86400000)
                  );
                  console.log("diff", daysDiff, firstDayObject);
                  if (daysDiff < 7) {
                    setDurationtype("1");
                    setDiffDays(daysDiff);
                    setActiveButton(daysDiff);
                    setWeekOfToday("1");
                  } else if (daysDiff >= 7 && daysDiff < 14) {
                    setDurationtype("2");
                    setDiffDays(daysDiff - 7);
                    setActiveButton(daysDiff - 7);
                    setWeekOfToday("2");
                  } else if (daysDiff >= 14 && daysDiff < 21) {
                    setDurationtype("3");
                    setDiffDays(daysDiff - 14);
                    setActiveButton(daysDiff - 14);
                    setWeekOfToday("3");
                  } else if (daysDiff >= 21 && daysDiff < 28) {
                    setDurationtype("4");
                    setDiffDays(daysDiff - 21 && daysDiff < 28);
                    setActiveButton(daysDiff - 21);
                    setWeekOfToday("4");
                  } else if (daysDiff >= 28) {
                    setDurationtype("5");
                    setDiffDays(daysDiff - 28);
                    setActiveButton(daysDiff - 28);
                    setWeekOfToday("5");
                  }
                })
                .catch((error) => {
                  // Handle any errors that occurred during the request
                  console.log("abcd", error.response.data);
                  setFirstDayObject(error.response.data);
                  console.log("error from dietdetials", error);
                  console.log("waittttt", error.response.status);
                });
            }
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.log("error from dietdetials", error);
          console.log("waittttt", error.response.status);
        });
    }
    fetchDietDetails();
  }, [activeButton, durationtype]);

  useEffect(() => {
    // Calculate today's date
    const today = new Date();
    const todayIndex = today.getDate() - 1;
    const todayWeek = Math.ceil(todayIndex / 7); // Calculate the current week
    const todayMonth = today.getMonth(); // Get the current month index

    // Set the active button based on the current duration type
    if (durationtype === "days") {
      setActiveButton(todayIndex);
    } else if (durationtype === "weeks") {
      setActiveButton(todayWeek);
    } else if (durationtype === "months") {
      setActiveButton(todayMonth);
    }

    // Format today's date
    const formattedDate = today.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
    });
    setSelectedFormattedDate(formattedDate);
  }, [durationtype]);

  const renderButtons = () => {
    let initialDate = firstDayObject?.status
      ? new Date(firstDayObject.first_day)
      : new Date(currentDate);

    if (firstDayObject?.status) {
      initialDate.setDate(initialDate.getDate() + (durationtype - 1) * 7 - 1);
    } else {
      setActiveButton(0);
      initialDate.setDate(initialDate.getDate() + (durationtype - 1) * 7);
    }

    const datesArray = Array.from({ length: 7 }, (_, index) => {
      const currentLoopDate = new Date(initialDate);
      currentLoopDate.setDate(currentLoopDate.getDate() + index);
      return currentLoopDate.toISOString().split("T")[0];
    });

    return datesArray.map((date, index) => (
      <div
        key={`container-${index}`}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Button
          key={`day-${index + 1}`}
          variant="outlined"
          className={`${classes.weekButton} ${
            activeButton === index ? classes.activeButton : ""
          }`}
          onClick={() => handleButtonClick(index)}
        >
          {constants.day} {index + 1}
          {/* {activeButton}
          {index}
          {activeButton === index ? constants.today : ""} */}
        </Button>
        <Divider />
        <p className={classes.weekButtonDate}>{date}</p>
      </div>
    ));
  };

  return (
    <div>
      <Grid className={classes.mainGrid} container>
        <Grid
          className={classes.horizontalContainer}
          container
          direction={{ xs: "column", sm: "row" }} // Stack vertically on small screens, horizontally on larger ones
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className={classes.inputLabel}>
            {durationtype === "days" ? "Daily " : "Weekly "}
            {constants.dietPlan}
          </Typography>

          <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={durationtype}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className={classes.inputLabel}
                MenuProps={{
                  classes: { icon: classes.dropdownIcon },
                }}
              >
                <MenuItem
                  value={"1"}
                  className={`${classes.dropdownItem} ${
                    durationtype === "1" && activeButton === null
                      ? classes.activeButton
                      : ""
                  }`}
                >
                  {constants.week}-1
                </MenuItem>
                <MenuItem value={"2"} className={classes.dropdownItem}>
                  {constants.week}-2
                </MenuItem>
                <MenuItem value={"3"} className={classes.dropdownItem}>
                  {constants.week}-3
                </MenuItem>
                <MenuItem value={"4"} className={classes.dropdownItem}>
                  {constants.week}-4
                </MenuItem>
                <MenuItem value={"5"} className={classes.dropdownItem}>
                  {constants.week}-5
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>

        <Container
          style={{
            paddingLeft: "20%", // Reduced padding for mobile responsiveness
            paddingTop: "10px", // Added top padding for better spacing
            paddingBottom: "10px", // Added bottom padding for better spacing
          }}
        >
          <div className={classes.buttonContainer}>{renderButtons()}</div>
        </Container>

        <DietDetails meals={meals} />
      </Grid>
    </div>
  );
};

export default ProgressTracker;
