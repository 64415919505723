import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import { KalorieProvider } from "./userContext/context";
import LoginScreen from "./commonScreens/login";
import AdminProtected from "./adminScreens/adminProtected";
import DisplayUsers from "./adminScreens/displayAllUsers";
import RegisterUser from "./adminScreens/userRegistration";
import AdminDashboard from "./adminScreens/adminDashboard";
import NutritionistProtected from "./screens/nutritionist/nutritionistProtected";
import NutritionistNav from "./screens/nutritionist/nutritionistNav";
import AddFood from "./screens/nutritionist/addFood";
import ChangePassword from "./screens/nutritionist/changePassword";
import RoleManager from "./adminScreens/roleManager";
import SideBar from "./components/reusableComponents/sideBar";
import ListofRequests from "./adminScreens/listofRequests";
import SearchBar from "./components/reusableComponents/searchBar";
import FoodDetails from "./screens/nutritionist/FoodDetails";
import FoodCardSlider from "./components/reusableComponents/foodSlider";
import EditFood from "./screens/nutritionist/EditFood";
import ApprovedRequest from "./adminScreens/approvedRequest";
import UnapprovedFoodFactCardSlider from "./adminScreens/approvedFootFactSlider";
import ApprovedFoodFactCardSlider from "./adminScreens/approvedFootFactSlider";
import ApprovedFoodItems from "./adminScreens/approvedFoodItems";
import UnapprovedFoodItems from "./adminScreens/unapprovedfoodItems";
import UnapprovedFoodTips from "./adminScreens/unapprovedFoodTips";
import ApprovedFoodTips from "./adminScreens/approvedFoodTips";
import Home from "./commonScreens/home";
import UserSignup from "./screens/user/userSignUp";
import SelectDietician from "./screens/user/selectDietician";
import SelectDietPlan from "./screens/user/selectDietPlan";
import PersonalInfo from "./screens/user/personalInfo";
import PhysicalHealthInfo from "./screens/user/physicalHealthInfo";
import FamilyHistory from "./screens/user/familyHistory";
import DietaryHabits from "./screens/user/dietary";
import PaymentMethod from "./screens/user/paymentMethod";
import Wait from "./screens/user/wait";
import GoalScreen from "./screens/user/goal";
import Main from "./components/kalorieKompassComponents/main/index";
import DietTracker from "./screens/user/dietTracker";
import ProgressTracker from "./screens/user/progressTracker";
import DietPlan from "./screens/user/dietPlan";
import ResponsiveBar from "./adminScreens/responsiveBar";
import AdminProfile from "./adminScreens/adminProfile";
import DietitionProtected from "./dietitionScreens/dietitionProtected";
import DietitianNav from "./dietitionScreens/dietitianNav";
import DietitionClients from "./dietitionScreens/dietitionClients";
import DietitionDashboard from "./dietitionScreens/dietitionDashboard";
import DietitionProfile from "./dietitionScreens/dietitionprofile";
import DietUserRequests from "./dietitionScreens/dietuserRequests";
import DisplayUserInfo from "./dietitionScreens/displayuserInfo";
import CreatePlan from "./dietitionScreens/createPlan";
import AvoidFoodItems from "./dietitionScreens/foodtoadd/avoid";
import DietationDietTracker from "./dietitionScreens/dietTracker";
import ProgressSummaryTrackerForClient from "./dietitionScreens/progressSummaryTrackerForClient";
import AddNewFoodMacronutrient from "./screens/nutritionist/addNewFoodMacronutrient";
import FoodDiary from "./screens/user/foodDiary";

import CalculateTEE from "./screens/user/calculateTEE";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import BloggerDashboard from "./BloggerScreens/bloggerDashboard";
import BloggerProtected from "./BloggerScreens/bloggerProtected";
import BloggerProfile from "./BloggerScreens/bloggerProfile";
import FoodFactsDetails from "./BloggerScreens/foodFactsDetails";
import FoodFactsList from "./BloggerScreens/foodFactsList";
import FoodFacts from "./BloggerScreens/foodFacts";
import FoodBlogs from "./BloggerScreens/foodBlogs";
import BloggerNav from "./BloggerScreens/bloggerNav";
import FoodBlogsDetails from "./BloggerScreens/foodBlogsDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import Blogs from "./commonScreens/blog";
import BlogsDetails from "./commonScreens/blogDetails";
import UserNav from "./screens/user/userNav";
import UserNavTabs from "./screens/user/userNavTabs";
import ClientNav from "./screens/user/clientNav";
import ClientNavTabs from "./screens/user/clientNavTabs";
import FaqsPage from "./screens/user/faqsPage";
import AboutUsPage from "./components/kalorieKompassComponents/starterpage/about";
import HowItWorks from "./components/kalorieKompassComponents/starterpage/howItWorks";
import Community from "./components/kalorieKompassComponents/starterpage/community";
import EditUser from "./adminScreens/editUser";
const userObject = JSON.parse(localStorage.getItem("user"));
var router = null;
var hours = 24; // Change this value to set the duration before clearing localStorage
var now = new Date().getTime();
var setupTime = localStorage.getItem("setupTime");

if (setupTime !== null) {
  // If setupTime is present, check if the time difference is greater than the specified hours
  if (now - setupTime > hours * 60 * 60 * 1000) {
    // If the time difference exceeds the specified hours, clear localStorage
    localStorage.clear();
    <Home />;
  }
}
if (!localStorage.getItem("accessToken")) {
  router = createBrowserRouter([
    {
      path: "/login",
      element: <LoginScreen />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/changepassword",
      element: <ChangePassword />,
    },
    {
      path: "/update-password",
      element: <ChangePassword />,
    },
    {
      path: "/signup",
      element: <UserSignup />,
    },
    {
      path: "/selectGoal",
      element: <GoalScreen />,
    },
    {
      path: "/selectDietician",
      element: <SelectDietician />,
    },
    {
      path: "/selectDietPlan",
      element: <SelectDietPlan />,
    },
    {
      path: "/personalInfo",
      element: <PersonalInfo />,
    },
    {
      path: "/physicalHealthInfo",
      element: <PhysicalHealthInfo />,
    },
    {
      path: "/familyHistory",
      element: <FamilyHistory />,
    },
    {
      path: "/dietaryHabits",
      element: <DietaryHabits />,
    },
    {
      path: "/paymentMethod",
      element: <PaymentMethod />,
    },
    {
      path: "/wait",
      element: <Wait />,
    },
    {
      path: "/calculateTEE",
      element: <CalculateTEE />,
    },
    {
      path: "/blogs",
      element: <Blogs />,
    },
    {
      path: "/aboutus",
      element: <AboutUsPage />,
    },
    {
      path: "/howitworks",
      element: <HowItWorks />,
    },
    {
      path: "/community",
      element: <Community />,
    },
    {
      path: "/faqs",
      element: <FaqsPage />,
    },
    {
      path: "/userBlogsdetails/:id",
      element: <BlogsDetails />,
    },
    {
      path: "/bloggerdashboard",
      element: <></>,
    },

    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);
} else {
  if (userObject.role === "Super Admin") {
    router = createBrowserRouter([
      // {
      //   path: "/",
      //   element: <Home />,
      // },
      {
        path: "/adminDashboard",
        element: (
          <AdminProtected>
            <AdminDashboard />
          </AdminProtected>
        ),
      },
      {
        path: "/userRegistration",
        element: (
          <AdminProtected>
            <RegisterUser />
          </AdminProtected>
        ),
      },
      {
        path: "/displayUsers",
        element: (
          <AdminProtected>
            <DisplayUsers />
          </AdminProtected>
        ),
      },
      {
        path: "/approvedrequests/:id/:name",
        element: (
          <AdminProtected>
            <ApprovedRequest />
          </AdminProtected>
        ),
      },
      {
        path: "/roleManager",
        element: (
          <AdminProtected>
            <RoleManager />
          </AdminProtected>
        ),
      },
      {
        path: "/requestlist",
        element: (
          <AdminProtected>
            {" "}
            <ListofRequests />
          </AdminProtected>
        ),
      },
      {
        path: "/searchbar",
        element: (
          <AdminProtected>
            <SearchBar />
          </AdminProtected>
        ),
      },

      {
        path: "/userRegistration/:id",
        element: (
          <AdminProtected>
            <EditUser />
          </AdminProtected>
        ),
      },
      {
        path: "/adminprofile",
        element: (
          <AdminProtected>
            <AdminProfile />
          </AdminProtected>
        ),
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/aboutus",
        element: <AboutUsPage />,
      },
      {
        path: "/howitworks",
        element: <HowItWorks />,
      },
      {
        path: "/community",
        element: <Community />,
      },
      {
        path: "/faqs",
        element: <FaqsPage />,
      },
      {
        path: "*",
        element: <Navigate to="/adminDashboard" />,
      },
    ]);
  } else if (userObject.role === "Nutritionist") {
    router = createBrowserRouter([
      // {
      //   path: "/",
      //   element: <Home />,
      // },
      {
        path: "/nutritionistDashboard",
        element: (
          <NutritionistNav>
            <FoodCardSlider />
          </NutritionistNav>
        ),
      },
      {
        path: "/addFood",
        element: (
          <NutritionistNav>
            <AddFood />
          </NutritionistNav>
        ),
      },
      {
        path: "/editFood",
        element: (
          <NutritionistNav>
            <EditFood />
          </NutritionistNav>
        ),
      },
      {
        path: "/allFoods",
        element: (
          <NutritionistNav>
            <FoodCardSlider />
          </NutritionistNav>
        ),
      },
      {
        path: "/foodDetails",
        element: (
          <NutritionistNav>
            <FoodDetails />
          </NutritionistNav>
        ),
      },
      {
        path: "/addNewFoodMacronutrient/:slug",
        element: (
          <NutritionistNav>
            <AddNewFoodMacronutrient />
          </NutritionistNav>
        ),
      },
      {
        path: "*",
        element: <Navigate to="/nutritionistDashboard" />,
      },
    ]);
  } else if (userObject.role === "Dietitian") {
    router = createBrowserRouter([
      // {
      //   path: "/",
      //   element: <Home />,
      // },
      {
        path: "/dietitionclients",
        element: (
          <DietitianNav>
            <DietitionClients />
          </DietitianNav>
        ),
      },
      {
        path: "/dietitiondashboard",
        element: (
          <DietitianNav>
            <DietUserRequests />
          </DietitianNav>
        ),
      },
      {
        path: "/dietitionprofile",
        element: (
          <DietitianNav>
            <DietitionProfile />
          </DietitianNav>
        ),
      },
      {
        path: "/dietuserrequests",
        element: (
          <DietitianNav>
            <DietUserRequests />
          </DietitianNav>
        ),
      },
      {
        path: "/displayuserinfo",
        element: (
          <DietitianNav>
            <DisplayUserInfo />
          </DietitianNav>
        ),
      },
      {
        path: "/createdietplan",
        element: (
          <DietitianNav>
            <CreatePlan />
          </DietitianNav>
        ),
      },
      {
        path: "/avoidfooditems",
        element: (
          <DietitianNav>
            <AvoidFoodItems />
          </DietitianNav>
        ),
      },
      {
        path: "/dietitionDietTracker",
        element: (
          <DietitianNav>
            <DietationDietTracker />
          </DietitianNav>
        ),
      },
      {
        path: "/clientProgressSummary",
        element: (
          <DietitianNav>
            <ProgressSummaryTrackerForClient />
          </DietitianNav>
        ),
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/aboutus",
        element: <AboutUsPage />,
      },
      {
        path: "/howitworks",
        element: <HowItWorks />,
      },
      {
        path: "/community",
        element: <Community />,
      },
      {
        path: "/faqs",
        element: <FaqsPage />,
      },
      {
        path: "*",
        element: <Navigate to="/dietitiondashboard" />,
      },
    ]);
  } else if (userObject.role === "Client") {
    router = createBrowserRouter([
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/clientdashboard",
        element: (
          <ClientNav>
            <ClientNavTabs />
          </ClientNav>
        ),
      },
      // {
      //   path: "/dietPlan",
      //   element: <DietPlan />,
      // },
      // {
      //   path: "/dietTracker",
      //   element: <DietTracker />,
      // },
      // {
      //   path: "/mealTracker",
      //   element: <Main />,
      // },
      // {
      //   path: "/progresstracker",
      //   element: <ProgressTracker />,
      // },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/userBlogsdetails/:id",
        element: <BlogsDetails />,
      },
      {
        path: "/aboutus",
        element: <AboutUsPage />,
      },
      {
        path: "/howitworks",
        element: <HowItWorks />,
      },
      {
        path: "/community",
        element: <Community />,
      },
      {
        path: "/faqs",
        element: <FaqsPage />,
      },
      {
        path: "*",
        element: <Navigate to="/clientdashboard" />,
      },
    ]);
  } else if (userObject.role === "User") {
    router = createBrowserRouter([
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/userdashboard",
        element: (
          <UserNav>
            <UserNavTabs />
          </UserNav>
        ),
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/userBlogsdetails/:id",
        element: <BlogsDetails />,
      },
      {
        path: "/aboutus",
        element: <AboutUsPage />,
      },
      {
        path: "/howitworks",
        element: <HowItWorks />,
      },
      {
        path: "/community",
        element: <Community />,
      },
      {
        path: "/faqs",
        element: <FaqsPage />,
      },
      {
        path: "*",
        element: <Navigate to="/userdashboard" />,
      },
    ]);
  } else if (userObject.role === "Blogger") {
    router = createBrowserRouter([
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/bloggerdashboard",
        element: (
          <BloggerNav>
            <BloggerDashboard />
          </BloggerNav>
        ),
      },
      {
        path: "/foodfacts",
        element: (
          <BloggerNav>
            <FoodFacts />
          </BloggerNav>
        ),
      },
      {
        path: "/foodfactsdetails",
        element: (
          <BloggerNav>
            <FoodFactsDetails />
          </BloggerNav>
        ),
      },
      {
        path: "/foodblogs",
        element: (
          <BloggerNav>
            <FoodBlogs />
          </BloggerNav>
        ),
      },
      {
        path: "/foodblogsdetails",
        element: (
          <BloggerNav>
            <FoodBlogsDetails />
          </BloggerNav>
        ),
      },
      {
        path: "/bloggerprofile",
        element: (
          <BloggerNav>
            <BloggerProfile />
          </BloggerNav>
        ),
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/aboutus",
        element: <AboutUsPage />,
      },
      {
        path: "/howitworks",
        element: <HowItWorks />,
      },
      {
        path: "/community",
        element: <Community />,
      },
      {
        path: "/faqs",
        element: <FaqsPage />,
      },
      {
        path: "*",
        element: <Navigate to="/bloggerdashboard" />,
      },
    ]);
  }
  //   {
  //     path: "/approvedfoodfacts",
  //     element: <ApprovedFoodFactCardSlider />,
  //   },

  //   {
  //     path: "/slider",
  //     element: <UnapprovedFoodFactCardSlider />,
  //   },

  //   {
  //     path: "/sidebar",
  //     element: <SideBar />,
  //   },

  //   {
  //     path: "/unapprovedfooditems",
  //     element: <UnapprovedFoodItems />,
  //   },
  //   {
  //     path: "/unapprovedfooditems",
  //     element: <ApprovedFoodItems />,
  //   },
  //   {
  //     path: "/unapprovedfoodtips",
  //     element: <UnapprovedFoodTips />,
  //   },
  //   {
  //     path: "/approvedfoodtips",
  //     element: <ApprovedFoodTips />,
  //   },

  //   {
  //     path: "/dietPlan",
  //     element: <DietPlan />,
  //   },
  //   {
  //     path: "/dietTracker",
  //     element: <DietTracker />,
  //   },
  //   {
  //     path: "/mealTracker",
  //     element: <Main />,
  //   },
  //   {
  //     path: "/unapprovedfooditems",
  //     element: <ApprovedFoodItems />,
  //   },
  //   {
  //     path: "/unapprovedfoodtips",
  //     element: <UnapprovedFoodTips />,
  //   },
  //   {
  //     path: "/approvedfoodtips",
  //     element: <ApprovedFoodTips />,
  //   },
  //   {
  //     path: "/responsivebar",
  //     element: <ResponsiveBar />,
  //   },
}
function App() {
  return (
    <main>
      <ReactNotifications />
      <KalorieProvider>
        <RouterProvider router={router}></RouterProvider>
      </KalorieProvider>

      {/* <LoginScreen/> */}
    </main>
  );
}

export default App;
