import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import constants from "../../constants.json";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { useMediaQuery, useTheme } from "@material-ui/core";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? " #D9D9D9" : "#D9D9D9",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light"
        ? "constants.blueTextColor"
        : "constants.blueTextColor",
    fontSize: 16,
    padding: 8,
    width: "45vh",
    height: "2rem",

    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function validateEmail(value) {
  // Basic email validation regex
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(value);
}

function validatePassword(value) {
  // Password validation: At least 8 characters
  return value.length >= 8;
}

function validateUsername(value) {
  // Username validation: Only alphanumeric characters
  const usernameRegex = /^[a-zA-Z0-9]+$/;
  return usernameRegex.test(value);
}

function validatePhoneNumber(value) {
  // Phone number validation: Only digits, 10 characters
  const phoneNumberRegex = /^\d{10}$/;
  return phoneNumberRegex.test(value);
}

function MuiTextField(props) {
  const [value, setValue] = useState(props.value || "");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    // Validation based on the type prop
    switch (props.type) {
      case "email":
        setError(!validateEmail(newValue));
        break;
      case "password":
        setError(!validatePassword(newValue));
        break;
      case "signin_password":
        // setError(!validatePassword(newValue));
        break;
      case "username":
        setError(!validateUsername(newValue));
        break;
      case "phone":
        setError(!validatePhoneNumber(newValue));
        break;
      default:
        setError(false);
    }

    if (props.onChange) {
      props.onChange(newValue, !error);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FormControl
      variant="standard"
      style={{ width: isMobile ? "80%" : "100%" }}
    >
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        style={{
          color: "#31525B",
          fontSize: "24px",
          fontWeight: "bold",
          fontFamily: constants.fontFamily,
        }}
      >
        {props.label}
      </InputLabel>
      <BootstrapInput
        id="bootstrap-input"
        value={value}
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === "Enter" && props.onKeyDown) {
            props.onKeyDown(event);
          }
        }}
        type={showPassword ? "text" : "password"}
        // endAdornment={
        //   props.type === "password" || props.type === "signin_password" ? (
        //     <InputAdornment position="end">
        //       <IconButton
        //         onClick={() => setShowPassword(!showPassword)}
        //         edge="end"
        //         aria-label="toggle password visibility"
        //       >
        //         {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        //       </IconButton>
        //     </InputAdornment>
        //   ) : null
        // }
      />
      {/* {error && <p style={{ color: "red" }}>Invalid {props.label}</p>} */}
    </FormControl>
  );
}

export default MuiTextField;
