import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import { useState, useEffect } from "react";
import IngredientsTracker from "./ingredientstracker";
import FoodAdder from "./foodadder";
import constants from "../constants.json";
import useStyles from "../assets/css/dietPlan";
const serverIp = process.env.REACT_APP_SERVERIP;
const port = process.env.REACT_APP_PORT;

const ProgressTracker = ({ dietPlanDataWithFoodAllowedAndNotAllowed }) => {
  const classes = useStyles();
  const [durationtype, setDurationtype] = useState("week1");
  const [activeButton, setActiveButton] = useState(0);
  const [selectedFormattedDate, setSelectedFormattedDate] = useState("");
  const [reload, setReload] = useState(false);
  const [diet, setDiet] = useState();
  const [rec, setRec] = useState();

  const handleChange = (event) => {
    setDurationtype(event.target.value);
    // setActiveButton(0); // Reset active button when changing duration type
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);

    if (durationtype === "week1") {
      const day = index + 1;
      const date = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        day
      );
      const formattedDate = date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
      setSelectedFormattedDate(formattedDate);
    }
  };

  async function fetchRecommendedCalories() {
    console.log("kaaa", dietPlanDataWithFoodAllowedAndNotAllowed);
    var response = await axios.get(
      `${serverIp}:${port}/api/get-recommended-calories/${dietPlanDataWithFoodAllowedAndNotAllowed.userData.user_id}`
    );
    console.log("response1111", response.data.RecommendedCalories);

    setRec(response.data.RecommendedCalories);
  }
  async function getMealTrackerData() {
    console.log("wowww", dietPlanDataWithFoodAllowedAndNotAllowed, {
      diet_plan_id:
        dietPlanDataWithFoodAllowedAndNotAllowed.dietplanObject.diet_plan_id,
      month: 1,
      week: durationtype.charAt(durationtype.length - 1),
      day: activeButton + 1,
    });
    try {
      var response = await fetch(
        `${serverIp}:${port}/api/diet-plan-schedule-dietitian`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            diet_plan_id:
              dietPlanDataWithFoodAllowedAndNotAllowed.dietplanObject
                .diet_plan_id,
            month: 1,
            week: durationtype.charAt(durationtype.length - 1),
            day: activeButton + 1,
          }),
        }
      );
      const result = await response.json();
      setDiet(result);
      console.log("newresponse111", result);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
    console.log("nooo", diet);
  }
  useEffect(() => {
    fetchRecommendedCalories();
    getMealTrackerData();
    // Calculate today's date
    const today = new Date();
    const todayIndex = today.getDate() - 1;
    const todayWeek = Math.ceil(todayIndex / 7); // Calculate the current week
    const todayMonth = today.getMonth(); // Get the current month index

    // Set the active button based on the current duration type
    if (durationtype === "days") {
      setActiveButton(todayIndex);
    } else if (durationtype === "weeks") {
      setActiveButton(todayWeek);
    } else if (durationtype === "months") {
      setActiveButton(todayMonth);
    }

    // Format today's date
    const formattedDate = today.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
    });
    setSelectedFormattedDate(formattedDate);
    console.log("dddddd", durationtype);
  }, [durationtype, activeButton, reload]);

  const renderButtons = () => {
    return Array.from({ length: 7 }, (_, index) => (
      <Button
        key={`day-${index + 1}`}
        variant="outlined"
        className={`${classes.weekButton} ${
          activeButton === index ? classes.activeButton : ""
        }`}
        onClick={() => {
          handleButtonClick(index);
          setReload(!reload);
        }}
      >
        {constants.day} {index + 1}
      </Button>
    ));
  };

  return (
    <div>
      <Container className={classes.topContainer}>
        <Container className={classes.horizontalContainer}>
          <Typography className={classes.inputLabel}>
            {durationtype === "days"
              ? constants.daily
              : durationtype === "weeks"
              ? constants.weekly
              : constants.monthly}
            {constants.goal}
          </Typography>

          <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={durationtype}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className={classes.inputLabel}
                MenuProps={{
                  classes: { icon: classes.dropdownIcon },
                }}
              >
                <MenuItem
                  value={"week1"}
                  className={`${classes.dropdownItem} ${
                    durationtype === "week1" && activeButton === null
                      ? classes.activeButton
                      : ""
                  }`}
                >
                  {constants.week}-1
                </MenuItem>
                <MenuItem value={"week2"} className={classes.dropdownItem}>
                  {constants.week}-2
                </MenuItem>
                <MenuItem value={"week3"} className={classes.dropdownItem}>
                  {constants.week}-3
                </MenuItem>
                <MenuItem value={"week4"} className={classes.dropdownItem}>
                  {constants.week}-4
                </MenuItem>
                <MenuItem value={"week5"} className={classes.dropdownItem}>
                  {constants.week}-5
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </Container>
        <Container
          style={{
            paddingLeft: "25%",
          }}
        >
          <div className={classes.buttonContainer}>{renderButtons()}</div>
        </Container>

        {rec && (
          <>
            <IngredientsTracker
              durationtype={durationtype}
              selectedButtonContent={
                activeButton !== null
                  ? {
                      label: selectedFormattedDate,
                      index: activeButton + 1,
                    }
                  : null
              }
              dietPlanDataWithFoodAllowedAndNotAllowed={
                dietPlanDataWithFoodAllowedAndNotAllowed
              }
              reload={reload}
              rec={rec}
            />
            {diet && (
              <>
                <FoodAdder
                  durationtype={durationtype}
                  selectedButtonContent={{
                    label: selectedFormattedDate,
                    index: activeButton + 1,
                  }}
                  dietPlanDataWithFoodAllowedAndNotAllowed={
                    dietPlanDataWithFoodAllowedAndNotAllowed
                  }
                  rec={rec}
                  setReload={setReload}
                  reload={reload}
                  diet={diet}
                />
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default ProgressTracker;
