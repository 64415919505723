import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Typography,
  FormControl,
  Button,
  InputLabel,
  Input,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useKalorie } from "../../userContext/context";
import { useNavigate } from "react-router-dom";
import constants from "../../constants.json";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import useStyles from "../../assets/css/common.js";
import logo from "../../assets/images/logo.png";

const InfoPage2 = () => {
  const classes = useStyles();

  const { data, updateData } = useKalorie();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateData(name, value);
  };

  const hanldeFruitFrequency = (event) => {
    updateData("frequency_of_fruit_intake", event.target.value);
  };
  const handleVegFrequency = (event) => {
    updateData("frequency_of_vegetables_intake", event.target.value);
  };
  const hanldeRiceType = (event) => {
    updateData("rice_type", event.target.value);
  };
  const handleFlourType = (event) => {
    updateData("flour_type", event.target.value);
  };
  useEffect(() => {
    console.log(data); // Log the updated data after state update
  }, [data]); // This effect runs whenever the 'data' state changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isError = false; // Flag to track if there's an error

    if (!data.frequency_of_vegetables_intake) {
      setError("Please select your frequency of vegetable intake");
      isError = true;
    }

    if (!data.frequency_of_fruit_intake) {
      setError("Please select your frequency of fruit intake");
      isError = true;
    }
    if (!data.flour_type) {
      setError("Please select your flour type");
      isError = true;
    }
    if (!data.rice_type) {
      setError("Please select your rice type");
      isError = true;
    }
    if (!data.water_intake) {
      setError("Please enter water intake");
      isError = true;
    }

    if (!isError) {
      setError("");

      navigate("/paymentMethod");
    }
  };
  const goback = () => {
    navigate(-1);
  };

  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={classes.outercontainer_info2}
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            variant="h4"
            className={classes.text1}
          >
            <img
              style={{ marginRight: "10px" }}
              src={logo}
              alt="Logo is missing"
            />
            {constants.appTitle}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon size="small" />}
            className={classes.buttonstyle3}
            onClick={goback}
          >
            Back
          </Button>
        </Grid>
        <Grid item container>
          <Grid item className={classes.triangleRight}></Grid>
          <Grid item className={classes.triangleLeft}></Grid>
          <Grid className={classes.outerGrid} container>
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              className={`${classes.mainContainer} ${classes.dietaryContainer}`}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit} // This handles the form submission
                className={classes.innerBox}
              >
                <div>
                  <div>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className={classes.inputLabel2}
                    >
                      Frequency of Vegetables
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      //   defaultValue="1"
                      name="radio-buttons-group"
                      onChange={handleVegFrequency}
                    >
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="daily"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        label="Daily"
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="weekly"
                        label="Weekly"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                    </RadioGroup>
                  </div>
                  <div>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className={classes.inputLabel2}
                    >
                      Frequency of Fruits
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      //   defaultValue="1"
                      name="radio-buttons-group"
                      onChange={hanldeFruitFrequency}
                    >
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="daily"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        label="Daily"
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="weekly"
                        label="Weekly"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                    </RadioGroup>
                  </div>

                  <div>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className={classes.inputLabel2}
                    >
                      Which flour do you use?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      //   defaultValue="1"
                      name="radio-buttons-group"
                      onChange={handleFlourType}
                    >
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="white"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        label="White"
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="brown"
                        label="Brown"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="all_types"
                        label="All types"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                    </RadioGroup>
                  </div>
                  <div>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className={classes.inputLabel2}
                    >
                      Which rice do you use?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      //   defaultValue="1"
                      name="radio-buttons-group"
                      onChange={hanldeRiceType}
                    >
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="white"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        label="White"
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="brown"
                        label="Brown"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                      <FormControlLabel
                        className={classes.margincontainer_info4}
                        value="all_types"
                        label="All types"
                        control={
                          <Radio
                            size="small"
                            classes={{ root: classes.radio_info2 }}
                          />
                        }
                        classes={{ label: classes.labelstyle_info2 }}
                      />
                    </RadioGroup>
                  </div>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-firstname"
                      className={classes.inputLabel} // Apply custom styles to the label
                    >
                      Water intake(number of glasses)
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-firstname"
                      type="text"
                      name="water_intake"
                      value={data.water_intake}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                    />
                  </FormControl>
                </div>

                {error && (
                  <Typography className={classes.errorText}>{error}</Typography>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  align="center"
                  className={[classes.buttonstyle_info2, classes.continueStyle]}
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage2;
