import { Container, Typography, LinearProgress, Grid } from "@material-ui/core";
import useStyles from "../assets/css/dietPlan";
import { useState } from "react";
import proteinimg from "../assets/images/proteins.png";
import fatimg from "../assets/images/fats.png";
import carbsimg from "../assets/images/carbs.png";
import { useEffect } from "react";
import constants from "../constants.json";

const IngredientsTracker = ({
  durationtype,
  selectedButtonContent,
  dietPlanDataWithFoodAllowedAndNotAllowed,
  reload,
  rec,
}) => {
  const [proteins, setProteins] = useState(0);
  const [fats, setFats] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const classes = useStyles();
  console.log("newwww", rec);
  async function getMealTrackerData() {
    try {
      const response = await fetch(
        `${serverIp}:${port}/api/diet-plan-food-items-tracker
        `,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            diet_plan_id:
              dietPlanDataWithFoodAllowedAndNotAllowed.dietplanObject
                .diet_plan_id,
            month: 1,
            week: durationtype.charAt(durationtype.length - 1),
            day: selectedButtonContent.index,
          }),
        }
      );

      const result = await response.json();
      console.log("newresponse000", result);

      if (result.status === true) {
        var protein_percent =
          (result.results[0].total_protein / (rec.proteins * 0.129598)) * 100;
        setProteins(protein_percent);
        var fat_percent =
          (result.results[0].total_fats / (rec.fats * 0.129598)) * 100;
        setFats(fat_percent);
        var carb_percent =
          (result.results[0].total_carbs / (rec.carbs * 0.129598)) * 100;
        setCarbs(carb_percent);
      } else {
        // setProteins(0);
        // setFats(0);
        // setCarbs(0);
        console.log("error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
  }
  useEffect(() => {
    console.log("hiiiiaaa", rec);
    setProteins(0);
    setFats(0);
    setCarbs(0);
    getMealTrackerData();
    console.log("hehe", selectedButtonContent.index);
  }, [durationtype, selectedButtonContent.index, reload]);
  const serverIp = process.env.REACT_APP_SERVERIP;
  const port = process.env.REACT_APP_PORT;

  // Use the passed props here
  console.log("Duration Type:", durationtype.charAt(durationtype.length - 1));
  console.log("Selected Button Content:", selectedButtonContent);
  const ProgressTracker = ({ Label, Value, sliderValue, fillColor }) => {
    const numericSliderValue = parseInt(sliderValue, 10); // Convert sliderValue to a number

    // Determine whether to display in red or use fillColor prop
    const isOver100 = numericSliderValue > 100;

    // Define a function to determine the color based on the Label
    const getColor = () => {
      switch (Label) {
        case "Proteins":
          return "protein";
        case "Fats":
          return "fats";
        case "Carbs":
          return "carb";
        default:
          return "red"; // Default color if Label doesn't match any case
      }
    };
    const color = getColor();

    // Define a function to determine the style based on the Label
    const getLabelStyle = () => {
      switch (Label) {
        case "Proteins":
          return { color: "#00B569" }; // Protein color
        case "Fats":
          return { color: "#2583EF" }; // Fat color
        case "Carbs":
          return { color: "#FDA101" }; // Carb color
        default:
          return { color: "#000000" }; // Default color if Label doesn't match any case
      }
    };

    const labelStyle = getLabelStyle();

    return (
      <Container className={classes.alignContainer}>
        <Container className={classes.horizontalContainer}>
          <Typography
            style={labelStyle}
            className={`${classes.labeltext2}${classes[Label]}`}
          >
            {Label}
          </Typography>
          <Typography
            className={isOver100 ? classes.labeltextRed : classes.labeltext2}
          >
            {isOver100 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>
                {`${Math.round(sliderValue)}%`}
              </span>
            ) : (
              `${Math.round(sliderValue)}%`
            )}
          </Typography>
        </Container>

        <LinearProgress
          value={isOver100 ? 100 : numericSliderValue} // Limit value to 100% if over 100
          variant="determinate"
          className={classes.sliderContainer}
          classes={{
            barColorPrimary: isOver100
              ? classes.linearProgressBarRed
              : classes.barColor,
          }}
          sx={{
            "--LinearProgress-radius": "0px",
            "--LinearProgress-progressThickness": "24px",
            boxShadow: "sm",
            borderColor: "neutral.500",
          }}
          thickness={32}
        />
      </Container>
    );
  };
  const barData = [
    (rec.proteins / rec.recommended_calories_intake) * 100,
    (rec.fats / rec.recommended_calories_intake) * 100,
    (rec.carbs / rec.recommended_calories_intake) * 100,
  ];

  // Set the color for each bar
  const barColors = ["#00B569", "#2583EF", "#FDA101"];
  const barLabels = ["Protein", "Fats", "Carbs"];

  return (
    <Grid
      style={{
        justifyContent: "space-around",
        marginBottom: "2%",
      }}
      spacing={2}
      container
    >
      <Grid item sm={12} md={6} lg={6}>
        <div className={classes.integredientStackerContainer}>
          <div className={classes.innerContainer1}>
            <Typography className={classes.labeltext}>
              {constants.recommendedDialyCaloriesforYou}:
              {rec.recommended_calories_intake} kcl
            </Typography>
            {/* <Typography className={classes.labeltext}></Typography> */}

            <div className={classes.barsDiv}>
              {barData.map((height, index) => (
                <div key={index} className={classes.barContainer}>
                  <div
                    style={{
                      height: `${2.6 * height}px`, // Set the height dynamically
                      width: "30px", // Set the width as per your requirement
                      backgroundColor: barColors[index], // Set the color dynamically
                      margin: "0 8px", // Set margin between bars
                      position: "relative",
                    }}
                  >
                    {" "}
                    <span className={classes.barText}>{height}%</span>
                  </div>
                  <span className={classes.barLabel}>{barLabels[index]}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.innerContainer2}>
            <div className={classes.smallContainer}>
              <img
                src={proteinimg}
                alt={constants.proteins}
                className={classes.imgstyle}
              />
              <div className={classes.textContainer}>
                <Typography className={classes.protienText}>
                  {constants.proteins}
                </Typography>
                <Typography className={classes.text2}>
                  {rec.proteins} kcl
                </Typography>
              </div>
            </div>
            <div className={classes.smallContainer}>
              <div className={classes.textContainer}>
                <Typography className={classes.fatText}>
                  {constants.fats}
                </Typography>
                <Typography className={classes.text2}>
                  {rec.fats} kcl
                </Typography>
              </div>
              <img
                src={fatimg}
                alt={constants.fats}
                className={classes.imgstyle}
              />
            </div>
            <div className={classes.smallContainer}>
              <img
                src={carbsimg}
                alt={constants.carbs}
                className={classes.imgstyle}
              />
              <div className={classes.textContainer}>
                <Typography className={classes.carbsText}>
                  {constants.carbs}
                </Typography>
                <Typography className={classes.text2}>
                  {rec.carbs} kcl
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={6} lg={6}>
        <div
          className={[
            classes.integredientStackerContainer,
            classes.offwhiteContainer,
          ]}
        >
          <Typography className={classes.labeltext3}>
            {constants.progressTracker}
          </Typography>

          <ProgressTracker
            Label={constants.proteins}
            Value={proteins}
            sliderValue={proteins}
          />
          <ProgressTracker
            Label={constants.fats}
            Value={fats}
            sliderValue={fats}
          />
          <ProgressTracker
            Label={constants.carbs}
            Value={carbs}
            sliderValue={carbs}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default IngredientsTracker;
