import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import constants from "../../../../constants.json";
import { useKalorie } from "../../../../userContext/context";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const SimplePieChart = ({ rec, dietSummary, type }) => {
  const { dateTrackingDiary, menuValue, setMenuValue } = useKalorie();

  // const [menuValue, setMenuValue] = useState("weekly");
  const [day, setday] = useState(1);
  console.log("noooooo", dietSummary);
  const data = [];
  let weeklyProtien = 0;
  let weeklyCarbs = 0;
  let weeklyFats = 0;
  let weeklyCals = 0;
  if (type === "macro") {
    if (menuValue === "weekly") {
      dietSummary.map((items, index) => {
        weeklyProtien = weeklyProtien + items.total_protein;
        weeklyCarbs = weeklyCarbs + items.total_carbs;
        weeklyFats = weeklyFats + items.total_fats;
      });
      data.push({
        name: "Protiens(" + weeklyProtien + "g)",
        value: weeklyProtien,
      });
      data.push({
        name: "Carbs(" + weeklyCarbs + "g)",
        value: weeklyCarbs,
      });
      data.push({
        name: "Fats(" + weeklyFats + "g)",
        value: weeklyFats,
      });
    } else if (menuValue === "daily") {
      dietSummary.map((items, index) => {
        if (items.date === dateTrackingDiary) {
          console.log("noooooo in dailiy pie charts", dietSummary);

          data.push({
            name: "Protiens(" + items?.total_protein + "g)",
            value: items?.total_protein,
          });
          data.push({
            name: "Carbs(" + items?.total_carbs + "g)",
            value: items?.total_carbs,
          });
          data.push({
            name: "Fats(" + items?.total_fats + "g)",
            value: items?.total_fats,
          });
        }
      });
    }
  } else if (type === "totalCal") {
    if (menuValue === "weekly") {
      dietSummary.map((items, index) => {
        weeklyCals = weeklyCals + items.total_calories;
      });
      data.push({
        name: "Taken Calories(" + weeklyCals + "g)",
        value: weeklyCals,
      });
      data.push({
        name: "Recommended Calories(" + rec * 7 + "g)",
        value: rec * 7,
      });
    } else if (menuValue === "daily") {
      dietSummary.map((items, index) => {
        if (items.date === dateTrackingDiary) {
          data.push({
            name: "Taken Calories(" + items?.total_calories + "g)",
            value: items?.total_calories,
          });
          data.push({
            name: "Recommended Calories(" + rec + "g)",
            value: rec,
          });
        }
      });
    }
  }
  return (
    <div
      style={{ height: "400px", width: "100%" }}
      className="bg-white rounded-xl shadow-md"
    >
      {/* <div className="px-4">
        <div style={{ justifyContent: "space-around" }} className="flex"> */}
      {/* <p className="font-semibold text-violet-500 py-2 w-10 h-10 align-middle bg-purple-2 rounded-full m-1 p-1 mb-2 text-sm">
                        KWD
                    </p> */}
      {/* <FormControl sx={{ m: 1, minWidth: 100 }} size="small"> */}
      {/* <InputLabel id="demo-select-small-label">Duration</InputLabel> */}
      {/* <Select
              sx={{ height: 30 }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={menuValue}
              // label="Weekly"
              onChange={console.log("hello")}
            >
              <MenuItem
                onClick={() => {
                  setMenuValue("weekly");
                }}
                value={"weekly"}
              >
                {constants.weekly}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuValue("daily");
                }}
                value={"daily"}
              >
                {constants.daily}
              </MenuItem>
            </Select>
          </FormControl>
          {menuValue === "daily" ? (
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small"> */}
      {/* <InputLabel id="demo-select-small-label">Duration</InputLabel> */}
      {/* <Select
                sx={{ height: 30 }}
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={day}
                // label="Weekly"
                onChange={console.log("hello")}
              >
                {dietSummary.map((items) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setday(items.day);
                      }}
                      value={items.day}
                    >
                      {constants.day} {items.day}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : null}
        </div> */}
      {/* <h1 className="font-bold text-start text-2xl mb-2">1,234.54 KWD</h1>
                <p className="flex text-sm ">
                    Revenue{" "}
                    <p className="text-lime-600 px-3">
                        <ShowChartIcon /> 7.2%{" "}
                    </p>
                </p> */}
      {/* </div> */}
      <ResponsiveContainer width="100%" height="80%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={"100%"}
            fill="#8884d8"
            dataKey="value"
            legendType="star"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
export default SimplePieChart;
