import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Divider,
  IconButton,
  TextField,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Input,
} from "@material-ui/core";
import { useKalorie } from "../userContext/context";
import { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import constants from "../constants.json";
const serverIp = process.env.REACT_APP_SERVERIP;
const port = process.env.REACT_APP_PORT;
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // height: "100px",
    marginTop: "40px",
    // border: "2px solid #FFD171",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  accordionstyle: {
    width: "100%",
    // height: "60px",
    // border: "2px solid #FFD171",
    // borderRadius: "20px",
    justifyContent: "space-between",
    backgroundColor: constants.offwhite,
    marginTop: "10px",
  },
  headercontainer: {
    width: "100%",
    // border: "2px solid black",
    // alignSelf: "flex-end",
    height: "30px",
    alignItems: "flex-start",
  },
  headertext: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.7rem",
  },
  text1: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    width: "28%",
  },
  text2: {
    color: constants.blueTextColor,
    fontFamily: constants.fontFamily,
    fontSize: "1rem",
    justifyItems: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  text3: {
    color: "#FFD171 !important",
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    justifyItems: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  purpletext: {
    color: "#A87DB4 !important",
  },
  textcontainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    // border: "2px solid #FFD171",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailscontainer: {
    display: "flex",
    flexDirection: "column",

    // border: "2px solid #FFD171",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2rem",
    color: "#A87DB4",
    fontFamily: constants.fontFamily,
  },
}));
const AccordionSection = ({
  mealType,
  selectedButtonContent,
  setReload,
  reload,
}) => {
  const classes = useStyles();
  const { meals, addMeal, noMeals } = useKalorie();
  const [expanded, setExpanded] = useState(false);
  const [mealTypeObjectList, setMealTypeObjectList] = useState([]);
  const [openResponsiveDeleteDialog, setOpenResponsiveDeleteDialog] =
    useState(false);
  console.log("yessss", mealType);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    if (selectedButtonContent === "Breakfast") {
      setMealTypeObjectList(mealType.breakfasts);
    } else if (selectedButtonContent === "Snack") {
      setMealTypeObjectList(mealType.snacks);
    } else if (selectedButtonContent === "Lunch") {
      setMealTypeObjectList(mealType.lunchs);
    } else if (selectedButtonContent === "Dinner") {
      setMealTypeObjectList(mealType.dinners);
    }
  }, [mealType, selectedButtonContent]);
  const removeMeal = async (mealId) => {
    const URL = `${serverIp}:${port}/api/remove-diet-plan-food-item/${mealId}`;
    await axios
      .delete(URL)
      .then((response) => {
        setReload(!reload);
      })
      .catch((error) => {
        // event.preventDefault();
      });
  };
  return (
    <Container className={classes.container}>
      <Container className={classes.headercontainer}>
        {mealTypeObjectList.length !== 0 ? (
          <>
            <Typography className={classes.headertext}>
              {selectedButtonContent}
            </Typography>
          </>
        ) : (
          <>
            {" "}
            <Typography
              style={{ width: "200%" }}
              className={classes.headertext}
            >
              {constants.noMealAddedYet}
            </Typography>
          </>
        )}
      </Container>
      <Container>
        {mealTypeObjectList.map((meal, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            className={classes.accordionstyle}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Container className={classes.textcontainer}>
                <Typography sx={{ width: "33%" }} className={classes.text1}>
                  {meal.food_name}
                </Typography>
                <Typography className={classes.text2} sx={{ width: "33%" }}>
                  {constants.caloriesCount}: {meal.calories_kcal} kcl
                </Typography>
                <Tooltip title="Delete" sx={{ width: "33%" }}>
                  <IconButton
                    class="ml-2 items-center bg-white-100 hover:bg-red-200  text-sm font-bold rounded-full px-2 py-1"
                    variant="outlined"
                    onClick={() => {
                      removeMeal(meal.id);
                    }}
                    aria-label="Delete"
                  >
                    <DeleteForeverIcon
                      style={{ color: constants.blueTextColor }}
                    />
                  </IconButton>
                </Tooltip>
                {/* <ResponsiveDeleteDialog meal={meal} /> */}
              </Container>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                  <Container className={classes.detailscontainer}>
                    <Typography className={classes.text3}>
                      {meal.fats_g} g
                    </Typography>
                    <Typography className={[classes.text3, classes.purpletext]}>
                      {constants.fats}
                    </Typography>
                  </Container>
                </Grid>

                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                  <Container className={classes.detailscontainer}>
                    <Typography className={classes.text3}>
                      {meal.protein_g} g
                    </Typography>
                    <Typography className={[classes.text3, classes.purpletext]}>
                      {constants.proteins}
                    </Typography>
                  </Container>
                </Grid>

                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                  <Container className={classes.detailscontainer}>
                    <Typography className={classes.text3}>
                      {meal.carbs_g} g
                    </Typography>
                    <Typography className={[classes.text3, classes.purpletext]}>
                      {constants.carbs}
                    </Typography>
                  </Container>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Container>
  );
};

export default AccordionSection;
