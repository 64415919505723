import React, {useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import MessageIcon from '@mui/icons-material/Message';
import { useForm } from "react-hook-form";
import '../components/reusableComponentsCss/slider.css';
import { IconButton, 
  TextField,Grid, Typography,Box,
  Button,Modal,CardActions,Card } from "@mui/material";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #A87BD4',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};
export default function UnapprovedFoodFactSlider({unapfacts, onApprove}){
  const { register,reset, handleSubmit, watch, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false);
  const [commentopen, setcommentOpen] = useState(false);
  const [name,setname]=useState('');
  const [content,setcontent]=useState('');
  const [imge,setimge]=useState();
  const handleOpen = (facts) => {
    setname(facts.name);
    setcontent(facts.description);
    setimge(facts.food_image);
    setOpen(true);
  }
  const handlecommentOpen=()=>setcommentOpen(true);
  const handlecommentClose=()=>{
    setcommentOpen(false);
  }
  const handleapprove=(factid,fact)=>{
    console.log("unapproved food slider page",factid);
    onApprove(factid,fact);
  }

  const handleClose = () => setOpen(false);
    return (
      <div >
        <Grid container>
          {unapfacts.map((facts,index)=>
          <Grid item 
          xs={12} sm={6} md={4} lg={3} 
          key={index}>
          <div  class="m-1 flip-card" >
          <div class="flip-card-inner">
          <div  className="flip-card-front  " >
          <Card className="py-1" sx={{width:"12rem",height:"9rem",backgroundColor:`${facts.card_color}` }}>
  <div className="mt-1"><img className="imagesize" src={facts.food_image}/></div>
  <h3 className="m-2 text-lg text-[#38A389]">
            {facts.name}
          </h3>
    </Card>
          </div>
          <div  class="flip-card-back">
          <h1 className="text-[#38A389] text-lg">{facts.name}</h1> 
      <p className="text-sm" onClick={()=>handleOpen(facts)}>{facts.title}</p> 
      <CardActions style={{display:'flex', justifyContent: "center" }}>
       <div className="flex justify-center items-center w-7 h-7  rounded-full ml-1 mr-2 transform cursor-pointer bg-[#e8ddf3] hover:text-purple-500 hover:scale-120"> <IconButton onClick={()=>handleapprove(facts.id,facts)} size="small"><CheckIcon sx={{color:'#38A389'}}/></IconButton></div>
       <div className="flex justify-center items-center w-8 h-8  rounded-full mr-2 transform cursor-pointer bg-[#e8ddf3] hover:text-purple-500 hover:scale-120"><IconButton size="small" onClick={handlecommentOpen}><MessageIcon className="mt-1 w-6 h-6" sx={{color:'#A87BD4'}}/></IconButton></div>
       <div className="flex justify-center items-center w-7 h-7  rounded-full mr-2 transform cursor-pointer bg-[#e8ddf3] hover:text-purple-500 hover:scale-120"> <IconButton size="small"><ClearIcon sx={{color:'red'}}/></IconButton> </div>
      </CardActions>
          </div>
          </div>
          </div>
          </Grid>
          )}
           </Grid>
        {commentopen?(
        <Modal
        open={commentopen}
        onClose={handlecommentClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="items-center">
          <form noValidate className='bg-white rounded-sm border-[E3CFF6]'>
                 <div className="mt-4" >
                 <TextField
                  id="message"
                  type='text'
                  InputProps={{
                    style: { borderRadius: '10px' }, 
                  }}
                  label="Enter Message"
                  sx={{height:'42px',borderRadius:'10px'}}
                {...register("message",{required:"Message is required"})}
                  className=" rounded-lg w-full p-2.5"
                />
                 {errors.message && <p className='text-red-500 text-xs'>{errors.message.message}</p>}
</div>
<div className="flex justify-end mt-7">
<Button variant="contained" endIcon={<SendIcon className="w-8 h-8" />} className="text-sm">Send</Button>
</div>
           </form>
          </div>
        </Box>
      </Modal>
      ):(
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center">
            <div>
              <img  src={imge}></img>
            </div>
            <div className="ml-3 justify-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {name}
          </Typography>
          <Typography  id="modal-modal-description" sx={{ mt: 1 }}>
           {content}
          </Typography>
          </div>
          </div>
        </Box>
      </Modal>
      )}
      </div>
    );
  }