import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, LinearProgress } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useState } from "react";
import useStyles from "../../../../assets/css/dietPlan";
import proteinimg from "../../../../assets/images/proteins.png";
import fatimg from "../../../../assets/images/fats.png";
import carbsimg from "../../../../assets/images/carbs.png";
import { useEffect } from "react";
import { useKalorie } from "../../../../userContext/context";
import constants from "../../../../constants.json";
import { Store } from "react-notifications-component";
import PieChartComp from "../../../reusableComponents/donutPieChart";
import axios from "axios";

const IngredientsTracker = ({
  durationtype,
  selectedButtonContent,
  reload,
  rec,
}) => {
  const classes = useStyles();

  const { data, totalCalories, setTotalCal, recCalories, setRecCalories } =
    useKalorie();

  const [proteins, setProteins] = useState(0);
  const [fats, setFats] = useState(0);
  const [carbs, setCarbs] = useState(0);

  const [recommendedCalories, setRecommendedCalories] = useState({});

  const serverIp = process.env.REACT_APP_API_URI;
  const port = process.env.REACT_APP_PORT;
  async function getMealTrackerData() {
    try {
      const response = await fetch(
        `${serverIp}:${port}/api/meal-tracker
        `,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            diet_plan_id: data.diet_plan_id,
            month: 1,
            week: durationtype.charAt(durationtype.length - 1),
            day: selectedButtonContent.index,
          }),
        }
      );

      const result = await response.json();
      console.log("newresponse000", result);
      console.log("reccc", rec);

      if (result.status === true) {
        var protein_percent =
          (result.results[0].total_protein / (rec.proteins * 0.129598)) * 100;
        setProteins(protein_percent);
        var fat_percent =
          (result.results[0].total_fats / (rec.fats * 0.129598)) * 100;
        setFats(fat_percent);
        var carb_percent =
          (result.results[0].total_carbs / (rec.carbs * 0.129598)) * 100;
        setCarbs(carb_percent);

        // setTotalCal(result.results[0].recommended_calories_intake);
      } else {
        // setProteins(0);
        // setFats(0);
        // setCarbs(0);
        console.log("error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }
  }
  useEffect(() => {
    console.log("hiiiiaaa", rec);
    setProteins(0);
    setFats(0);
    setCarbs(0);
    getMealTrackerData();
    console.log("hehe", selectedButtonContent.index);
  }, [durationtype, selectedButtonContent.index, reload]);
  // Use the passed props here
  console.log("Duration Type:", durationtype);
  console.log("Selected Button Content:", selectedButtonContent);
  const ProgressTracker = ({ Label, Value, sliderValue, fillColor }) => {
    const numericSliderValue = parseInt(sliderValue, 10); // Convert sliderValue to a number

    const isOver100 = numericSliderValue > 100;

    // Define a function to determine the color based on the Label
    const getColor = () => {
      switch (Label) {
        case "Proteins":
          return "protein";
        case "Fats":
          return "fats";
        case "Carbs":
          return "carb";

        default:
          return "red"; // Default color if Label doesn't match any case
      }
    };
    const color = getColor();

    useEffect(() => {
      getColor();
    }, [color]);

    // Determine the color based on the Label
    const sanitizedSliderValue =
      isNaN(numericSliderValue) || !isFinite(numericSliderValue)
        ? 0
        : numericSliderValue;

    const getLabelStyle = () => {
      switch (Label) {
        case "Proteins":
          return { color: "#00B569", fontSize: "1.4rem" }; // Protein color
        case "Fats":
          return { color: "#2583EF", fontSize: "1.4rem" }; // Fat color
        case "Carbs":
          return { color: "#FDA101", fontSize: "1.4rem" }; // Carb color
        default:
          return { color: "#000000", fontSize: "1.4rem" }; // Default color if Label doesn't match any case
      }
    };

    const labelStyle = getLabelStyle();
    useEffect(() => {
      const fetchDietDetails = async () => {
        const apiUrl = `${serverIp}:${port}/api/weekly-meal-calories`;
        console.log("im data:::::", data);
        const userObject = JSON.parse(localStorage.getItem("user"));
        const req_data = {
          user_id: userObject.user_id,
          week: durationtype.charAt(durationtype.length - 1),
        };
        await axios
          .post(apiUrl, req_data)
          .then((response) => {
            console.log("user", userObject);
            console.log("response from dietdetails", response.data);
            if (response.status === 200) {
              setTotalCal(response.data.results[0].total_calories);
              console.log(
                "items.total_calories",
                response.data.results[0].total_calories
              );
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      };
      fetchDietDetails();

      if (recCalories - totalCalories < 0) {
        Store.addNotification({
          title: "No more calories left!",
          message: "You've exeeded your dialy recommended calorie intake!",
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 2000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
      if (recCalories - totalCalories == 500) {
        Store.addNotification({
          title: "500 kcal left!",
          message: "You are near your dialy goal of calories",
          type: "warning",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 2000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
    }, [recCalories, totalCalories]);
    return (
      <Container className={classes.alignContainer}>
        <Container className={classes.horizontalContainer}>
          <Typography
            style={labelStyle}
            className={`${classes.labeltext2}${classes[Label]}`}
          >
            {Label}
          </Typography>
          <Typography
            className={isOver100 ? classes.labeltextRed : classes.labeltext2}
          >
            {isOver100 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>
                {`${Math.round(sliderValue)}%`}
              </span>
            ) : (
              `${Math.round(sliderValue)}%`
            )}
          </Typography>
        </Container>

        <LinearProgress
          value={isOver100 ? 100 : numericSliderValue} // Limit value to 100% if over 100
          variant="determinate"
          className={classes.sliderContainer}
          classes={{
            barColorPrimary: isOver100
              ? classes.linearProgressBarRed
              : classes.barColor,
          }}
          sx={{
            "--LinearProgress-radius": "0px",
            "--LinearProgress-progressThickness": "24px",
            boxShadow: "sm",
            borderColor: "neutral.500",
          }}
          thickness={32}
        />
      </Container>
    );
  };
  const barData = [
    (rec.proteins / rec.recommended_calories_intake) * 100,
    (rec.fats / rec.recommended_calories_intake) * 100,
    (rec.carbs / rec.recommended_calories_intake) * 100,
  ];
  const ChartData = [
    {
      color: constants.totalColor,
      title: "total",
      value:
        (rec.recommended_calories_intake / rec.recommended_calories_intake) *
        100,
    },
    {
      color: constants.consumedColor,
      title: "consumed",
      value: (totalCalories / rec.recommended_calories_intake) * 100,
    },
    {
      color: constants.remainingColor,
      title: "remaining",
      value:
        ((rec.recommended_calories_intake - totalCalories) /
          rec.recommended_calories_intake) *
        100,
    },
  ];

  // Set the color for each bar
  const barColors = ["#00B569", "#2583EF", "#FDA101"];
  const barLabels = ["Protein", "Fats", "Carbs"];
  return (
    <Grid container className={classes.topGrid}>
      <Grid
        item
        className={classes.ingredientStackerGrid1}
        spacing={2}
        container
      >
        <Grid item sm={12} md={11} lg={11} className={classes.progressGrid}>
          <Typography className={classes.labeltext3}>
            {constants.progressTracker}
          </Typography>

          <ProgressTracker
            Label={constants.proteins}
            Value={proteins}
            sliderValue={proteins}
          />
          <ProgressTracker
            Label={constants.fats}
            Value={fats}
            sliderValue={fats}
          />
          <ProgressTracker
            Label={constants.carbs}
            Value={carbs}
            sliderValue={carbs}
          />
        </Grid>
        <Grid item sm={12} md={11} lg={11} className={classes.progressGrid}>
          <div className={classes.integredientStackerContainer}>
            <div className={classes.innerContainer1}>
              <Typography className={classes.labeltext}>
                {constants.recommendedDialyCaloriesforYou}:
                {rec.recommended_calories_intake} kcl
              </Typography>
              <div className={classes.barsDiv}>
                {barData.map((height, index) => (
                  <div key={index} className={classes.barContainer}>
                    <div
                      style={{
                        height: `${2.6 * height}px`, // Set the height dynamically
                        width: "30px", // Set the width as per your requirement
                        backgroundColor: barColors[index], // Set the color dynamically
                        margin: "0 8px", // Set margin between bars
                        position: "relative",
                      }}
                    >
                      {" "}
                      <span className={classes.barText}>{height}%</span>
                    </div>
                    <span className={classes.barLabel}>{barLabels[index]}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.innerContainer2}>
              <div className={classes.smallContainer}>
                <img
                  src={proteinimg}
                  alt={constants.proteins}
                  className={classes.imgstyle}
                />
                <div className={classes.textContainer}>
                  <Typography className={classes.text1}>
                    {constants.proteins}
                  </Typography>
                  <Typography className={classes.text2}>
                    {rec.proteins} kcl
                  </Typography>
                </div>
              </div>
              <div className={classes.smallContainer}>
                <div className={classes.textContainer}>
                  <Typography className={classes.fatText}>
                    {constants.fats}
                  </Typography>
                  <Typography className={classes.text2}>
                    {rec.fats} kcl
                  </Typography>
                </div>
                <img
                  src={fatimg}
                  alt={constants.fats}
                  className={classes.imgstyle}
                />
              </div>
              <div className={classes.smallContainer}>
                <img
                  src={carbsimg}
                  alt={constants.carbs}
                  className={classes.imgstyle}
                />
                <div className={classes.textContainer}>
                  <Typography className={classes.carbsText}>
                    {constants.carbs}
                  </Typography>
                  <Typography className={classes.text2}>
                    {rec.carbs} kcl
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item sm={12} md={6} lg={6} className={classes.innerContainer1}>
        {" "}
        <Typography className={classes.chartText1}>
          {constants.totalCalories}:
        </Typography>
        <Typography className={[classes.chartText, classes.totalCal]}>
          {rec.recommended_calories_intake} kcl
        </Typography>
        <Typography className={classes.chartText1}>
          {constants.consumedCalories}:
        </Typography>
        <Typography className={[classes.chartText, classes.consumedCal]}>
          {totalCalories} kcl
        </Typography>
        <Typography className={classes.chartText1}>
          {constants.remainingCalories}:
        </Typography>
        <Typography className={[classes.chartText, classes.remainingCal]}>
          {recCalories - totalCalories} kcl
        </Typography>
        <PieChartComp chartData={ChartData} />
      </Grid>
    </Grid>
  );
};

export default IngredientsTracker;
