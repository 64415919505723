import * as React from "react";
import { useState } from "react";
import profile from "../assets/images/userProfile.png";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems } from "./listItems";
import NotificationCenter from "react-notification-center-component";
import { useNavigate } from "react-router-dom";
import constants from "../constants";
import { useMediaQuery } from "@material-ui/core";
import { TimerOutlined } from "@material-ui/icons";
const drawerWidth = 240;
const userObject = JSON.parse(localStorage.getItem("user"));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "rgba(6, 100, 125, 0.88) !important",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: "fixed", // Set the position to fixed
  top: 0, // Position at the top of the viewport
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: open ? drawerWidth : 0,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const theme = createTheme({
  palette: {
    primary: {
      main: "#38A389",
    },
    secondary: {
      main: "rgba(6, 100, 125, 0.88)",
    },
  },

  root: {
    "&.Mui-selected": {
      backgroundColor: "yellow",
      color: "differentTextColor",
      "&:hover": {
        backgroundColor: "yellow",
      },
    },
  },
});
export default function Dashboard({ children }) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const isMatch = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("md")
  );
  const handleClick = (event) => {
    console.log("wowow", Boolean(event.currentTarget));
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [activeTab, setActiveTab] = useState("Dashboard");
  const handleListItemClick = (event, tabName) => {
    setActiveTab(tabName);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => navigate("/bloggerprofile")}>Profile</MenuItem>
      <MenuItem
        onClick={() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          navigate("/");
          window.location.reload();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton>
          <NotificationCenter
            className="checking"
            appId="R1mZPd6xWo"
            subscriberId={"64e7f7397a784904801d0ad0" + userObject.user_id}
          />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          style={{
            backgroundColor: theme.palette.secondary.main,
            width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
            marginLeft: open ? `${drawerWidth}px` : "0",
            transition: "width 0.3s ease, margin-left 0.3s ease", // Smooth transition
          }}
          position="absolute"
          open={open}
        >
          <Toolbar sx={{ pr: "24px" }}>
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
                className="text-3xl font-bold tracking-wide text-[#FFFFFFE6]"
              >
                {constants.appTitle}
              </p>
            </Typography>
            {!isMatch ? (
              <>
                <MenuItem>
                  <NotificationCenter
                    className="checking"
                    appId="R1mZPd6xWo"
                    subscriberId={
                      "64e7f7397a784904801d0ad0" + userObject.user_id
                    }
                  />
                </MenuItem>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit"
                >
                  <img
                    className="h-12 w-22 rounded-full"
                    src={profile}
                    alt=""
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  id="primary-search-account-menu"
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Link
                      href="/Profile"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <Typography variant="inherit" noWrap>
                        User Settings
                      </Typography>
                    </Link>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      // Remove items from local storage
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("user");

                      // Delay of 500ms before navigating and reloading
                      setTimeout(() => {
                        navigate("/"); // Navigate to the home route
                        window.location.reload(); // Reload the page
                      }, 1500); // 500 milliseconds delay
                    }}
                  >
                    <Typography variant="inherit" noWrap>
                      Sign Out
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <IconButton>
                  <NotificationCenter
                    className="checking"
                    appId="R1mZPd6xWo"
                    subscriberId={
                      "64e7f7397a784904801d0ad0" + userObject.user_id
                    }
                  />
                </IconButton>

                <IconButton onClick={handleClick} color="inherit">
                  <MoreVertIcon style={{ color: "black" }} />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  id="primary-search-account-menu"
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Link
                      href="/ProfileSettings"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <Typography variant="inherit" noWrap>
                        User Settings
                      </Typography>
                    </Link>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("user");
                      navigate("/");
                      window.location.reload();
                    }}
                  >
                    <Typography variant="inherit" noWrap>
                      Sign Out
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
        <div style={{ backgroundColor: theme.palette.secondary.main }}>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                backgroundColor: theme.palette.secondary.main,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List
              style={{ backgroundColor: theme.palette.secondary.main }}
              component="nav"
            >
              <MainListItems
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </List>
          </Drawer>
        </div>
        <Box
          component="main"
          sx={{
            backgroundColor: "white",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            marginLeft: "50px",
            transition: "margin-left 0.3s ease", // Smooth transition
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
