import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  InputAdornment,
  IconButton,
  Input,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useKalorie } from "../../../../userContext/context";
import sha256 from "crypto-js/sha256";
import constants from "../../../../constants.json";
const useStyles = makeStyles((theme) => ({
  outercontainer: {
    width: "50%",
    height: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    height: "100%",
    marginTop: "6rem",
    alignItems: "center",
  },
  iconstyle: {
    color: "#FFD171 !important",
  },
  input: {
    marginBottom: "1rem",
    width: "300px",
    color: "#FFD171",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",
    "&:before": {
      borderColor: "#FFD171",
    },
    "&:after": {
      borderColor: "#FFD171",
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderColor: "#FFD171 !important",
    },
  },
  notchedOutline: {
    borderColor: "#FFD171 !important",
  },
  inputLabel: {
    color: "#FFD171 !important",
    borderColor: "#FFD171",
    fontFamily: constants.fontFamily,
    fontSize: "1.2rem",

    "&.Mui-focused": {
      color: "#FFD171 !important",
      borderColor: "#FFD171",
      fontFamily: constants.fontFamily,
      fontSize: "1rem",
    },
  },

  Icon: {
    color: "#FF7171",
    fontSize: "1.5rem",
    marginRight: "0.5rem",
    marginBottom: "-0.3rem",
  },

  labelContainer: {
    width: "350px",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    marginBottom: "1rem",
  },
  calendarIcon: {
    color: "#FFD171",
    fontSize: "1.5rem",
  },

  labelStyle2: {
    width: "230px",
    borderRadius: "30px",
    border: "2px solid #FFD171",
    color: "#FFD171 ",
    fontFamily: constants.fontFamily,
    fontSize: "1.3rem",
    paddingTop: "0.5rem",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "5rem",
    marginTop: "1rem",
    cursor: "pointer",
    "& $Icon": {
      color: "#FFD171",
    },
  },
}));

const PasswordSection = () => {
  const classes = useStyles();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { data, updateData } = useKalorie();
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    setPasswordData({
      ...passwordData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSaveClick = async (event) => {
    console.log("handle save click");
    console.log(passwordData);

    // Check if newPassword and confirmPassword match
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setErrorMessage("New Password and Confirm Password do not match");
      return;
    }
    const apiUrl = process.env.REACT_APP_API_URL;
    // passwordData.newPassword = sha256(passwordData.newPassword).toString();
    try {
      const response = await fetch(apiUrl + "/api/save-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          old_password: passwordData.oldPassword,
          new_password: sha256(passwordData.newPassword).toString(),
        }),
      });

      const result = await response.json();

      if (result.success === "true") {
        console.log("response", result.message);
      } else {
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenario
    }

    setPasswordData({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setErrorMessage("");
  };

  return (
    <div className={classes.outercontainer}>
      <form noValidate autoComplete="off" className={classes.form}>
        <div>
          <InputLabel
            htmlFor="standard-adornment-oldpassword"
            className={classes.inputLabel}
          >
            Enter Old Password
          </InputLabel>
          <Input
            autoComplete="off"
            id="standard-adornment-oldpassword"
            type={showOldPassword ? "text" : "password"}
            name="oldPassword"
            value={passwordData.oldPassword}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle old password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownPassword}
                  className={classes.iconstyle}
                >
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            className={classes.input}
          />
        </div>
        <div>
          <InputLabel
            htmlFor="standard-adornment-newpassword"
            className={classes.inputLabel}
          >
            Enter New Password
          </InputLabel>
          <Input
            autoComplete="off"
            id="standard-adornment-newpassword"
            type={showNewPassword ? "text" : "password"}
            name="newPassword"
            value={passwordData.newPassword}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle new password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                  className={classes.iconstyle}
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            className={classes.input}
          />
        </div>
        <div>
          <InputLabel
            htmlFor="standard-adornment-confirmpassword"
            className={classes.inputLabel}
          >
            Confirm New Password
          </InputLabel>
          <Input
            autoComplete="off"
            id="standard-adornment-confirmpassword"
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={passwordData.confirmPassword}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  className={classes.iconstyle}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            className={classes.input}
          />
        </div>
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
        <label className={classes.labelStyle2} onClick={handleSaveClick}>
          <SaveIcon className={classes.Icon} />
          Save Changes
        </label>
      </form>
    </div>
  );
};

export default PasswordSection;
