import React, { Component,useState } from "react";
import Tips from '../assets/images/nutritionisttips.png'
import CheckIcon from '@mui/icons-material/Check';
import '../components/reusableComponentsCss/slider.css';
import {  Grid,Card, Modal,Typography,Box } from "@mui/material";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #A87BD4',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};
export default function ApprovedFoodTips({aptips}){
  const [open, setOpen] = useState(false);
  const [content,setcontent]=useState('');
  const handleOpen = (tips) => {
    setcontent(tips.description);
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
    return (
      <div >
         <Grid container 
         >
          {aptips.map((tips,index)=>
            <Grid item xs={12} sm={6} md={4} lg={3} 
             key={index}
            >
          <div onClick={()=>handleOpen(tips)}> 
          <Card className="m-1 ml-3 py-1 hover:shadow-sm" sx={{width:"12rem",height:"9rem",backgroundColor:"#EEC4C4" }}>
  <div className="mt-1"><img className="imagesize" src={Tips}/></div>
  <div className="flex items-center justify-center">
  <h3 className="m-2 text-lg text-[#38A389]">
            {tips.title.split(' ').slice(0,2).join(' ')} 
          </h3>
          <span className="inline mb-2"> 
   <div className=" items-center w-7 h-7 rounded-full mt-1 mr-2 transform bg-[#f1eef3] hover:text-purple-500 hover:scale-120"><CheckIcon sx={{color:'#38A389'}}/></div>
</span>
          </div>
    </Card>
          </div>
          </Grid>
          )}
          </Grid>
        
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center">
          <Typography  id="modal-modal-description" sx={{ mt: 1 }}>
           {content}
          </Typography>
          </div>
        </Box>
      </Modal>
      </div>
    );
  }