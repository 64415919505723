import React from "react";
import constants from "../../../constants.json";
import community1 from "../../../assets/images/community1.png";
import community2 from "../../../assets/images/community2.png";
import community3 from "../../../assets/images/community3.png";
import communityicon1 from "../../../assets/images/communityicon1.png";
import communityicon2 from "../../../assets/images/communityicon2.png";
import communityicon3 from "../../../assets/images/communityicon3.png";
import Header from "../../../components/kalorieKompassComponents/starterpage/headerPage";
import Footer from "../../../components/kalorieKompassComponents/starterpage/footer";
//
import CommunityBackground from "../../../assets/images/CommunityBackground.png";
import { Typography, Container, Button, Grid, Box } from "@material-ui/core/";
import { useNavigate } from "react-router-dom";
import useStyles from "../../../assets/css/style.js";
import { styled } from "@mui/material/styles";
// import constants from "../../../constants.json";
const Wrapper = styled(Box)(({ theme }) => ({
  background: "#1976d2",
  // height: "100vh",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    // background: "orange",
  },
  [theme.breakpoints.down("sm")]: {
    // background: "blue",
  },
  [theme.breakpoints.up("lg")]: {
    // background: "purple",
  },
}));

const Community = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <Wrapper>
        <Container maxWidth={false} disableGutters>
          <Grid
            container
            direction="column"
            xs={12}
            style={{
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
              paddingLeft: "10%",
              paddingRight: "5%",
              backgroundImage: `url(${CommunityBackground})`,
              backgroundColor: "#31525B",
            }}
          >
            <div class="flex">
              <h1 className="text-3xl font-bold  p-3 text-whiteColor max-w-md m-4">
                {constants.communityHeading}{" "}
                <span className="font-medium italic ">
                  {constants.communityHeading2}
                </span>
                <div class=" overflow-hidden border-t-2 border-orangeColor mt-4 max-w-xs w-32"></div>
              </h1>
            </div>

            <div class="flex ">
              <h1 className="text-xl  p-3 text-whiteColor max-w-xl m-4">
                {constants.communitySubHeadingText}
              </h1>
            </div>
            <div className="mb-32 border-4 justify-center items-center text-whiteColor border-orangeColor-box p-3 py-2 m-4 w-32 max-w-md">
              View More
              {/* <button className="btn btn-outline btn-warning btn-xs sm:btn-sm md:btn-md lg:btn-lg">
                
              </button> */}
            </div>
          </Grid>
        </Container>
      </Wrapper>
      <div className="flex flex-wrap justify-center p-3 m-4">
        <div
          style={{ backgroundColor: "#FAF9F2" }}
          className="card w-full sm:w-80 md:w-96 m-2 shadow-xl bg-greyColor p-4"
        >
          <div className="border-1 border-orangeColor-border border-dashed">
            <figure>
              <img src={community1} className="w-full h-auto" />
            </figure>
            <div className="card-body flex flex-col items-center text-center">
              <img src={communityicon1} className="w-24 h-auto" />
              <h2
                style={{ color: "#31525B" }}
                className="card-title text-2xl sm:text-3xl text-primaryColor max-w-full"
              >
                {constants.communityCardHeading1}
              </h2>
              <p className="text-base sm:text-lg">
                {constants.communityCardPara1}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#FAF9F2" }}
          className="card w-full sm:w-80 md:w-96 m-2 shadow-xl bg-greyColor p-4"
        >
          <div className="border-1 border-orangeColor-border border-dashed">
            <figure>
              <img src={community2} className="w-full h-auto" />
            </figure>
            <div className="card-body flex flex-col items-center text-center">
              <img src={communityicon2} className="w-24 h-auto" />
              <h2
                style={{ color: "#31525B" }}
                className="card-title text-2xl sm:text-3xl text-primaryColor max-w-full"
              >
                {constants.communityCardHeading2}
              </h2>
              <p className="text-base sm:text-lg">
                {constants.communityCardPara2}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#FAF9F2" }}
          className="card w-full sm:w-80 md:w-96 m-2 shadow-xl bg-greyColor p-4"
        >
          <div className="border-1 border-orangeColor-border border-dashed">
            <figure>
              <img src={community3} className="w-full h-auto" />
            </figure>
            <div className="card-body flex flex-col items-center text-center">
              <img src={communityicon3} className="w-24 h-auto" />
              <h2
                style={{ color: "#31525B" }}
                className="card-title text-2xl sm:text-3xl text-primaryColor max-w-full"
              >
                {constants.communityCardHeading3}
              </h2>
              <p className="text-base sm:text-lg">
                {constants.communityCardPara3}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Community;
