import React from 'react'
import user from '../assets/images/customer.jpg';
export default function AdminProfile() {
  return (
    <div>
      <div className='flex items-center justify-center'>
       <img className='rounded-full h-40 w-40' 
       src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
       >

       </img>
      </div>
    </div>
  )
}
