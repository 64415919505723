import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Typography,
  FormControl,
  Button,
  InputLabel,
  Input,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useKalorie } from "../../userContext/context";
import { useNavigate } from "react-router-dom";
import constants from "../../constants.json";
import Footer from "../../components/kalorieKompassComponents/starterpage/footer";
import useStyles from "../../assets/css/common.js";
import logo from "../../assets/images/logo.png";

const InfoPage2 = () => {
  const classes = useStyles();

  const { data, updateData } = useKalorie();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    updateData("image", file);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateData(name, value);
  };

  const handleDiabetesChange = (event) => {
    updateData("family_history_of_diabetes", parseFloat(event.target.value));
  };
  const handleHeartChange = (event) => {
    updateData(
      "family_history_of_heart_attack",
      parseFloat(event.target.value)
    );
  };
  useEffect(() => {
    console.log(data); // Log the updated data after state update
  }, [data]); // This effect runs whenever the 'data' state changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isError = false; // Flag to track if there's an error

    const errors = [];

    // Check each field and add error messages to the errors array
    if (data.family_history_of_diabetes === "") {
      errors.push("Please select if you have a family history of diabetes");
    }

    if (data.family_history_of_heart_attack === "") {
      errors.push(
        "Please select if you have a family history of heart problems"
      );
    }

    if (!data.gym_walk_routine) {
      errors.push(
        "Please enter your gym or walk routine, if you have none, please type none"
      );
    }

    if (!data.dine_out_habits) {
      errors.push(
        "Please enter your dine out habits, if you have none, please type none"
      );
    }

    // If there are errors, set the first error as the error message
    if (errors.length > 0) {
      setError(errors[0]);
    } else {
      // If no errors, reset the error state and navigate to the next page
      setError("");
      navigate("/dietaryHabits");
    }
  };
  const goback = () => {
    navigate(-1);
  };
  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={classes.outercontainer_info2}
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            variant="h4"
            className={classes.text1}
          >
            <img
              style={{ marginRight: "10px" }}
              src={logo}
              alt="Logo is missing"
            />
            {constants.appTitle}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon size="small" />}
            className={classes.buttonstyle3}
            onClick={goback}
          >
            Back
          </Button>
        </Grid>
        <Grid item container>
          <Grid item className={classes.triangleRight}></Grid>
          <Grid item className={classes.triangleLeft}></Grid>
          <Grid className={classes.outerGrid} container>
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              className={`${classes.mainContainer} ${classes.familyHistoryContainer}`}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit} // This handles the form submission
                className={classes.innerBox}
              >
                <div>
                  <div>
                    <FormControl variant="standard">
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        className={[classes.inputLabel1]}
                      >
                        Do you have a family history of diabeties?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        //   defaultValue="1"
                        name="radio-buttons-group"
                        onChange={handleDiabetesChange}
                      >
                        <FormControlLabel
                          className={classes.margincontainer_info3}
                          value="1"
                          control={
                            <Radio
                              size="small"
                              classes={{ root: classes.radio_info2 }}
                            />
                          }
                          label="Yes"
                          classes={{ label: classes.labelstyle_info2 }}
                        />
                        <FormControlLabel
                          className={classes.margincontainer_info3}
                          value="0"
                          label="No"
                          control={
                            <Radio
                              size="small"
                              classes={{ root: classes.radio_info2 }}
                            />
                          }
                          classes={{ label: classes.labelstyle_info2 }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl variant="standard" sx={{ m: 1 }}>
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        className={[classes.inputLabel1]}
                      >
                        Do you have a family history of heart problems?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        //   defaultValue="1"
                        name="radio-buttons-group"
                        onChange={handleHeartChange}
                      >
                        <FormControlLabel
                          className={classes.margincontainer_info3}
                          value="1"
                          control={
                            <Radio
                              size="small"
                              classes={{ root: classes.radio_info2 }}
                            />
                          }
                          label="Yes"
                          classes={{ label: classes.labelstyle_info2 }}
                        />
                        <FormControlLabel
                          className={classes.margincontainer_info3}
                          value="0"
                          label="No"
                          control={
                            <Radio
                              size="small"
                              classes={{ root: classes.radio_info2 }}
                            />
                          }
                          classes={{ label: classes.labelstyle_info2 }}
                          sx={{ m: 1, width: "25ch" }}
                          variant="standard"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-firstname"
                      className={classes.inputLabel} // Apply custom styles to the label
                    >
                      Any exercise or walk routine?
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-firstname"
                      type="text"
                      name="gym_walk_routine"
                      value={data.gym_walk_routine}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel
                      htmlFor="standard-adornment-firstname"
                      className={classes.inputLabel} // Apply custom styles to the label
                    >
                      Dine out habits
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      id="standard-adornment-firstname"
                      type="text"
                      name="dine_out_habits"
                      value={data.dine_out_habits}
                      onChange={handleChange}
                      className={classes.input} // Apply custom styles to the input
                    />
                  </FormControl>
                </div>
                {error && (
                  <Typography className={classes.errorText}>{error}</Typography>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  align="center"
                  className={[classes.buttonstyle_info2, classes.continueStyle]}
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage2;
