import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Container, Box, Typography, Button } from "@material-ui/core";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import profilepic from "../../../../assets/images/profilepic.JPG";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import constants from "../../../../constants.json";
const useStyles = makeStyles((theme) => ({
  outercontainer: {
    width: "40%",
    height: "75%",
    // border: "2px solid #A87DB4",
    marginTop: "3rem",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "87vh",
    // border: "2px solid #A87DB4",
    backgroundColor: "white",
    marginTop: "auto",
    borderTopLeftRadius: "100px",
    zIndex: 1,
  },
  innercontainer: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    height: "100%",
    // border: "2px solid #A87DB4",
  },
  innercontainer2: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    height: "100%",
    // border: "2px solid #A87DB4",
  },
  HeaderText: {
    color: "#FF7171 !important", // Customize placeholder color
    fontFamily: constants.fontFamily,
    fontSize: "1.8rem",
    margin: "3rem",
  },
  buttonstyle: {
    width: "250px",
    height: "40px",
    alignSelf: "center",
    marginTop: "1.5rem",

    borderRadius: "30px",
    border: "2px solid #FFD171",
    color: "#FFD171 ", // Customize placeholder color
    fontFamily: constants.fontFamily,
    fontSize: "1.1rem",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    // paddingLeft: "1.2rem",
    cursor: "pointer",
    paddingTop: "0.5rem",
  },
  buttonColor: {
    border: "2px solid #FF7171",
    color: "#FF7171 ",
    height: "45px",
  },
  buttonColor2: {
    border: "2px solid white",
    color: "#FF7171",
  },

  iconstyle: {
    fontSize: "1.7rem",
    marginRight: "0.5rem",
    marginBottom: "-0.2rem",
  },
  iconstyle2: {
    fontSize: "1.7rem",
    marginRight: "0.2rem",
  },
  profilepicstyle: {
    width: "250px",
    height: "250px",
    borderRadius: "40px",
    border: "1px solid #FFD171",
    marginTop: "0rem",
    alignSelf: "center",
  },
}));

const ProfilePic = () => {
  const classes = useStyles();
  const [avatar, setAvatar] = useState(null);
  const navigate = useNavigate();
  const handleAvatarChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setAvatar(url);
    }
  };

  const handleDeleteAvatar = () => {
    setAvatar(null);
  };
  const handleLogout = () => {
    //localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <>
      <div className={classes.outercontainer}>
        <img
          src={avatar || profilepic} // Display selected avatar or default
          alt="profilepic"
          className={classes.profilepicstyle}
        />
        <label className={classes.buttonstyle}>
          <AddAPhotoIcon className={classes.iconstyle} />
          Change Avatar
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleAvatarChange}
          />
        </label>
        <Button
          className={[classes.buttonstyle, classes.buttonColor]}
          onClick={handleDeleteAvatar}
        >
          <DeleteOutlineIcon className={classes.iconstyle2} />
          Delete Avatar
        </Button>
        {/* <Button
          className={[classes.buttonstyle, classes.buttonColor2]}
          onClick={handleLogout}
        >
          <ExitToAppIcon className={classes.iconstyle2} />
          Log Out
        </Button> */}
      </div>
    </>
  );
};

export default ProfilePic;
